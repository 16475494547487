import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { headerTabs } from "./mockData";
import Search_Modal from "./Search_Modal";
import Authenticate_Dialogue from "../../src/common/Authenticate_Dialogue";

const Header = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const title = "Are you sure you want to log out ?";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <header className="ib-header header-default header-fixed header--sticky fluid-header">
        <div className="header-inner d-flex align-items-center justify-content-between">
          <div className="header-left d-flex align-items-center">
            <div className="logo-wrapper">
              <Link to="/" className="logo logo-light">
                <img src="/images/logo.png" alt="brand" />{" "}
              </Link>
              <Link to="/live" className="btn btn_live">
                Live<span className="live-icon"></span>
              </Link>
            </div>
          </div>
          <div className="header-right d-flex align-items-center">
            <ul className="headerNav">
              {headerTabs?.map((item) => {
                return (
                  <li
                    key={item.route}
                    className={` ${
                      location.pathname === item.route && "active"
                    }`}
                  >
                    <Link to={item.route}>{item.title} </Link>
                  </li>
                );
              })}
            </ul>
            <ul className="header-right-inner">
              <li className="wallet-button">
                {" "}
                <Link
                  to="/brand-register"
                  className="btn btn-gradient btn-small"
                >
                  <span>
                    <i className="ri-wallet-3-line"></i>Register Your Brand{" "}
                  </span>
                </Link>
              </li>
              <li className="setting-option mobile-menu-bar d-block d-xl-none">
                <button className="hamberger-button">
                  <i className="ri-menu-2-fill"></i>
                </button>
              </li>
             
               {localStorage.getItem('checkbrand-token') ? 
                <li className="avatar-info">
                <Link to={location.pathname}>
                  <img src="/images/avatar/user.png" alt="user avatar" />
                </Link>
                <ul className="submenu">
                  <li>
                    <Link to="/profile">
                      <i className="ri-user-line"></i> Profile
                    </Link>
                  </li>
                  <li>
                    <Link to={location.pathname + `#`}>
                      <i className="ri-edit-line"></i> Create Item
                    </Link>
                  </li>
                  <li>
                    <Link to={location.pathname + `#`}>
                      <i className="ri-layout-grid-line"></i>Authors
                    </Link>
                  </li>
                    <li>
                      <Link onClick={() => setOpen(true)}>
                        <i className="ri-logout-box-r-line"></i>Log out
                      </Link>
                    </li>
                </ul>
              </li> :
                <li className="searchBoxIcon"><Link to="/login">
                <i
                  className="ri-login-box-line" style={{color:'white'}}
                ></i>
                </Link></li>
                }
               
              
              <li className="searchBoxIcon">
                <i
                  className="ri-search-line"
                  data-bs-toggle="modal"
                  data-bs-target="#searchModal"
                ></i>
              </li>
              <li>
                <div
                  className="menu-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#sidemenuToggle"
                >
                  <span></span> <span></span> <span></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <Search_Modal />
      <Authenticate_Dialogue open={open} setOpen={setOpen} title={title} />
    </>
  );
};

export default Header;
