import React, { useEffect, useState } from "react";
import { reqData } from "./api";
import axios from "axios";
import { Link } from "react-router-dom";

const Search_Modal = () => {
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchLoading, setSearchLoading] = useState();
  const [categoryData, setCategoryData] = useState();
  const [catId, setCatId] = useState([1]);
  const [sortedData, setSortedData] = useState();
  const [loading, setLoading] = useState();
  const categoryApi = process.env.REACT_APP_CATEGORIES;
  const searchApi = `https://api.frontend.checkbrand.online/public/api/v1/search_results?search=${search}&cat=${catId.join(
    "-"
  )}`;
  function getData() {
    reqData(categoryApi, setCategoryData, "category_data", "", setLoading);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (categoryData) {
      const data = categoryData?.categories.sort((a, b) => {
        return a.id - b.id;
      });
      setSortedData(data);
    }
  }, [categoryData]);

  const handleChange = (e) => {
    // console.log("Reached", e.target.value);
    setSearch(e.target.value);
  };

  const handleCategory = (id) => {
    if (catId.includes(id)) {
      setCatId(catId.filter((cat) => cat !== id));
    } else {
      setCatId([...catId, id]);
    }
  };

  useEffect(() => {
    let timer;
    const debounceSearch = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setSearchLoading(true);
        axios
          .get(`${searchApi}`)
          .then((res) => {
            console.log("Response is", res.data);
            setSearchLoading(false);
            setSearchData(res.data);
          })
          .catch((error) => {
            console.log("Error is", error);
          });
      }, 1000);
    };
    if (search.length > 0) {
      debounceSearch();
    }
    else if(search.length == 0){
      setSearchLoading(false)
      setSearchData([])
    }
    return () => clearTimeout(timer);
  }, [search, catId]);

  return (
    <>
      <div
        className="modal fade popup searchModel"
        id="searchModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="bg-video-wrap">
            <video
              src="https://checkbrand.online/frontend/videos/CBsearchVideos.mp4"
              loop
              muted
              autoPlay
              controls
            ></video>
            <div className="overlay"></div>
            <div className="modal-content">
              <div className="modal-body">
                <div className="row align-items-center">
                  <div className="col">
                    <form id="modal-contact-form-data">
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <div className="SearchMain">
                        <img
                          src="https://checkbrand.online/frontend/images/Searchlogo.png"
                          className="SearchLogo"
                          alt="Search Logo"
                        />
                        <div className="searchTxt">
                          Check Your Digital Rank and Score
                        </div>
                        <ul className="searchByPanel">
                          {sortedData?.map((item) => {
                            return (
                              <li
                                key={item.id}
                                onClick={() => handleCategory(item.id)}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                }}
                              >
                                <a
                                  className={`${
                                    catId.includes(item.id) && "active"
                                  }`}
                                >
                                  {item.name}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <input
                          type="hidden"
                          name="selected_cat_ids"
                          id="selected_cat_ids"
                          value=""
                        />
                        <div className="search-form-input">
                          <input
                            className="raven-search-form-input ui-autocomplete-input"
                            type="search"
                            name="search_box"
                            placeholder="Search"
                            value={search}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          <button
                            type="submit"
                            className="mainscreen-form__btn"
                          ></button>
                        </div>
                        {searchLoading && (
                          <ul
                            className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content ui-corner-all"
                            id="ui-id-1"
                            tabindex="0"
                            style={{ width: "801px" }}
                          >
                            <li
                              id="ui-id-27"
                              className="ui-corner-all"
                              tabindex="-1"
                            >
                              loading...
                            </li>
                          </ul>
                        )}
                        {searchData.length > 0 && (
                          <ul
                            className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content ui-corner-all"
                            id="ui-id-1"
                            tabindex="0"
                            style={{ width: "801px" }}
                          >
                            {searchData?.map((item) => {
                              return (
                                <Link
                                  key={item.url}
                                  className="ui-menu-item"
                                  role="presentation"
                                  to={`/dashboard/${item.url}`}
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <li
                                    id="ui-id-27"
                                    className="ui-corner-all"
                                    tabindex="-1"
                                  >
                                    {item.label}
                                  </li>
                                </Link>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search_Modal;
