export const data = {
  websites: [
    {
      name: "accounts.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=accounts.google.com&t=2&s=1&h=e7bbf324f8b1fece5635282a0abeb31cd2f132bd1c09b3aed8dedaf78d53afe1",
      isVirtual: false,
    },
    {
      name: "amazon.com",
      image:
        "https://site-images.similarcdn.com/image?url=amazon.com&t=2&s=1&h=43a07f575342a8fbb74cd3ca3ebf33760f2fbfbff603546fb4f7208244147dcc",
      isVirtual: false,
    },
    {
      name: "accuweather.com",
      image:
        "https://site-images.similarcdn.com/image?url=accuweather.com&t=2&s=1&h=9187eee86ff3830362647044a9df61d120227fa5016e01840a05005a662aad79",
      isVirtual: false,
    },
    {
      name: "apple.com",
      image:
        "https://site-images.similarcdn.com/image?url=apple.com&t=2&s=1&h=f81bb484ae2fd14ee7d230f4992fcf6f0e071e846a94e3f49400616e3f9c5063",
      isVirtual: false,
    },
    {
      name: "amazon.co.jp",
      image:
        "https://site-images.similarcdn.com/image?url=amazon.co.jp&t=2&s=1&h=c45fedff53f96bbf8ccb7d2d22ef189477b06d9ca83a590d2f039b1bf74cffb4",
      isVirtual: false,
    },
    {
      name: "aajtak.in",
      image:
        "https://site-images.similarcdn.com/image?url=aajtak.in&t=2&s=1&h=7e8a86d5b79cd67b5a3c7f5e6c71ced10008d6e8d1185ccee1321447a0675339",
      isVirtual: false,
    },
    {
      name: "auth0.openai.com",
      image:
        "https://site-images.similarcdn.com/image?url=auth0.openai.com&t=2&s=1&h=3704ee822f998f423a2e387b55e53df0e280c3bc4f5186ee624b4e4829c604c8",
      isVirtual: false,
    },
    {
      name: "aliexpress.com",
      image:
        "https://site-images.similarcdn.com/image?url=aliexpress.com&t=2&s=1&h=d276f202957fc1992170305299cf0078603f7f931e2158aa3ab6097731f1ed40",
      isVirtual: false,
    },
    {
      name: "adclick.g.doubleclick.net",
      image:
        "https://site-images.similarcdn.com/image?url=adclick.g.doubleclick.net&t=2&s=1&h=47b2356a164895f5e8942a9ac5be2e0ed3dd2035dda8b9459638e30aa5f5def1",
      isVirtual: false,
    },
    {
      name: "apps.samsung.com",
      image:
        "https://site-images.similarcdn.com/image?url=apps.samsung.com&t=2&s=1&h=804d8324e11dd03273124423faf01589789d0d9b5e9736f00dc76c0b2686e510",
      isVirtual: false,
    },
    {
      name: "chat.openai.com",
      image:
        "https://site-images.similarcdn.com/image?url=chat.openai.com&t=2&s=1&h=8fee1f5260ec149945b968a7f2f87ff79212fe24dea022cb9d1fa76966b65221",
      isVirtual: false,
    },
    {
      name: "calendar.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=calendar.google.com&t=2&s=1&h=76cbf5886d2ceca045b40a37b67de84af05e30d4d86570d6b1624d950f97d7d0",
      isVirtual: false,
    },
    {
      name: "cnn.com",
      image:
        "https://site-images.similarcdn.com/image?url=cnn.com&t=2&s=1&h=8d77c5bef2bd0070b09808da0d30f46988a802ce7bc113dc46a86e5979f5de5b",
      isVirtual: false,
    },
    {
      name: "chaturbate.com",
      image:
        "https://site-images.similarcdn.com/image?url=chaturbate.com&t=2&s=1&h=1332512fcd9678951da64ea326b3bcc9c2ec638fc124c24fa750d9f9d62e9aec",
      isVirtual: false,
    },
    {
      name: "canva.com",
      image:
        "https://site-images.similarcdn.com/image?url=canva.com&t=2&s=1&h=76c69b938e0fc8e4bebe1e545dba8f4ad7bd78aec8c65f149f36030ee1036a65",
      isVirtual: false,
    },
    {
      name: "crmpt.livejasmin.com",
      image:
        "https://site-images.similarcdn.com/image?url=crmpt.livejasmin.com&t=2&s=1&h=d1ed0e5b3aa407a50de4e910ce0502ee0a431a93ea0f6f5da938aab792293379",
      isVirtual: false,
    },
    {
      name: "chrome.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=chrome.google.com&t=2&s=1&h=bde228006fa511ac0e01ca7404d52e89f9743e6515d4b2aa865b2d6467285e84",
      isVirtual: false,
    },
    {
      name: "classroom.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=classroom.google.com&t=2&s=1&h=3dc0adf3dfeafc001b4a6e1a20c7a6dc9113f4c186ba2b5b79f49d4264062269",
      isVirtual: false,
    },
    {
      name: "canadapost-postescanada.ca",
      image:
        "https://site-images.similarcdn.com/image?url=canadapost-postescanada.ca&t=2&s=1&h=1d95300340f30d72358c791f8c0848567d6e60a5fee318501c8d86d3ec47cd5d",
      isVirtual: false,
    },
    {
      name: "criteo.com",
      image:
        "https://site-images.similarcdn.com/image?url=criteo.com&t=2&s=1&h=8e7b4e18edf8161ce54e81a1c9250810c0878cc83f246b1a1faabafd03bcd315",
      isVirtual: false,
    },
    {
      name: "docs.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=docs.google.com&t=2&s=1&h=0aab41c700d49dad2edb44243116b177685cc209ce877947385723a54baf680a",
      isVirtual: false,
    },
    {
      name: "drive.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=drive.google.com&t=2&s=1&h=754fae1bb338f62771de64117d1d78fc084195207c806a70294137b9adebfdd7",
      isVirtual: false,
    },
    {
      name: "docomo.ne.jp",
      image:
        "https://site-images.similarcdn.com/image?url=docomo.ne.jp&t=2&s=1&h=16a87fc9a45cb81edf2e6ba66104b6480c2f443bb36d5ed521eb5935c1d45f53",
      isVirtual: false,
    },
    {
      name: "discord.com",
      image:
        "https://site-images.similarcdn.com/image?url=discord.com&t=2&s=1&h=133c0aed0b1b6c3760db4875f51accd42ff1157854dd0ee0eabab700af80a348",
      isVirtual: false,
    },
    {
      name: "doubleclick.net",
      image:
        "https://site-images.similarcdn.com/image?url=doubleclick.net&t=2&s=1&h=7d038da3d6f5b4c4d3a446f5cc82f3c5568122bcf5c905db87b0e804d31ebb64",
      isVirtual: false,
    },
    {
      name: "dzen.ru",
      image:
        "https://site-images.similarcdn.com/image?url=dzen.ru&t=2&s=1&h=76e674010f5f1d4ba0f2d8749e9402436d316a06483f5e583234a3df27fc1291",
      isVirtual: false,
    },
    {
      name: "duckduckgo.com",
      image:
        "https://site-images.similarcdn.com/image?url=duckduckgo.com&t=2&s=1&h=dff2821cfcef1d36cdbbf5a78aa7eac7d5da8ef335fa460cfd2cc6180c60aa2a",
      isVirtual: false,
    },
    {
      name: "dailymail.co.uk",
      image:
        "https://site-images.similarcdn.com/image?url=dailymail.co.uk&t=2&s=1&h=163dde613d0992e84a49733622183531d7612fae3a989a281d931914c9a70bd6",
      isVirtual: false,
    },
    {
      name: "disneyplus.com",
      image:
        "https://site-images.similarcdn.com/image?url=disneyplus.com&t=2&s=1&h=4f29705468e3b90435f6b2a0280ce436b0f177dfbab9dbc533ed94616fbe68cf",
      isVirtual: false,
    },
    {
      name: "dsg.bio",
      image:
        "https://site-images.similarcdn.com/image?url=dsg.bio&t=2&s=1&h=3facbfd3270ae6ecfd007fa4aa8101492ca24fae1fe31c90c789400f8c02027a",
      isVirtual: false,
    },
    {
      name: "en.m.wikipedia.org",
      image:
        "https://site-images.similarcdn.com/image?url=m.wikipedia.org&t=2&s=1&h=5040a1c3bb2e7bdb91e263d8ac4f4f28369a271ee39654275a06b6e05be3cbd6",
      isVirtual: false,
    },
    {
      name: "en.wikipedia.org",
      image:
        "https://site-images.similarcdn.com/image?url=wikipedia.org&t=2&s=1&h=460eb7e23fbc3666abf9d8b5497c3ca33d1de8ff9c66dd5d182c7633fe475c61",
      isVirtual: false,
    },
    {
      name: "ebay.com",
      image:
        "https://site-images.similarcdn.com/image?url=ebay.com&t=2&s=1&h=7f6f9f419ebf2319e686381f48e1ea1bbb2f25fc404930fdfbb3a60985cb6695",
      isVirtual: false,
    },
    {
      name: "etsy.com",
      image:
        "https://site-images.similarcdn.com/image?url=etsy.com&t=2&s=1&h=bd66ff8813252ef97c25e177aab219d2b5499741697455eacd4fe7a819b35d1c",
      isVirtual: false,
    },
    {
      name: "es.pornhub.com",
      image:
        "https://site-images.similarcdn.com/image?url=es.pornhub.com&t=2&s=1&h=698a217fbfd005d75ca654952edd07a7a6103e329a8d77493a0687972f179080",
      isVirtual: false,
    },
    {
      name: "e.mail.ru",
      image:
        "https://site-images.similarcdn.com/image?url=e.mail.ru&t=2&s=1&h=1362498dc9378ec868f92e556b5d8903a9399f40366b57550592b26e954351c2",
      isVirtual: false,
    },
    {
      name: "es.m.wikipedia.org",
      image:
        "https://site-images.similarcdn.com/image?url=es.m.wikipedia.org&t=2&s=1&h=b26a3fc25bd44d8a6281be1ef31cc57874bbd895e24adeebe84e88d46c14a1a0",
      isVirtual: false,
    },
    {
      name: "espn.com",
      image:
        "https://site-images.similarcdn.com/image?url=espn.com&t=2&s=1&h=bdc882e84af990d955fb65dbb65290ab6cd2ff9b3b8cb19a71c689fe7409a571",
      isVirtual: false,
    },
    {
      name: "eporner.com",
      image:
        "https://site-images.similarcdn.com/image?url=eporner.com&t=2&s=1&h=9fa3967dc61d06d2b0514b735e35cda3f24d9331d14db780ca34fa8eddb2b031",
      isVirtual: false,
    },
    {
      name: "ebay.co.uk",
      image:
        "https://site-images.similarcdn.com/image?url=ebay.co.uk&t=2&s=1&h=5846db8049aa85923ea54a349e9cfab924c6db7a4288e512561d624fde5b672a",
      isVirtual: false,
    },
    {
      name: "facebook.com",
      image:
        "https://site-images.similarcdn.com/image?url=facebook.com&t=2&s=1&h=be773d6b77aa3d59b6a671c5c27ad729b1ae77400e89776e2f749cce6b926c4b",
      isVirtual: false,
    },
    {
      name: "fandom.com",
      image:
        "https://site-images.similarcdn.com/image?url=fandom.com&t=2&s=1&h=285c419cea12e9eca78123e80a368fd3a390e10303a3c4ffb70447e980df3212",
      isVirtual: false,
    },
    {
      name: "foxnews.com",
      image:
        "https://site-images.similarcdn.com/image?url=foxnews.com&t=2&s=1&h=38cb679f6039f10f4437be7a64a136200a3d7361c2c18537258d48e26fd7285f",
      isVirtual: false,
    },
    {
      name: "fc2.com",
      image:
        "https://site-images.similarcdn.com/image?url=fc2.com&t=2&s=1&h=b4a03f8407a64dc84ee36a87d67b9f6428bdf6b7d4f83c7cbcdd1a2e4ae65b8f",
      isVirtual: false,
    },
    {
      name: "faq.whatsapp.com",
      image:
        "https://site-images.similarcdn.com/image?url=faq.whatsapp.com&t=2&s=1&h=41d14ebf8e15aa4c2312b487df05d89080c9fd77dc20f9a3d088f916052e3365",
      isVirtual: false,
    },
    {
      name: "flipkart.com",
      image:
        "https://site-images.similarcdn.com/image?url=flipkart.com&t=2&s=1&h=30513a2e21705ad598a9d16dfffece6207f5b4b7e4666af8d8dfc3bb93ab61ca",
      isVirtual: false,
    },
    {
      name: "finance.yahoo.com",
      image:
        "https://site-images.similarcdn.com/image?url=finance.yahoo.com&t=2&s=1&h=7a34f06e6425581896f08e1b0dee2ccb135f733463564422b8a5f6570b2ce135",
      isVirtual: false,
    },
    {
      name: "fr.pornhub.com",
      image:
        "https://site-images.similarcdn.com/image?url=fr.pornhub.com&t=2&s=1&h=e3fddfdfd7b9aae9cb8583066933b6e4d281715da092aaaec27f0b9bb981e7b4",
      isVirtual: false,
    },
    {
      name: "forbes.com",
      image:
        "https://site-images.similarcdn.com/image?url=forbes.com&t=2&s=1&h=bbdcb4590dab74191aa655e2a887b4b34c7b129876caf2279ec2b35dcc5847dd",
      isVirtual: false,
    },
    {
      name: "fedex.com",
      image:
        "https://site-images.similarcdn.com/image?url=fedex.com&t=2&s=1&h=4126e66fe26beaff66dfb358f15809f8e4c0b8ca0c1e8a5b40730477b7a4d79b",
      isVirtual: false,
    },
    {
      name: "google.com",
      image:
        "https://site-images.similarcdn.com/image?url=google.com&t=2&s=1&h=eb35e0fbafa3eb290132ffdfe47187d950d68daaa659ec1bd4b4e834b8f16461",
      isVirtual: false,
    },
    {
      name: "googleadservices.com",
      image:
        "https://site-images.similarcdn.com/image?url=googleadservices.com&t=2&s=1&h=c0cca2b8c0cd2920ad4634ef4be51888a836900c2d6797062b88048290451fba",
      isVirtual: false,
    },
    {
      name: "g.doubleclick.net",
      image:
        "https://site-images.similarcdn.com/image?url=g.doubleclick.net&t=2&s=1&h=d57b3f3b3f1a3f7f09b0bbbb96455b3e2fa1c5e17970eb69b81039acece803df",
      isVirtual: false,
    },
    {
      name: "globo.com",
      image:
        "https://site-images.similarcdn.com/image?url=globo.com&t=2&s=1&h=d43aa75cabc939383c66271b0de2de1cce20a20180a27cb130bf5d699031211e",
      isVirtual: false,
    },
    {
      name: "google.com.br",
      image:
        "https://site-images.similarcdn.com/image?url=google.com.br&t=2&s=1&h=f504c8926f9f375805be71b441f7e0075edd929c948130d744438f373adaf17c",
      isVirtual: false,
    },
    {
      name: "godpvqnszo.com",
      image:
        "https://site-images.similarcdn.com/image?url=godpvqnszo.com&t=2&s=1&h=91fd7fed9c7f3dc039caed94ff4b54f7ef42e8f23fec10a54587b10b08508644",
      isVirtual: false,
    },
    {
      name: "googleads.g.doubleclick.net",
      image:
        "https://site-images.similarcdn.com/image?url=googleads.g.doubleclick.net&t=2&s=1&h=3b1a6852d05c4a11cf40962febfc1e474046d8337d13e06e1ffad5fa10016fd5",
      isVirtual: false,
    },
    {
      name: "github.com",
      image:
        "https://site-images.similarcdn.com/image?url=github.com&t=2&s=1&h=6525c30f1af9ea5ee8912788b611744e1debcba39a2d0dd3d410b2916169883b",
      isVirtual: false,
    },
    {
      name: "google.de",
      image:
        "https://site-images.similarcdn.com/image?url=google.de&t=2&s=1&h=473e6b51dba1ccd6fb40f8eb77cab04ca43adfde99d5232b7c67ac03b0ba266a",
      isVirtual: false,
    },
    {
      name: "homedepot.com",
      image:
        "https://site-images.similarcdn.com/image?url=homedepot.com&t=2&s=1&h=f574b769e5b693728923863fd746e4a2838fa8620d05ee41f115274f01838015",
      isVirtual: false,
    },
    {
      name: "healthline.com",
      image:
        "https://site-images.similarcdn.com/image?url=healthline.com&t=2&s=1&h=14a6bf5cae4cfb8535bcfee52be531389098dda721a7d81d019db9e9844b2626",
      isVirtual: false,
    },
    {
      name: "hotstar.com",
      image:
        "https://site-images.similarcdn.com/image?url=hotstar.com&t=2&s=1&h=0c708daf95c65a61711bc84fa306c644dab5248d705a38035e60f97f1293d22c",
      isVirtual: false,
    },
    {
      name: "hindustantimes.com",
      image:
        "https://site-images.similarcdn.com/image?url=hindustantimes.com&t=2&s=1&h=a16885741015eecaf08ce41dd1d914053acc7aed68297ba9647c21e787da6908",
      isVirtual: false,
    },
    {
      name: "haokan.baidu.com",
      image:
        "https://site-images.similarcdn.com/image?url=haokan.baidu.com&t=2&s=1&h=8e681e13c5f5b131fa552ee00b8ca22249f49d5115f1a46066f1c742da00038a",
      isVirtual: false,
    },
    {
      name: "hurriyet.com.tr",
      image:
        "https://site-images.similarcdn.com/image?url=hurriyet.com.tr&t=2&s=1&h=dab349f295258eb2365b5995c88c1b1c3a07d97ec09ceb8facac7e28cfb43bed",
      isVirtual: false,
    },
    {
      name: "hulu.com",
      image:
        "https://site-images.similarcdn.com/image?url=hulu.com&t=2&s=1&h=a7e1959613ec40448b34f4446efc4fd5442f150686995704b295cb09b4d0d99a",
      isVirtual: false,
    },
    {
      name: "hm.com",
      image:
        "https://site-images.similarcdn.com/image?url=hm.com&t=2&s=1&h=5ddeab8c146081ba0873c597e27cbef2b4e127c71d068c0ab738ec30a57c0688",
      isVirtual: false,
    },
    {
      name: "hbomax.com",
      image:
        "https://site-images.similarcdn.com/image?url=hbomax.com&t=2&s=1&h=2bb4cf11567ff796ffc819417443a4198118ea66cbdd6fcf40ba6521596cb80c",
      isVirtual: false,
    },
    {
      name: "hqporner.com",
      image:
        "https://site-images.similarcdn.com/image?url=hqporner.com&t=2&s=1&h=a5947bf615cd7fc1a828a7c07552d48677d78ada5609228479b4a5d7db27efce",
      isVirtual: false,
    },
    {
      name: "instagram.com",
      image:
        "https://site-images.similarcdn.com/image?url=instagram.com&t=2&s=1&h=acbe9ff09f263f0114ce8473b3d3e401bfdc360d5aff845e04182e8d2335ba49",
      isVirtual: false,
    },
    {
      name: "indeed.com",
      image:
        "https://site-images.similarcdn.com/image?url=indeed.com&t=2&s=1&h=6934fa9f350ce0af40d09bf8e2b5250854df6e550108eb6740545a2bc9715ec7",
      isVirtual: false,
    },
    {
      name: "imdb.com",
      image:
        "https://site-images.similarcdn.com/image?url=imdb.com&t=2&s=1&h=678a62224cd21e08f46ddef929ff72ae022d1025a427237155a87041bdcd3960",
      isVirtual: false,
    },
    {
      name: "infobae.com",
      image:
        "https://site-images.similarcdn.com/image?url=infobae.com&t=2&s=1&h=21edf2d5f301df128b93b5797a27c8ad4e362f4cf83f01830d346a68ac5ad687",
      isVirtual: false,
    },
    {
      name: "item.rakuten.co.jp",
      image:
        "https://site-images.similarcdn.com/image?url=item.rakuten.co.jp&t=2&s=1&h=958ca3a4f091ec8887f7da8bc64930ca9c35a200831ec9eb036e3309ba4b45b7",
      isVirtual: false,
    },
    {
      name: "ikea.com",
      image:
        "https://site-images.similarcdn.com/image?url=ikea.com&t=2&s=1&h=fdd9b1dfe36a63ed219642c283b824dddead6d785cb29de803b82f53f4bb9712",
      isVirtual: false,
    },
    {
      name: "indiatimes.com",
      image:
        "https://site-images.similarcdn.com/image?url=indiatimes.com&t=2&s=1&h=fdb44b216ea2297879e476abfcab5c51a75ae12f51f3d53fb887d125010ce416",
      isVirtual: false,
    },
    {
      name: "interia.pl",
      image:
        "https://site-images.similarcdn.com/image?url=interia.pl&t=2&s=1&h=e1cb11e626d8bd605a14c2688bb95cc3be3da28a150d980953052312624ff23b",
      isVirtual: false,
    },
    {
      name: "ilovepdf.com",
      image:
        "https://site-images.similarcdn.com/image?url=ilovepdf.com&t=2&s=1&h=ee01a3beeca617017ecb310813456410e52a859f1f511039569f4af3547a4c4a",
      isVirtual: false,
    },
    {
      name: "ixxx.com",
      image:
        "https://site-images.similarcdn.com/image?url=ixxx.com&t=2&s=1&h=261a3eacd924785a62c4867e06e0849c4f9626eb0ff130e237c3cac1a138be23",
      isVirtual: false,
    },
    {
      name: "ja.m.wikipedia.org",
      image:
        "https://site-images.similarcdn.com/image?url=ja.m.wikipedia.org&t=2&s=1&h=60d56fc10fcefd61eb97dec27adb36abc7d10346666652c2872014848e143e3a",
      isVirtual: false,
    },
    {
      name: "jingyan.baidu.com",
      image:
        "https://site-images.similarcdn.com/image?url=jingyan.baidu.com&t=2&s=1&h=49988fadcc5bbfb49b2ee02d69143e1e48a87b79cdd071ebc13f594ab7cbbc9a",
      isVirtual: false,
    },
    {
      name: "jw.org",
      image:
        "https://site-images.similarcdn.com/image?url=jw.org&t=2&s=1&h=12995e4752f964bfdb037742a402bea989d2447e41f76141ea9a914df646233f",
      isVirtual: false,
    },
    {
      name: "jd.com",
      image:
        "https://site-images.similarcdn.com/image?url=jd.com&t=2&s=1&h=0c86d9d9cec070624e3620747b6e3b7a03f16071ae38a2b8be04c7939f44a13f",
      isVirtual: false,
    },
    {
      name: "ja.wikipedia.org",
      image:
        "https://site-images.similarcdn.com/image?url=ja.wikipedia.org&t=2&s=1&h=7787a56bd58ceb9e8576424c784015428aba1e1dc3d05884410dd9fc47f80752",
      isVirtual: false,
    },
    {
      name: "justdial.com",
      image:
        "https://site-images.similarcdn.com/image?url=justdial.com&t=2&s=1&h=b6b8f9dc3b5a0a4a75e70e727a7c937fe37341b47d0a096ecc79b781b092510f",
      isVirtual: false,
    },
    {
      name: "jsmcrptjmp.com",
      image:
        "https://site-images.similarcdn.com/image?url=jsmcrptjmp.com&t=2&s=1&h=64df3616519ce9db48ca8cb2631b22977011a82ed18823cd1a405c37fc584594",
      isVirtual: false,
    },
    {
      name: "jp.pornhub.com",
      image:
        "https://site-images.similarcdn.com/image?url=jp.pornhub.com&t=2&s=1&h=9dbcc43c2d1e94f28e569d4332b5e08c99d456c1dd27607367f491d5455b564f",
      isVirtual: false,
    },
    {
      name: "jio.com",
      image:
        "https://site-images.similarcdn.com/image?url=jio.com&t=2&s=1&h=6f54b7268a50621355b2f547f0b86bafba5fbfde2aff2cd36882361e11197a7c",
      isVirtual: false,
    },
    {
      name: "justwatch.com",
      image:
        "https://site-images.similarcdn.com/image?url=justwatch.com&t=2&s=1&h=0d33865dce4216827dd835aca39488acaab3561ef6759e9dc1fe5ce46269fbf4",
      isVirtual: false,
    },
    {
      name: "kinopoisk.ru",
      image:
        "https://site-images.similarcdn.com/image?url=kinopoisk.ru&t=2&s=1&h=7e52f735d68f95d9c09739e7eed822529d6d4966141eed53e5c4f20b371e7638",
      isVirtual: false,
    },
    {
      name: "kompas.com",
      image:
        "https://site-images.similarcdn.com/image?url=kompas.com&t=2&s=1&h=a4aeebec4f9708dd3adcc7b8b2f7c9a3fdf5cd428f6bb3e619aa6477e3274be9",
      isVirtual: false,
    },
    {
      name: "kick.com",
      image:
        "https://site-images.similarcdn.com/image?url=kick.com&t=2&s=1&h=58964f32cf18f499f11ab5202cd771ea8ce388ae8c2caeb7fcd0e78dba3aa6e4",
      isVirtual: false,
    },
    {
      name: "kakao.com",
      image:
        "https://site-images.similarcdn.com/image?url=kakao.com&t=2&s=1&h=72fa6433b4c2be7f89eb8e5be0dd4e5062e37bd84dd0fac0c14405f481bb87ae",
      isVirtual: false,
    },
    {
      name: "kleinanzeigen.de",
      image:
        "https://site-images.similarcdn.com/image?url=kleinanzeigen.de&t=2&s=1&h=138d6fec9a66261feffae836b31b0715c549b0dabb37ee3b271bc58fce7d9eb8",
      isVirtual: false,
    },
    {
      name: "keep.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=keep.google.com&t=2&s=1&h=1d092ad4e8dd30070daf76aa8407b7e625e3c01a00f472413251171478ca00f0",
      isVirtual: false,
    },
    {
      name: "kakaku.com",
      image:
        "https://site-images.similarcdn.com/image?url=kakaku.com&t=2&s=1&h=813552960786a3bde01ab2f87ed4f6f81d2c38831d623cdbd7acba39eacf2b7e",
      isVirtual: false,
    },
    {
      name: "kp.ru",
      image:
        "https://site-images.similarcdn.com/image?url=kp.ru&t=2&s=1&h=d02941a40bc12d59aaf18a69610a54560125fb02c668f92a19c184d15d13c8e1",
      isVirtual: false,
    },
    {
      name: "kindred.co",
      image:
        "https://site-images.similarcdn.com/image?url=kindred.co&t=2&s=1&h=b597955dc4454dcead38b19a23d88dd05317864aa5aad7381c30c2c895bbd4a4",
      isVirtual: false,
    },
    {
      name: "khabaronline.ir",
      image:
        "https://site-images.similarcdn.com/image?url=khabaronline.ir&t=2&s=1&h=91826793fb809242d175bc2d43f294a180859ecee80e500bd9ee1aac00ab9ff6",
      isVirtual: false,
    },
    {
      name: "live.com",
      image:
        "https://site-images.similarcdn.com/image?url=live.com&t=2&s=1&h=4f68d3ffc8947028c823ba7e44ba3467cd07f113e6cbf03281186fb2ff9369d2",
      isVirtual: false,
    },
    {
      name: "linkedin.com",
      image:
        "https://site-images.similarcdn.com/image?url=linkedin.com&t=2&s=1&h=64a0e043fc8ff74ac308e3f0e136ae7d1b6544be4e9e704ad7fd062070be48a1",
      isVirtual: false,
    },
    {
      name: "l.facebook.com",
      image:
        "https://site-images.similarcdn.com/image?url=l.facebook.com&t=2&s=1&h=10397c7d1f2875f5f21b10be568943b93896e49ba5d30f98b25ea937a1416b4c",
      isVirtual: false,
    },
    {
      name: "login.live.com",
      image:
        "https://site-images.similarcdn.com/image?url=login.live.com&t=2&s=1&h=a8c5c0b14a6e9cdea99eaf664550e70e76667d436aca7011d461563442c9a53c",
      isVirtual: false,
    },
    {
      name: "login.microsoftonline.com",
      image:
        "https://site-images.similarcdn.com/image?url=login.microsoftonline.com&t=2&s=1&h=b314d2bd841fef42fe45afd437d74cb8ce7470f10819d3c6d21c581b389f7bc4",
      isVirtual: false,
    },
    {
      name: "lp.pinduoduo.com",
      image:
        "https://site-images.similarcdn.com/image?url=lp.pinduoduo.com&t=2&s=1&h=ead499afc6ee186ee7a1af0fd2bc11b643060ffad88a487974bf4b401ba085c2",
      isVirtual: false,
    },
    {
      name: "livejasmin.com",
      image:
        "https://site-images.similarcdn.com/image?url=livejasmin.com&t=2&s=1&h=146bab3d6f30872b4a250b8d924748639fbb3f40597a3b40d78172d834fefe81",
      isVirtual: false,
    },
    {
      name: "line.me",
      image:
        "https://site-images.similarcdn.com/image?url=line.me&t=2&s=1&h=046bcffef789234285c9ddf94fb40b0fc7d36aeaf4f061b951b050fa4477f71c",
      isVirtual: false,
    },
    {
      name: "lm.facebook.com",
      image:
        "https://site-images.similarcdn.com/image?url=lm.facebook.com&t=2&s=1&h=7ef5370ebbf3b94fd2d2fc97f74ccac2901033c7ea9b9b21bfa31e16a3693d36",
      isVirtual: false,
    },
    {
      name: "latonaheyday.space",
      image:
        "https://site-images.similarcdn.com/image?url=latonaheyday.space&t=2&s=1&h=829ced41f54b7b9dd3e3f49730d8cb768b15d47610d0d21ed699e3a9178bfc74",
      isVirtual: false,
    },
    {
      name: "mail.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=mail.google.com&t=2&s=1&h=bdc21d597156a9f81824c32b3ddedb24272ba7f1441f554aac2544fd8b723aec",
      isVirtual: false,
    },
    {
      name: "m.facebook.com",
      image:
        "https://site-images.similarcdn.com/image?url=m.facebook.com&t=2&s=1&h=17590e4857d7ca8cdbbc7d61aabd502bb0f03639b5369a9b521a8a192c8c5540",
      isVirtual: false,
    },
    {
      name: "m.youtube.com",
      image:
        "https://site-images.similarcdn.com/image?url=m.youtube.com&t=2&s=1&h=fa55c3b5f3e01c876d081d5ec635428aac36f4d962001aa4bacbf4d6bb43f0c2",
      isVirtual: false,
    },
    {
      name: "m.wikipedia.org",
      image:
        "https://site-images.similarcdn.com/image?url=m.wikipedia.org&t=2&s=1&h=5040a1c3bb2e7bdb91e263d8ac4f4f28369a271ee39654275a06b6e05be3cbd6",
      isVirtual: false,
    },
    {
      name: "mobile.twitter.com",
      image:
        "https://site-images.similarcdn.com/image?url=mobile.twitter.com&t=2&s=1&h=42af89ac7a0adf2d00a32fc893947753f52291cc531efd06a7490aa5ec2a25d8",
      isVirtual: false,
    },
    {
      name: "m.baidu.com",
      image:
        "https://site-images.similarcdn.com/image?url=m.baidu.com&t=2&s=1&h=41d55b07749cc6832812daa4ea18e95d62738f17ae0d8537b65db1b8f255dc9a",
      isVirtual: false,
    },
    {
      name: "microsoftonline.com",
      image:
        "https://site-images.similarcdn.com/image?url=microsoftonline.com&t=2&s=1&h=9b3ce90d85cdd9b1437910383a87ef98af7c2d1ecfb7d9736ee6d99aa65c01e3",
      isVirtual: false,
    },
    {
      name: "microsoft.com",
      image:
        "https://site-images.similarcdn.com/image?url=microsoft.com&t=2&s=1&h=47a418caa42749a0e924f26be103fa62a0ddf9cec8ef2c315a29ea3dbe296fbc",
      isVirtual: false,
    },
    {
      name: "mail.ru",
      image:
        "https://site-images.similarcdn.com/image?url=mail.ru&t=2&s=1&h=0449e16df76ff278543768e084e7d6633e3ac66f98e13696ffcd4e1d25a51851",
      isVirtual: false,
    },
    {
      name: "max.com",
      image:
        "https://site-images.similarcdn.com/image?url=max.com&t=2&s=1&h=ae8a407d9f60e22c1bf0bbbfb9f972350e0d15858da9903bfbc5c7c142f0683d",
      isVirtual: false,
    },
    {
      name: "netflix.com",
      image:
        "https://site-images.similarcdn.com/image?url=netflix.com&t=2&s=1&h=68a2885c7960ece3b1d7dd9b592e7ad1b35ddeb89fc89650435c1507f3f1c0b3",
      isVirtual: false,
    },
    {
      name: "naver.com",
      image:
        "https://site-images.similarcdn.com/image?url=naver.com&t=2&s=1&h=d0cb2a7c7e5048fcae43abc238269736c1eaae31a8f89160c5be0080115448fc",
      isVirtual: false,
    },
    {
      name: "nytimes.com",
      image:
        "https://site-images.similarcdn.com/image?url=nytimes.com&t=2&s=1&h=4ac64aeeb8e35d92fb646e1ad48c3191db51e5cf15d5ab9ddefdd2ff959bf8f1",
      isVirtual: false,
    },
    {
      name: "news.yahoo.co.jp",
      image:
        "https://site-images.similarcdn.com/image?url=news.yahoo.co.jp&t=2&s=1&h=4eed840fc545c145038c24cee5e95f84f96c44f7d9d3088054ae53737b3469c6",
      isVirtual: false,
    },
    {
      name: "news.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=news.google.com&t=2&s=1&h=72ee5822b051b126c9224b509faf7ca137f108a748f5c9aae60e7842933e6634",
      isVirtual: false,
    },
    {
      name: "news.yahoo.com",
      image:
        "https://site-images.similarcdn.com/image?url=news.yahoo.com&t=2&s=1&h=9ca97585dda3aa8c0795827938d219e6453dce2b6a09a7c2c387f949d559cb2b",
      isVirtual: false,
    },
    {
      name: "nih.gov",
      image:
        "https://site-images.similarcdn.com/image?url=nih.gov&t=2&s=1&h=393218660c82cae6a55ff02a8db86c9498ecfbf2a5f572e24f1cf64123876483",
      isVirtual: false,
    },
    {
      name: "notifications.canadapost-postescanada.ca",
      image:
        "https://site-images.similarcdn.com/image?url=notifications.canadapost-postescanada.ca&t=2&s=1&h=00171a0811f5c4289d0812025333e5612735fed4d4003b55e78a9112688a8500",
      isVirtual: false,
    },
    {
      name: "nlm.nih.gov",
      image:
        "https://site-images.similarcdn.com/image?url=nlm.nih.gov&t=2&s=1&h=8970dfdb366e1f3b03f9eda80cb943c09d0bf4ed7e6258f5675c0ab5c040e791",
      isVirtual: false,
    },
    {
      name: "noodlemagazine.com",
      image:
        "https://site-images.similarcdn.com/image?url=noodlemagazine.com&t=2&s=1&h=9ecaf30677bdcb7cc499df3516435907f4bb4734ef421631cb9d92939a44eff1",
      isVirtual: false,
    },
    {
      name: "openai.com",
      image:
        "https://site-images.similarcdn.com/image?url=openai.com&t=2&s=1&h=2e9ae820de5f71b75c263fb8b396891383bdd6172185a3db509f79decb5023dc",
      isVirtual: false,
    },
    {
      name: "outlook.live.com",
      image:
        "https://site-images.similarcdn.com/image?url=outlook.live.com&t=2&s=1&h=b2787f0f9d3c2addbe2d501c8bf8037b5849a8a8f82890e6738457430be67a43",
      isVirtual: false,
    },
    {
      name: "office.com",
      image:
        "https://site-images.similarcdn.com/image?url=office.com&t=2&s=1&h=c320c9bba8706c784b863ecf11b87fe62249f94477c3fc4a3787b119bc4d9db9",
      isVirtual: false,
    },
    {
      name: "outlook.office.com",
      image:
        "https://site-images.similarcdn.com/image?url=outlook.office.com&t=2&s=1&h=780b31d77145a28f50e13bafc6bab62b50015da4d1364a6bbd02d1f26b00e183",
      isVirtual: false,
    },
    {
      name: "outbrain.com",
      image:
        "https://site-images.similarcdn.com/image?url=outbrain.com&t=2&s=1&h=e7d46789a23bb5df792e9623bc361c31c65786bfd166611a81a2b42cdce7e45a",
      isVirtual: false,
    },
    {
      name: "ok.ru",
      image:
        "https://site-images.similarcdn.com/image?url=ok.ru&t=2&s=1&h=1a44fc9aef46e269cc5473554e499280408eb08ec76b3bc777dae606f3706bc9",
      isVirtual: false,
    },
    {
      name: "onelink.me",
      image:
        "https://site-images.similarcdn.com/image?url=onelink.me&t=2&s=1&h=6068119d7e9ecf48c42e082263014c6da81df419863cc51306f82e21a8398305",
      isVirtual: false,
    },
    {
      name: "open.spotify.com",
      image:
        "https://site-images.similarcdn.com/image?url=open.spotify.com&t=2&s=1&h=5ee5ce00236f77315c64f0c8e702f3fc15ce26a756bbcb015f00df07ca6df506",
      isVirtual: false,
    },
    {
      name: "ozon.ru",
      image:
        "https://site-images.similarcdn.com/image?url=ozon.ru&t=2&s=1&h=27abc04f15716886381ba37b35b3b6ec8e4b0a01c3e76daa2bf2f7ae1166125b",
      isVirtual: false,
    },
    {
      name: "onlyfans.com",
      image:
        "https://site-images.similarcdn.com/image?url=onlyfans.com&t=2&s=1&h=b3b1bb56879c3e5f60ace0f9a23740817a91d1fe02cfb31f795c7d6f28f6728d",
      isVirtual: false,
    },
    {
      name: "pornhub.com",
      image:
        "https://site-images.similarcdn.com/image?url=pornhub.com&t=2&s=1&h=a7cd82dfb7084774816cabbe511a6b4b7a4f6b6bb484a76d3a4b7c63e66cdebb",
      isVirtual: false,
    },
    {
      name: "play.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=play.google.com&t=2&s=1&h=b33b6014c6ed3187811ba83562adc8ef7828615746c38bd105ab715e667c4968",
      isVirtual: false,
    },
    {
      name: "pinterest.com",
      image:
        "https://site-images.similarcdn.com/image?url=pinterest.com&t=2&s=1&h=ec976160ca16242c6ab9ed7bc48e7b0f04c4100ceac5bcb6d3ea55450aa12210",
      isVirtual: false,
    },
    {
      name: "paypal.com",
      image:
        "https://site-images.similarcdn.com/image?url=paypal.com&t=2&s=1&h=b76dd86eb38ce2ec2534acd9451f22982019a9c445250e2a35549ea9f480fd7b",
      isVirtual: false,
    },
    {
      name: "paid.outbrain.com",
      image:
        "https://site-images.similarcdn.com/image?url=paid.outbrain.com&t=2&s=1&h=c27761910591dd496e0c20a9a29ff15c9cb31a2f0fadfc059c177c4995d83eca",
      isVirtual: false,
    },
    {
      name: "pinduoduo.com",
      image:
        "https://site-images.similarcdn.com/image?url=pinduoduo.com&t=2&s=1&h=d33290cb1e8a80d60318fb7a474e7861d41d7471f1d0996ba558216fa4c46d0e",
      isVirtual: false,
    },
    {
      name: "parimatch-in.com",
      image:
        "https://site-images.similarcdn.com/image?url=parimatch-in.com&t=2&s=1&h=e599b2e7358d415c226b08fe260d17a89b750fd27792eea5fd8a2b245122196c",
      isVirtual: false,
    },
    {
      name: "photos.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=photos.google.com&t=2&s=1&h=279de52388c9ddd408153bfd64f9526d4d8bb48c7afc88cf1d58f38f72287403",
      isVirtual: false,
    },
    {
      name: "pixiv.net",
      image:
        "https://site-images.similarcdn.com/image?url=pixiv.net&t=2&s=1&h=fb002711fec47eeef20199211e9b84a8add55513dfa4a3299c8e812cd16b4034",
      isVirtual: false,
    },
    {
      name: "primevideo.com",
      image:
        "https://site-images.similarcdn.com/image?url=primevideo.com&t=2&s=1&h=3712447c38c42c7860e3b7c1b53be72324370114645f3bbbc697479a7bb7e00c",
      isVirtual: false,
    },
    {
      name: "qq.com",
      image:
        "https://site-images.similarcdn.com/image?url=qq.com&t=2&s=1&h=2bcce8b878b3521d8c830783a6dfa0697cd2da2b96ca7ec88ba8fbd837e01d7b",
      isVirtual: false,
    },
    {
      name: "quora.com",
      image:
        "https://site-images.similarcdn.com/image?url=quora.com&t=2&s=1&h=2292ee60dfa24a38b0f960b7755fce61c1f7ba685f4f9290b129e2e180491c12",
      isVirtual: false,
    },
    {
      name: "quizlet.com",
      image:
        "https://site-images.similarcdn.com/image?url=quizlet.com&t=2&s=1&h=ffe13ded408e89d8288e5f6a36fc515abfbf3fd8676a29dc3d5730fbe0ed68c5",
      isVirtual: false,
    },
    {
      name: "qualtrics.com",
      image:
        "https://site-images.similarcdn.com/image?url=qualtrics.com&t=2&s=1&h=7071c2e1cc90b20770de789b1d92f1cd88def1e0315ade9b2df6a96c5af8579a",
      isVirtual: false,
    },
    {
      name: "qorno.com",
      image:
        "https://site-images.similarcdn.com/image?url=qorno.com&t=2&s=1&h=d491789676c10c3955e8e3e03f330b901563bad0ac238bed56267c4d0e156fef",
      isVirtual: false,
    },
    {
      name: "quillbot.com",
      image:
        "https://site-images.similarcdn.com/image?url=quillbot.com&t=2&s=1&h=1bb519807d1cce2301a3c791ba8a090b9997c0c79a4cef662a100e5d489b3710",
      isVirtual: false,
    },
    {
      name: "qidian.com",
      image:
        "https://site-images.similarcdn.com/image?url=qidian.com&t=2&s=1&h=b6af2de71ed87d6261c4be039519628b3b3aac5a652c9137a3283756ca349dc3",
      isVirtual: false,
    },
    {
      name: "quizizz.com",
      image:
        "https://site-images.similarcdn.com/image?url=quizizz.com&t=2&s=1&h=0ac2eb0bba2b6bd891de3948c273bc3cdb9312d024ec61ef82733926e93cca95",
      isVirtual: false,
    },
    {
      name: "qrco.de",
      image:
        "https://site-images.similarcdn.com/image?url=qrco.de&t=2&s=1&h=53fc7133e4f2103f279187e5a6bd9a70f2188bef17ee34be90b6a032e04af449",
      isVirtual: false,
    },
    {
      name: "qbo.intuit.com",
      image:
        "https://site-images.similarcdn.com/image?url=qbo.intuit.com&t=2&s=1&h=08c5a34c4674172a75b98711060482018f19192b900e6f1c8f12fe303143e8f8",
      isVirtual: false,
    },
    {
      name: "reddit.com",
      image:
        "https://site-images.similarcdn.com/image?url=reddit.com&t=2&s=1&h=66f2412047e0362ec60d5583d4b186511a8e859446bb112c60d22968facae906",
      isVirtual: false,
    },
    {
      name: "realsrv.com",
      image:
        "https://site-images.similarcdn.com/image?url=realsrv.com&t=2&s=1&h=07d38b43684cdaed155bf376e8b11a8e4d9889843949ee3d954d24d8432a9e5a",
      isVirtual: false,
    },
    {
      name: "rakuten.co.jp",
      image:
        "https://site-images.similarcdn.com/image?url=rakuten.co.jp&t=2&s=1&h=e5b0ac5d490ac54e8686b6106552d67315c435eb80cafe1b91e23b9046f12f9a",
      isVirtual: false,
    },
    {
      name: "roblox.com",
      image:
        "https://site-images.similarcdn.com/image?url=roblox.com&t=2&s=1&h=25fffffefa6ca95ed540e8a090f7fb9ecd4a54187105a3b15c3cc761ba04d90c",
      isVirtual: false,
    },
    {
      name: "rubika.ir",
      image:
        "https://site-images.similarcdn.com/image?url=rubika.ir&t=2&s=1&h=fdf697696fad08b058e778de1993c50b1d739982aa015ca6a3d4503db6312260",
      isVirtual: false,
    },
    {
      name: "rumble.com",
      image:
        "https://site-images.similarcdn.com/image?url=rumble.com&t=2&s=1&h=ab4e9eef8a892f77c2dea4786c175844c81d8e1990bf7164424dac6d0f5c1ddf",
      isVirtual: false,
    },
    {
      name: "r.secprf.com",
      image:
        "https://site-images.similarcdn.com/image?url=r.secprf.com&t=2&s=1&h=0f9c0cf70ceaec7d46a85062369980c9ee4c74331e89aaeaa6007b340ffe735d",
      isVirtual: false,
    },
    {
      name: "r.search.yahoo.com",
      image:
        "https://site-images.similarcdn.com/image?url=r.search.yahoo.com&t=2&s=1&h=7faa9357bf8dcd1f8dfcbcb8f4afd6adedd7510d1956cb825b179b576476bf0a",
      isVirtual: false,
    },
    {
      name: "realtor.com",
      image:
        "https://site-images.similarcdn.com/image?url=realtor.com&t=2&s=1&h=b8561e7915042a858314936924bf5e1daac5719c35b778bed9630f35c17f5db9",
      isVirtual: false,
    },
    {
      name: "redd.it",
      image:
        "https://site-images.similarcdn.com/image?url=redd.it&t=2&s=1&h=d3972716563874f962442d8c853b8e3567af1247a4f1f50d373f2c8b1d0a8faf",
      isVirtual: false,
    },
    {
      name: "support.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=support.google.com&t=2&s=1&h=4e6ff9e362c9d2fe4065c845c728bd490e282183b59d8edff84ae6d6884c5440",
      isVirtual: false,
    },
    {
      name: "samsung.com",
      image:
        "https://site-images.similarcdn.com/image?url=samsung.com&t=2&s=1&h=acbcd0840863d87073a9c8b029e1f766fd1432cf4d709b92c86a0490904c5c0d",
      isVirtual: false,
    },
    {
      name: "smt.docomo.ne.jp",
      image:
        "https://site-images.similarcdn.com/image?url=smt.docomo.ne.jp&t=2&s=1&h=c064e6fb83be35f04430c0f8718a7716fac77a6801a5a48d9a058d344c0c3977",
      isVirtual: false,
    },
    {
      name: "search.yahoo.com",
      image:
        "https://site-images.similarcdn.com/image?url=search.yahoo.com&t=2&s=1&h=e598ab1bba5add97d2be66771087003d488a40f4235e8e92ba96275f613c0184",
      isVirtual: false,
    },
    {
      name: "stripchat.com",
      image:
        "https://site-images.similarcdn.com/image?url=stripchat.com&t=2&s=1&h=a748a46a573e70399ff913741591dc8423fa350a1791a8ca220108abf0ca5fa2",
      isVirtual: false,
    },
    {
      name: "syndication.realsrv.com",
      image:
        "https://site-images.similarcdn.com/image?url=syndication.realsrv.com&t=2&s=1&h=76255b2da12d018b85c3721574812adcde9ee0a526df5cef34a61602a2e76274",
      isVirtual: false,
    },
    {
      name: "spankbang.com",
      image:
        "https://site-images.similarcdn.com/image?url=spankbang.com&t=2&s=1&h=d2aa7c442f63edd8b2fcc612343135635ecae0622dc2e7e780461de47a74d224",
      isVirtual: false,
    },
    {
      name: "search.yahoo.co.jp",
      image:
        "https://site-images.similarcdn.com/image?url=search.yahoo.co.jp&t=2&s=1&h=608b55677e56c3e1726f42b4e210227f71e04b79a4458bf4742c88563e544846",
      isVirtual: false,
    },
    {
      name: "sharepoint.com",
      image:
        "https://site-images.similarcdn.com/image?url=sharepoint.com&t=2&s=1&h=e80016f238bd5f77055653d82e17e9736e20e1a4382bd030ad60eb02cee57358",
      isVirtual: false,
    },
    {
      name: "spotify.com",
      image:
        "https://site-images.similarcdn.com/image?url=spotify.com&t=2&s=1&h=2568efe3e14a060e5353b625cd312e03aa31f963afbffa0453ab53d7b0e11640",
      isVirtual: false,
    },
    {
      name: "twitter.com",
      image:
        "https://site-images.similarcdn.com/image?url=twitter.com&t=2&s=1&h=05438debe431144d9c727828570d1754a25bd9286bc14f3aa65a4f05b9057e25",
      isVirtual: false,
    },
    {
      name: "tiktok.com",
      image:
        "https://site-images.similarcdn.com/image?url=tiktok.com&t=2&s=1&h=398f47f8e1724c54f9f760f76988b2f9cf0c4111523f9d3f26e03d62731b0c17",
      isVirtual: false,
    },
    {
      name: "t.co",
      image:
        "https://site-images.similarcdn.com/image?url=t.co&t=2&s=1&h=468df83b203c647410a90affa0b8f59f793ef8e10f0fa03978562d331e4bdcf6",
      isVirtual: false,
    },
    {
      name: "turbopages.org",
      image:
        "https://site-images.similarcdn.com/image?url=turbopages.org&t=2&s=1&h=eb1aaf99c62734c0fbc178c86066bb049e072d0386a106215057174afc1ce8c6",
      isVirtual: false,
    },
    {
      name: "t.me",
      image:
        "https://site-images.similarcdn.com/image?url=t.me&t=2&s=1&h=3151265a64fa94112029f3e81d07655b605dd870fad655049a47e0f89cb877cc",
      isVirtual: false,
    },
    {
      name: "taboola.com",
      image:
        "https://site-images.similarcdn.com/image?url=taboola.com&t=2&s=1&h=635414013637abb44c555dc2d28c001ba5c19537eba8f548f194035ca7457687",
      isVirtual: false,
    },
    {
      name: "translate.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=translate.google.com&t=2&s=1&h=464499d3601afc6e7ccdaf31630ba0eb2908f00801df74e4f2d9aa51fa2d2d61",
      isVirtual: false,
    },
    {
      name: "twitch.tv",
      image:
        "https://site-images.similarcdn.com/image?url=twitch.tv&t=2&s=1&h=355e363b41e14a959cda6ccf0989ff8444402dd8844820c31b74a89bf3ed2792",
      isVirtual: false,
    },
    {
      name: "trc.taboola.com",
      image:
        "https://site-images.similarcdn.com/image?url=trc.taboola.com&t=2&s=1&h=eeb3ca5e347c1431f800e82febb4d5fa6d3f29924a8c05d29288150d13deb634",
      isVirtual: false,
    },
    {
      name: "tieba.baidu.com",
      image:
        "https://site-images.similarcdn.com/image?url=tieba.baidu.com&t=2&s=1&h=d243520ee6ce16bfe95a86bd0dcce24a64e6f2238c2008138e4d61a2b5e5027c",
      isVirtual: false,
    },
    {
      name: "uol.com.br",
      image:
        "https://site-images.similarcdn.com/image?url=uol.com.br&t=2&s=1&h=04f2caf2d601291e8f907821d9e01065c4c1363e8d83703eb66b4add71572467",
      isVirtual: false,
    },
    {
      name: "us02web.zoom.us",
      image:
        "https://site-images.similarcdn.com/image?url=us02web.zoom.us&t=2&s=1&h=2355b2b735de7f502a96c73bc53343e879c726d630bd4759ff5d1bc7ddc2c06a",
      isVirtual: false,
    },
    {
      name: "usps.com",
      image:
        "https://site-images.similarcdn.com/image?url=usps.com&t=2&s=1&h=9b5d6f83cff602a08c733908c511a8d86616529219e0e89a6e54248dfacdfcb3",
      isVirtual: false,
    },
    {
      name: "ups.com",
      image:
        "https://site-images.similarcdn.com/image?url=ups.com&t=2&s=1&h=8f74240d183892d7bef38bc1268ea23a07aee15d77b9ef8bb58117ebbcb11d5b",
      isVirtual: false,
    },
    {
      name: "usatoday.com",
      image:
        "https://site-images.similarcdn.com/image?url=usatoday.com&t=2&s=1&h=12e3f7b1e68a80c63dcd355a5c23a3b5dbd4a7596076f6756e6b9a42e7a1f8b9",
      isVirtual: false,
    },
    {
      name: "us06web.zoom.us",
      image:
        "https://site-images.similarcdn.com/image?url=us06web.zoom.us&t=2&s=1&h=e125cb92d322d6832489004c9abaf669acaf17aad26322cb24b7f45aed3dadce",
      isVirtual: false,
    },
    {
      name: "udn.com",
      image:
        "https://site-images.similarcdn.com/image?url=udn.com&t=2&s=1&h=ae37bad3067614995bba46ba580a0f15f75039eb0aef7860a0399dd1ee05db7b",
      isVirtual: false,
    },
    {
      name: "udemy.com",
      image:
        "https://site-images.similarcdn.com/image?url=udemy.com&t=2&s=1&h=42ad9fe0bd63704e9437c30343d4d7a8f6ac49e72b7e05a25b9802923388f922",
      isVirtual: false,
    },
    {
      name: "us04web.zoom.us",
      image:
        "https://site-images.similarcdn.com/image?url=us04web.zoom.us&t=2&s=1&h=98ef36000835fc62668e7bcc56e375b457add1e67e7711c7fec40df2d9b2a528",
      isVirtual: false,
    },
    {
      name: "uptodown.com",
      image:
        "https://site-images.similarcdn.com/image?url=uptodown.com&t=2&s=1&h=ee3ab62a9c56ca8b57e1fdfcf8de7902fe1f1a00c49c8075ee359c844fcc45b3",
      isVirtual: false,
    },
    {
      name: "vk.com",
      image:
        "https://site-images.similarcdn.com/image?url=vk.com&t=2&s=1&h=96c7d9c0167ed93f1aba87c7ec931f6e76e122c08d08cbf046db42369bac906e",
      isVirtual: false,
    },
    {
      name: "vm.tiktok.com",
      image:
        "https://site-images.similarcdn.com/image?url=vm.tiktok.com&t=2&s=1&h=03c6aa2156336ffbd676582a177c56fc60b2eb3a845f20a5853ae4c0e8b41175",
      isVirtual: false,
    },
    {
      name: "vt.tiktok.com",
      image:
        "https://site-images.similarcdn.com/image?url=vt.tiktok.com&t=2&s=1&h=6de64384ed468ee773560e182e9e12178ded58e27dc74ddccfc3cc627cccec68",
      isVirtual: false,
    },
    {
      name: "vegamovies.tips",
      image:
        "https://site-images.similarcdn.com/image?url=vegamovies.tips&t=2&s=1&h=80729cda0577b05dd7c469e30c4cf987797819c63c139edf43422c386167265d",
      isVirtual: false,
    },
    {
      name: "vnexpress.net",
      image:
        "https://site-images.similarcdn.com/image?url=vnexpress.net&t=2&s=1&h=999dcd060082d93c13ddf507d8429edf055dcde430c9abf4a2df99fe78ad6caf",
      isVirtual: false,
    },
    {
      name: "vimeo.com",
      image:
        "https://site-images.similarcdn.com/image?url=vimeo.com&t=2&s=1&h=859fde7b2f22e85a62fa89537463c229c61bbeb09e3f8f14b3c47cc2ac60813e",
      isVirtual: false,
    },
    {
      name: "v.qq.com",
      image:
        "https://site-images.similarcdn.com/image?url=v.qq.com&t=2&s=1&h=c77bbdcb13464226de8f7c1a4481f2f016cd42e1617178006810b2f0dd996dcd",
      isVirtual: false,
    },
    {
      name: "verified.capitalone.com",
      image:
        "https://site-images.similarcdn.com/image?url=verified.capitalone.com&t=2&s=1&h=117e98b2cc718404c0f38333bd1b429c98b644cee2e27a2fa486bbaa8c3000cc",
      isVirtual: false,
    },
    {
      name: "v.daum.net",
      image:
        "https://site-images.similarcdn.com/image?url=v.daum.net&t=2&s=1&h=df249ee66111f24a31755bd7e44b2a6c271c2e48335254978687681b2b1fbff6",
      isVirtual: false,
    },
    {
      name: "verizon.com",
      image:
        "https://site-images.similarcdn.com/image?url=verizon.com&t=2&s=1&h=d075b564f9864a7305986489d5795c4681777da1cfa4b0193b0636b72607f41e",
      isVirtual: false,
    },
    {
      name: "wikipedia.org",
      image:
        "https://site-images.similarcdn.com/image?url=wikipedia.org&t=2&s=1&h=460eb7e23fbc3666abf9d8b5497c3ca33d1de8ff9c66dd5d182c7633fe475c61",
      isVirtual: false,
    },
    {
      name: "whatsapp.com",
      image:
        "https://site-images.similarcdn.com/image?url=whatsapp.com&t=2&s=1&h=d766df2c2537e7a216389c9fbc4118d24ceca7fd169c78048289caab483ba581",
      isVirtual: false,
    },
    {
      name: "web.whatsapp.com",
      image:
        "https://site-images.similarcdn.com/image?url=web.whatsapp.com&t=2&s=1&h=451e82ca011741d37d72edee1d8fdc272155924d894fe937e292066acb713bc8",
      isVirtual: false,
    },
    {
      name: "weather.com",
      image:
        "https://site-images.similarcdn.com/image?url=weather.com&t=2&s=1&h=4211c15438cff54a25f9d9ee48f05a68bd36ddd7ffacdf93e3449739797bb3cb",
      isVirtual: false,
    },
    {
      name: "walmart.com",
      image:
        "https://site-images.similarcdn.com/image?url=walmart.com&t=2&s=1&h=66a59d35a24ccf994c844e30665fc6d8185c228fbae9a9688803e6b80047e9d3",
      isVirtual: false,
    },
    {
      name: "win.parimatch-in.com",
      image:
        "https://site-images.similarcdn.com/image?url=win.parimatch-in.com&t=2&s=1&h=53cf0273f23afeac590b5004e6183045c0aa7f83a6cb644757d028610a41952e",
      isVirtual: false,
    },
    {
      name: "wordpress.com",
      image:
        "https://site-images.similarcdn.com/image?url=wordpress.com&t=2&s=1&h=597c87d93a835404d6d88e1eb47fc3f2be28671e9dd2d8d8119f746654be8394",
      isVirtual: false,
    },
    {
      name: "wildberries.ru",
      image:
        "https://site-images.similarcdn.com/image?url=wildberries.ru&t=2&s=1&h=8cd26c3b92038b0f80c635266876ed792de8f638e58e721d30834c6abb4c54e6",
      isVirtual: false,
    },
    {
      name: "weixin.qq.com",
      image:
        "https://site-images.similarcdn.com/image?url=weixin.qq.com&t=2&s=1&h=5d500e5fd78568c0ade20c39098a2b9de80dac581160cfd3b6e2ccd4e230df7a",
      isVirtual: false,
    },
    {
      name: "wp.pl",
      image:
        "https://site-images.similarcdn.com/image?url=wp.pl&t=2&s=1&h=6c051f43a698d03b8ad6a3eea18dd500fb06e38358a6c860b00c21f372c626e4",
      isVirtual: false,
    },
    {
      name: "xvideos.com",
      image:
        "https://site-images.similarcdn.com/image?url=xvideos.com&t=2&s=1&h=f5d29d9ed79e14cfc8033f2eb7d9290f977f74104155880926ae289e214c3fd2",
      isVirtual: false,
    },
    {
      name: "xnxx.com",
      image:
        "https://site-images.similarcdn.com/image?url=xnxx.com&t=2&s=1&h=83f7124390efdbd049ae2139a52591e3767a84e0ac3a812b5214a2fcc1cc4070",
      isVirtual: false,
    },
    {
      name: "xhamster.com",
      image:
        "https://site-images.similarcdn.com/image?url=xhamster.com&t=2&s=1&h=52f199cf7134b108239b1e5dcd4503fbc1a48d8f2fd45ef8610ec0c5ac0c8b00",
      isVirtual: false,
    },
    {
      name: "xhamster20.desi",
      image:
        "https://site-images.similarcdn.com/image?url=xhamster20.desi&t=2&s=1&h=e9c1abe026493a62e976ad692c52d00a83473b0017995880b3a3940ecd9333ae",
      isVirtual: false,
    },
    {
      name: "xiaohongshu.com",
      image:
        "https://site-images.similarcdn.com/image?url=xiaohongshu.com&t=2&s=1&h=9ddb3232bbd0492ebde38dfcdc5704d240f4b3060cc20566479e279c453421b2",
      isVirtual: false,
    },
    {
      name: "xhamsterlive.com",
      image:
        "https://site-images.similarcdn.com/image?url=xhamsterlive.com&t=2&s=1&h=85951dcf644b506479bddf5462f16b3c8672d9ddec2709926d06382ad33f5942",
      isVirtual: false,
    },
    {
      name: "xvideos2.com",
      image:
        "https://site-images.similarcdn.com/image?url=xvideos2.com&t=2&s=1&h=9d472aa81369ed3f01dccada198dcbb6f1112744bd61bc93803ee040f2b2e2dc",
      isVirtual: false,
    },
    {
      name: "xoso.com.vn",
      image:
        "https://site-images.similarcdn.com/image?url=xoso.com.vn&t=2&s=1&h=7d62d5920c12bbc3a7b4b2bd561f8bd9e2f8a43e2d7fd0af193caf7525ffce0c",
      isVirtual: false,
    },
    {
      name: "xosodaiphat.com",
      image:
        "https://site-images.similarcdn.com/image?url=xosodaiphat.com&t=2&s=1&h=377179c948862c186fdc0a218d127dfbdebb7548e51d2bd304873aab8470496c",
      isVirtual: false,
    },
    {
      name: "xlivrdr.com",
      image:
        "https://site-images.similarcdn.com/image?url=xlivrdr.com&t=2&s=1&h=94cdd33f57b258eb2696a60feebce1ac6e9531d1afcda148435b38b9bb95091b",
      isVirtual: false,
    },
    {
      name: "youtube.com",
      image:
        "https://site-images.similarcdn.com/image?url=youtube.com&t=2&s=1&h=e117fb09ba0a34dee9830863bf927d29fc864e342583a36a667bf12346e88e20",
      isVirtual: false,
    },
    {
      name: "yahoo.com",
      image:
        "https://site-images.similarcdn.com/image?url=yahoo.com&t=2&s=1&h=a135e030f99f8070348f49e9e1879c9093cdb33ad6b658fa2593fed09fa6060e",
      isVirtual: false,
    },
    {
      name: "yandex.ru",
      image:
        "https://site-images.similarcdn.com/image?url=yandex.ru&t=2&s=1&h=f0674e5e56f12160a4f31738b3aecd42e2ebffde994db0c5ebdd214ef6dde60e",
      isVirtual: false,
    },
    {
      name: "yahoo.co.jp",
      image:
        "https://site-images.similarcdn.com/image?url=yahoo.co.jp&t=2&s=1&h=009b21944c61439f0bec7afd2e150470de636fa4d2b2cee4804e537911a0e7fc",
      isVirtual: false,
    },
    {
      name: "youtu.be",
      image:
        "https://site-images.similarcdn.com/image?url=youtu.be&t=2&s=1&h=396ef5ca9a5e9f496c736c30d45603f3b0fbb2125a271b669c890ebedcf85be0",
      isVirtual: false,
    },
    {
      name: "yiyouliao.com",
      image:
        "https://site-images.similarcdn.com/image?url=yiyouliao.com&t=2&s=1&h=55c3d854f96e462219ec31a6c244c0e20f169965319635604618ffb56d933381",
      isVirtual: false,
    },
    {
      name: "ya.ru",
      image:
        "https://site-images.similarcdn.com/image?url=ya.ru&t=2&s=1&h=e2a9eea91482db002f44ae243f6a3ceeb124e167d556d45d1e96b76ff86d7ef6",
      isVirtual: false,
    },
    {
      name: "youporn.com",
      image:
        "https://site-images.similarcdn.com/image?url=youporn.com&t=2&s=1&h=b314094e9fb82d344387e09c098cac45e24f85def08408c5086880973f878702",
      isVirtual: false,
    },
    {
      name: "yandex.com",
      image:
        "https://site-images.similarcdn.com/image?url=yandex.com&t=2&s=1&h=18600aac2e8d8b380282238d4f96c095692737b87d0d836b70dea015dfc291e0",
      isVirtual: false,
    },
    {
      name: "yelp.com",
      image:
        "https://site-images.similarcdn.com/image?url=yelp.com&t=2&s=1&h=4317a7e8cd20f5fc89a159b367640f9199a280755ae68ff7f5ade8161fd02512",
      isVirtual: false,
    },
    {
      name: "zoom.us",
      image:
        "https://site-images.similarcdn.com/image?url=zoom.us&t=2&s=1&h=8d7b62ea2a4830de4939cf04e4b067a49a8b4b203528c8cc3dc73a4a92f6efdb",
      isVirtual: false,
    },
    {
      name: "zhidao.baidu.com",
      image:
        "https://site-images.similarcdn.com/image?url=zhidao.baidu.com&t=2&s=1&h=de2fcbfddadaefce27897fe183003d7cd48684a9b6f1cae446e7bf7d01956f28",
      isVirtual: false,
    },
    {
      name: "zhihu.com",
      image:
        "https://site-images.similarcdn.com/image?url=zhihu.com&t=2&s=1&h=813fdcaed1a945c07a1deeb0d27d8f35bc1207351edb9376ce331d8b60191139",
      isVirtual: false,
    },
    {
      name: "zillow.com",
      image:
        "https://site-images.similarcdn.com/image?url=zillow.com&t=2&s=1&h=97efb315379da3d8dba623b1d3538d86154a01f43cd1be32f127ddc993e5bb0a",
      isVirtual: false,
    },
    {
      name: "zhuanlan.zhihu.com",
      image:
        "https://site-images.similarcdn.com/image?url=zhuanlan.zhihu.com&t=2&s=1&h=3a6d4c22705cb59fa9c712713628b851b9dd292e05224eaf62c7120192f49773",
      isVirtual: false,
    },
    {
      name: "zemanta.com",
      image:
        "https://site-images.similarcdn.com/image?url=zemanta.com&t=2&s=1&h=69355c432403ffc2dec6bd6a29f907110f53687747501f9557d6ea44a509db55",
      isVirtual: false,
    },
    {
      name: "zalo.me",
      image:
        "https://site-images.similarcdn.com/image?url=zalo.me&t=2&s=1&h=809ba5a7412056dc88109f7e804ac06cdaa93182464a46bb7024b448441e6bc0",
      isVirtual: false,
    },
    {
      name: "zara.com",
      image:
        "https://site-images.similarcdn.com/image?url=zara.com&t=2&s=1&h=92036de89e0997d84315c1b75909120523d1e63cee8d34c5f5d664c56d6cb60f",
      isVirtual: false,
    },
    {
      name: "zoro.to",
      image:
        "https://site-images.similarcdn.com/image?url=zoro.to&t=2&s=1&h=27bd0193241ee2e043e7bab5fe5d04c05cf4fdec6b5e1690c4d4225c1149404f",
      isVirtual: false,
    },
    {
      name: "zendesk.com",
      image:
        "https://site-images.similarcdn.com/image?url=zendesk.com&t=2&s=1&h=357dbb6d1c9efc894622efef2413a80e647d8e85ecda2fd01d2af1cb5655b843",
      isVirtual: false,
    },
    {
      name: "baidu.com",
      image:
        "https://site-images.similarcdn.com/image?url=baidu.com&t=2&s=1&h=178228b3c52f0d8b0cb0b138670cccb41d21f5ff03551da7375a97012005daa7",
      isVirtual: false,
    },
    {
      name: "bit.ly",
      image:
        "https://site-images.similarcdn.com/image?url=bit.ly&t=2&s=1&h=1b0043db45cbea5941cec1b946c399c43f7b2dc7291a7900f9359a04b4d576a8",
      isVirtual: false,
    },
    {
      name: "bing.com",
      image:
        "https://site-images.similarcdn.com/image?url=bing.com&t=2&s=1&h=a37ae326ae78ba2da30e9c8da030e84e07d4c76c5452c239f85c9050781ffde8",
      isVirtual: false,
    },
    {
      name: "booking.com",
      image:
        "https://site-images.similarcdn.com/image?url=booking.com&t=2&s=1&h=f5289690c4152b11e402f6581a32a461108d0aac2a1979351493ac0e3e538065",
      isVirtual: false,
    },
    {
      name: "bilibili.com",
      image:
        "https://site-images.similarcdn.com/image?url=bilibili.com&t=2&s=1&h=1a9d57d94f62bc96ce60b3f2440e9cfe1431dd4c1aeca128d063298eedea3382",
      isVirtual: false,
    },
    {
      name: "bbc.com",
      image:
        "https://site-images.similarcdn.com/image?url=bbc.com&t=2&s=1&h=291e039829bc725eb527993be3c2f65b3b36650ab5d0ccac6af3ce04a40ab895",
      isVirtual: false,
    },
    {
      name: "bbc.co.uk",
      image:
        "https://site-images.similarcdn.com/image?url=bbc.co.uk&t=2&s=1&h=2504df308ae156462f074227084317d30aac945a43377934e7bceaf5906ceccf",
      isVirtual: false,
    },
    {
      name: "baike.baidu.com",
      image:
        "https://site-images.similarcdn.com/image?url=baike.baidu.com&t=2&s=1&h=b4ac92b01d98f4beb26b04b39cc4bd4039dfa27d50210528bfc12c72376bc152",
      isVirtual: false,
    },
    {
      name: "baijiahao.baidu.com",
      image:
        "https://site-images.similarcdn.com/image?url=baijiahao.baidu.com&t=2&s=1&h=c6275a27b5daed685edb11a5eec18ba8655d99021de062c9bf8b22fe08f1f98a",
      isVirtual: false,
    },
    {
      name: "blog.naver.com",
      image:
        "https://site-images.similarcdn.com/image?url=blog.naver.com&t=2&s=1&h=0c192ba81be48fafd71574734be437adb9960202ec4a6112a173294b1459c861",
      isVirtual: false,
    },
    {
      name: "accounts.google.com",
      image:
        "https://site-images.similarcdn.com/image?url=accounts.google.com&t=2&s=1&h=e7bbf324f8b1fece5635282a0abeb31cd2f132bd1c09b3aed8dedaf78d53afe1",
      isVirtual: false,
    },
    {
      name: "amazon.com",
      image:
        "https://site-images.similarcdn.com/image?url=amazon.com&t=2&s=1&h=43a07f575342a8fbb74cd3ca3ebf33760f2fbfbff603546fb4f7208244147dcc",
      isVirtual: false,
    },
    {
      name: "accuweather.com",
      image:
        "https://site-images.similarcdn.com/image?url=accuweather.com&t=2&s=1&h=9187eee86ff3830362647044a9df61d120227fa5016e01840a05005a662aad79",
      isVirtual: false,
    },
    {
      name: "apple.com",
      image:
        "https://site-images.similarcdn.com/image?url=apple.com&t=2&s=1&h=f81bb484ae2fd14ee7d230f4992fcf6f0e071e846a94e3f49400616e3f9c5063",
      isVirtual: false,
    },
    {
      name: "amazon.co.jp",
      image:
        "https://site-images.similarcdn.com/image?url=amazon.co.jp&t=2&s=1&h=c45fedff53f96bbf8ccb7d2d22ef189477b06d9ca83a590d2f039b1bf74cffb4",
      isVirtual: false,
    },
    {
      name: "aajtak.in",
      image:
        "https://site-images.similarcdn.com/image?url=aajtak.in&t=2&s=1&h=7e8a86d5b79cd67b5a3c7f5e6c71ced10008d6e8d1185ccee1321447a0675339",
      isVirtual: false,
    },
    {
      name: "auth0.openai.com",
      image:
        "https://site-images.similarcdn.com/image?url=auth0.openai.com&t=2&s=1&h=3704ee822f998f423a2e387b55e53df0e280c3bc4f5186ee624b4e4829c604c8",
      isVirtual: false,
    },
    {
      name: "aliexpress.com",
      image:
        "https://site-images.similarcdn.com/image?url=aliexpress.com&t=2&s=1&h=d276f202957fc1992170305299cf0078603f7f931e2158aa3ab6097731f1ed40",
      isVirtual: false,
    },
    {
      name: "adclick.g.doubleclick.net",
      image:
        "https://site-images.similarcdn.com/image?url=adclick.g.doubleclick.net&t=2&s=1&h=47b2356a164895f5e8942a9ac5be2e0ed3dd2035dda8b9459638e30aa5f5def1",
      isVirtual: false,
    },
    {
      name: "apps.samsung.com",
      image:
        "https://site-images.similarcdn.com/image?url=apps.samsung.com&t=2&s=1&h=804d8324e11dd03273124423faf01589789d0d9b5e9736f00dc76c0b2686e510",
      isVirtual: false,
    },
    {
      name: "google.com",
      image:
        "https://site-images.similarcdn.com/image?url=google.com&t=2&s=1&h=eb35e0fbafa3eb290132ffdfe47187d950d68daaa659ec1bd4b4e834b8f16461",
      isVirtual: false,
    },
    {
      name: "googleadservices.com",
      image:
        "https://site-images.similarcdn.com/image?url=googleadservices.com&t=2&s=1&h=c0cca2b8c0cd2920ad4634ef4be51888a836900c2d6797062b88048290451fba",
      isVirtual: false,
    },
    {
      name: "g.doubleclick.net",
      image:
        "https://site-images.similarcdn.com/image?url=g.doubleclick.net&t=2&s=1&h=d57b3f3b3f1a3f7f09b0bbbb96455b3e2fa1c5e17970eb69b81039acece803df",
      isVirtual: false,
    },
    {
      name: "globo.com",
      image:
        "https://site-images.similarcdn.com/image?url=globo.com&t=2&s=1&h=d43aa75cabc939383c66271b0de2de1cce20a20180a27cb130bf5d699031211e",
      isVirtual: false,
    },
    {
      name: "google.com.br",
      image:
        "https://site-images.similarcdn.com/image?url=google.com.br&t=2&s=1&h=f504c8926f9f375805be71b441f7e0075edd929c948130d744438f373adaf17c",
      isVirtual: false,
    },
    {
      name: "godpvqnszo.com",
      image:
        "https://site-images.similarcdn.com/image?url=godpvqnszo.com&t=2&s=1&h=91fd7fed9c7f3dc039caed94ff4b54f7ef42e8f23fec10a54587b10b08508644",
      isVirtual: false,
    },
    {
      name: "googleads.g.doubleclick.net",
      image:
        "https://site-images.similarcdn.com/image?url=googleads.g.doubleclick.net&t=2&s=1&h=3b1a6852d05c4a11cf40962febfc1e474046d8337d13e06e1ffad5fa10016fd5",
      isVirtual: false,
    },
    {
      name: "github.com",
      image:
        "https://site-images.similarcdn.com/image?url=github.com&t=2&s=1&h=6525c30f1af9ea5ee8912788b611744e1debcba39a2d0dd3d410b2916169883b",
      isVirtual: false,
    },
    {
      name: "google.de",
      image:
        "https://site-images.similarcdn.com/image?url=google.de&t=2&s=1&h=473e6b51dba1ccd6fb40f8eb77cab04ca43adfde99d5232b7c67ac03b0ba266a",
      isVirtual: false,
    },
  ],
  companies: null,
};

export const categories = [
  {
    id: 1,
    title: "TWITTER",
    data: [
      {
        id: 1,
        image: "images/img-1.jpg",
        description:
          "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain.",
      },
      { id: 2, image: "images/img-1.jpg", description: "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain." },
    ],
  },
  {
    id: 1,
    title: "FACEBOOK",
     data: [
      {
        id: 1,
        image: "images/img-1.jpg",
        description:
          "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain.",
      },
      { id: 2, image: "images/img-1.jpg", description: "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain." },
    ],
  },
  {
    id: 1,
    title: "YOUTUBE",
     data: [
      {
        id: 1,
        image: "images/img-1.jpg",
        description:
          "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain.",
      },
      { id: 2, image: "images/img-1.jpg", description: "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain." },
    ],
  },
  {
    id: 1,
    title: "NEWS",
     data: [
      {
        id: 1,
        image: "images/img-1.jpg",
        description:
          "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain.",
      },
      { id: 2, image: "images/img-1.jpg", description: "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain." },
    ],
  },
  {
    id: 1,
    title: "GOOGLE SEARCH",
     data: [
      {
        id: 1,
        image: "images/img-1.jpg",
        description:
          "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain.",
      },
      { id: 2, image: "images/img-1.jpg", description: "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain." },
    ],
  },
  {
    id: 1,
    title: "WIKI",
     data: [
      {
        id: 1,
        image: "images/img-1.jpg",
        description:
          "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain.",
      },
      { id: 2, image: "images/img-1.jpg", description: "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain." },
    ],
  },
  {
    id: 1,
    title: "REDDIT",
     data: [
      {
        id: 1,
        image: "images/img-1.jpg",
        description:
          "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain.",
      },
      { id: 2, image: "images/img-1.jpg", description: "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain." },
    ],
  },
  {
    id: 1,
    title: "BLOGS",
     data: [
      {
        id: 1,
        image: "images/img-1.jpg",
        description:
          "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain.",
      },
      { id: 2, image: "images/img-1.jpg", description: "CheckBrand is the only platform to track your digital ranking, digital presence, and social media presence along with detailed analytics reports by which you can measure all the parameters of a digital domain." },
    ],
  },
];

export const graph1 = {
  series: [
    {
      name: "series1",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    {
      name: "series2",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2018-09-19T00:00:00.000Z",
        "2018-09-19T01:30:00.000Z",
        "2018-09-19T02:30:00.000Z",
        "2018-09-19T03:30:00.000Z",
        "2018-09-19T04:30:00.000Z",
        "2018-09-19T05:30:00.000Z",
        "2018-09-19T06:30:00.000Z",
      ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  },
};

export const graph = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export const headerTabs=[
  {id:1,route:'/',title:"Home"},
  {id:2,route:'/top100',title:"Top 100"},
  // {id:3,route:'#',title:"Trends"},
  {id:4,route:'/blogs',title:"Blogs"}
 ]
