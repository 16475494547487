import React from "react";
import { Link, useLocation } from "react-router-dom";

const Section2 = ({ dash_data}) => {
  const location = useLocation();
  const slug = location.pathname.replace("/dashboard/", "");
  return (
    <>
        <section className="InsightsSectionArea bgArea-5">
          <div className="container">
            <h1>
              Current
              <strong>Digital Ranking</strong>
            </h1>
            <div className="row align-items-center">
              <div className="col">
                <div className="InsightsArea">
                  <h2>
                    #
                    <span className="number counter-item-active">
                      {dash_data?.response?.global_ranking}
                    </span>
                  </h2>
                  <h6>Digital Ranking</h6>
                </div>
              </div>
              <div className="col">
                <div className="InsightsArea">
                  <h2>
                    <span className="number counter-item-active">
                      {Math.floor(dash_data?.response?.brand_value / 10000000)}
                    </span>{" "}
                    Cr
                  </h2>
                  <h6>Brand Value</h6>
                </div>
              </div>
              <div className="col">
                <div className="InsightsArea">
                  <div className="circularprogressbar">
                    <div className="boxss">
                      <div
                        className="chartcircular progressblue"
                        data-percent="86.37"
                        data-scale-color="#fc6f31"
                        data-bar-color="#fc6f31"
                      >
                        <h2>
                          <span className="number counter-item-active">
                            {Math.floor(dash_data?.response?.score)}%
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <h6>Brand Score</h6>
                </div>
              </div>
              <div className="col">
                <div className="InsightsArea">
                  <h2>
                    #
                    <span className="number counter-item-active">
                      {dash_data?.response?.category_rank}
                    </span>
                  </h2>
                  <h6>Category Ranking</h6>
                </div>
              </div>

              <div className="col">
                <div className="InsightsArea">
                  <h2>
                    <span className="number counter-item-active">
                      {Math.floor(dash_data?.response?.avg_rating)}
                    </span>
                    /5
                  </h2>
                  <h6>Rating</h6>
                  <Link to={`/review/${slug}`} className="ratingBtn mt-2">
                    Your Rating
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
};

export default Section2;
