import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
// import { Navigation } from 'react-router-dom';
import { getDashData } from '../../api/api'
import { useNavigate } from 'react-router-dom';
import Loader from '../../common/Loader';
import Header from '../../common/Header';
import TopTabArea from '../../common/TopTabArea';
// import Authenticate_Dialogue from '../../../common/Authenticate_Dialogue';
import OverView from './Overview/Overview';
import Security_Privacy from './Security_Privacy/Security_Privacy'
import Performace_Technical from './Performance_Technical/Performace_Technical'
import Footer from '../../common/Footer';
import Accessiblity from './Accessibility/Accessiblity'
import Branding_Visual from './Branding_Visual/Branding_Visual'
import Interactivity_Engagement from './Interactivity_Engagement/Interactivity_Engagement'
import Content_Information from './Content_Information/Content_Information'
import Mobile_Responsive from './Mobile_Responsive/Mobile_Responsive'
import Integration_Services from './Integration_Services/Integration_Services';
import Navigation from './Navigation/Navigation'
// import Authenticate_Dialogue from '../../common/Authenticate_Dialogue';

const Web_Dashboard = () => {
    const location=useLocation();
    const [open, setOpen] = React.useState(false);
    const id=location.pathname.replace('/web-dashboard/','')
    const [dashData,setDashData]=useState([]);
    const[isAuthenticated,setIsAuthenticated]=useState();
    const [loading,setLoading]=useState();
    async function  getData() {
   await setLoading(true);
   await axios
     .get(`${getDashData}${id}`, {
       headers: {
         accept: "*/*",
         "Auth-Token": `${localStorage.getItem('token')}`,
       },
     })
     .then((res) => {
       console.log("!@#$%^&*(", res?.data?.data);
       setDashData(res.data.data);
     })
     .catch((error) => {
      setIsAuthenticated(error?.response?.data?.message)
       console.log("Error",error?.response?.data?.message);
     });
     await setLoading(false)
    }

    useEffect(()=>{
   getData();
    },[])
  // const [loading,setLoading]=useState();
    const navigate = useNavigate();
 
  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //   } else {
  //     navigate("/login");
  //   }
  // }, []);

  useEffect(()=>{
    if(isAuthenticated === 'Unauthorized'){
      setOpen(true)
      console.log("You are not authenticated please login again.")
    }

  },[isAuthenticated])

  const [activeTab,setActiveTab]=useState('Overview')
  return <>
  {loading ? <Loader /> : 
  <>
  <Header />
  <TopTabArea dashData={dashData} setActiveTab={setActiveTab} activeTab={activeTab} />
  {/* <Authenticate_Dialogue open={open} setOpen={setOpen} title="You are not authenticated. Please login again" /> */}
      {(() => {
        switch (activeTab) {
          case 'Overview':
            return <OverView dashData={dashData} loading={loading} setActiveTab={setActiveTab}  />
            case 'Security and Privacy':
              return <Security_Privacy activeTab={activeTab} dashData={dashData} loading={loading} />
              case 'Performance and Technical':
              return <Performace_Technical activeTab={activeTab} dashData={dashData} loading={loading} />
              case 'Integration & Services':
              return <Integration_Services activeTab={activeTab} dashData={dashData} loading={loading} />
              case 'Mobile Responsiveness':
              return <Mobile_Responsive activeTab={activeTab} dashData={dashData} loading={loading} />
              case 'Content and Information':
              return <Content_Information activeTab={activeTab} dashData={dashData} loading={loading} />
              case 'Interactivity and Engagement':
              return <Interactivity_Engagement activeTab={activeTab} dashData={dashData} loading={loading} />
              case 'Branding and Visual Identity':
              return <Branding_Visual activeTab={activeTab} dashData={dashData} loading={loading} />
              case 'Navigation':
              return <Navigation activeTab={activeTab} dashData={dashData}  loading={loading} />
              case 'Accessibility':
              return <Accessiblity activeTab={activeTab} dashData={dashData} loading={loading} />
        }
      })()}
      
      <Footer />
  </>}
  </>
}

export default Web_Dashboard
