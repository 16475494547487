import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { reqData } from "../../common/api";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
const Main_Blog = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const blogApi = process.env.REACT_APP_BLOGS;
  const location=useLocation();
  const navigate=useNavigate();

  function getData() {
    reqData(blogApi, setData, "", "", setLoading);
  }

  useEffect(() => {
    getData();
  }, []);

  const formattedDate = (blogDate) => {
    const date = new Date(blogDate);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  };

  const getImage=(defaultImage,blogImage)=>{
    console.log("Default Image is",defaultImage,"Blob Image",blogImage);
   const image = blogImage ? defaultImage?.replace("/blog_default.jpeg","") + "/" + blogImage : defaultImage;
   return image;
  }

  console.log('BLOGS',data);

  function getDetails(slug){
    navigate(`/blog_details/${slug}`)
  }

  const bg_color='#ffffff'
  return (
    <>
    {loading? <Loader bgColor={bg_color} /> :
      <div className="wrapper">
        <Header />
        <section className="innerHeader">
          <h2>Blog</h2>
          <span>Digital | Social | Online | Influencers</span>
        </section>
        <section className="pb-10 live-auction pt-8">
          <div className="container">
            {data?.blogs?.map((item, index) => {
              return (
                <div className="row" key={index}>
                  <div className="col-md-12">
                    <div className="BlogMainPage">
                      <div className="loop-grid mb-30">
                        <div className="widget-header-1 position-relative mb-30  wow fadeInUp animated">
                          <h5 className="mt-5 mb-30">
                            {item.category}
                            <p
                              // 
                            //   data-bs-toggle="modal"
                            //   data-bs-target="#RegisterBrand"
                              className="btn btn-gradient btn-small"
                            >
                              <span
                                style={{
                                  padding: 0,
                                  color: "white",
                                  fontWeight: 400,
                                }}
                                onClick={()=>navigate(`/sub_blogs/${item.slug}`)}
                              >
                                View All{" "}
                              </span>
                            </p>
                          </h5>
                        </div>

                        <div className="row">
                          {item?.blogs?.map((blogItem, index) => {
                            return index === 0 ? (
                              <div className="col-lg-8 mb-30" onClick={()=>getDetails(blogItem.slug)}>
                                <div className="carausel-post-1 hover-up border-radius-10 overflow-hidden transition-normal position-relative wow fadeInUp animated">
                                  <div className="position-relative post-thumb">
                                    <div
                                      className="thumb-overlay img-hover-slide position-relative"
                                      style={{
                                        backgroundImage: `url(${getImage(data.blog_default_image_path,blogItem.image)})`,
                                      }}
                                    >
                                      <a className="img-link" ></a>
                                      <span className="top-left-icon bg-warning">
                                        <i className="ri-walk-line"></i>
                                      </span>
                                      <div className="post-content-overlay text-white ml-30 mr-30 pb-30">
                                        {/* <div className="entry-meta meta-0 font-small mb-4">
                                             <a ><span
                                                   className="post-cat text-info text-uppercase">Travel</span></a>
                                             <a ><span
                                                   className="post-cat text-success text-uppercase">Animal</span></a>
                                          </div> */}
                                        <h3 className="post-title font-weight-900 mb-1">
                                          <a className="text-white" >
                                            {blogItem.title}
                                          </a>
                                        </h3>
                                        <div className="entry-meta meta-1 font-small text-white mt-4 ">
                                          <span className="post-on">
                                            {blogItem?.reading_time}
                                          </span>
                                          <span className="hit-count has-dot">
                                            {blogItem.views} Views
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <article onClick={()=>getDetails(blogItem.slug)}
                                className="col-lg-4 col-md-6 mb-30 wow fadeInUp animated"
                                data-wow-delay="0.2s"
                              >
                                <div className="post-card-1 border-radius-10 hover-up">
                                  <div
                                    className="post-thumb thumb-overlay img-hover-slide position-relative"
                                    style={{
                                        backgroundImage: `url(${getImage(data.blog_default_image_path,blogItem.image)})`,
                                      }}
                                  >
                                    <a className="img-link" ></a>
                                    <span className="top-right-icon bg-success">
                                      <i className="ri-advertisement-line"></i>
                                    </span>
                                    <ul className="social-share">
                                      <li>
                                        <a >
                                          <i className="elegant-icon social_share"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="fb"
                                          
                                          title="Share on Facebook"
                                          target="_blank"
                                        >
                                          <i className="elegant-icon social_facebook"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="tw"
                                          
                                          target="_blank"
                                          title="Tweet now"
                                        >
                                          <i className="elegant-icon social_twitter"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="pt"
                                          
                                          target="_blank"
                                          title="Pin it"
                                        >
                                          <i className="elegant-icon social_pinterest"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="post-content p-30">
                                    {/* <div className="entry-meta meta-0 font-small mb-4">
                                       <a ><span className="post-cat text-info">Travel</span></a>
                                       <a ><span className="post-cat text-success">Food</span></a>
                                    </div> */}
                                    <div className="d-flex post-card-content">
                                      <h5 className="post-title mb-4 font-weight-900">
                                        <a  style={{fontSize:'14px'}}>{blogItem.title}</a>
                                      </h5>
                                      <div className="entry-meta meta-1 float-start font-x-small text-uppercase">
                                        <span className="post-on">
                                          {formattedDate(blogItem.date)}
                                        </span>
                                        <span className="time-reading has-dot">
                                          {blogItem?.reading_time}
                                        </span>
                                        <span className="post-by has-dot">
                                          {blogItem.views} views
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </article>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        <Footer />
      </div>}
    </>
  );
};

export default Main_Blog;
