import React, { useState } from "react";
import { Link } from "react-router-dom";

const Brand_Register = () => {
  const [show, setShow] = useState(false);
  const [icons, setIcons] = useState([
    { id: 1, icon: "ri-facebook-line", hoverBgColor: "icon-facebook" },
    { id: 1, icon: "ri-twitter-line", hoverBgColor: "icon-twitter" },
    { id: 1, icon: "ri-instagram-line", hoverBgColor: "icon-instagram" },
    { id: 1, icon: "ri-linkedin-line", hoverBgColor: "icon-linkedin" },
    { id: 1, icon: "ri-youtube-line", hoverBgColor: "icon-youtube" },
  ]);

  const [socialField,setSocialField]=useState([
    {id:1,
    }
  ])
  return (
    <>
      <div className="bg-video-wrap promoPageBg">
        <video
          src="https://checkbrand.online/frontend/videos/CBsearchVideos.mp4"
          loop
          muted
          autoPlay
        />
        <div className="overlay" />
      </div>

      <div className="oxyy-login-register login-30">
        <div className="container-fluid px-0">
          <div className="row g-0 min-vh-100">
            <div className="col-md-6">
              <div className="hero-wrap d-flex align-items-center h-100">
                <div className="hero-mask opacity-5 bg-dark" />
                <div
                  className="hero-bg hero-bg-scroll"
                  style={{ backgroundImage: "url('./images/login-bg-6.jpg')" }}
                />
                <div className="hero-content mx-auto w-100 h-100">
                  <div className="container d-flex flex-column h-100">
                    <div className="row g-0">
                      <div className="col-11 col-lg-9 mx-auto">
                        <div className="logo mt-5 mb-5">
                          {" "}
                          <Link className="d-flex" to="/" title="">
                            <img src="images/logo.png" alt="" />
                          </Link>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="row g-0 mt-3">
                      <div className="col-11 col-lg-10 mx-auto">
                        <h1 className="text-9 text-white fw-300 mb-5">
                          <span className="fw-500">Check Your </span>Digital
                          Rank and Score
                        </h1>
                        <p>
                          CheckBrand is a one-stop solution that will provide
                          you with Digital Ranking & Digital Brand Value with
                          our unique AI algorithm and will help you further
                          enhance your digital presence. To know more about your
                          Brand presence, kindly register with us.
                        </p>

                        <a className="btn btn-gradient btn-small">
                          <span>
                            <i className="ri-home-line" />
                            Home
                          </span>
                        </a>

                        <div className="social mt-4">
                          {icons.map((item)=>{
                          return <a className={item.hoverBgColor} key={item.id}>
                            <i className={item.icon} />
                          </a>})}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column align-items-center bg-dark">
              <div className="container my-auto py-5">
                <div className="row g-0">
                  <div className="col-11  mx-auto">
                    <h3 className="text-white mb-2">Register Your Brand</h3>
                    <p className="text-2 text-light mb-4">
                      To measure your Digital-Ranking | Digital Score | Share of
                      Voice | Competition Analysis | Digital media monitoring
                    </p>

                    <form id="loginForm" className="form-dark" method="post">
                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="emailAddress"
                            required
                            placeholder="Brand Name *"
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <input
                            type="password"
                            className="form-control"
                            id="loginPassword"
                            required
                            placeholder="Email Address *"
                            autoComplete="off"
                          />
                        </div>

                        <div className="col-lg-6 mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="emailAddress"
                            required
                            placeholder="Phone *"
                            autoComplete="off"
                          />
                        </div>
                        <div
                          className="col-lg-6 mb-3"
                          onClick={() => setShow(!show)}
                        >
                          <a
                            id="add_social_link"
                            className="btn btn-gradient btn-small"
                          >
                            <span>
                              <i className="ri-home-line" />
                              {show ? "Hide Social Links" : "Add Social Links"}
                            </span>
                          </a>
                        </div>
                        {show && (
                          <div className="col-lg-12 mb-3">
                            <div className="socialLinksPanel">
                              <div className="row">
                                <div className="col-lg-6 mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="emailAddress"
                                    required
                                    placeholder="Website Link"
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="emailAddress"
                                    required
                                    placeholder="Facebook Link"
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="emailAddress"
                                    required
                                    placeholder="Twitter Link"
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="emailAddress"
                                    required
                                    placeholder="Instagram Link"
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="emailAddress"
                                    required
                                    placeholder="Linkedin Link"
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="emailAddress"
                                    required
                                    placeholder="Youtube Link"
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-lg-12 mb-3">
                          <select
                            id=""
                            name="brand_user_type"
                            className="form-control"
                          >
                            <option value="">-- Select --</option>
                            <option value="2">Actors</option>
                            <option value="4">CEO</option>
                            <option value="5">Company</option>
                            <option value="7">Cricketers</option>
                            <option value="6">Influencers</option>
                            <option value="8">Ministries</option>
                            <option value="1">Politicians</option>
                            <option value="0">Others</option>
                          </select>
                        </div>
                        <div className="col-12 col-lg-8">
                          <div className="row">
                            <div className="radio col-lg-12 d-flex mb-1">
                              <input
                                id="trms"
                                name="terms"
                                type="checkbox"
                                value="1"
                                className="chkbox mr-2"
                              />
                              <label
                                htmlFor="trms"
                                className="radio-label mb-0"
                              >
                                I accept{" "}
                                <a target="_blank">terms and conditions</a>
                              </label>
                            </div>
                            <div className="radio col-lg-12 d-flex">
                              <input
                                id="nwsltr"
                                name="newsletter"
                                type="checkbox"
                                className="chkbox mr-2"
                                value="1"
                              />
                              <label
                                htmlFor="nwsltr"
                                className="radio-label mb-0"
                              >
                                I want to receive news via email
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <input
                            type="submit"
                            value="REGISTER BRAND"
                            className="btn btn-gradient btn-small"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brand_Register;
