import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { reqData } from "../common/api";

const Login = () => {
  const [data,setData]=useState();
  const [loading,setLoading]=useState();
  const navigate=useNavigate();
  const [login,setLogin]=useState({
    email:"",
    password:""
  })

  const [error, setError] = useState({
    emailError: "",
    passwordError: "",
  });

  const login_api=process.env.REACT_APP_LOGIN;

    const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(value)) {
        setError({ ...error, emailError: "Invalid email address" });
      } else {
        setError({ ...error, emailError: "" });
      }
    }
    setLogin({ ...login, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    reqData(login_api,setData,"login",login, setLoading,navigate)
  };

  useEffect(() => {
    if (login.password.length > 0) {
      if (login.password.length < 5 || login.password.length > 20) {
        setError({
          ...error,
          passwordError:
            "Password length should be more than 5 and less than or equal to 20",
        });
      } else {
        setError({ ...error, passwordError: "" });
      }
    }
  }, [login.password]);

  useEffect(()=>{
    if(localStorage.getItem('checkbrand-token')){
      navigate("/")
    }
  },[])

  // useEffect(()=>{
  //   if(data?.access_token){
  //     localStorage.setItem('checkbrand-token',data.access_token)
  //     // navigate("/")
  //   }
  // },[data])

  // useEffect(()=>{
  //   if(!localStorage.getItem("checkbrand-token")){
  //     navigate("/login")
  //   }
  //   else{
  //     navigate("/dashboard")
  //   }
  // },[])
  // console.log("Data is",data)

  return (
    <>
      <section className="loginBoxArea">
        <div className="fxt-content-wrap">
          <div className="fxt-heading-content">
            <div className="fxt-inner-wrap fxt-transformX-R-50 fxt-transition-delay-3">
              <div className="fxt-transformX-R-50 fxt-transition-delay-10">
                <Link to="/" className="fxt-logo">
                  <img src="/images/logo.png" alt="Logo" />
                </Link>
              </div>
              <div className="fxt-transformX-R-50 fxt-transition-delay-10">
                <div className="fxt-middle-content">
                  <div className="fxt-sub-title">Welcome to</div>
                  <h1 className="fxt-main-title">We're a Digital Agency.</h1>
                  <p className="fxt-description">
                    We are glad to see you again! Get access to your Orders,
                    Wishlist and Recommendations.
                  </p>
                </div>
              </div>
              <div className="fxt-transformX-R-50 fxt-transition-delay-10">
                <div className="fxt-switcher-description">
                  Don't have an account?
                  <Link to="/" className="fxt-switcher-text">
                    Register
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="fxt-form-content">
            <div className="fxt-main-form">
              <div className="fxt-inner-wrap fxt-opacity fxt-transition-delay-13">
                <h2 className="fxt-page-title">Log In</h2>
                <p className="fxt-description">
                  Log in to try our amazing services
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="email" className="fxt-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      name="email"
                      value={login.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required="required"
                    />
                    <p style={{marginLeft:'10px',color:'red'}}>{error.emailError}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="fxt-label">
                      Password
                    </label>
                    <input
                      id="password"
                      type="password"
                      className="form-control"
                      name="password"
                      value={login.password}
                      onChange={handleChange}
                      placeholder="Enter Password"
                      required="required"
                    />
                     <p style={{marginLeft:'10px',color:'red'}}>{error.passwordError}</p>
                  </div>
                  <div className="form-group">
                    <Link to="/" className="fxt-switcher-text">
                      Forgot Password
                    </Link>
                  </div>
                  <div className="form-group mb-3">
                    {/* <Link to="/dashboard"> */}
                    <button type="submit" className="fxt-btn-fill">
                       { loading ? "Loading..." : "Log in"}
                      {/* {loading ? "Loading..." : "Log in"} */}
                    </button>
                    {/* </Link> */}
                  </div>
                </form>
                <div className="fxt-divider-text">OR</div>
                <div id="fxt-login-option" className="fxt-login-option">
                  <ul>
                    <li className="fxt-google active">
                      <Link to="/">
                      <span className="fxt-option-icon"><i style={{fontSize:"18px",marginRight:'5px',color:'white'}} className="fa">&#xf1a0;</i></span>
                        <span className="fxt-option-text ml-2" style={{color:'white'}}>
                          Continue with Google
                        </span>
                      </Link>
                    </li>
                    <li className="fxt-facebook">
                      <Link to="/">
                        <span className="fxt-option-icon">
                        <i style={{fontSize:"18px",marginRight:'5px',color:'white'}} className="fa">&#xf099;</i>
                        </span>
                        <span className="fxt-option-text ml-2">
                          Continue with Facebook
                        </span>
                      </Link>
                    </li>
                    <li className="fxt-apple">
                      <Link to="/">
                        <span className="fxt-option-icon">
                        <i style={{fontSize:"18px",marginRight:'5px',color:'white'}} className="fa">&#xf179;</i>
                        </span>
                        <span className="fxt-option-text ml-2">
                          Continue with Apple
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;