import React from 'react'

const Keywords_Graph = (active) => {
  return <>
  <div
                className={`tab-pane active`}
                id="Keywords-home"
                role="tabpanel"
                aria-labelledby="Keywords-home-tab"
              >
                <div className="row">
                  <div className="col-md-4">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-bars line-height16"></i>
                        </div>
                        <div className="chart-title-text">1 Word</div>
                      </div>
                      <div id="hashtags">
                        <table
                          width="100%"
                          className="newsTableBox"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th scope="col" className="post-number">
                                #
                              </th>
                              <th scope="col">Keywords</th>
                              <th scope="col" className="post-medium-col">
                                Count
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="odd">
                              <td>2</td>
                              <td>https</td>
                              <td>1955</td>
                            </tr>
                            <tr className="odd">
                              <td>3</td>
                              <td>the</td>
                              <td>1476</td>
                            </tr>
                            <tr className="odd">
                              <td>4</td>
                              <td>of</td>
                              <td>1399</td>
                            </tr>
                            <tr className="odd">
                              <td>5</td>
                              <td>narendra</td>
                              <td>1258</td>
                            </tr>
                            <tr className="odd">
                              <td>6</td>
                              <td>modi</td>
                              <td>1221</td>
                            </tr>
                            <tr className="odd">
                              <td>7</td>
                              <td>pm</td>
                              <td>975</td>
                            </tr>
                            <tr className="odd">
                              <td>8</td>
                              <td>in</td>
                              <td>919</td>
                            </tr>
                            <tr className="odd">
                              <td>9</td>
                              <td>are</td>
                              <td>619</td>
                            </tr>
                            <tr className="odd">
                              <td>10</td>
                              <td>is</td>
                              <td>520</td>
                            </tr>
                            <tr className="odd">
                              <td>11</td>
                              <td>cm</td>
                              <td>515</td>
                            </tr>
                            <tr className="odd">
                              <td>12</td>
                              <td>four</td>
                              <td>506</td>
                            </tr>
                            <tr className="odd">
                              <td>13</td>
                              <td>photos</td>
                              <td>498</td>
                            </tr>
                            <tr className="odd">
                              <td>14</td>
                              <td>के</td>
                              <td>486</td>
                            </tr>
                            <tr className="odd">
                              <td>15</td>
                              <td>and</td>
                              <td>479</td>
                            </tr>
                            <tr className="odd">
                              <td>16</td>
                              <td>to</td>
                              <td>441</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-bars line-height16"></i>
                        </div>
                        <div className="chart-title-text">2 Word</div>
                      </div>
                      <div id="hashtags">
                        <table
                          width="100%"
                          className="newsTableBox"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th scope="col" className="post-number">
                                #
                              </th>
                              <th scope="col">Keywords</th>
                              <th scope="col" className="post-medium-col">
                                Count
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="odd">
                              <td>2</td>
                              <td>narendra modi</td>
                              <td>1096</td>
                            </tr>
                            <tr className="odd">
                              <td>3</td>
                              <td>pm narendra</td>
                              <td>572</td>
                            </tr>
                            <tr className="odd">
                              <td>4</td>
                              <td>https via</td>
                              <td>407</td>
                            </tr>
                            <tr className="odd">
                              <td>5</td>
                              <td>namo app</td>
                              <td>404</td>
                            </tr>
                            <tr className="odd">
                              <td>6</td>
                              <td>via namo</td>
                              <td>402</td>
                            </tr>
                            <tr className="odd">
                              <td>7</td>
                              <td>of the</td>
                              <td>302</td>
                            </tr>
                            <tr className="odd">
                              <td>8</td>
                              <td>with the</td>
                              <td>265</td>
                            </tr>
                            <tr className="odd">
                              <td>9</td>
                              <td>app https</td>
                              <td>255</td>
                            </tr>
                            <tr className="odd">
                              <td>10</td>
                              <td>cm eknath</td>
                              <td>250</td>
                            </tr>
                            <tr className="odd">
                              <td>11</td>
                              <td>eknath shinde</td>
                              <td>250</td>
                            </tr>
                            <tr className="odd">
                              <td>12</td>
                              <td>four photos</td>
                              <td>250</td>
                            </tr>
                            <tr className="odd">
                              <td>13</td>
                              <td>yogi adityanath</td>
                              <td>250</td>
                            </tr>
                            <tr className="odd">
                              <td>14</td>
                              <td>droupadi murmu</td>
                              <td>249</td>
                            </tr>
                            <tr className="odd">
                              <td>15</td>
                              <td>cm yogi</td>
                              <td>248</td>
                            </tr>
                            <tr className="odd">
                              <td>16</td>
                              <td>is viral</td>
                              <td>248</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-bars line-height16"></i>
                        </div>
                        <div className="chart-title-text">3 Word</div>
                      </div>
                      <div id="hashtags">
                        <table
                          width="100%"
                          className="newsTableBox"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th scope="col" className="post-number">
                                #
                              </th>
                              <th scope="col">Keywords</th>
                              <th scope="col" className="post-medium-col">
                                Count
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="odd">
                              <td>2</td>
                              <td>pm narendra modi</td>
                              <td>473</td>
                            </tr>
                            <tr className="odd">
                              <td>3</td>
                              <td>via namo app</td>
                              <td>402</td>
                            </tr>
                            <tr className="odd">
                              <td>4</td>
                              <td>https via namo</td>
                              <td>401</td>
                            </tr>
                            <tr className="odd">
                              <td>5</td>
                              <td>namo app https</td>
                              <td>255</td>
                            </tr>
                            <tr className="odd">
                              <td>6</td>
                              <td>cm eknath shinde</td>
                              <td>250</td>
                            </tr>
                            <tr className="odd">
                              <td>7</td>
                              <td>cm yogi adityanath</td>
                              <td>248</td>
                            </tr>
                            <tr className="odd">
                              <td>8</td>
                              <td>four photos is</td>
                              <td>248</td>
                            </tr>
                            <tr className="odd">
                              <td>9</td>
                              <td>of four photos</td>
                              <td>248</td>
                            </tr>
                            <tr className="odd">
                              <td>10</td>
                              <td>photos is viral</td>
                              <td>248</td>
                            </tr>
                            <tr className="odd">
                              <td>11</td>
                              <td>adityanath in their</td>
                              <td>246</td>
                            </tr>
                            <tr className="odd">
                              <td>12</td>
                              <td>and up cm</td>
                              <td>246</td>
                            </tr>
                            <tr className="odd">
                              <td>13</td>
                              <td>are misleading @shinjineemjmdr</td>
                              <td>246</td>
                            </tr>
                            <tr className="odd">
                              <td>14</td>
                              <td>are photos of</td>
                              <td>246</td>
                            </tr>
                            <tr className="odd">
                              <td>15</td>
                              <td>claim that they</td>
                              <td>246</td>
                            </tr>
                            <tr className="odd">
                              <td>16</td>
                              <td>collage of four</td>
                              <td>246</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  </>
}

export default Keywords_Graph
