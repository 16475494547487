import React, { useState } from "react";

const LeftMenu = ({ pathname }) => {
  const[active,setActive]=useState("#Digital-Ranking");
  const menuLinks = [
    { id: 1, toPath: "#Digital-Ranking", icon: "ri-edit-circle-line" ,title:"Digital Ranking"},
    { id: 2, toPath: "#Recommendation", icon: "ri-medal-line" ,title:"Recommendations"},
    // { id: 3, toPath: "#Competition", icon: "ri-line-chart-line" ,title:"Competition"},
    { id: 4, toPath: "#Trends", icon: "ri-hashtag" ,title:"Trends"},
    { id: 5, toPath: "#Consultation", icon: "ri-user-line" ,title:"Consultation"},
  ];
  return (
    <>
      <div className="searchLeftMenu">
        <ul>
          {menuLinks?.map((item,index)=>{
        return  <li key={index} className={`${item.toPath === active && "active"}`} onClick={()=>setActive(item.toPath)}>
            <a href={pathname + item.toPath} className="scroll">
              <i className={item.icon}></i>
            </a>
            <span>{item.title}</span>
          </li>})}
        </ul>
      </div>
    </>
  );
};

export default LeftMenu;
