import './App.css';
import React, { useEffect } from 'react';
import Preloader from "../src/common/Preloader";
import Menu from './common/Menu';
import Modal from './common/Modal';
import Register_Modal from './component/Home/Modal';
import{ BrowserRouter as Router, Routes,Route, Navigate } from 'react-router-dom'
import Home from './component/Home';
import Top100 from './component/Top100/Top100';
import Login from './auth/Login';
import { useState } from 'react';
import Main from './component/InnerComponent/Main'
import { useLocation } from 'react-router-dom';
import MainReviews from './component/Reviews/MainReviews';
import Dash_Main from './dashboard/Dash_Main';
import Brand_Register from './common/Brand_Register';
import Web_Dashboard from './component/Web_Dashboard/Web_Dashboard';
import Authenticate_Dialogue from './common/Authenticate_Dialogue';
import axios from 'axios';
import Live from '../src/component/Live/Main'
import Main_Blog from './component/Blogs/Main_Blog';
import Sub_Blog from './component/Blogs/Sub_Blog';
import Detail_Blog from './component/Blogs/Detail_Blog';
import Contact from './component/Contact/Contact';
import Profile from './component/Profile/Profile';
function App() {
  const [showWeb,setShowWeb]=useState(false);
  const getToken=localStorage.getItem('checkbrand-token');
  const [open,setOpen]=useState(false);
  const title="You have been logged out. Please login again"
  const headers = { 'Authorization': `Bearer ${getToken}` }
  useEffect(()=>{
    axios.get(`https://api.frontend.checkbrand.online/public/api/v1/token`, {headers}).then((res)=>{
      // console.log("Authenticated Data is",res.data.status)
      setOpen(res?.data?.status)
    }).catch((error)=>{
      console.log("Error during authenticity is",error)
    })
  },[])

  useEffect(()=>{
    if(open === 'expired'){
      setOpen(true)
    }
  },[open])
  return <>
  <Router>
  <Modal />
  <Menu />
  <Routes>
    <Route path='/' element={<Home setShowWeb={setShowWeb} showWeb={showWeb} />} />
    <Route path='/top100' element={<Top100 />} />
    <Route path='/login' element={<Login />} />
    <Route path='/dashboard/:id' element={<Main />} />
    <Route path='/detailed-dashboard' element={<Dash_Main />} />
    <Route path='/review/:id' element={<MainReviews />} />
    <Route path='/brand-register' element={<Brand_Register />} />
    <Route path='/web-dashboard/:id' element={<Web_Dashboard />} />
    <Route path='/live' element={<Live />} />
    <Route path='/blogs' element={<Main_Blog />} />
    <Route path='/contact' element={<Contact />} />
    <Route path='/sub_blogs/:id' element={<Sub_Blog />} />
    <Route path='/blog_details/:id' element={<Detail_Blog />} />
    <Route path='/profile' element={<Profile />} />
  </Routes>
  <Register_Modal />
  </Router> 
  {/* } */}
  </>
}

export default App;
