import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Compare from "./Compare";

const Section1 = ({
  dash_data,
  pathname,
  setCompareData,
  compareData,
  setAllData,
  allData,
  loading_compared,
  setLoading_Compared,
  setLoadingScreen
}) => {
  const [initialWeb, setInitialWeb] = useState('');

  useEffect(() => {
    if (dash_data) {
      setInitialWeb(dash_data?.response?.name);
      setCompareData(dash_data?.response);
    }
  }, [dash_data]);
  const compareField = {
    compareWeb: "",
    disabled:false,
    placeholder:true
  };
  const [field, setField] = useState([compareField]);

  return (
    <>
      <section className="searchSectionBox" id="Digital-Ranking">
        <div className="container">
          <div className="bgWhiteBox p-3">
            <div className="d-flex align-items-center gap-2">
              <div className="searchbar col">
                <div className="compareInputPanel">
                  <form acceptCharset="UTF-8" id="search_form">
                    <input
                      className="search_input mb-0 ui-autocomplete-input"
                      type="search"
                      name="initialWeb"
                      autoComplete="off"
                      value={initialWeb}
                    />
                    <p
                      className="searchloading  text-center"
                      style={{ display: "none" }}
                    >
                      loading...
                    </p>
                    <Link to={pathname + `#`} className="search_icon">
                      <i className="ri-search-line"></i>
                    </Link>
                    {/* <Link to={pathname +`#`} className="search_icon" style={{marginLeft:'10px'}}><i className="ri-search-line"></i></Link> */}
                  </form>
                  

                  {/* <div className="close-Area d-none ">
                    <i className="fas fa-ellipsis-v"></i>
                  </div> */}
                </div>
              </div>

              {field?.map((item, index) => {
                return (
                  <Compare
                  key={index}
                    item={item}
                    index={index}
                    id={dash_data?.response?.brand_user_type_id}
                    field={field}
                    compareData={compareData}
                    setField={setField}
                    compareField={compareField}
                    pathname={pathname}
                    setCompareData={setCompareData}
                    setAllData={setAllData}
                    allData={allData}
                    loading_compared={loading_compared}
                    setLoading_Compared={setLoading_Compared}
                    setLoadingScreen={setLoadingScreen}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section1;
