import React, { useRef ,useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import { rankingData } from "../../../commonJson/mockData";
import Common_Chart from "../../../common/Common_Chart";
import { useLocation } from "react-router-dom";

const Dash_2 = ({ dashData,setActiveTab }) => {
 const[show,setShow]=useState(false);
 const parentRef = useRef(null);
 const location = useLocation();
  const data1 = {
    series: [
      {
        name: "powermin",
        data: [240, 160, 671, 414, 555, 257, 901],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        labels: {
          show: false, // Set this to false to remove x-axis data
        },
      },
      yaxis: {
        labels: {
          show: false, // Set this to false to remove y-axis data
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      colors: ["#f6b243"],
    },
  };
  const searchPanelTab = [
    { id: 1, title: "Security and Privacy", score: "40", percent:"50", imgSrc: "/images/icon/01.svg" },
    { id: 2, title: "Performance and Technical", score: "40", percent:"50", imgSrc: "/images/icon/02.svg" },
    { id: 3, title: "Integration & Services", score: "40", percent:"50", imgSrc: "/images/icon/03.svg" },
    { id: 4, title: "Mobile Responsiveness", score: "40", percent:"50", imgSrc: "/images/icon/04.svg" },
    { id: 5, title: "Content and Information", score: "40", percent:"50", imgSrc: "/images/icon/05.svg" },
    { id: 6, title: "Interactivity and Engagement", score: "40", percent:"50", imgSrc: "/images/icon/06.svg" },
    { id: 7, title: "Branding and Visual Identity", score: "40", percent:"50", imgSrc: "/images/icon/07.svg" },
    { id: 8, title: "Navigation", score: "40", percent:"50", imgSrc: "/images/icon/08.svg" },
    { id: 9, title: "Accessibility", score: "40", percent:"50", imgSrc: "/images/icon/09.svg" },
  ];

  const handleClick = (item) => {
    setActiveTab(item.title);
  };


  const handleScroll = () => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      console.log("Rect.top",Math.floor(rect.top),"Height of window",window.innerHeight)
      if (Math.floor(rect.top) >= window.innerHeight) {
        console.log("Reached")
        setShow(true);
      } 
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  
  return (
    <>
       <section className="sectionQrPanelTotal">
         <div className="container">
            <div className="sectionQraboxTotal">

              

              <div className="row align-items-center">

                {/* <!-- left Area --> */}
                <div className="col-lg-6" style={{fontSize:'18px'}}>
                  All Ranking Score
                </div>
                {/* <!-- left Area --> */}

                {/* <!-- left Area --> */}
                <div className="col-lg-6 text-end boxTotal">

                  <h6>500/900</h6>
                  
                </div>
                {/* <!-- left Area --> */}
                

              </div>
             


            </div>
          </div>

       </section>
      <section className="sectionQrPanel">
         <div className="container">
         {/* <h1 onClick={()=>setShow(!show)}>
                      Click me
                    </h1> */}
            <div className="row">
               {/* <!-- box 01 --> */}
               {searchPanelTab?.map((item,index)=>{
               return<div className="col-lg-4" key={index} onClick={() => handleClick(item)} ref={parentRef}>
                  <div className="sectionQrabox">
                    
                     <a href={`${location.pathname}#`}>
                        <img src={item.imgSrc} />
                        {item.title}
                        <h4><span><i className="ri-line-chart-line"></i> Score </span> {item.score}/100</h4>
                        <div className="mt-2 sectionQrProgressArea">
                           <div className="d-flex">
                              <span className="tx-13 font-weight-semibold">India</span> 
                              <div className="ms-auto"><span className="number-font">{item.percent}</span>/100%</div>
                           </div>
                           <div className="progress ht-8 br-5">
                              <div className={`progress-bar progress-bar-striped progress-bar-animated bg-0${item.id}`} style={{
                        width: show ? "60%" : "0%",
                        transition: "width 1s ease-in-out", // Add transition here
                      }}></div>
                           </div>
                        </div>
                     </a>
                  </div>
               </div>})}
            </div>
         </div>
      </section>
      <section className="rankingSection-02" style={{display:'none'}}>
        <div className="container">
          <div className="d-flex gap-3  align-items-center mb-4 headingArea">
            <div>
              {/* <img src="http://webalytics.companydemo.in//assets/images/img06.jpg" alt="Logo" /> */}
              <img
                src={dashData?.website_logo}
                alt="Logo"
                style={{
                  height: "44px",
                  objectFit: "contain",
                  padding: "2px",
                  background: "white",
                }}
              />
            </div>
            <div>
              <h1>{dashData?.website_name}</h1>
            </div>
          </div>
          <div className="row">
            {dashData?.ranking?.map((item, index) => {
              return (
                <div className="col-md-3 col-lg-4" key={index}>
                  <div
                    className="card-03"
                    style={{
                      backgroundImage: `linear-gradient(to right, 
                        ${
                          (item.title == "Global Rank" && "#ff416c") ||
                          (item.title == "Country Rank" && "#56ccf2") ||
                          (item.title == "Category Rank" && "#78d23f")
                        } ,
                         ${
                           (item.title == "Global Rank" && "#ff4b2b") ||
                           (item.title == "Country Rank" && "#2f80ed") ||
                           (item.title == "Category Rank" && "#4ca80b")
                         })`,
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <i className="ri-dashboard-line"></i>
                      <div className="ms-auto text-end color-w">
                        {item.title}
                        <span>{item.rank}</span>
                      </div>
                    </div>

                    <div id="chart7"></div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row mt-3">
            <div className="col-lg-8">
              <div className="card bSd-01">
                <div className="card-body">
                  <Common_Chart height={305} width={750} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card bSd-01">
                <div className="card-body p-0">
                  <div className="p-3">
                    <h2 className="mb-3">Category Rank Last Month</h2>

                    <div className="d-flex gap-3 MinistryLogoArea align-items-center">
                      <div className="MinistryLogoArea">
                        {/* <img src="http://webalytics.companydemo.in//assets/images/img06.jpg" alt="Logo" /> */}
                        <img
                          src={dashData?.website_logo}
                          alt="Logo"
                          style={{
                            height: "91px",
                            objectFit: "contain",
                            padding: "10px",
                          }}
                        />
                      </div>
                      <div className="ministryName">
                        {dashData?.website_url}
                        <span>{dashData?.category_rank_last_month}</span>
                      </div>
                    </div>
                    {/* <!-- <div className="mt-3 text-end"><a href={`${location.pathname}#`} className="btn btn-info">See Top Websites</a></div> --> */}
                  </div>
                  <div className="" id="chart10">
                    <ReactApexChart
                      options={data1.options}
                      series={data1.series}
                      type="area"
                      height={150}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dash_2;
