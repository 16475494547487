import React from "react";

const Preloader = ({showPreLoader}) => {
  return (
    <>
      <div id="preloader" style={{display:showPreLoader && "none"}}>
        <div id="ctn-preloader" className="ctn-preloader">
          <div className="animation-preloader">
            <div className="icon">
              <img
                src="images/logo-preloader.png"
                alt="logo"
                className="m-auto d-block"
              />{" "}
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preloader;
