import React, { useEffect, useState } from 'react'
import Slider from "react-slick";

// import Header from '../../common/Header'
import { reqData } from '../../common/api';
import { useLocation } from 'react-router-dom';
import Header from '../../common/Header';

const Detail_Blog = () => {
   const [data,setData]=useState([]);
   const location=useLocation();
   const slug=location.pathname.replace("/blog_details/","")
   const [loading,setLoading]=useState();
   const settings = {
      dots: false,
      infinite: true,
      speed: 300,
      autoplay: true,
      slidesToShow: 2,
      slidesToScroll: 1,
    };
    const slider = React.useRef(null);
   const blogDetailApi=`${process.env.REACT_APP_DETAIL_BLOG}${slug}`;

   function getData(){
      reqData(blogDetailApi,setData,"","",setLoading)
   }

   useEffect(()=>{
      getData();
   },[])

   console.log("DETAIL_BLOG",data)
  return <>
  <div className="wrapper">
   <Header />
    <section className="innerHeader">
      <h2>Blog</h2>
      <span>Digital | Social | Online | Influencers</span>
    </section>
    
    <section className="pb-10 live-auction pt-8">
      <div className="container">
        <div className="row">
          
          <div className="col-md-12">
            <section className="section-p BlogPageArea two">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 pr-0">
                    <div className=" blog-img-card q-radius-10 position-relative q-p-margin-b-30">
                      <img src={`https://checkbrand.online/uploads/topics/${data?.detail?.photo_file}`} className="card-img-top"
                        alt="blog.webp" />
                      <div className="blog-cata-n q-radius-5 position-absolute">
                        <p className="m-0 q-text-heading-color">Business</p>
                      </div>
                      <div className="card-body">
                        <div className="col-lg-12 likeBoxArea">
                          
                          <div className="additional-info">
                            <p className="trending-news"><a href="#">TRENDING NEWS</a></p>
                            <p className="reading-time"><span>Reading Time</span> : 4 minutes 41 seconds</p>
                            <p className="by-admin"><i className="ri-pencil-fill"></i> by Admin</p>
                          </div>
                         
                          <div className="post-actions">
                            <span className="action-item"><i className="ri-thumb-up-fill"></i> 123 </span>
                            <span className="action-item"><i className="ri-thumb-down-fill"></i> 10 </span>
                            <span className="action-item"><i className="ri-message-fill"></i> 25 </span>
                            <span className="action-item"><i className="ri-eye-fill"></i> 500 </span>
                          </div>
                        </div>
                        <div className="post-details">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 pr-0">
                              <img src="https://html.loyalcoder.com/quax/assets/img/blog/Image_Placeholder.webp" 
                                className="img-fluid" alt="Image_Placeholder.webp" />
                            </div>
                            <div className="col-lg-5 col-md-5 pr-0 pl-0">
                              <p className="m-0 pt-1">Charles Mabery</p>
                            </div>
                            <div className="col-lg-5 col-md-5 pr-0">
                              <p className="m-0 pt-1">
                                <span>
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M8 0C6.41775 0 4.87103 0.469191 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842854 10.1217 0 8 0ZM10.984 10.1L10.339 10.906C10.2536 11.0128 10.1292 11.0814 9.99327 11.0966C9.85732 11.1118 9.7209 11.0724 9.614 10.987L7.453 9.387C7.302 9.26616 7.18011 9.11293 7.09633 8.93862C7.01255 8.76431 6.96903 8.57339 6.969 8.38V3.355C6.969 3.21815 7.02337 3.0869 7.12014 2.99013C7.2169 2.89336 7.34815 2.839 7.485 2.839H8.517C8.65386 2.839 8.7851 2.89336 8.88187 2.99013C8.97864 3.0869 9.033 3.21815 9.033 3.355V8L10.9 9.371C10.9537 9.41312 10.9985 9.46547 11.0319 9.52503C11.0652 9.58459 11.0864 9.65016 11.0942 9.71796C11.102 9.78577 11.0963 9.85444 11.0774 9.92002C11.0585 9.9856 11.0267 10.0468 10.984 10.1Z"
                                      fill="#707070"></path>
                                  </svg>
                                </span>
                                October 25, 2021
                              </p>
                            </div>
                          </div>
                        </div>
                        <h3 className="card-title q-p-margin-t-10 q-p-margin-b-10">{data?.detail?.title_en}
                        </h3>
                        <p className="card-text q-p-margin-b-40">Pick a template, customize the content and design elements,
                          and launch! Or, design your next fabulous email on your own with our simple visual builder.
                          Pick a template, customize the content and design elements.
                        </p>
                        <div className="col-lg-12 likeBoxArea two">
                          <div className="post-actions">
                            <span className="action-item"> sep 23,2023</span>
                              <span className="action-item"><i className="ri-thumb-up-fill"></i> 123 </span>
                              <span className="action-item"><i className="ri-thumb-down-fill"></i> 10 </span>
                              <span className="action-item"><i className="ri-message-fill"></i> 25 </span>
                              <span className="action-item"><i className="ri-eye-fill"></i> 500 </span>
                          </div>
                         
                          <div className="social-icons align-self-center d-flex justify-content-between">
                            <a href="#" target="_blank"><i className="ri-facebook-fill"></i></a>
                            <a href="#" target="_blank"><i className="ri-instagram-fill"></i></a>
                            <a href="#" target="_blank"><i className="ri-twitter-fill"></i></a>
                            <a href="#" target="_blank"><i className="ri-linkedin-fill"></i></a>
                            <a href="#" target="_blank"><i className="ri-reddit-fill"></i></a>
                            <a href="#" target="_blank"><i className="ri-whatsapp-fill"></i></a>
                          </div>
                        </div>
                        
                        <div className="col-lg-12">
                          <div className="tagBox">
                            <a className="q-section-gray q-text-paragraph-2 q-radius-3 q-p-margin-t-10" href="#">Keyword</a>
                            <a className="q-section-gray q-text-paragraph-2 q-radius-3 q-p-margin-t-10" href="#">Keyword</a>
                            <a className="q-section-gray q-text-paragraph-2 q-radius-3 q-p-margin-t-10" href="#">Keyword</a>
                            <a className="q-section-gray q-text-paragraph-2 q-radius-3 q-p-margin-t-10" href="#">Keyword</a>
                            <a className="q-section-gray q-text-paragraph-2 q-radius-3 q-p-margin-t-10" href="#">Keyword</a>
                            <a className="q-section-gray q-text-paragraph-2 q-radius-3 q-p-margin-t-10" href="#">Keyword</a>
                          </div>
                        </div>

                        
                        <div className="recentPostArea pb-4">
                          <h3>Recent Post</h3>
                          <div
                            className="slider blog-detail-slider slick-gutter-15 slick-gutter-10 slick-pagination-50  slick-arrow-top">
                               <Slider ref={slider}  {...settings}>
                            {data?.recents?.map((item,index)=>{
                           return <div className="blogDetailCard explore-style-one" key={index}>
                              <div className="thumb">
                                <a href="product-details.html"><img src="images/news/01.jpg" style={{width:'326px'}}
                                    alt="nft live auction thumbnail" /></a>
                                {/* <div className="countdown btn btn-gradient btn-small" data-date="2022-10-10">
                                </div> */}
                                
                                <button className="reaction-btn"><i className="ri-heart-fill"></i><span>08</span></button>
                                
                              </div>
                              <div className="content newsArea">
                                <div className="header d-flex-between pt-4 pb-1">
                                  <h3 className="title"><a href="#">Prime Minister Modi Will Inaug...</a></h3>
                                </div>
                                <div className="product-share-wrapper">
                                  <div className="profile-share d-flex-center">
                                    <p>13, Jan 2023</p>
                                  </div>
                                </div>
                                <div className="action-wrapper d-flex-between pt-2">
                                  <div className="IcolorBox "><i className="ri-time-line"></i> May 5, 2023</div>
                                  <div className="IcolorBox"><i className="ri-thumb-up-line"></i> 6 <i
                                      className="ri-thumb-down-line"></i> 8</div>
                                </div>
                              </div>
                              
                            </div>})}
                            </Slider>
                          </div>
                        </div>
                        <div className="eltdCommentHolder line-bottom ">
                          <div className="eltd-related-posts-title tagBox">
                            <h3>Comment</h3>
                          </div>
                        </div>
                        <div className="eltdCommentForm">
                          <div className="eltd-related-posts-title">
                            <h6 className="mb-2">Post a Comment</h6>
                          </div>
                          <form method="post" accept="" action="https://checkbrand.online/blog/post/comment#cmntfrm">
                            <input type="hidden" name="_token" value="6ns0MY9WaYYL104zz7ezGYor1sHeilQJIVB32Ikk" />
                            <textarea name="message" placeholder="Write your comment here..." className="m-0" cols="45"
                              rows="8"></textarea>
                            <div className="row mt-2">
                              <div className="col-4">
                                <input type="text" name="name" placeholder="Your full name" className="mb-1" value="" />
                              </div>
                              <div className="col-4">
                                <input type="text" name="email" placeholder="E-mail address" className="mb-1" value="" />
                              </div>
                              <div className="col-4">
                                <input type="text" name="" placeholder="Website" />
                              </div>
                            </div>
                            <p className="comment-form-cookies-consent d-flex">
                              <input type="checkbox" value="yes" /><label>Save my name, email, and website in this browser for the next time I comment..</label>
                            </p>
                            <p className="form-submit">
                              <a href="javascript:void(0)" className="btn btn-gradient btn-small">
                                <span>Submit </span>
                              </a>
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="pagination-box mx-auto">
                      <nav aria-label="">
                        <ul className="pagination">
                          <li className="page-item q-radius-5">
                            <a className="page-link" href="#">
                              <svg width="20" height="10" viewBox="0 0 20 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M1.218 5.12689L5.1 8.98689C5.2395 9.12573 5.42845 9.20347 5.62527 9.203C5.82209 9.20253 6.01066 9.12389 6.1495 8.98439C6.28834 8.84488 6.36608 8.65594 6.36561 8.45912C6.36514 8.2623 6.2865 8.07373 6.147 7.93489L3.547 5.3439L19.258 5.3439C19.4548 5.3439 19.6435 5.26572 19.7827 5.12657C19.9218 4.98742 20 4.79869 20 4.6019C20 4.40511 19.9218 4.21637 19.7827 4.07722C19.6435 3.93807 19.4548 3.85989 19.258 3.85989L3.54 3.85989L6.14 1.26889C6.2795 1.13005 6.35814 0.941477 6.35861 0.744658C6.35908 0.547838 6.28134 0.358892 6.1425 0.219389C6.00366 0.0798855 5.81509 0.00126076 5.61827 0.00079155C5.42145 0.000322342 5.2325 0.0780611 5.093 0.216902L1.215 4.0759C1.14617 4.14509 1.09164 4.22717 1.05454 4.31744C1.01744 4.40772 0.998482 4.50442 0.99876 4.60202C0.999039 4.69962 1.01854 4.7962 1.05616 4.88626C1.09378 4.97632 1.14877 5.05809 1.218 5.12689Z"
                                  fill="#ACACAC"></path>
                              </svg>
                            </a>
                          </li>
                          <li className="page-item q-radius-5 active" aria-current="page">
                            <a className="page-link" href="#">1</a>
                          </li>
                          <li className="page-item q-radius-5">
                            <a className="page-link" href="#">2</a>
                          </li>
                          <li className="page-item q-radius-5">
                            <a className="page-link" href="#">3</a>
                          </li>
                          <li className="page-item q-radius-5">
                            <a className="page-link" href="#">...</a>
                          </li>
                          <li className="page-item q-radius-5">
                            <a className="page-link" href="#">
                              <svg width="20" height="10" viewBox="0 0 20 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M18.782 4.07599L14.9 0.21599C14.7605 0.0771495 14.5716 -0.000588814 14.3747 -0.000119962C14.1779 0.00034889 13.9893 0.0789889 13.8505 0.218493C13.7117 0.357997 13.6339 0.546942 13.6344 0.743761C13.6349 0.940581 13.7135 1.12915 13.853 1.26799L16.453 3.85898H0.742C0.545209 3.85898 0.356479 3.93716 0.217327 4.07631C0.0781748 4.21546 0 4.40419 0 4.60098C0 4.79778 0.0781748 4.98651 0.217327 5.12566C0.356479 5.26481 0.545209 5.34299 0.742 5.34299H16.46L13.86 7.93399C13.7205 8.07283 13.6419 8.2614 13.6414 8.45822C13.6409 8.65504 13.7187 8.84399 13.8575 8.98349C13.9963 9.123 14.1849 9.20162 14.3817 9.20209C14.5786 9.20256 14.7675 9.12482 14.907 8.98598L18.785 5.12699C18.8538 5.05779 18.9084 4.97571 18.9455 4.88544C18.9826 4.79517 19.0015 4.69846 19.0012 4.60086C19.001 4.50326 18.9815 4.40668 18.9438 4.31662C18.9062 4.22656 18.8512 4.14479 18.782 4.07599Z"
                                  fill="#2D4C9F"></path>
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col-lg-4 mt-0 mt-lg-0">
                    <div className="sidebar float-end float-md-start float-sm-start">
                      <div className="input-group mb-3">
                        <input type="search" className="form-control" placeholder="Type here" aria-label="Type here"
                          aria-describedby="button-addon2" />
                        <button className="btn quax-primary-btn" type="submit" id="button-addon2">Search</button>
                      </div>
                      
                      <div className="resent-post sidebar-box q-p-margin-t-30 q-border q-radius-10">
                        <h5>Recent Posts</h5>
                        <div className="post-item post-item-border">
                          <div className="row">
                            <div className="col-sm-5"><img
                                src="https://html.loyalcoder.com/quax/assets/img/blog/Rectangle300.webp"
                                className="img-fluid" alt="Rectangle300.webp" /></div>
                            <div className="col-sm-7 align-self-center">
                              <p className="para-one q-text-heading-color">Ten Sass That Had Gone Way Too Far.</p>
                            </div>
                          </div>
                        </div>
                        <div className="post-item post-item-border q-p-margin-t-20">
                          <div className="row">
                            <div className="col-sm-5"><img
                                src="https://html.loyalcoder.com/quax/assets/img/blog/Rectangle300(1).webp"
                                className="img-fluid" alt="Rectangle300(1).webp" /></div>
                            <div className="col-sm-7">
                              <p className="para-one q-text-heading-color">Ten Moments To Remember From Seo.</p>
                            </div>
                          </div>
                        </div>
                        <div className="post-item post-item-border q-p-margin-t-20">
                          <div className="row">
                            <div className="col-sm-5"><img
                                src="https://html.loyalcoder.com/quax/assets/img/blog/Rectangle300(2).webp"
                                className="img-fluid" alt="Rectangle300(2).webp" /></div>
                            <div className="col-sm-7">
                              <p className="para-one q-text-heading-color">Simple Guidance For You In Startup Business.</p>
                            </div>
                          </div>
                        </div>
                        <div className="post-item post-item-border q-p-margin-t-20">
                          <div className="row">
                            <div className="col-sm-5"><img
                                src="https://html.loyalcoder.com/quax/assets/img/blog/Rectangle300(3).webp"
                                className="img-fluid" alt="Rectangle300(3).webp" /></div>
                            <div className="col-sm-7">
                              <p className="para-one q-text-heading-color">Seven Lessons I've Learned From Business.</p>
                            </div>
                          </div>
                        </div>
                        <div className="post-item q-p-margin-t-20">
                          <div className="row">
                            <div className="col-sm-5"><img
                                src="https://html.loyalcoder.com/quax/assets/img/blog/Rectangle300(4).webp"
                                className="img-fluid" alt="Rectangle300(4).webp" /></div>
                            <div className="col-sm-7">
                              <p className="para-one q-text-heading-color">All You Need To Know About Agency..</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sidebar-box q-p-margin-t-30 q-border q-radius-10">
                        <h5>Categories</h5>
                        <div className="sidebar-catagory">
                           {data?.categories?.slice(0,9).map((item,index)=>{
                         return <p className="para-one q-text-heading-color" key={index}>{item.title_en}</p>})}
                        </div>
                      </div>
                      <div className="sidebar-box q-p-margin-t-30 q-border q-radius-10">
                        <h5>Tags</h5>
                        <div className="sidebar-tags row">
                           {data?.popular_tags?.slice(0,10).map((item,index)=>{
                         return <div className=" q-p-margin-b-20" key={index}><a
                              className="q-section-gray q-text-paragraph-2 q-radius-3" href="#">{item.tags}</a>
                              </div>})}
                        </div>
                      </div>
                     /* The above code is a HTML code snippet that creates a sidebar box with a title
                     "About Author" and a paragraph of text inside. The code uses CSS classes to
                     style the box with a border, rounded corners, and some margin. */
                      {/* <div className="sidebar-box q-p-margin-t-30 q-border q-radius-10">
                        <h5>About Author</h5>
                        <div className="about-text">
                          <p className="para-three">Pick a template, customize the content and design elements, and launc
                            design your next fabulous email on your with our simple visual builder. Pick a template,
                            customize the content and design elements.
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
  </>
}

export default Detail_Blog
