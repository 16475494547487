import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { graph1, graph } from "../common/mockData";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import General_Graph from "../dash-graph/General_Graph";
import Sentiment_Graph from "../dash-graph/Sentiment_Graph";
import Users_Graph from "../dash-graph/Users_Graph";
import Links_Graph from "../dash-graph/Links_Graph";
import Types_Graph from "../dash-graph/Types_Graph";
import Keywords_Graph from "../dash-graph/Keywords_Graph";
ChartJS.register(ArcElement, Tooltip, Legend);

const Container = () => {
  const [show, setShow] = useState(`General`);
  const [active, setActive] = useState();
  function showData(data) {
    setShow(data);
    setActive(true);
  }
  return (
    <>
      <div className="container-fluid">
        {/* <!-- Bread Crumb  --> */}
        <div className="breadcrumb-header">
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="content-title mb-2">Dashboard</h4>
            </div>
            <div className="d-flex my-auto">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item " aria-current="page">
                    Dashboard 02
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        {/* New PAge implementation */}

        <div className="">
          <div className="row">
            {/* <!-- section 01 left --> */}
            <div className="col-lg-3">
              <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                <div className="iq-card-body pt-2 pb-1 position-relative">
                  <div className="user-details-block">
                    <div className="user-profile text-center">
                      <img
                        src="https://checkbrand.online/frontend/images/profile_pic_1604568989924813044.jpeg"
                        alt="profile-img"
                        className="avatar-130 img-fluid"
                      />
                    </div>
                    <div className="text-center mt-1">
                      <h4>
                        <b>Virat Kohli</b>
                      </h4>
                      <p className="m-0">Cricketor</p>
                    </div>

                    <div className="dateArea">
                      <span>Report Date:</span> 24/08/2023
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- ./section 01 left --> */}

            {/* <!-- section 01 right --> */}

            <div className="col-lg-9 pl-0">
              {/* <!-- section 01 top --> */}
              <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                <div className="iq-card-body">
                  <div className="row">
                    {/* <!-- 01 --> */}
                    <div className="col globalRankBox">
                      <div className="d-flex align-items-center">
                        <i className="ri-global-line"></i>
                        <div>
                          <h6>Digital Ranking</h6>
                          <h3>2</h3>
                        </div>
                      </div>
                    </div>
                    {/* <!-- ./01 --> */}

                    {/* <!-- 01 --> */}
                    <div className="col globalRankBox">
                      <div className="d-flex align-items-center">
                        <i className="ri-price-tag-2-line"></i>
                        <div>
                          <h6>Brand Value</h6>
                          <h3>413 Cr</h3>
                        </div>
                      </div>
                    </div>
                    {/* <!-- ./01 --> */}

                    {/* <!-- 01 --> */}
                    <div className="col globalRankBox">
                      <div className="d-flex align-items-center">
                        <i className="ri-robot-line"></i>
                        <div>
                          <h6>Brand Score</h6>
                          <h3>84/100</h3>
                        </div>
                      </div>
                    </div>
                    {/* <!-- ./01 --> */}

                    {/* <!-- 01 --> */}
                    <div className="col globalRankBox">
                      <div className="d-flex align-items-center">
                        <i className="ri-bookmark-3-line"></i>
                        <div>
                          <h6>Category Ranking</h6>
                          <h3>#1</h3>
                        </div>
                      </div>
                    </div>
                    {/* <!-- ./01 --> */}
                  </div>
                </div>
              </div>
              {/* <!-- ./section 01 top --> */}

              {/* <!-- section 01 bottom --> */}
              <div className="row panelArea-10">
                {/* <!-- 01 --> */}
                <div className="col globalRankBox-2 align-items-center">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                    <div className="iq-card-body p-0 d-flex align-items-center">
                      <i className="ri-arrow-up-line"></i>
                      <div>
                        <h3>15.0%</h3>
                        <h6>block</h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- ./01 -->  */}
                {/* <!-- 01 --> */}
                <div className="col globalRankBox-2 align-items-center">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                    <div className="iq-card-body p-0 d-flex align-items-center">
                      <i className="fa fa-line-chart"></i>
                      <div>
                        <h3>84/100</h3>
                        <h6>block</h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- ./01 -->    */}

                {/* <!-- 01 --> */}
                <div className="col globalRankBox-2 align-items-center">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                    <div className="iq-card-body p-0 d-flex align-items-center">
                      <i className="ri-arrow-right-up-line"></i>
                      <div>
                        <h3>84/100</h3>
                        <h6>Gain</h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- ./01 --> */}

                {/* <!-- 01 --> */}
                <div className="col globalRankBox-2 align-items-center">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                    <div className="iq-card-body p-0 d-flex align-items-center">
                      <i className="ri-bar-chart-line"></i>
                      <div>
                        <h3>84%</h3>
                        <h6>Score </h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- ./01 --> */}

                {/* <!-- 01 --> */}
                <div className="col globalRankBox-2 align-items-center">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                    <div className="iq-card-body p-0 d-flex align-items-center">
                      <i className="ri-star-line"></i>
                      <div>
                        <h3>4/5</h3>
                        <h6>Rating </h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- ./01 --> */}
              </div>

              {/* <!-- ./section 01 bottom --> */}
            </div>

            {/* <!-- ./section 01 right --> */}
          </div>
        </div>

        <div className="aboutAreaBox">
          <div className="row">
            {/* <!-- about left --> */}
            <div className="col-lg-5">
              <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                <div className="iq-card-body pt-2 pb-1 position-relative">
                  <div className="politBox">
                    <h6>About Us</h6>
                    <p style={{fontSize:'14px !important'}}>
                      adobe is changing the world through digital experiences.
                      we help our cust adobe is changing the world through
                      digital experiences...
                    </p>

                    <table
                      width="100%"
                      border="0"
                      cellspacing="0"
                      cellpadding="0"
                      className="mb-2"
                    >
                      <tbody>
                        <tr>
                          <td style={{width:"130px"}}>Company</td>
                          <td>Adobe, Inc.</td>
                        </tr>
                        <tr>
                          <td>Year Founded</td>
                          <td>1982</td>
                        </tr>
                        <tr>
                          <td>Employees</td>
                          <td>10001</td>
                        </tr>
                        <tr>
                          <td>HQ</td>
                          <td>United States, California, San Jose</td>
                        </tr>
                        <tr>
                          <td>Annual Revenue</td>
                          <td>$1.0B</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- ./about left --> */}
            {/* <!-- about middle --> */}
            <div className="col-lg-4">
              <div className="boxSimilarlyrankedSites">
                <h4>Similarly Ranked Brands</h4>
                <ul>
                  <li>
                    <span>574,953</span>
                    <span className="w40">
                      <img src="images/img13.png" />
                    </span>
                    <span className="rankedNameArea">
                      forensicsciencesociety.com
                    </span>
                  </li>
                  <li>
                    <span>574,953</span>
                    <span className="w40">
                      <img src="images/img13.png" />
                    </span>
                    <span className="rankedNameArea">forensicsciencesoc...</span>
                  </li>
                  <li className="active">
                    <span>574,953</span>
                    <span className="w40">
                      <img src="images/img13.png" />
                    </span>
                    <span className="rankedNameArea">checkbrand.online</span>
                  </li>
                  <li>
                    <span>574,953</span>
                    <span className="w40">
                      <img src="images/img13.png" />
                    </span>
                    <span className="rankedNameArea">forensicsciencesoc...</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* <!--./ about middle --> */}

            {/* <!--about right --> */}
            <div className="col-lg-3">
              <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
                <div className="iq-card-body p-0 pb-3 position-relative">
                  <h4 className="boxheader">Similarly Ranked Brands</h4>
                  <div className="d-flex">
                    {/* <!-- 01 --> */}
                    <div className="col-md-3 progressBarSentiments" style={{width:'25% !important',margin:'auto'}}>
                      <div className="progress bg-success">
                        <i className="fa fa-smile-o" aria-hidden="true"></i>
                        <div className="progress-bar" style={{height: "30%"}}></div>
                      </div>
                      <h5>33%</h5>
                      <p>Positive</p>
                    </div>
                    {/* <!-- ./01 --> */}

                    {/* <!-- 01 --> */}

                    <div className="col-md-3 progressBarSentiments" style={{width:'25% !important',margin:'auto'}}>
                      <div className="progress bg-info">
                        <i className="fa fa-meh-o" aria-hidden="true"></i>
                        <div className="progress-bar" style={{height: "50%"}}></div>
                        <i className="fa fa-meh-0"></i>
                      </div>
                      <h5>59%</h5>
                      <p>Neutral</p>
                    </div>

                    {/* <!-- ./01 --> */}

                    {/* <!-- 01 --> */}

                    <div className="col-md-3 progressBarSentiments" style={{width:'25% !important',margin:'auto'}}>
                      <div className="progress bg-danger">
                        <i className="fa fa-frown-o" aria-hidden="true"></i>
                        <div className="progress-bar" style={{height: "70%"}}></div>
                        <i className="fa fa-frown-0"></i>
                      </div>
                      <h5>80%</h5>
                      <p>Negative</p>
                    </div>

                    {/* <!-- ./01 --> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <!--./about right --> */}
          </div>
        </div>

        {/* New Page ended */}
        {/* <!-- ./Bread Crumb  --> */}

        {/* <!-- section 01 --> */}
      

        {/* <!-- ./section 01 --> */}

        {/* <!-- Socila Mentions Area --> */}
        <div className="socilaMentionsArea pt-3 pb-3">
          <div className="d-flex justify-content-between">
            {/* <!-- 01 --> */}
            <div className="col socilaMentionsBox">
              <a href="#" className="active">
                <i className="fa fa-globe"></i> <span>All</span>
                <div className="panel_results_counter">20.79 Cr</div>
              </a>
            </div>
            {/* <!-- ./01 --> */}
            <div className="col socilaMentionsBox">
              <a href="#">
                <i className="fa fa-twitter"></i> <span>Twitter</span>
                <div className="panel_results_counter">8.08 Cr</div>
              </a>
            </div>
            <div className="col socilaMentionsBox">
              <a href="#">
                <i className="fa fa-instagram"></i> <span>Instagram</span>
                <div className="panel_results_counter">6.84 Cr</div>
              </a>
            </div>
            <div className="col socilaMentionsBox">
              <a href="#">
                <i className="fa fa-facebook-f"></i> <span>Facebook</span>
                <div className="panel_results_counter">4.7 Cr</div>
              </a>
            </div>
            <div className="col socilaMentionsBox">
              <a href="#">
                <i className="fa fa-newspaper-o"></i> <span>News</span>
                <div className="panel_results_counter">3345</div>
              </a>
            </div>
            <div className="col socilaMentionsBox">
              <a href="#">
                <i className="fa fa-play"></i> <span>Videos</span>
                <div className="panel_results_counter">434</div>
              </a>
            </div>
            <div className="col socilaMentionsBox" style={{ display: "none" }}>
              <a href="#">
                <i className="fa fa-volume-up"></i> <span>Podcasts</span>
                <div className="panel_results_counter">0</div>
              </a>
            </div>
            <div className="col socilaMentionsBox">
              <a href="#">
                <i className="fa fa-comments"></i> <span>Forums</span>
                <div className="panel_results_counter">234</div>
              </a>
            </div>
            <div className="col socilaMentionsBox">
              <a href="#">
                <i className="fa fa-rss"></i> <span>Blog</span>
                <div className="panel_results_counter">345</div>
              </a>
            </div>
            <div className="col socilaMentionsBox">
              <a href="#">
                <i className="fa fa-share-alt"></i> <span>Web</span>
                <div className="panel_results_counter">122</div>
              </a>
            </div>
          </div>
        </div>
        {/* <!-- ./Socila Mentions Area -->
               <!-- Methodology Section--> */}
        <div className="methodologySection pt-3 pb-3">
          <div className="row align-items-center justify-content-between">
            <div className="col-6 col-md-4 col-lg-2 methodologyArea pt-hide">
              <div className="methodologyPanel">
                {" "}
                <i className="fa fa-anchor"></i> <span>Reach</span>{" "}
                <span className="ratio">20.79 Cr</span>{" "}
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-2 methodologyArea">
              <div className="methodologyPanel">
                {" "}
                <i className="fa fa-bullhorn"></i> <span>Engagement </span>{" "}
                <span className="ratio">17 Lac</span>{" "}
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-2 methodologyArea">
              <div className="methodologyPanel">
                {" "}
                <i className="fa fa-users"></i> <span>Followers </span>{" "}
                <span className="ratio">20.79 Cr</span>{" "}
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-2 methodologyArea">
              <div className="methodologyPanel">
                {" "}
                <i className="fa fa-file-text"></i> <span>News </span>{" "}
                <span className="ratio">1343</span>{" "}
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-2 methodologyArea">
              <div className="methodologyPanel">
                {" "}
                <i className="fa fa-cogs"></i> <span>Influencers </span>{" "}
                <span className="ratio">1632</span>{" "}
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-2 methodologyArea">
              <div className="methodologyPanel">
                {" "}
                <i className="fa fa-smile-o"></i> <span>Sentiments </span>{" "}
                <span className="ratio">19.27 % positive</span>{" "}
              </div>
            </div>
            <div className="col-2 col-md-4 col-lg-2 methodologyArea">
              <div className="methodologyPanel">
                {" "}
                <i className="fa fa-refresh"></i> <span>Mentions </span>{" "}
                <span className="ratio">19 Lac</span>{" "}
              </div>
            </div>
            <div className="col-2 col-md-4 col-lg-2 methodologyArea">
              <div className="methodologyPanel">
                {" "}
                <i className="fa fa-bar-chart"></i> <span>Trends </span>{" "}
                <span className="ratio">1.33 K</span>{" "}
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-2 methodologyArea pt-hide">
              <div className="methodologyPanel">
                {" "}
                <i className="fa fa-share-alt"></i> <span>Viral </span>{" "}
                <span className="ratio">2344</span>{" "}
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-2 methodologyArea">
              <div className="methodologyPanel">
                {" "}
                <i className="fa  fa-thumbs-up"></i> <span>Like </span>{" "}
                <span className="ratio">9</span>{" "}
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-2 methodologyArea">
              <div className="methodologyPanel">
                {" "}
                <i className="fa fa-thumbs-down"></i> <span>Dislike </span>{" "}
                <span className="ratio">2</span>{" "}
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-2 methodologyArea">
              <div className="methodologyPanel">
                {" "}
                <i className="fa fa-eye"></i> <span>Views</span>{" "}
                <span className="ratio"> 0</span>{" "}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ./Methodology Section -->
               <!-- Posts By Social Network --> */}
        <div className="tabSectionArea pb-3">
          <div className="card-body p-0 newTabDsPanel">
            <ul className="nav custom-tabs author-tabs justify-content-lg-center p-0 d-flex">
              <li onClick={() => showData("General")}>
                <a
                  className={`btn btn-small btn-outline-1 ${
                    show === "General" && "active"
                  } `}
                  id="general-home-tab"
                  data-toggle="pill"
                  href="#general-home"
                  role="tab"
                  aria-controls="general-home"
                  aria-selected="true"
                >
                  General
                </a>
              </li>
              <li onClick={() => showData("Sentiment")}>
                <a
                  className={`btn btn-small btn-outline-1 ${
                    show === "Sentiment" && "active"
                  } `}
                  id="Sentiment-home-tab"
                  data-toggle="pill"
                  href="#Sentiment-home"
                  role="tab"
                  aria-controls="Sentiment-home"
                  aria-selected="false"
                >
                  Sentiment
                </a>
              </li>
              <li onClick={() => showData("Users")}>
                <a
                  className={`btn btn-small btn-outline-1 ${
                    show === "Users" && "active"
                  } `}
                  id="Users-home-tab"
                  data-toggle="pill"
                  href="#Users-home"
                  role="tab"
                  aria-controls="Users-home"
                  aria-selected="false"
                >
                  Users
                </a>
              </li>
              <li onClick={() => showData("Links")}>
                <a
                  className={`btn btn-small btn-outline-1 ${
                    show === "Links" && "active"
                  } `}
                  id="links-home-tab"
                  data-toggle="pill"
                  href="#links-home"
                  role="tab"
                  aria-controls="links-home"
                  aria-selected="false"
                >
                  Links
                </a>
              </li>
              <li onClick={() => showData("Types")}>
                <a
                  className={`btn btn-small btn-outline-1 ${
                    show === "Types" && "active"
                  } `}
                  id="Types-home-tab"
                  data-toggle="pill"
                  href="#Types-home"
                  role="tab"
                  aria-controls="Types-home"
                  aria-selected="false"
                >
                  Types
                </a>
              </li>
              <li onClick={() => showData("Keywords")}>
                <a
                  className={`btn btn-small btn-outline-1 ${
                    show === "Keywords" && "active"
                  } `}
                  id="Keywords-home-tab"
                  data-toggle="pill"
                  href="#Keywords-home"
                  role="tab"
                  aria-controls="Keywords-home"
                  aria-selected="false"
                >
                  Keywords
                </a>
              </li>
            </ul>
            <div
              className="tab-content author-tabs-content"
              id="pills-tabContent"
            >
              {(() => {
                switch (show) {
                  case "General":
                    return <General_Graph active="active" />;
                  case "Sentiment":
                    return <Sentiment_Graph active="active" />;
                  case "Users":
                    return <Users_Graph active="active" />;
                  case "Links":
                    return <Links_Graph active="active" />;
                  case "Types":
                    return <Types_Graph active="active" />;
                  case "Keywords":
                    return <Keywords_Graph active="active" />;
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        </div>
        {/* <!-- ./Posts By Social Network --> */}

        {/* <!-- DOMAIN HISTORY --> */}

        <section className="ruralDevtSection mt-3">
          <div className="container">
            <div className="row">
              {/* <!-- left section --> */}
              <div className="col-lg-6">
                {/* <!-- DOMAIN HISTORY chart box 01 --> */}
                <div className="boxAreaChart shadow-none mb-0">
                  <h6>DOMAIN HISTORY</h6>
                  <div className="boxAreaChartInner">
                    <div style={{ height: "240px" }}>
                      <ReactApexChart
                        options={graph1.options}
                        series={graph1.series}
                        type="area"
                        height={240}
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- ./DOMAIN HISTORY chart box 01 --> */}
              </div>
              {/* <!-- ./left section --> */}
              {/* <!-- right section --> */}
              <div className="col-lg-6">
                {/* <!-- TRAFFIC RANK chart box 01 --> */}
                <div className="boxAreaChart shadow-none mb-0">
                  <h6>TRAFFIC RANK</h6>
                  <div className="boxAreaChartInner">
                    <div style={{ height: "240px" }}>
                      <ReactApexChart
                        options={graph1.options}
                        series={graph1.series}
                        type="area"
                        height={240}
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- ./TRAFFIC RANK chart box 01 --> */}
              </div>
              {/* <!-- ./right section --> */}
            </div>
          </div>
        </section>
        {/* <!-- ./DOMAIN HISTORY --> */}

        {/* <!-- section 05 --> */}
        <section className="pb-5 pt-5" id="Consultation">
          {/* <!-- End .section-title --> */}
          <div className="author-profile-wrapper">
            <div className="row">
              {/* <!-- box-01 --> */}
              <div className="col-lg-4">
                <div className="boxAreaChart mb-4">
                  <h6 className="mb-0">Gender</h6>
                  <div
                    className="consultationTableArea"
                    style={{
                      height: "250px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Doughnut data={graph} />
                  </div>
                </div>
              </div>
              {/* <!-- ./box-01 --> */}

              {/* <!-- box-01 --> */}
              <div className="col-lg-4">
                <div className="boxAreaChart mb-4">
                  <h6 className="mb-0">Gender Analysis</h6>
                  <div
                    className="consultationTableArea"
                    style={{
                      height: "250px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Doughnut data={graph} />
                  </div>
                </div>
              </div>
              {/* <!-- ./box-01 --> */}

              {/* <!-- box-01 --> */}
              <div className="col-lg-4">
                <div className="boxAreaChart mb-4">
                  <h6 className="mb-0">Sentiment Analysis</h6>
                  <div
                    className="consultationTableArea"
                    style={{
                      height: "250px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/* Sentiment Analysis Chart Area */}
                    <Doughnut data={graph} />
                  </div>
                </div>
              </div>
              {/* <!-- ./box-01 --> */}
            </div>
          </div>
          {/* <!-- End .row --> */}

          {/* <!-- End .container --> */}
        </section>
        {/* <!-- ./section 05 --> */}
      </div>
    </>
  );
};

export default Container;
