import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { reqData } from "../../common/api";
import { Skeleton } from "@mui/material";

const Client = () => {
   const settings = {
      dots: false,
      infinite: true,
      speed: 400,
      gap:10,
      autoplay: true,
      slidesToShow: 7,
      slidesToScroll: 1,
      centerPadding: 30
    };
    const clientApi = process.env.REACT_APP_CLIENT;
    const [data,setData]=useState();
    const [loading,setLoading]=useState();
    function getData(){
      reqData(clientApi, setData,"client","",setLoading)
    }
    const dummyArr=[1,2,3,4,5,6,7,8,9,10]
    useEffect(()=>{
      getData();
    },[])
    // const data=[1,2,3,4,5,6,7,8,9,10,11,12]
  return (
    <>
      <section className="clientSection live-auction bg-8">
        <div className="container">
          <div className="slider slick-client slick-initialized slick-slider">
            <div className="slick-list draggable" style={{ padding: "0px 10px" }}>
               <Slider {...settings} >
                {loading ? dummyArr.map((item)=>{
                  return <div key={item}
                  className="clientBox addMargin slick-slide slick-cloned"
                  style={{ paddingLeft:'10px',paddingRight:'10px' }}
                  data-slick-index="-7"
                  id=""
                  aria-hidden="true"
                  tabIndex="-1"
                >
                  <Skeleton height={50} width={150} variant="rectangular" animation="pulse" />
                </div>
                }) : data?.partners?.map((item,index)=>{
                  return <div key={index}
                    className="clientBox addMargin slick-slide slick-cloned"
                    style={{ paddingLeft:'10px',paddingRight:'10px' }}
                    data-slick-index="-7"
                    id=""
                    aria-hidden="true"
                    tabIndex="-1"
                  >
                    <img src={data.image_path + item.client} alt="brand" />
                  </div>
                  })}
                </Slider>
              {/* </div> */}
            </div>
          </div>
          {/* <!-- End .slick-activation-01 --> */}
        </div>
      </section>
    </>
  );
};

export default Client;
