import React from 'react';
import FaqTab from '../../../common/FaqTab';
import Common_Tab from '../../../common/Common_Tab';

const Mobile_Responsive = ({activeTab,dashData,loading}) => {
  const questions = [
    {
      id: 1,
      title:
        "The website smoothly adjusts its layout and content to different screen sizes and devices and content is easily readable and does not require excessive zooming or scrolling on mobile screens.",
      description: "",
      ariaControls: "first",
    },
    {
      id: 2,
      title:
        "Images and media adapt and display properly on different screen sizes and different mobile browsers",
      description: "",
      ariaControls: "second",
    },
    {
      id: 3,
      title:
        "Forms and input fields are user-friendly and responsive on mobile devices.",
      description: "",
      ariaControls: "third",
    },
    {
      id: 4,
      title:
        "The size of the target for pointer inputs is at least 44 by 44 CSS pixels except when Equivalent, Inline, User Agent Control, Essential",
      description: "",
      ariaControls: "fourth",
    },
    {
      id: 5,
      title:
        "View Port Meta tag present",
      description: "",
      ariaControls: "fifth",
    },
    {
      id: 6,
      title:
        "Are the Mobile Performance score on parameters like: Largest Contentful Paint (LCP), First Input Delay (FID), Cumulative Layout Shift (CLS), First Contentful Paint (FCP) all lying in the green range on the tool? ",
      description: "",
      ariaControls: "sixth",
    },
  ];
  return <>
  <FaqTab activeTab={activeTab} dashData={dashData} questions={questions} />
  <Common_Tab dashData={dashData} loading={loading} />
  </>
}

export default Mobile_Responsive
