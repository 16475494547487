import React from 'react'
import Header from './Dash_Header'
import Menu from './Dash_Menu'
import Container from './Dash_Container'
import Footer from './Dash_Footer'
// import './Dash_Main.scss'
// import '../../public/css/style.css'
// import styles from './Dash_Main.module.css'
import styled from 'styled-components';


const Main = () => {

  return <>
  {/* <MyComponentWrapper> */}
  <div className={`wrapper1`}>
    <div id='content-page' className='content-page'>
    <Header />
    <Menu />
    <Container />
    <Footer />
  </div>
  </div>
  {/* </MyComponentWrapper> */}
  </>
}

export default Main
