import React, { useEffect, useState } from 'react'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import ReviewModal from './ReviewModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom'
import { reqData } from '../../common/api'
import Fade from 'react-reveal/Fade';

const MainReviews = () => {
    const [show,setShow]=useState(false);
    const [animation,setAnimation] = useState("right");
    const [sucess,setSucess]=useState();
    const [error,setError]=useState();
    const [loading,setLoading]=useState();
    const location=useLocation();
    const slug=location.pathname.replace("/review","");
    const reviewApi=`${process.env.REACT_APP_CHECKBRAND_REVIEWS}${slug}`;
    const [reviewData,setReviewData]=useState();

    const getData=()=>{
      reqData(reviewApi,setReviewData,"review","",setLoading)
    }

    useEffect(()=>{
      getData();
    },[])

    console.log("Review Data is",reviewData)

  return <>
  <Header />
  <ToastContainer />
  <Section1 reviewData={reviewData} />
  <Section2 setShow={setShow} reviewData={reviewData} />
  <Section3  reviewData={reviewData} />
  {show&&
  <ReviewModal setShow={setShow} setSucess={setSucess} setError={setError} animation={animation} getData={getData} />
  }
  <Footer />
  </>
}

export default MainReviews
