import React, { useState } from "react";

const Header = () => {
  const [notification,setNotification]=useState(false);
  const [message,setMessage]=useState(false);
  const [profile,setProfile]=useState(false);
  console.log("NOTIFICATION",notification)
  return (
    <>
      <div className="iq-top-navbar">
        <div className="container-fluid">
          <div className="iq-navbar-custom">
            <nav className="navbar navbar-expand-lg navbar-light p-0">
              <img src="dash_logo1.png" className="logo" />
              <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{justifyContent:'end'}}>
                <div className="iq-search-bar ml-auto">
                  <form action="#" className="searchbox">
                    <input
                      type="text"
                      className="text search-input"
                      placeholder="Type here to search..."
                    />
                    <a className="search-link" href="#">
                      <i className="ri-search-line"></i>
                    </a>
                  </form>
                </div>
                <ul className="navbar-nav  navbar-list">
                  <li className="nav-item iq-full-screen">
                    <a href="#" className="iq-waves-effect" id="btnFullscreen">
                      <i className="ri-fullscreen-line"></i>
                    </a>
                  </li>
                  <li className="nav-item"  onClick={()=>setNotification(!notification)}>
                    <a href="#" className="search-toggle iq-waves-effect">
                      <span
                        className="ripple rippleEffect"
                        style={{width: "63px", height: "63px", top: "7.5px", left: "-10.8125px"}}
                      ></span>
                      <i className="ri-notification-3-fill"></i>
                      <span className="bg-danger dots"></span>
                    </a>
                    {notification ?
                    <div className="iq-sub-dropdown">
                    <div className="iq-card shadow-none m-0">
                       <div className="iq-card-body p-0 ">
                          <div className="bg-primary p-3">
                             <h5 className="mb-0 text-white">All Notifications<small className="badge  badge-light float-right pt-1">4</small></h5>
                          </div>
                          <a href="#" className="iq-sub-card">
                             <div className="media align-items-center">
                                <div className="">
                                   <img className="avatar-40 rounded" src="images/user/01.jpg" alt="" />
                                </div>
                                <div className="media-body ml-3">
                                   <h6 className="mb-0 ">Emma Watson Bini</h6>
                                   <small className="float-right font-size-12">Just Now</small>
                                   <p className="mb-0">95 MB</p>
                                </div>
                             </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                             <div className="media align-items-center">
                                <div className="">
                                   <img className="avatar-40 rounded" src="images/user/02.jpg" alt="" />
                                </div>
                                <div className="media-body ml-3">
                                   <h6 className="mb-0 ">New customer is join</h6>
                                   <small className="float-right font-size-12">5 days ago</small>
                                   <p className="mb-0">Jond Bini</p>
                                </div>
                             </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                             <div className="media align-items-center">
                                <div className="">
                                   <img className="avatar-40 rounded" src="images/user/03.jpg" alt="" />
                                </div>
                                <div className="media-body ml-3">
                                   <h6 className="mb-0 ">Two customer is left</h6>
                                   <small className="float-right font-size-12">2 days ago</small>
                                   <p className="mb-0">Jond Bini</p>
                                </div>
                             </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                             <div className="media align-items-center">
                                <div className="">
                                   <img className="avatar-40 rounded" src="images/user/04.jpg" alt="" />
                                </div>
                                <div className="media-body ml-3">
                                   <h6 className="mb-0 ">New Mail from Fenny</h6>
                                   <small className="float-right font-size-12">3 days ago</small>
                                   <p className="mb-0">Jond Bini</p>
                                </div>
                             </div>
                          </a>
                       </div>
                    </div>
                 </div> : ""}
                  </li>
                  <li className="nav-item dropdown"  onClick={()=>setMessage(!message)}>
                    <a href="#" className="search-toggle iq-waves-effect">
                      <i className="ri-mail-open-fill"></i>
                      <span className="bg-primary count-mail"></span>
                    </a>
                    {message &&
                    <div
                      className="iq-sub-dropdown"
                      style={{zIndex: "9"}}
                    >
                      <div className="iq-card shadow-none m-0">
                        <div className="iq-card-body p-0 ">
                          <div className="bg-primary p-3">
                            <h5 className="mb-0 text-white">
                              All Messages
                              <small className="badge  badge-light float-right pt-1">
                                5
                              </small>
                            </h5>
                          </div>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img
                                  className="avatar-40 rounded"
                                  src="images/user/01.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Bini Emma Watson</h6>
                                <small className="float-left font-size-12">
                                  13 Jun
                                </small>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img
                                  className="avatar-40 rounded"
                                  src="images/user/02.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Lorem Ipsum Watson</h6>
                                <small className="float-left font-size-12">
                                  20 Apr
                                </small>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img
                                  className="avatar-40 rounded"
                                  src="images/user/03.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Why do we use it?</h6>
                                <small className="float-left font-size-12">
                                  30 Jun
                                </small>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img
                                  className="avatar-40 rounded"
                                  src="images/user/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Variations Passages</h6>
                                <small className="float-left font-size-12">
                                  12 Sep
                                </small>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img
                                  className="avatar-40 rounded"
                                  src="images/user/05.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Lorem Ipsum generators</h6>
                                <small className="float-left font-size-12">
                                  5 Dec
                                </small>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>}
                  </li>
                </ul>
              </div>
              <ul className="navbar-list">
                <li className="">
                  <a
                    href="#"
                    className="search-toggle iq-waves-effect d-flex align-items-center" onClick={()=>setProfile(!profile)}
                  >
                    <img
                      src="https://checkbrand.online/frontend/images/profile_pic_1604568989924813044.jpeg"
                      className="img-fluid rounded mr-3"
                      alt="user"
                    />
                    <div className="caption" >
                      <h6 className="mb-0 line-height" style={{fontSize:'14px'}}>Virat kohli</h6>
                      <span className="font-size-12" style={{fontSize:'14px'}}>Cricketor</span>
                    </div>
                  </a>
                  {profile &&
                  <div
                    className="iq-sub-dropdown iq-user-dropdown"
                    style={{zIndex: "9"}}
                  >
                    <div
                      className="iq-card shadow-none m-0"
                      style={{zIndex: "9"}}
                    >
                      <div className="iq-card-body p-0 ">
                        <div className="bg-primary p-3">
                          <h5 className="mb-0 text-white line-height">
                            Hello Bini Jets
                          </h5>
                          <span className="text-white font-size-12">Available</span>
                        </div>
                        <a
                          href="profile.html"
                          className="iq-sub-card iq-bg-primary-hover"
                        >
                          <div className="media align-items-center">
                            <div className="rounded iq-card-icon iq-bg-primary">
                              <i className="ri-file-user-line"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">My Profile</h6>
                              <p className="mb-0 font-size-12">
                                View personal profile details.
                              </p>
                            </div>
                          </div>
                        </a>
                        <a
                          href="profile-edit.html"
                          className="iq-sub-card iq-bg-primary-hover"
                        >
                          <div className="media align-items-center">
                            <div className="rounded iq-card-icon iq-bg-primary">
                              <i className="ri-profile-line"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Edit Profile</h6>
                              <p className="mb-0 font-size-12">
                                Modify your personal details.
                              </p>
                            </div>
                          </div>
                        </a>
                        <a
                          href="account-setting.html"
                          className="iq-sub-card iq-bg-primary-hover"
                        >
                          <div className="media align-items-center">
                            <div className="rounded iq-card-icon iq-bg-primary">
                              <i className="ri-account-box-line"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Account settings</h6>
                              <p className="mb-0 font-size-12">
                                Manage your account parameters.
                              </p>
                            </div>
                          </div>
                        </a>
                        <a
                          href="privacy-setting.html"
                          className="iq-sub-card iq-bg-primary-hover"
                        >
                          <div className="media align-items-center">
                            <div className="rounded iq-card-icon iq-bg-primary">
                              <i className="ri-lock-line"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Privacy Settings</h6>
                              <p className="mb-0 font-size-12">
                                Control your privacy parameters.
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="d-inline-block w-100 text-center p-3">
                          <a
                            className="bg-primary iq-sign-btn"
                            href="sign-in.html"
                            role="button"
                          >
                            Sign out<i className="ri-login-box-line ml-2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
