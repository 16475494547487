import React from 'react';
import FaqTab from '../../../common/FaqTab';
import Common_Tab from '../../../common/Common_Tab';

const Performace_Technical = ({activeTab,dashData,loading}) => {
  const questions = [
    {
      id: 1,
      title:
        "Does the website work properly on different browsers like Chrome, Firefox, Safari? ",
      description: "",
      ariaControls: "first",
    },
    {
      id: 2,
      title:
        "Does search return results within 2 seconds?",
      description: "",
      ariaControls: "second",
    },
    {
      id: 3,
      title:
        "Website page load speed for users (0) 8+ (1) 3-8 sec (2) 0-3 sec",
      description: "",
      ariaControls: "third",
    },
    {
      id: 4,
      title:
        "What is the uptime and availability of the website over the past 3 months (0) below 90% (1) 90- 95% (2) Above 95% ",
      description: "",
      ariaControls: "fourth",
    },
    {
      id: 5,
      title:
        "Does the website use caching, compression, and other optimization techniques?",
      description: "",
      ariaControls: "fifth",
    },
    {
      id: 6,
      title:
        "Does the site use a CDN for faster content delivery?",
      description: "",
      ariaControls: "sixth",
    },
    {
      id: 7,
      title:
        "Does the website have a disaster recovery plan?",
      description: "",
      ariaControls: "seventh",
    },
  ];
  return <>
  <section className="ruralDevtSection_web pt-5 pb-5 mb-5">
         <div className="container">
            {/* <!-- heading --> */}
            <div className="d-flex gap-3  align-items-center mb-4 headingArea_web">
               <div>
                  <img src={dashData?.website_logo} />
               </div>
               <div>
                  <h1> {activeTab}</h1>
               </div>
            </div>
            {/* <!-- ./heading --> */}
            
        {/* <!-- Performance and Technical Box --> */}
        <div className="performanceTechnicalBox_web">

            <div className="row">

                {/* <!-- Left Area --> */}
                <div className="col-lg-6">
                    {/* <!-- box 90 --> */}
                    <div className="questionBox">
                       <h4 className="align-items-center"><span>Q1</span> Does the website work properly on different browsers like Chrome, Firefox, Safari?</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <img src="/images/icon2/01.jpg" /> <span>Chrome</span></div>
                           <div><i className="ri-check-line"></i> <img src="/images/icon2/02.jpg" /> <span>Safari</span></div>
                           <div><i className="ri-close-line"></i> <img src="/images/icon2/03.jpg" /> <span>Firefox</span></div>
                       </div>
                    </div>

                     {/* <!-- ./box 90 --> */}
{/*  */}
                      {/* <!-- box 90 --> */}
                    <div className="questionBox">
                       <h4 className="align-items-center"><span>Q3</span> Website page load speed for users ?</h4> 
                       <div className="anSBos gap-3 align-items-center">
                        <div><i className="ri-check-line"></i> <span>0-3 sec</span></div>
                        <div><i className="ri-close-line"></i> <span>3-8 sec</span></div>
                           <div><i className="ri-close-line"></i> <span>8+ sec</span></div>
                           
                           
                           
                       </div>
                    </div>

                     {/* <!-- ./box 90 --> */}

                      {/* <!-- box 90 --> */}
                    <div className="questionBox">
                       <h4 className="align-items-center"><span>Q5</span> Does the website use caching, compression, and other optimization techniques?</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Caching</span></div>
                           <div><i className="ri-close-line"></i> <span>Compression</span></div>
                           <div><i className="ri-close-line"></i> <span>Optimization Techniques</span></div>
                           
                       </div>
                    </div>

                     {/* <!-- ./box 90 --> */}

                     {/* <!-- box 90 --> */}
                    <div className="questionBox">
                       <h4 className="align-items-center"><span>Q7</span> Does the website have a &nbsp; <a href="#"> disaster recovery plan?</a></h4> 
                        <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                           
                       </div>
                    </div>

                     {/* <!-- ./box 90 --> */}
                    
                </div>
                {/* <!-- ./Left Area --> */}
{/*  */}
                 {/* <!-- Right Area --> */}
                <div className="col-lg-6">
                       {/* <!-- box 90 --> */}
                     <div className="questionBox">
                       <h4 className="align-items-center"><span>Q2</span> What is the uptime and availability of the website over the past 3 months (0) below 90% (1) 90- 95% (2) Above 95% </h4> 
                         <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>below 90%</span></div>
                           <div><i className="ri-close-line"></i> <span>90- 95%</span></div>
                           <div><i className="ri-close-line"></i> <span>Above 95%</span></div>
                           
                       </div>
                    </div>
                    {/* <!--./ box 90 --> */}
                     {/* <!-- box 90 --> */}
                     <div className="questionBox">
                       <h4 className="align-items-center"><span>Q4</span> Does search return results within 2 seconds?</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                           
                       </div>
                    </div>
                    {/* <!--./ box 90 --> */}

                     {/* <!-- box 90 --> */}
                     <div className="questionBox">
                       <h4 className="align-items-center"><span>Q5</span> Does the site use a CDN for faster content delivery?</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                           
                       </div>
                    </div>
                    {/* <!--./ box 90 --> */}


                    
                </div>
                {/* <!-- ./Right Area --> */}
                

            </div>
            

        </div>

        {/* <!-- ./Performance and Technical Box --> */}





         </div>
      </section>
  {/* <FaqTab activeTab={activeTab} dashData={dashData} questions={questions} /> */}
  <Common_Tab dashData={dashData} loading={loading} />
  </>
}

export default Performace_Technical
