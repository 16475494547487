import React, { useEffect, useState } from 'react'
import { reqData } from '../../../common/api';
import { useLocation } from 'react-router-dom';

const Viral_section = ({index,item}) => {
    const [data,setData]=useState();
    const viral_api=`${process.env.REACT_APP_CHECKBRAND_VIRAL}${item.slug}`;
    const location=useLocation();
    const [viralData,setViralData]=useState()
    const [loading,setLoading]=useState();
    const [error,setError]=useState()
    const getData=()=>{
        reqData(viral_api,setData,"viral","",setLoading,"",setError)
    }

    useEffect(()=>{
        getData();
    },[])

    useEffect(()=>{
        if(data){
            setViralData(data[item.slug])
        }
    },[data])
  return <>
  <div className="col-lg-4" key={index}>
                <div className="boxAreaChart mb-4">
                  <h6 className="mb-0">VIRAL {item.title}</h6>
                  <div className="consultationTableArea">
                    <table
                      width="100%"
                      className="newsTableBox"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>{item.title}</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {viralData?.map((viralData,keyIndex)=>{
                        return <tr key={keyIndex}>
                          <td>
                            <span>{keyIndex + 1}</span>
                          </td>
                          <td>
                            <img src={`https://checkbrand.online/uploads/topics/` + viralData.photo_file} alt="" />
                          </td>
                          <td>
                            <a href={`${location.pathname}#`}>
                              {viralData.title_en}
                            </a>
                          </td>
                        </tr>})}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
  </>
}

export default Viral_section
