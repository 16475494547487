import React,{useEffect, useRef} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Register_Email from './Register_Email';

const Modal = () => {
   const location=useLocation();
   const ref=useRef();
   useEffect(()=>{
      ref.current.click();
   },[location.pathname])
  return <>
  <div className="modal fade popup sidemenuToggle" id="sidemenuToggle" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
               <div className="modal-content bid-success-content">
                  <button type="button" ref={ref} className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                  <i className="ri-close-fill"></i>
                  </button>
                  <div className="modal-body pa-8">
                     <div className="row">
                        <div className="col-12 mb-5">
                           <nav className="side-nav w-100">
                              <ul>
                                 <li> <Link to="/"> <i className="ri-home-4-line" ></i> Home</Link> </li>
                                 <li> <Link to="/"> <i className="ri-rss-line"></i> Blogs</Link> </li>
                                 <li> <Link to="/top100"><i className="ri-vip-crown-2-line"></i> TOP 100</Link> </li>
                                 <li> <Link to="/"><i className="ri-search-eye-line"></i> Resources </Link> </li>
                                 <li> <Link to="/"><i className="ri-database-2-line"></i> FAQ </Link> </li>
                                 <li> <Link to="/"><i className="ri-profile-line"></i> Trends </Link> </li>
                                 <li> <Link to="/"><i className="ri-price-tag-3-line"></i> Price </Link> </li>
                                 <li> <Link to="/brand-register"><i className="ri-user-line"></i> Register Your Brand </Link> </li>
                                 <li> <Link to="/"><i className="ri-global-fill"></i> Top 500 </Link> </li>
                                 <li> <Link to="/"><i className="ri-file-search-line"></i> Google Search </Link> </li>
                                 <li> <Link to="/"><i className="ri-file-list-3-line"></i> Top News </Link> </li>
                                 <li> <Link to="/"><i className="ri-creative-commons-sa-line"></i> Top Wiki </Link> </li>
                              </ul>
                           </nav>
                        </div>
                        <div className="col-lg-4">
                           <div className="boxPanelBd">
                              <div className="footer-widget">
                                 <h4>Follow Us</h4>
                              </div>
                              <div className="social">
                                 <Link className="icon-facebook" to="#"><i className="ri-facebook-line"></i></Link>
                                 <Link className="icon-twitter" to="#"><i className="ri-twitter-line"></i></Link>
                                 <Link className="icon-instagram" to="#"><i className="ri-instagram-line"></i></Link>
                                 <Link className="icon-linkedin" to="#"><i className="ri-linkedin-line"></i></Link>
                                 <Link className="icon-linkedin" to="#"><i className="ri-youtube-line"></i></Link>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-4">
                           <div className="boxPanelBd">
                              <div className="footer-widget">
                                 <h4>Contact Us</h4>
                              </div>
                              <Link to="#"  className="btn btn-gradient btn-small">
                              <span><i className="ri-wallet-3-line"></i>Support </span></Link>
                           </div>
                        </div>
                        <div className="col-lg-4">
                           <div className="boxPanelBd">
                              <div className="footer-widget">
                                 <h4>Mail Us</h4>
                              </div>
                              {/* <form > */}
                                 {/* <div className="subscribe-mail">
                                    <input type="text" name="email" id="subscribe-email" placeholder="Email Address" />
                                    <button className="btn btn-small btn-gradient"><span><i className="ri-send-plane-line"></i></span></button>
                                 </div> */}
                                 <Register_Email />
                              {/* </form> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
  </>
}

export default Modal
