export const countryRank = [
  {
    id: 1,
    title: "Global Rank",
    rank: "299361",
    icon: "ri-global-line",
    bgColor: "#623aa2",
  },
  {
    id: 2,
    title: "Country Rank",
    rank: "299361",
    icon: "ri-map-pin-line",
    bgColor: "#f8c246",
  },
  {
    id: 3,
    title: "Category Rank",
    rank: "299361",
    icon: "ri-function-line",
    bgColor: "#53caf0",
  },
];

export const sliderdata = ["1", "2"];

export const graph = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export const graph1 = {
  series: [
    {
      name: "series1",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    {
      name: "series2",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2018-09-19T00:00:00.000Z",
        "2018-09-19T01:30:00.000Z",
        "2018-09-19T02:30:00.000Z",
        "2018-09-19T03:30:00.000Z",
        "2018-09-19T04:30:00.000Z",
        "2018-09-19T05:30:00.000Z",
        "2018-09-19T06:30:00.000Z",
      ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  },
};

export const progressBar = [
  { id: 1, title: "Mobile Friendliness", percent: "35%", bgColor: "#623aa3" },
  {
    id: 2,
    title: "Backlinks on the pages",
    percent: "56%",
    bgColor: "#ec428b",
  },
  {
    id: 3,
    title: "Domain Age, URL, and Authority",
    percent: "20%",
    bgColor: "#f49e3f",
  },
  { id: 4, title: "Keywords on the pages", percent: "64%", bgColor: "#0d6efd" },
];

export const rankingData = [
  {
    id: 1,
    title: "Category Rank",
    rank: "#886",
    gradientLeft: "#ff416c",
    gradientRight: "#ff4b2b",
  },
  {
    id: 1,
    title: "Country Rank",
    rank: "#19962",
    gradientLeft: "#56ccf2",
    gradientRight: "#2f80ed",
  },
  {
    id: 1,
    title: "Country Rank",
    rank: "#19962",
    gradientLeft: "#78d23f",
    gradientRight: "#4ca80b",
  },
];

export const headerRoute = [
  { id: 1, title: "Overview", route: "#" },
  { id: 2, title: "Security and Privacy", route: "#" },
  { id: 3, title: "Performance and Technical", route: "#" },
  { id: 4, title: "Integration & Services", route: "#" },
  { id: 5, title: "Mobile Responsiveness", route: "#" },
  { id: 6, title: "Content and Information", route: "#" },
  { id: 7, title: "Interactivity and Engagement", route: "#" },
  { id: 8, title: "Branding and Visual Identity", route: "#" },
  { id: 9, title: "Navigation", route: "#" },
  { id: 9, title: "Accessibility", route: "#" },
  // { id: 8, title: "Considerations", route: "#" },
  // { id: 8, title: "", route: "#" },
];

export const charts = [
  {
    id: 1,
    title: "Performance grade",
    icon: "ri-dashboard-3-line",
    detail: "67",
    grade: "C",
  },
  {
    id: 2,
    title: "Page size",
    icon: "ri-file-text-line",
    detail: "8.75MB",
  },
  {
    id: 3,
    title: "Load time",
    icon: "ri-history-line",
    detail: "22s",
  },
  {
    id: 4,
    title: "Requests",
    icon: "ri-file-edit-line",
    detail: "84",
  },
];

export const performanceSuggestion = [
  {
    id: 1,
    target: "one",
    labelled: "collapseOne",
    title: "Make fewer HTTP requests",
    description:
      "Decreasing the number of components on a page reduces the number of HTTP requests required to render the page, resulting in faster page loads. Some ways to reduce the number of components include: combine files, combine multiple scripts into one script, combine multiple CSS files into one style sheet, and use CSS Sprites and image maps.",
  },
  {
    id: 2,
    target: "two",
    labelled: "collapseTwo",
    title: "Make fewer HTTP requests",
    description:
      "Decreasing the number of components on a page reduces the number of HTTP requests required to render the page, resulting in faster page loads. Some ways to reduce the number of components include: combine files, combine multiple scripts into one script, combine multiple CSS files into one style sheet, and use CSS Sprites and image maps.",
  },
];

export const contentData = [
  { id: 1, title: "Image", percent: "76.6", storage: "8.37" },
  { id: 2, title: "Script", percent: "14.5", storage: "1.59" },
  { id: 3, title: "CSS", percent: "1.2", storage: "0.12" },
  { id: 4, title: "HTML", percent: "4.1", storage: "0.44" },
  { id: 5, title: "Font", percent: "0.3", storage: "0.03" },
];

export const responseData = [
  {
    id: 1,
    title: "Forbidden from accessing a valid URL",
    response: "4",
    code: "403",
  },
  { id: 2, title: "OK", response: "92", code: "200" },
  { id: 3, title: "Moved Permanently", response: "1", code: "301" },
  { id: 4, title: "Moved Permanently", response: "1", code: "301" },
  { id: 5, title: "Moved Permanently", response: "1", code: "301" },
];

export const backlinkData = [
  {
    id: 1,
    title: "Pradhan Mantri Awas Yojana",
    url: "https://nhb.org.in/government-scheme/pradhan-mantri-awas-yojana-credit-linked-subsidy-scheme/",
    anchorText: "Home",
    linkUrl: "https://rural.nic.in/",
    type: "Follow",
  },
  {
    id: 2,
    title: "Indian National Congress - Wikipedia",
    url: "https://en.wikipedia.org/wiki/Indian_National_Congress",
    anchorText: "National Rural Employment Guarantee Act 2005",
    linkUrl:
      "https://rural.nic.in/sites/default/files/nrega/Library/Books/1_MGNREGA_Act.pdf",
    type: "No Follow",
  },
  {
    id: 3,
    title: "telschool",
    url: "https://telschoola.blogspot.com/",
    anchorText: "rural",
    linkUrl: "https://rural.nic.in/",
    type: "No Follow",
  },
  {
    id: 4,
    title: "Council of Ministers| National Portal of India",
    url: "https://www.india.gov.in/my-government/whos-who/council-ministers",
    anchorText: "Contact",
    linkUrl: "https://rural.nic.in/about-us/whos-who",
    type: "Follow",
  },
  {
    id: 5,
    title: "Data & Graphs – National Housing Bank",
    url: "https://nhb.org.in/data-graphs/",
    anchorText: "Home",
    linkUrl: "https://rural.nic.in/",
    type: "Follow",
  },
  {
    id: 6,
    title: "Data & Graphs – National Housing Bank",
    url: "https://nhb.org.in/data-graphs/",
    anchorText: "Home",
    linkUrl: "https://rural.nic.in/",
    type: "Follow",
  },
];

export const backlinkType = [
  {
    id: 1,
    title: "No. of Follow Backlinks",
    rank: "#886",
    gradientLeft: "#ff416c",
    gradientRight: "ff4b2b",
  },
  {
    id: 2,
    title: "No. of No Follow Backlinks",
    rank: "#19962",
    gradientLeft: "78d23f",
    gradientRight: "4ca80b",
  },
];

export const backTypes = [
  { id: 1, title: "Text", percent: "35%", bgColor: "#623aa2" },
  { id: 1, title: "Image", percent: "35%", bgColor: "#f1388b" },
  { id: 1, title: "Form", percent: "35%", bgColor: "#ff9f40" },
  { id: 1, title: "Frame", percent: "35%", bgColor: "#0d6efd" },
];

export const countryData = [
  {
    id: 1,
    title: "India",
    logo: "india.jpg",
    contryImg: "http://webalytics.companydemo.in/india_main.png",
    percent: "",
  },
  {
    id: 2,
    title: "Japan",
    logo: "japan.png",
    contryImg: "http://webalytics.companydemo.in/japan_main.png",
    percent: "",
  },
  {
    id: 3,
    title: "UK",
    logo: "UK.jpeg",
    contryImg: "http://webalytics.companydemo.in/canada_main.png",
    percent: "",
  },
  {
    id: 4,
    title: "Canada",
    logo: "Canada.webp",
    contryImg: "http://webalytics.companydemo.in/canada_main.png",
    percent: "",
  },
  {
    id: 5,
    title: "US",
    logo: "US_main.png",
    contryImg:
      "http://blog.logomyway.com/wp-content/uploads/2011/09/country-flags.jpg",
    percent: "",
  },
  {
    id: 6,
    title: "Others",
    logo: "others_logo.jpeg",
    contryImg:
      "http://blog.logomyway.com/wp-content/uploads/2011/09/country-flags.jpg",
    percent: "",
  },
];

export const data4 = {
  labels: ["18-24", "25-34", "35-44", "45-54", "55-64", "65+"],
  datasets: [
    {
      label: "Male",
      data: [
        207214.656, 165418.518, 49448.952, 35379.5478, 29551.6356, 4709.424,
      ],
      borderColor: "rgba(75, 192, 192, 1)",
      tension: 0.4,
      pointBackgroundColor: "rgba(75, 192, 192, 1)", // Set the data points color
      pointBorderColor: "rgba(75, 192, 192, 1)", // Set the data points border color
      pointRadius: 5, // Set the radius of the data points
      pointHoverRadius: 7, // Set the radius of the data points on hover
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          // This can happen if the chart is not visible yet
          return null;
        }
        const gradient = ctx.createLinearGradient(
          chartArea.left,
          0,
          chartArea.right,
          0
        );
        gradient.addColorStop(0, "rgba(75, 192, 192, 0.1)");
        gradient.addColorStop(1, "rgba(75, 192, 192, 0.7)");
        return gradient;
      },
    },
    {
      label: "Female",
      data: [
        44798.3958, 15717.7026, 18307.8858, 3414.3324, 12185.6346, 2590.1832,
      ],
      borderColor: "#742774",
      tension: 0.4,
      pointBackgroundColor: "#742774", // Set the data points color
      pointBorderColor: "#742774", // Set the data points border color
      pointRadius: 5, // Set the radius of the data points
      pointHoverRadius: 7, // Set the radius of the data points on hover
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          // This can happen if the chart is not visible yet
          return null;
        }
        const gradient = ctx.createLinearGradient(
          chartArea.left,
          0,
          chartArea.right,
          0
        );
        gradient.addColorStop(0, "rgba(116, 39, 116, 0.1)");
        gradient.addColorStop(1, "rgba(116, 39, 116, 0.7)");
        return gradient;
      },
    },
  ],
};

export const options4 = {
  plugins: {
    title: {
      display: true,
      text: "Bar Chart",
    },
  },
  scales: {
    y: {
      beginAtZero: true, // Start the y-axis at zero
      ticks: {
        stepSize: 55000, // Customize the step size between ticks (change as per your data)
      },
    },
  },
};

export const data3 = {
  labels: ["Direct", "Referrals", "Search", "Social", "Mall", "Display"],
  datasets: [
    {
      label: "Value",
      backgroundColor: "rgba(0, 255, 0, 0.2)",
      borderColor: "rgb(0, 255, 0)",
      borderWidth: 1,
      data: [112084.2, 16129.7, 1530.56, 16129.7, 0, 176.6],
    },
  ],
};

export const options3 = {
  plugins: {
    title: {
      display: true,
      text: "Bar Chart",
    },
  },
  datalabels: {
    anchor: "end",
    align: "end",
    formatter: function (value, context) {
      const percentage = (
        (value / context.dataset.data.reduce((a, b) => a + b, 0)) *
        100
      ).toFixed(2);
      return percentage + "%";
    },
  },
  scales: {
    y: {
      ticks: {
        stepSize: 30000, // Change the stepSize to the desired value (100000)
      },
    },
  },
};
export const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
];

export const data = {
  labels: ["Female", "Male"],
  datasets: [
    {
      label: "Count",
      backgroundColor: "rgba(0, 255, 0, 0.2)",
      borderColor: "rgb(0, 255, 0)",
      borderWidth: 1,
      data: [318357.06, 270320.9],
    },
  ],
};

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Bar Chart",
    },
  },
  datalabels: {
    anchor: "end",
    align: "end",
    formatter: function (value, context) {
      const percentage = (
        (value / context.dataset.data.reduce((a, b) => a + b, 0)) *
        100
      ).toFixed(2);
      return percentage + "%";
    },
  },
  scales: {
    y: {
      ticks: {
        stepSize: 80000, // Change the stepSize to the desired value (100000)
      },
    },
  },
};

export const comparison = [
  { id: 1, first: "rural.nic.in", second: "powermin.gov.in" },
  { id: 2, first: "rural.nic.in", second: "powermin.gov.in" },
  { id: 3, first: "rural.nic.in", second: "powermin.gov.in" },
];
// export const data2 = {
//   labels: ['Positive 😄', 'Neutral 😐', 'Negative 😞'],
//   datasets: [
//     {
//       label: 'Visits',
//       color:['rgba(0, 255, 0, 0.5)', 'rgba(128, 128, 128, 0.5)', 'rgba(255, 0, 0, 0.5)'],
//       backgroundColor: ['rgba(0, 255, 0, 0.5)', 'rgba(128, 128, 128, 0.5)', 'rgba(255, 0, 0, 0.5)'],
//       borderColor: ['rgb(0, 255, 0)', 'rgb(128, 128, 128)', 'rgb(255, 0, 0)'],
//       borderWidth: 1,
//       data: [33, 59, 8],
//     },
//   ],
// };

// const emojiMapping = {
//   Positive: '😄',
//   Neutral: '😐',
//   Negative: '😞',
// };

// export const options2 = {
//   plugins: {
//     title: {
//       display: true,
//       text: 'Bar Chart',
//     },
//     legend: {
//       display: true,
//       position: 'top',
//       labels: {
//         font: {
//           size: 14, // Adjust the size of the legend labels
//         },
//       },
//     },
//     tooltip: {
//       font:{
//         size:24,
//       },
//       callbacks: {
//         label: (context) => {
//           const label = context.dataset.label || '';
//           const emoji = emojiMapping[label] || '';
//           return emoji + ' ' + context.formattedValue;
//         },
//       },
//       itemSort: (a, b) => b.datasetIndex - a.datasetIndex, // For adding class to the first tooltip item
//     },
//   },
//   scales: {
//     x: {
//       display: true, // Hide the x-axis
//       grid: {
//         display: false, // Hide the x-axis grid lines
//         drawOnChartArea: false,
//       },
//       ticks: {
//         display: true, // Hide the x-axis ticks
//       },
//     },
//     y: {
//       display: true, // Hide the y-axis
//       beginAtZero: true,
//       grid: {
//         display: true, // Hide the y-axis grid lines
//         drawOnChartArea: false,},
//       ticks: {
//         display: true, // Hide the y-axis ticks
//         stepSize: 25,
//         callback: (value) => `${value}%`,
//       },
//     },
//   },
// };

export const data2 = {
  labels: ["October", "November", "December"],
  datasets: [
    {
      label: "Visits",
      backgroundColor: "rgba(0, 255, 0, 0.2)",
      borderColor: "rgb(0, 255, 0)",
      borderWidth: 1,
      data: [367400, 395000, 346400],
    },
  ],
};

export const options2 = {
  plugins: {
    title: {
      display: true,
      text: "Bar Chart",
    },
  },
  scales: {
    y: {
      ticks: {
        stepSize: 100000, // Change the stepSize to the desired value (100000)
      },
    },
  },
};

// const dash_json = {
//   website_name: "",
//   website_logo: "",
//   website_description: "",
//   website_url: "",
//   category_rank_last_month: "",
//   organic_Traffic: "",
//   organic_Keyword: "",
//   ranking: [
//     {
//       id: 1,
//       title: "Global Rank",
//       rank: "23456",
//     },
//     {
//       id: 1,
//       title: "Country Rank",
//       rank: "23456",
//     },
//     {
//       id: 1,
//       title: "Category Rank",
//       rank: "23456",
//     },
//   ],
//   performaceGrade: [
//     {
//       id: 1,
//       title: "Performance grade",
//       detail: "67",
//       grade: "C",
//     },
//     {
//       id: 2,
//       title: "Page size",
//       detail: "8.75MB",
//     },
//     {
//       id: 3,
//       title: "Load time",
//       detail: "22s",
//     },
//     {
//       id: 4,
//       title: "Requests",
//       detail: "84",
//     },
//   ],
//   pieData: [
//     { id: 1, title: "Mobile Friendliness", percent: "35%" },
//     {
//       id: 2,
//       title: "Backlinks on the pages",
//       percent: "56%",
//     },
//     {
//       id: 3,
//       title: "Domain Age, URL, and Authority",
//       percent: "20%",
//     },
//     { id: 4, title: "Keywords on the pages", percent: "64%" },
//   ],
//   performance_suggestion: [
//     {
//       id: 1,
//       title: "Make fewer HTTP requests",
//       description:
//         "Decreasing the number of components on a page reduces the number of HTTP requests required to render the page, resulting in faster page loads. Some ways to reduce the number of components include: combine files, combine multiple scripts into one script, combine multiple CSS files into one style sheet, and use CSS Sprites and image maps.",
//     },
//     {
//       id: 2,
//       title: "Make fewer HTTP requests",
//       description:
//         "Decreasing the number of components on a page reduces the number of HTTP requests required to render the page, resulting in faster page loads. Some ways to reduce the number of components include: combine files, combine multiple scripts into one script, combine multiple CSS files into one style sheet, and use CSS Sprites and image maps.",
//     },
//   ],
//   web_content_data: [
//     { id: 1, title: "Image", percent: "76.6", storage: "8.37" },
//     { id: 2, title: "Script", percent: "14.5", storage: "1.59" },
//     { id: 3, title: "CSS", percent: "1.2", storage: "0.12" },
//     { id: 4, title: "HTML", percent: "4.1", storage: "0.44" },
//     { id: 5, title: "Font", percent: "0.3", storage: "0.03" },
//   ],
//   back_linkData: [
//     {
//       id: 1,
//       title: "Pradhan Mantri Awas Yojana",
//       url: "https://nhb.org.in/government-scheme/pradhan-mantri-awas-yojana-credit-linked-subsidy-scheme/",
//       anchorText: "Home",
//       linkUrl: "https://rural.nic.in/",
//       type: "Follow",
//     },
//     {
//       id: 2,
//       title: "Indian National Congress - Wikipedia",
//       url: "https://en.wikipedia.org/wiki/Indian_National_Congress",
//       anchorText: "National Rural Employment Guarantee Act 2005",
//       linkUrl:
//         "https://rural.nic.in/sites/default/files/nrega/Library/Books/1_MGNREGA_Act.pdf",
//       type: "No Follow",
//     },
//     {
//       id: 3,
//       title: "telschool",
//       url: "https://telschoola.blogspot.com/",
//       anchorText: "rural",
//       linkUrl: "https://rural.nic.in/",
//       type: "No Follow",
//     },
//     {
//       id: 4,
//       title: "Council of Ministers| National Portal of India",
//       url: "https://www.india.gov.in/my-government/whos-who/council-ministers",
//       anchorText: "Contact",
//       linkUrl: "https://rural.nic.in/about-us/whos-who",
//       type: "Follow",
//     },
//     {
//       id: 5,
//       title: "Data & Graphs – National Housing Bank",
//       url: "https://nhb.org.in/data-graphs/",
//       anchorText: "Home",
//       linkUrl: "https://rural.nic.in/",
//       type: "Follow",
//     },
//     {
//       id: 6,
//       title: "Data & Graphs – National Housing Bank",
//       url: "https://nhb.org.in/data-graphs/",
//       anchorText: "Home",
//       linkUrl: "https://rural.nic.in/",
//       type: "Follow",
//     },
//   ],
//   back_link: [
//     {
//       id: 1,
//       title: "No. of Follow Backlinks",
//       rank: "#886",
//     },
//     {
//       id: 2,
//       title: "No. of No Follow Backlinks",
//       rank: "#19962",
//     },
//   ],
//   back_link_types: [
//     { id: 1, title: "Text", percent: "35%" },
//     { id: 2, title: "Image", percent: "35%" },
//     { id: 3, title: "Form", percent: "35%" },
//     { id: 4, title: "Frame", percent: "35%" },
//   ],
//   different_country_data: [
//     {
//       id: 1,
//       title: "India",
//       logo: "india.jpg",
//       contryImg: "http://webalytics.companydemo.in/india_main.png",
//       percent: "",
//     },
//     {
//       id: 2,
//       title: "Japan",
//       logo: "japan.png",
//       contryImg: "http://webalytics.companydemo.in/japan_main.png",
//       percent: "",
//     },
//     {
//       id: 3,
//       title: "UK",
//       logo: "UK.jpeg",
//       contryImg: "http://webalytics.companydemo.in/canada_main.png",
//       percent: "",
//     },
//     {
//       id: 4,
//       title: "Canada",
//       logo: "Canada.webp",
//       contryImg: "http://webalytics.companydemo.in/canada_main.png",
//       percent: "",
//     },
//     {
//       id: 5,
//       title: "US",
//       logo: "US_main.png",
//       contryImg:
//         "http://blog.logomyway.com/wp-content/uploads/2011/09/country-flags.jpg",
//       percent: "",
//     },
//     {
//       id: 6,
//       title: "Others",
//       logo: "others_logo.jpeg",
//       contryImg:
//         "http://blog.logomyway.com/wp-content/uploads/2011/09/country-flags.jpg",
//       percent: "",
//     },
//   ],
//   last_6_response: [
//     {
//       code_name: "403",
//       code_count: 0,
//     },
//     {
//       code_name: "200",
//       code_count: 207,
//     },
//     {
//       code_name: "301",
//       code_count: 0,
//     },
//   ],
//   traffic_engagement: [
//     { id: 1, title: "Last Month Traffic", data: 169800 },
//     { id: 1, title: "Daily Page Visits", data: 169800 },
//     { id: 1, title: "Last Month Change", data: 169800 },
//     { id: 1, title: "Avg Visit Duration", data: 169800 },
//     { id: 1, title: "Pages per Visit", data: 169800 },
//     { id: 1, title: "Bounce Rate", data: 169800 },
//   ],
// };
