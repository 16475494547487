import React from "react";
import Rating from "@mui/material/Rating";


const Section3 = ({reviewData}) => {
  function returnDate(dateString){
    var dateObject = new Date(dateString);
    var monthNames = [
     "January", "February", "March",
     "April", "May", "June",
     "July", "August", "September",
     "October", "November", "December"
    ];
    var day = dateObject.getDate();
    var monthIndex = dateObject.getMonth();
    var year = dateObject.getFullYear();
    var formattedDate = day + ' ' + monthNames[monthIndex] + ' ' + year;
    return formattedDate;
  }
  return (
    <>
      <section className="profileSection pt-5 pb-5">
        <div className="container">
          <div className="row">
            {/* <!-- left Profile--> */}
            <div className="col-lg-8">
              {/* <!-- box-01 --> */}
              {reviewData?.reviews?.slice(0,3).map((item,index)=>{
              return <div className="companyProfile reviewsDetailbox mb-4" key={index}>
                <div className="d-flex align-items-center reviewsStar mb-3">
                <Rating style={{marginRight:'10px'}}
                      name="read-only"
                      value={item?.rating}
                      readOnly
                      size="medium"
                    />
                  {/* {Array(item.rating).fill("-").map((index)=>{
                 return <i className="ri-star-fill" key={index}></i>
                })} */}
                  {/* <div className="ProfileName">{item.rating}/5</div> */}
                </div>
                <div className="dateArea">
                  <strong>{item.name}</strong> | <span>{returnDate(item.created_at)}</span>
                </div>

                <p>
                  {" "}
                  {item.review}
                </p>
              </div>})}
            </div>
            <div className="col-lg-4">
              {/* <!-- mentions box 01 --> */}
              <div className="boxAreaChart mb-4">
                <h6>Digital Ranking</h6>
                <div className="chartContainer">
                  <section className="valuePanel">
                    <h2 className="m-0">
                      #<span className="number counter-item-active">14</span>
                    </h2>
                  </section>
                </div>
              </div>
              {/* <!-- ./mentions box 01 -->
                        <!-- ENGAGEMENT box 01 --> */}
              <div className="boxAreaChart mb-4">
                <h6>Brand Value</h6>
                <div className="chartContainer">
                  <section className="valuePanel">
                    <h2 className="m-0">
                      <span className="number counter-item-active">547</span> Lac
                    </h2>
                  </section>
                </div>
              </div>
              {/* <!-- ./ENGAGEMENT box 01 -->
                      <!-- mentions box 01 --> */}
              <div className="boxAreaChart mb-4">
                <h6>MENTIONS</h6>
                <div className="chartContainer">
                  <section className="valuePanel">
                    <h2 className="m-0">
                      <span className="number counter-item-active">4</span> Lac
                    </h2>
                  </section>
                </div>
              </div>
              {/* <!-- ./mentions box 01 -->
                        <!-- ENGAGEMENT box 01 --> */}
              <div className="boxAreaChart mb-4">
                <h6>ENGAGEMENT</h6>
                <div className="chartContainer">
                  <section className="valuePanel">
                    <h2 className="m-0">
                      <span className="number counter-item-active">47</span> Lac
                    </h2>
                  </section>
                </div>
              </div>
              {/* <!-- ./ENGAGEMENT box 01 --> */}
            </div>

            {/* <!-- right Section --> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Section3;
