import React from 'react';
import { headerRoute } from '../commonJson/mockData';
import { useLocation } from 'react-router-dom';

const TopTabArea = ({dashData,setActiveTab,activeTab}) => {
  const location=useLocation();
  return <>
  <section className="topTabArea" >
      {/* <!-- top --> */}
      <div className="app-sidebar">
        <div className="container">
          <div className="d-flex align-items-center">
            {/* <!-- left top section --> */}
            <div className="col">
              <div className="boxArea-02">
                {/* <img src="http://webalytics.companydemo.in/assets/images/img01.png" alt="img01" /> */}
                <img src={dashData?.website_logo} alt="img01" />
                 {dashData.website_name}
              </div>
            </div>
            {/* <!-- ./left top section -->
            <!-- right top section --> */}
            <div className="topRightSection">
              <div className="d-flex gap-2 align-items-center">
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="ri-bar-chart-2-line"></i> All traffic </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a className="dropdown-item" href={`${location.pathname}#`}>All traffic</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href={`${location.pathname}#`}>Desktop</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href={`${location.pathname}#`}>Mobile</a>
                    </li>
                  </ul>
                </div>
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="ri-calendar-2-line"></i> June 2023 </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a className="dropdown-item" href={`${location.pathname}#`}>Last 1 Month</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href={`${location.pathname}#`}>Last 2 Months (Nov 2022 - Dec 2022)</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href={`${location.pathname}#`}>Last 3 Months (Nov 2022 - Dec 2022)</a>
                    </li>
                  </ul>
                </div>
                <div className="downloadBtn">
                  <i className="ri-download-2-line"></i>
                </div>
              </div>
            </div>
            {/* <!-- ./right top section --> */}
          </div>
        </div>
      </div>
      {/* <!-- ./top --> */}

<div className="topMenuSection">
    <div className="topMenuArea">
        <ul className="">
          {headerRoute?.map((item)=>{
            return <li key={item.id} className={item.title === activeTab ? "active" : ""} onClick={()=>setActiveTab(item.title)}><a href={`${location.pathname}${item.route}`}>{item.title}</a></li>})}
        </ul>
    </div>

 </div>



      
    </section>
  </>
}

export default TopTabArea
