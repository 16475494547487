import React from "react";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <>
      <div className="popup-mobile-menu">
        <div className="inner">
          <div className="header-top">
            <div className="logo logo-custom-css">
              <Link to="/" className="logo logo-light">
                <img src="images/logo-white.png" alt="brand" />{" "}
              </Link>
            </div>
            <div className="close-menu">
              <button className="close-button">
                <i className="ri-close-fill"></i>
              </button>
            </div>
          </div>
          <nav>
            <ul className="mainmenu">
              <li className="has-dropdown has-menu-child-item">
                <Link className="active" to="/">
                  Home
                </Link>
                <ul className="submenu">
                  <li>
                    <Link to="/">Home Style One </Link>
                  </li>
                  <li>
                    <Link to="/" className="active">
                      Home Style Two
                    </Link>
                  </li>
                  <li>
                    <Link to="/">Home Style Three</Link>
                  </li>
                  <li>
                    <Link to="/">Home Style Four </Link>
                  </li>
                  <li>
                    <Link to="/">Home Style Five</Link>
                  </li>
                  <li>
                    <Link to="/">Home Style Six</Link>
                  </li>
                  <li>
                    <Link to="/">
                      Home Style Seven<span className="new">(New)</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has-dropdown has-menu-child-item">
                <Link to="#">Explore</Link>
                <ul className="submenu">
                  <li>
                    <Link to="/">Explore Filter</Link>
                  </li>
                  <li>
                    <Link to="/">Explore Isotop</Link>
                  </li>
                  <li>
                    <Link to="/">Explore Carousel</Link>
                  </li>
                  <li>
                    <Link to="/">Explore Filter Sidebar</Link>
                  </li>
                  <li>
                    <Link to="/">Live Auction 1</Link>
                  </li>
                  <li>
                    <Link to="/">Live Auction 2</Link>
                  </li>
                  <li>
                    <Link to="/">Live Auction 3</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/">Activity</Link>
              </li>
              <li className="has-dropdown has-menu-child-item">
                <Link to="#">Community</Link>
                <ul className="submenu">
                  <li>
                    <Link to="/">Blog</Link>
                  </li>
                  <li>
                    <Link to="/">Blog Details</Link>
                  </li>
                </ul>
              </li>
              <li className="has-dropdown has-menu-child-item">
                <Link to="#">Pages</Link>
                <ul className="submenu">
                  <li>
                    <Link to="/">Item Details</Link>
                  </li>
                  <li>
                    <Link to="/">Authors</Link>
                  </li>
                  <li>
                    <Link to="/">Author Profile</Link>
                  </li>
                  <li>
                    <Link to="/">All Sellers</Link>
                  </li>
                  <li>
                    <Link to="/">All Sellers 2</Link>
                  </li>
                  <li>
                    <Link to="/">Popular Collections</Link>
                  </li>
                  <li>
                    <Link to="/">Popular Collections-2</Link>
                  </li>
                  <li>
                    <Link to="/">Wallet Connect</Link>
                  </li>
                  <li>
                    <Link to="/">Create Item</Link>
                  </li>
                  <li>
                    <Link to="/">Notification</Link>
                  </li>
                  <li>
                    <Link to="/">Sign In</Link>
                  </li>
                  <li>
                    <Link to="/">Sign In 2</Link>
                  </li>
                  <li>
                    <Link to="/">Forgot Password</Link>
                  </li>
                  <li>
                    <Link to="/">SignUp</Link>
                  </li>
                  <li>
                    <Link to="/">404</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Menu;
