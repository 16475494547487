import React from 'react';
import FaqTab from '../../../common/FaqTab';
import Common_Tab from '../../../common/Common_Tab';

const Branding_Visual = ({activeTab,dashData,loading}) => {
  const questions = [
    {
      id: 1,
      title:
        "Is the visual design of the website aligned with the government's overall identity, including branding guidelines, color schemes, logo usage, and visual style?",
      description: "",
      ariaControls: "first",
    },
    {
      id: 2,
      title:
        "Are the logo, fonts, name, headings and other branding elements consistent and placed on same location on the website?",
      description: "",
      ariaControls: "second",
    },
    {
      id: 3,
      title:
        "Does the website indicate ownership information",
      description: "",
      ariaControls: "third",
    },
    {
      id: 4,
      title:
        "Is the website domain name on respective government authorised like .gov.in, .gov, gov.uk etc",
      description: "",
      ariaControls: "fourth",
    },
   
  ];
  return <>
  <FaqTab activeTab={activeTab} dashData={dashData} questions={questions} />
  <Common_Tab dashData={dashData} loading={loading} />
  </>
}

export default Branding_Visual
