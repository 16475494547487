import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { reqData } from "../../common/api";
import { ToastContainer, toast } from "react-toastify";

const Contact = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [error,setError]=useState();
  const contactApi = process.env.REACT_APP_CONTACT;
  const [contactField, setContactField] = useState({
    firstname: "",
    lastname: "",
    email: "",
    comment: "",
    phone_no: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactField({ ...contactField, [name]: value });
  };
  const getData = () => {
    const formData=new FormData();
    formData.append('firstname',contactField.firstname)
    formData.append('lastname',contactField.lastname)
    formData.append('email',contactField.email)
    formData.append('comment',contactField.comment)
    formData.append('phone_no',contactField.phone_no)
    reqData(contactApi, setData, "contact", formData, setLoading,"",setError);
  };

  console.log("Contact field is", contactField);

  useEffect(()=>{
      if(data?.message == "success"){
        toast('Contact form submitted')
        setContactField({
            firstname: "",
            lastname: "",
            email: "",
            comment: "",
            phone_no: "",
          })
      }
  },[data])
  return (
    <>
      <div className="wrapper">
        <Header />
        <ToastContainer />
        <section className="innerHeader">
          <h2>Contact Us</h2>
          <span>Digital | Social | Online | Influencers</span>
        </section>
        <section className="section-p conatctDetailArea section-gray">
          <div className="container">
            <div className="social-in">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="icon-box text-center">
                    <svg
                      width="70"
                      height="70"
                      viewBox="0 0 70 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
                        fill="white"
                      ></path>
                      <path
                        d="M35 69C53.7777 69 69 53.7777 69 35C69 16.2223 53.7777 1 35 1C16.2223 1 1 16.2223 1 35C1 53.7777 16.2223 69 35 69Z"
                        stroke="#FF4F4F"
                        stroke-width="2"
                      ></path>
                      <path
                        d="M34.075 46.516C27.264 36.642 26 35.629 26 32C26 29.6131 26.9482 27.3239 28.636 25.636C30.3239 23.9482 32.6131 23 35 23C37.3869 23 39.6761 23.9482 41.364 25.636C43.0518 27.3239 44 29.6131 44 32C44 35.629 42.736 36.642 35.925 46.516C35.8215 46.6655 35.6832 46.7878 35.5221 46.8722C35.361 46.9566 35.1819 47.0007 35 47.0007C34.8181 47.0007 34.639 46.9566 34.4779 46.8722C34.3168 46.7878 34.1785 46.6655 34.075 46.516ZM35 35.75C35.7417 35.75 36.4667 35.5301 37.0834 35.118C37.7001 34.706 38.1807 34.1203 38.4645 33.4351C38.7484 32.7498 38.8226 31.9958 38.6779 31.2684C38.5333 30.541 38.1761 29.8728 37.6517 29.3483C37.1272 28.8239 36.459 28.4668 35.7316 28.3221C35.0042 28.1774 34.2502 28.2516 33.5649 28.5355C32.8797 28.8193 32.294 29.2999 31.882 29.9166C31.4699 30.5333 31.25 31.2583 31.25 32C31.25 32.9946 31.6451 33.9484 32.3483 34.6517C33.0516 35.3549 34.0054 35.75 35 35.75Z"
                        fill="#FF4F4F"
                      ></path>
                    </svg>
                    <h4 className="q-text-primary-color q-p-margin-t-30">
                      Office Address
                    </h4>
                    <p className="pera-two">
                      <span>B-1, Nearest Metro Station Sarita</span>
                      <br />
                      <span>Vihar, Exit from Gate No:1 New </span>
                      <span> Delhi-110044</span>
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="icon-box text-center">
                    <svg
                      width="70"
                      height="70"
                      viewBox="0 0 70 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
                        fill="white"
                      ></path>
                      <path
                        d="M35 69C53.7777 69 69 53.7777 69 35C69 16.2223 53.7777 1 35 1C16.2223 1 1 16.2223 1 35C1 53.7777 16.2223 69 35 69Z"
                        stroke="#FF4F4F"
                        stroke-width="2"
                      ></path>
                      <path
                        d="M46.315 39.96L41.065 37.71C40.8407 37.6142 40.5914 37.594 40.3546 37.6522C40.1177 37.7105 39.9063 37.8441 39.752 38.033L37.428 40.874C33.779 39.1535 30.8424 36.217 29.122 32.568L31.963 30.243C32.1523 30.089 32.2862 29.8775 32.3445 29.6406C32.4028 29.4037 32.3823 29.1542 32.286 28.93L30.036 23.68C29.9304 23.4385 29.7439 23.2413 29.5087 23.1225C29.2734 23.0037 29.004 22.9706 28.747 23.029L23.872 24.154C23.6242 24.2112 23.403 24.3507 23.2446 24.5497C23.0863 24.7488 23 24.9956 23 25.25C22.9997 28.1063 23.5621 30.9347 24.6551 33.5737C25.748 36.2126 27.3501 38.6104 29.3698 40.6301C31.3896 42.6499 33.7874 44.2519 36.4263 45.3449C39.0653 46.4378 41.8937 47.0002 44.75 47C45.005 47.0008 45.2528 46.915 45.4527 46.7566C45.6525 46.5981 45.7926 46.3765 45.85 46.128L46.975 41.253C47.0326 40.9943 46.9981 40.7237 46.8777 40.4877C46.7572 40.2517 46.5582 40.0651 46.315 39.96Z"
                        fill="#FF4F4F"
                      ></path>
                    </svg>
                    <h4 className="q-text-primary-color q-p-margin-t-30">
                      Phone Numbers
                    </h4>
                    <p className="pera-two">
                      <span> +91 92891 34393</span>
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="icon-box text-center">
                    <svg
                      width="70"
                      height="70"
                      viewBox="0 0 70 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
                        fill="white"
                      ></path>
                      <path
                        d="M35 69C53.7777 69 69 53.7777 69 35C69 16.2223 53.7777 1 35 1C16.2223 1 1 16.2223 1 35C1 53.7777 16.2223 69 35 69Z"
                        stroke="#FF4F4F"
                        stroke-width="2"
                      ></path>
                      <path
                        d="M46.545 31.944C46.5865 31.9118 46.6362 31.8918 46.6884 31.8862C46.7407 31.8807 46.7935 31.8899 46.8408 31.9128C46.8881 31.9357 46.9281 31.9713 46.9562 32.0157C46.9843 32.0601 46.9995 32.1115 47 32.164V41.75C46.9997 42.3467 46.7626 42.9188 46.3407 43.3407C45.9188 43.7626 45.3467 43.9997 44.75 44H25.25C24.6533 43.9997 24.0812 43.7626 23.6593 43.3407C23.2374 42.9188 23.0003 42.3467 23 41.75V32.169C22.9997 32.1161 23.0144 32.0643 23.0424 32.0194C23.0704 31.9745 23.1105 31.9385 23.1581 31.9155C23.2057 31.8925 23.2589 31.8834 23.3114 31.8893C23.3639 31.8953 23.4137 31.916 23.455 31.949C24.5 32.764 25.9 33.8 30.678 37.273C31.667 38 33.336 39.514 35 39.5C36.673 39.514 38.375 37.963 39.327 37.269C44.108 33.8 45.5 32.759 46.545 31.944ZM35 38C36.088 38.019 37.653 36.631 38.441 36.059C44.661 31.545 45.135 31.151 46.569 30.026C46.7034 29.921 46.8121 29.7866 46.8867 29.6333C46.9614 29.4799 47.0002 29.3116 47 29.141V28.25C46.9997 27.6533 46.7626 27.0812 46.3407 26.6593C45.9188 26.2374 45.3467 26.0003 44.75 26H25.25C24.6533 26.0003 24.0812 26.2374 23.6593 26.6593C23.2374 27.0812 23.0003 27.6533 23 28.25V29.141C23.0002 29.3117 23.0391 29.48 23.1138 29.6335C23.1884 29.787 23.2969 29.9215 23.431 30.027C24.865 31.147 25.339 31.546 31.559 36.06C32.347 36.631 33.913 38.019 35 38Z"
                        fill="#FF4F4F"
                      ></path>
                    </svg>
                    <h4 className="q-text-primary-color q-p-margin-t-30">
                      Email Address
                    </h4>
                    <p className="pera-two">
                      <span>info@checkbrand.online</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-p ContactFormArea">
          <div className="container">
            <div className="section-heading text-center q-p-margin-b-30">
              <h2>WRITE TO US</h2>
              <p className="para-three">
                Kindly Fill-Up the form. We will communicate with you.
              </p>
            </div>
            <div className="message-form mx-auto text-center q-p-margin-t-40">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="q-p-margin-b-24">
                    <input
                      type="text"
                      name="firstname"
                      className=" border-0 dt-input section-gray"
                      placeholder="First Name *"
                      value={contactField.firstname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="q-p-margin-b-24">
                    <input
                      type="text"
                      name="lastname"
                      className=" border-0 dt-input section-gray"
                      placeholder="Last Name *"
                      value={contactField.lastname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="q-p-margin-b-24">
                    <input
                      type="text"
                      name="email"
                      className=" border-0 dt-input section-gray"
                      placeholder="Email *"
                      value={contactField.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="q-p-margin-b-24">
                    <input
                      type="text"
                      name="phone_no"
                      className=" border-0 dt-input section-gray"
                      placeholder="Phone no. *"
                      value={contactField.phone_no}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="q-p-margin-b-40">
                    <textarea
                      name="comment"
                      className=" border-0 ms-input section-gray"
                      placeholder="Type message ..."
                      value={contactField.comment}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
              <a className="btn btn-gradient mt-5" onClick={getData}>
                <span>Submit Message</span>
              </a>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
