import React,{useState,useEffect} from 'react';
import { reqData } from "./api";
import { ToastContainer, toast } from "react-toastify";

const Register_Email = () => {
    const [email,setEmail]=useState('');
    const subscribeApi=process.env.REACT_APP_CHECKBRAND_SUBSCRIBE;
    const [data,setData]=useState();
    const [error,setError]=useState();
    const[loading,setloading]=useState()
    const getData=()=>{
      let formData=new FormData();
      formData.append('email',email)
      reqData(subscribeApi,setData,"subscribe",formData,setloading,"",setError);
      setEmail('')
    }
  
    useEffect(()=>{
      if(data?.validation === true){
        toast("Email address Registered")
      }
      else if (error){
        toast("Some error ocurred while registering email")
      }
    },[data,error])
  
    // console.log("Data of subscribe",data)
  return <>
   
   <ToastContainer />
   <div className="subscribe-mail">
                      <input
                        type="text"
                        name="email"
                        id="subscribe-email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                      />
                      <button className="btn btn-small btn-gradient" onClick={getData}>
                        <span>
                          <i className="ri-send-plane-line"></i>
                        </span>
                      </button>
                    </div>
  </>
}

export default Register_Email
