import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ReactApexChart from 'react-apexcharts';
import { charts,countryRank } from "../../../commonJson/mockData";
import { progressBar ,graph , graph1 , sliderdata} from "../../../commonJson/mockData";
ChartJS.register(ArcElement, Tooltip, Legend);

const Dash_1 = ({dashData}) => {
  charts.filter((item)=>{
    if(item.title == 'Performance grade' ){
      item.detail = dashData?.performance_grade
    }
    else if(item.title == 'Requests'){
      item.detail = dashData?.requests
    }
    else if(item.title == 'Page size'){
      item.detail = dashData?.page_size
    }
    else{
      item.detail = `${parseFloat(dashData?.load_time).toFixed(2)}s`
    }
  })
  const replace=(detail,title)=>{
    const newDetailData = `${detail}`;
    if(newDetailData.includes(".")){
    const newDetail=parseFloat(detail).toFixed(2);
    if(title == 'Load time'){
    return  `${newDetail}s`
    }
    else if(title == 'Page size'){
      return `${newDetail} Mb`
    }
    else{
      return newDetail
    }
  }
  return detail
  }

  const getPercent=(percent)=>{
    console.log("Percent is",percent.toFixed(2));
    return `${percent.toFixed(2)}%`
  }
  return (
    <>
      <section className="ruralDevtSection_web">
        <div className="container">
          <div className="row">
            <div className="col-lg-6  align-items-center pt-0 pb-0">
              <div className="ruralDevtBox">
                <div className="d-flex gap-3 MinistryLogoArea align-items-center mb-4">
                  <div>
                    {/* <img src="http://webalytics.companydemo.in/assets/images/img06.jpg" alt="Logo" /> */}
                    <img src={dashData?.website_logo} alt="Logo" style={{height:'108px' ,objectFit:'contain',padding:'10px'}} />
                  </div>
                  <div>
                    <span className="devtContArea">
                      <i className="ri-line-chart-line"></i> Analysis Report{" "}
                    </span>
                    <h1>
                      {dashData?.website_name} 
                      {/* <span>{dashData.website_name} </span> */}
                    </h1>
                  </div>
                </div>
                <p>{dashData?.website_description}
                  {/* Ministry of Rural Development is responsible for implementing
                  various schemes and policies aimed at improving the
                  socio-economic condition of rural communities, including
                  programs related to employment, housing, infrastructure, and
                  education. */}
                </p>
              </div>
              <div className="row">
                {dashData?.ranking?.map((item)=>{
                return<div key={item.id} className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <div className="card-1">
                    <div className="card-body-1">
                      <div className="float-end iconBoxAr" style={{background:item.title == "Global Rank" && '#623aa2'
                    || item.title == "Country Rank" && "#f8c246" || item.title == 'Category Rank' && '#53caf0'}}>
                        <i className={item.title == "Global Rank" && 'ri-global-line'
                    || item.title == "Country Rank" && "ri-map-pin-line" || item.title == 'Category Rank' && 'ri-function-line'}></i>
                      </div>
                      <div className="text-muted">
                        <h5>{item.title}</h5>
                      </div>
                      <span className="rankCard">
                        <span>#</span>{item.rank}{" "}
                      </span>
                    </div>
                  </div>
                </div>})}
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-flex pt-0 pb-0">
              <div className="banner-slider">
                <div className="slider-img">
                {sliderdata.map((index)=>{
                return <div key={index}>
                  <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2">
                    {dashData?.performaceGrade?.map((item,index)=>{
                    return<div className="col" key={index} style={{marginBottom:'10px'}}>
                      <div className="card bRadius-15">
                        <div className="card-body p-0">
                          <div className="p-3 pb-0 cardBox_web">
                            <div className="float-end iconBoxAr fs-3 lh-base">
                              <i className={item.title == 'Performance grade' && 'ri-dashboard-3-line' 
                              || item.title == 'Page size' && 'ri-file-text-line' || item.title == 'Load time' && 'ri-history-line' 
                              || item.title == 'Requests' && 'ri-file-edit-line' }></i>
                            </div>
                            <p className="mb-0">{item.title}</p>
                            {item.grade ? <h5 className="mb-0">
                             <span style={{marginRight:'5px'}}>{item.grade}</span>{replace(item.detail,item.title)}
                            </h5> : <h5 className="mb-0">
                              {replace(item.detail,item.title)}
                            </h5>
                            }
                          </div>
                          <div className="" id="chart1">
                          <ReactApexChart
                              options={graph1.options}
                              series={graph1.series}
                              type="area"
                              height={150}
                            />
                          </div>
                        </div>
                      </div>
                    </div>})}
                  </div>
                  <div className="chartboxArea mt-3 mb-3">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <Doughnut data={graph} />
                      </div>
                      <div className="col-lg-6">
                        {dashData?.pieData?.map((item)=>{
                        return <div className="progress-wrapper mb-3" key={item.id}>
                          <div className="d-flex align-items-end">
                            <div className="text-secondary">
                              {item.title}
                            </div>
                            <div className="text-success ms-auto" style={{fontWeight:"500"}}>{item.percent == 'N/A' ? item.percent : getPercent(item.percent)}</div>
                          </div>
                          <div
                            className="progress mt-2"
                            style={{ height: "6px" }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: item.percent, background: 
                                item.title == 'Mobile Friendliness' && '#623aa3' 
                              || item.title == 'Backlinks on the pages' && '#ec428b'
                              ||  item.title == 'Domain Age, URL, and Authority' && '#f49e3f'
                              || item.title == 'Keywords on the pages' && '#0d6efd'}}
                            ></div>
                          </div>
                        </div>})}
                      </div>
                    </div>
                  </div>
                </div>})}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
     
    </>
  );
};

export default Dash_1;
