import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { reqData } from "../../common/api";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const Auction = ({ showWeb }) => {
  const top5brand = process.env.REACT_APP_TOP_5_BRANDS;
  const [data, setData] = useState([]);
  const [loading,setLoading]=useState();
  const dummyArr=[1,2,3,4,5]

  function getData() {
    reqData(top5brand, setData,"brands_5","",setLoading);
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="container">
        {showWeb ? (
          <div className="work-process ">
            <div className="headingArea">
              <h6>POPULAR FACULTY SECTOR</h6>
              <h3>Top notch Faculties</h3>
              <p>
                Cum ultrices cupiditate fames donec potenti semper. Consequuntur
                nesciunt corporis viverra perferendis laborum natus vel
              </p>
            </div>
            <div className="row row-gutter-y-30">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="work-process__item">
                  <Link to="/web-dashboard/64ba4bc30a5cc99888083682" className="work-process__icon">
                    <img src="images/img04a.png" />
                  </Link>
                  <h3 className="work-process__title">rural.nic.in</h3>
                  <p className="work-process__text">
                    <span>166.0M</span> Visits
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="work-process__item">
                  <Link to="/web-dashboard/64afee4b162ad10a860389b2" className="work-process__icon">
                    <img src="images/img04a.png" />
                  </Link>
                  <h3 className="work-process__title">
                    <Link to="#">lalitkala.gov.in</Link>
                  </h3>
                  <p className="work-process__text">
                    <span>145.0M</span> Visits
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="work-process__item">
                  <Link to="#" className="work-process__icon">
                    <img src="images/img04a.png" />
                  </Link>
                  <h3 className="work-process__title">
                    <Link to="#">powermin.gov.in</Link>
                  </h3>
                  <p className="work-process__text">
                    <span>136.0M</span> Visits
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="work-process__item">
                  <Link to="#" className="work-process__icon">
                    <img src="images/img04a.png" />
                  </Link>
                  <h3 className="work-process__title">
                    <Link to="#">dbtindia.gov.in</Link>
                  </h3>
                  <p className="work-process__text">
                    <span>116.0M</span> Visits
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="work-process__item">
                  <Link to="#" className="work-process__icon">
                    <img src="images/img04a.png" />
                  </Link>
                  <h3 className="work-process__title">
                    <Link to="#">rural.nic.in</Link>
                  </h3>
                  <p className="work-process__text">
                    <span>106.0M</span> Visits
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center mt-10">
                <Link to="/top100" className="btn btn-gradient">
                  <span>
                    <i className="ri-loader-4-fill"></i> View All Profiles
                  </span>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="DigitalRankScoreArea">
            <div className="headingArea">
              <h6>POPULAR FACULTY SECTOR</h6>
              <h3>Top notch Faculties</h3>
              <p>
                Cum ultrices cupiditate fames donec potenti semper. Consequuntur
                nesciunt corporis viverra perferendis laborum natus vel
              </p>
            </div>
            <div className="row bg30">
              {loading ? dummyArr.map((item) => {
                return (
                  <div key={item} className="col-lg-3 col-md-6 col-sm-12">
                    <div className="popularRank">
                      <div className="popularRankImgst m-auto">
                      <Skeleton variant="circular" width={132} height={132}  animation="pulse"/>
                      </div>
                      <Skeleton variant="text" height={20} width={80} style={{margin:'5px auto 5px auto'}} />
                      <Skeleton variant="text" height={20} width={80} style={{margin:'5px auto 5px auto'}} />
                    </div>
                </div>
                )
              })
            : data?.top5brands?.map((item,index)=>{
           return <div className="col-lg-3 col-md-6 col-sm-12" key={item.public_url}>
            <Link to={`/dashboard/${item.public_url}`}>
              <div className="popularRank">
                <div className="popularRankImgst m-auto">
                  <img
                    src={data.image_path + item.profile_pic_thumb}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <h3>{item.name}</h3>
                  LEARN MORE <i className="ri-arrow-right-line"></i>
              </div>
            </Link>
          </div>})
          }
            </div>
            <div className="row">
              <div className="col-12 text-center mt-10">
                <Link to="/top100" className="btn btn-gradient">
                  <span>
                    <i className="ri-loader-4-fill"></i> View All Profiles
                  </span>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Auction;
