import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { getApiData } from "../../api/api";
const Web_rank = () => {
    const [value, setValue] = useState("");
    const [show, setShow] = useState(false);
    const [originalData, setOriginalData] = useState([]);
    const [loading, setLoading] = useState(false);
    

    useEffect(() => {
        if (value?.length > 0) {
          setShow(true);
        } else {
          setShow(false);
        }
      }, [value]);
  
    async function getData() {
      try {
        setLoading(true);
        const response = await axios.get(
          `${getApiData}?perpage=10`,
          {
            headers: {
              accept: "*/*",
            },
          }
        );
        setOriginalData(response?.data?.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error?.response?.data?.message);
        setLoading(false);
      }
    }
  
    useEffect(() => {
      getData();
    }, []);
    const filteredData=originalData.filter((item)=>{
      return  item.name.toLowerCase().includes(value.toLowerCase())
    })
  return (
    <>
      <div id="webRank-sale" className="tab-pane fade">
        {/* <!-- Website Rank Section --> */}
        <div className="d-flex align-items-center">
          {/* <!-- banner left --> */}
          <div className="col-md-6">
            <div className="bannerHdText">
              <h1>
                Check Your <span>Website Rank</span>
              </h1>
              <p>
                Explore potential with precision – assess its rank and score for
                strategic growth and success.
              </p>
              <div className="pr-5 mr-2">
                <div
                  className="serachArea d-flex mt-5"
                  style={{
                    position: "relative",
                    borderTopLeftRadius: "50px",
                    borderTopRightRadius: "50px",
                    borderBottomLeftRadius: show ? "0px" : "50px",
                    borderBottomRightRadius: show ? "0px" : "50px",
                  }}
                >
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Check Your Website Ranking"
                  />
                  <button className="btn">Search </button>
                  <div
                    id="inputField1"
                    className=""
                    style={{
                      background: "white",
                      width: "100%",
                      position: "absolute",
                      top: "70px",
                      left: "0px",
                      display: show ? "" : "none",
                      padding: "30px",
                      zIndex: 999,
                      borderRadius: "0px 0px 50px 50px",
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        borderRadius: "100px",
                      }}
                    >
                      {filteredData?.length > 0 ? (
                        filteredData
                          .map((item) => {
                            return (
                                <Link to={`/web-dashboard/${item._id}`} key={item._id}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "flex-col",
                                  background: "white",
                                }}
                              >
                                <div style={{ display: "inline-flex" }}>
                                  <img
                                    src={item.logo_url}
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      marginTop: "5px",
                                      marginRight: "10px",
                                      objectFit:'contain'
                                    }}
                                  />
                                  <p>{item.name}  ({item.url})</p>
                                </div>
                              </div>
                              </Link>
                            );
                          })
                      ) : (
                        <div
                          style={{
                            display: "inline-flex",
                            borderRadius: "0px 0px 50px 50px",
                            textAlign: "center",
                          }}
                        >
                          <p>No webiste found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bannerBtn mt-5">
                <Link to="/brand-register" className="btn">
                  Register Your Brand{" "}
                </Link>{" "}
                <Link to="/login" className="btn btn-bd ms-2">
                  Get Started{" "}
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="bannerWebImg">
              <div className="cardBox-2 cardPO-3 moving_animation">
                <img src="images/img11.png" />
              </div>

              <div className="cardBox-2 cardPO-2 moving_animation ps-4 pe-4">
                <div className="rankingBox text-center">
                  <div className="heartIcon ">
                    <i className="ri-heart-fill"></i>
                  </div>
                  <div className="d-flex-center justify-content-center mt-4">
                    <div className="number counter-item-active">82</div>
                    <div className="count-kilo">
                      <span>/</span>100
                    </div>
                  </div>
                  <div className="rateName mt-2 mb-2">Website Rank</div>
                  <div className="ratingBox upColor">
                    {" "}
                    <i className="ri-arrow-up-fill slide_up_down"></i>15%{" "}
                  </div>
                </div>
              </div>

              <img src="images/img09.png" />

              <div className="cardBox-2 cardPO-1 moving_animation">
                <img src="images/img10.png" />
              </div>

              <div className="cardBox-2 cardPO-4 moving_animation">
                <div className="rankingBox text-center">
                  <div className="d-flex align-items-center gap-2">
                    <img src="images/img12.png" />
                    <div className="d-flex-center">
                      <div className="number counter-item-active fs-25">
                        124.6
                      </div>
                      <div className="count-kilo fs-20">M</div>
                    </div>
                    <div className="ratingBox upColor fs-20">Visits</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Web_rank;
