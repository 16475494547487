import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { reqData } from "../../common/api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Profile = () => {
  const [updateProfile, setUpdateProfile] = useState({
    name: "",
    email: "",
    password: "",
    phone_no: "",
    image: "",
    getImage: "",
  });
  const [updatedData,setUpdatedData]=useState()
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const updateProfileApi = process.env.REACT_APP_UPDATE_PROFILE;
  const getData = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("checkbrand-token")}`,
    };

    try {
      const response = await axios.get(updateProfileApi, { headers });
      setData(response.data);
    } catch (error) {
      console.log("ERROR OF PROFILE IS", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data) {
      setUpdateProfile({
        name: data?.profile?.name,
        email: data?.profile?.email,
        password: "",
        phone_no: data?.profile?.phone_no,
        image: "",
        getImage: data?.img_base_url + data?.profile?.photo,
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "image") {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          setUpdateProfile({ ...updateProfile, getImage: reader.result });
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      setUpdateProfile({ ...updateProfile, [name]: value });
    }
  };
  const headers = { 'Authorization': `Bearer ${localStorage.getItem('checkbrand-token')}` }

  const postData = () => {
    const formData = new FormData();
    formData.append("name", updateProfile.name);
    formData.append("email", updateProfile.email);
    formData.append("password", updateProfile.password);
    formData.append("avatar", updateProfile.getImage);
    formData.append("phone_no", updateProfile.phone_no);
    axios
      .post(
        "https://api.frontend.checkbrand.online/public/api/v1/update_profile",
        formData, {headers})
      .then((res) => {
        setUpdatedData(res.data)
        console.log("Response data of updation is", res.data);
      })
      .catch((error) => {
        console.log("Error is", error);
      });
  };

  useEffect(()=>{
    if(updatedData?.status === 'sucess'){
      toast('Profile updated successfully')
      getData();
    }

  },[updatedData])

  console.log("Data is", updateProfile);
  return (
    <>
      <div className="wrapper">
        <Header />
        <ToastContainer />
        <section className="innerHeader">
          <h2>Contact Us</h2>
          <span>Digital | Social | Online | Influencers</span>
        </section>
        <section className="section-p ContactFormArea">
          <div className="container">
            <div className="section-heading text-center q-p-margin-b-30">
              <h3>UPDATE PROFILE</h3>
            </div>
            <div className="message-form mx-auto text-center q-p-margin-t-40">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="q-p-margin-b-24">
                    <input
                      name="name"
                      type="text"
                      className=" border-0 dt-input section-gray"
                      placeholder="Your Name *"
                      value={updateProfile.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="q-p-margin-b-24">
                    <input
                      name="email"
                      type="text"
                      className=" border-0 dt-input section-gray"
                      placeholder="Your Mail  *"
                      value={updateProfile.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="q-p-margin-b-24">
                    <input
                      name="password"
                      type="text"
                      className=" border-0 dt-input section-gray"
                      placeholder="Enter Password (optional)"
                      value={updateProfile.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="q-p-margin-b-24">
                    <input
                      name="phone_no"
                      type="text"
                      className=" border-0 dt-input section-gray"
                      placeholder="Phone Number *"
                      value={updateProfile.phone_no}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12 ">
                  <div
                    className="q-p-margin-b-24"
                    style={{ display: "inline-flex", gap: "20px" }}
                  >
                    <div class="avatar-info">
                      <a href="/profile">
                        <img
                          src={updateProfile.getImage}
                          alt="user avatar"
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "cover",
                            borderRadius: "30px",
                          }}
                        />
                      </a>
                    </div>
                    {/* <!-- <label for="fileInput">Choose File *</label> --> */}
                    <input
                      name="image"
                      type="file"
                      id="fileInput"
                      className="file-input  border-0 dt-input section-gray"
                      accept="image/*"
                      value={updateProfile.image}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div  className="btn btn-gradient mt-5" onClick={postData}>
                <span>Submit Message</span>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
