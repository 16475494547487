import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const About = ({ dash_data }) => {
   const [mentionDate, setMentionDate] = useState();
   const [metionLabel, setMentionLabel] = useState();
   const [mention_bg, setMention_Bg] = useState(["#26de81"]);
   const [follower_bg, setFollower_Bg] = useState(["#26de81"]);
   const [mentionData, setMentionData] = useState();
   const [followerDate, setFollowerDate] = useState();
   const [followerLabel, setFollowerLabel] = useState();
   const [followerData, setFollowerData] = useState();
 
   const totalMentions = (mention) => {
     const lakhValue = Math.round(mention / 100000);
     return lakhValue;
   };
 
   useEffect(() => {
     if (dash_data) {
       const mention_date = dash_data?.response?.mentions_data.map(
         (item) => item.date
       );
       const mention_label = dash_data?.response?.mentions_data.map(
         (item) => item.count
       );
       const follower_date = dash_data?.response?.followers_data.map(
         (item) => item.date
       );
       const follower_label = dash_data?.response?.followers_data.map(
         (item) => item.count
       );
       setMentionDate(mention_date);
       setMentionLabel(mention_label);
       setMentionData({
         labels: mentionDate,
         datasets: [
           {
             label: "",
             data: metionLabel,
             fill: true,
             borderColor: "rgba(252, 92, 101)",
             backgroundColor: "rgba(252, 92, 101, 0.4)",
             pointBackgroundColor: mention_bg,
             pointBorderColor: mention_bg,
             pointRadius: 5,
             pointHoverRadius: 7,
           },
         ],
       });
 
       setFollowerDate(follower_date);
       setFollowerLabel(follower_label);
       setFollowerData({
         labels: followerDate,
         datasets: [
           {
             label: "",
             data: followerLabel,
             fill: true,
             borderColor: "rgba(38, 222, 129, 0.6)",
             backgroundColor: "rgba(38, 222, 129, 0.3)",
             pointBackgroundColor: follower_bg,
             pointBorderColor: follower_bg,
             pointRadius: 5,
             pointHoverRadius: 7,
           },
         ],
       });
     }
   }, [dash_data]);
 
   useEffect(() => {
     if (metionLabel) {
       const newData = [...mention_bg];
       for (let i = 0; i <= metionLabel.length - 1; i++) {
         if (metionLabel[i + 1] > metionLabel[i]) {
           newData.push("#26de81");
         } else if (metionLabel[i + 1] < metionLabel[i]) {
           newData.push("#fc5c65");
         }
         setMention_Bg(newData);
       }
     }
   }, [metionLabel]);
 
   useEffect(() => {
     if (followerLabel) {
       const newData = [...follower_bg];
       for (let i = 0; i <= followerLabel.length - 1; i++) {
         if (followerLabel[i + 1] > followerLabel[i]) {
           newData.push("#26de81");
         } else if (followerLabel[i + 1] < followerLabel[i]) {
           newData.push("#fc5c65");
         }
 
         setFollower_Bg(newData);
       }
     }
   }, [followerLabel]);
  return (
    <>
      <section className="profileSection pt-5">
        <div className="container">
          <div className="headingAreaBox mb-5 d-flex">
            <h1>
              About <strong>Us</strong>
            </h1>
          </div>
          <div className="row">
            {/* <!-- left Profile--> */}
            <div className="col-lg-7">
              <div className="companyProfile">
                <div className="d-flex align-items-center mb-3">
                  <img
                    src={
                      dash_data?.response?.sentiments?.dp_path +
                      dash_data?.response?.sentiments?.dp
                    }
                  />
                  <div className="ProfileName">
                    {dash_data?.response?.name}
                    <span>{dash_data?.response?.type}</span>
                  </div>
                </div>
                <h1
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    marginTop: "20px",
                  }}
                >
                  Description
                </h1>
                <p style={{ marginTop: "10px" }}>
                  {" "}
                  {dash_data?.response?.description
                    ? dash_data?.response?.description
                    : "N/A"}{" "}
                </p>
                <div className="politBox" style={{ display: "none" }}>
                  <table
                    width="100%"
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "130px" }}>Company</td>
                        <td>Adobe, Inc.</td>
                      </tr>
                      <tr>
                        <td>Year Founded</td>
                        <td>1982</td>
                      </tr>
                      <tr>
                        <td>Employees</td>
                        <td>10001</td>
                      </tr>
                      <tr>
                        <td>HQ</td>
                        <td>United States, California, San Jose</td>
                      </tr>
                      <tr>
                        <td>Annual Revenue</td>
                        <td>$1.0B</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <!-- left Profile--> */}

            {/* <!-- right Profile--> */}
            <div className="col-lg-5">
              <div className="boxSimilarlyrankedSites">
                <h4>Similarly Ranked Brands</h4>
                <ul>
                  {dash_data?.response?.similar_ranking_brands
                    ?.slice(0, 3)
                    .map((item, index) => {
                      return (
                        <Link key={index} to={`/dashboard/${item.public_url}`}>
                          <li className="">
                            <span>#{index + 1}</span>
                            <span className="w40">
                              <img
                                src={
                                  `https://checkbrand.online/frontend/images/` +
                                  item.pic
                                }
                                style={{ padding: "0px !important" }}
                              />
                            </span>
                            <span
                              className="rankedNameArea"
                              style={{ marginLeft: "20px" }}
                            >
                              {item.name}
                            </span>
                          </li>
                        </Link>
                      );
                    })}
                  {/* <li>
  <span>574,953</span>
  <span className="w40"><img src="/images/img13.png" /></span>
   <span className="rankedNameArea">forensicsciencesoc...</span>
 </li>   */}
                </ul>
              </div>
            </div>
            {/* <!-- right Profile--> */}
          </div>
        </div>
      </section>
      <div className="SearchPostSection" id="Recommendation">
        <section className="InsightsSectionArea">
          <div className="container">
            <h1 className="d-flex align-items-center">
              Current&nbsp;
              <strong>Recommendations</strong>
              <Link to="/detailed-dashboard" className="ms-auto btnAnalytics">
                Click on Detailed Analytics
              </Link>
            </h1>
            <div className="row  pt-3 pb-0">
              <div className="col-3">
                <div className="boxAreaChart mb-4 dashboardAddedHeight">
                  <h6>MENTIONS</h6>
                  <div className="chartContainer">
                    <section className="valuePanel">
                      <h2 className="m-0">
                        <span className="number counter-item-active">
                          {totalMentions(dash_data?.response?.mentions)}
                        </span>{" "}
                        Lac
                      </h2>
                    </section>
                  </div>
                </div>
                <div className="boxAreaChart mb-4 dashboardAddedHeight">
                  <h6>ENGAGEMENT</h6>
                  <div className="chartContainer">
                    <section className="valuePanel">
                      <h2 className="m-0">
                        <span className="number counter-item-active">
                          {Math.floor(
                            dash_data?.response?.sentiments.engagements / 100000
                          )}
                        </span>{" "}
                        Lac
                      </h2>
                    </section>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="boxAreaChart">
                  <h6>MENTIONS</h6>
                  <div style={{ height: "300px", width: "100% !important" }}>
                    {mentionData && (
                      <Line
                        data={{
                          labels: mentionDate,
                          datasets: [
                            {
                              label: "",
                              data: metionLabel,
                              fill: true,
                              borderColor: "rgba(252, 92, 101, 0.6)",
                              backgroundColor: "rgba(252, 92, 101, 0.4)",
                              pointBackgroundColor: mention_bg,
                              pointBorderColor: mention_bg,
                              pointRadius: 6,
                              pointHoverRadius: 8,
                            },
                          ],
                        }}
                        options={{
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              display: false,
                            },
                            title: {
                              display: false,
                              text: "Bar Chart",
                            },
                          },
                          scales: {
                            y: {
                              min:Math.min(metionLabel),
                              // beginAtZero: true,
                              ticks: {
                                stepSize: (Math.max(metionLabel)-Math.min(metionLabel)/2),
                              },
                            },
                          },
                        }}
                        width={400}
                        height={200}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row  pt-3">
              <div className="col-3">
                <div className="boxAreaChart mb-4 dashboardAddedHeight">
                  <h6> SENTIMENT</h6>
                  <div className="chartContainer-14">
                    <section id="Chatrcontent1n">
                      <div className="d-flex sentimentBox">
                        <div className="col p-0">
                          <h2 className="font-29 Gcolor">
                            <span className="number counter-item-active">
                              {dash_data?.response?.sentiments.positive}
                            </span>
                            %
                          </h2>
                          <div className="Gcolor">
                            <i className="ri-user-smile-line"></i> Positive
                          </div>
                        </div>
                        <div className="col p-0">
                          <h2 className="font-29 Rcolor">
                            <span className="number counter-item-active">
                              {dash_data?.response?.sentiments.negative}
                            </span>
                            %
                          </h2>
                          <div className="Rcolor">
                            <i className="ri-emotion-sad-fill"></i> Negative
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div className="boxAreaChart mb-4 dashboardAddedHeight">
                  <h6>POTENTIAL REACH</h6>
                  <div className="chartContainer">
                    <section className="valuePanel">
                      <h2 className="m-0">
                        <span className="number counter-item-active">
                          {Math.floor(
                            dash_data?.response?.potential_reach / 10000000
                          )}
                        </span>{" "}
                        Lac
                      </h2>
                    </section>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="boxAreaChart">
                  <h6>FOLLOWERS</h6>
                  <div style={{ height: "300px", width: "100% !important" }}>
                    {followerData && (
                      <Line
                        data={{
                          labels: followerDate,
                          datasets: [
                            {
                              label: "",
                              data: followerLabel,
                              fill: true,
                              borderColor: "rgba(38, 222, 129, 0.6)",
                              backgroundColor: "rgba(38, 222, 129, 0.3)",
                              pointBackgroundColor: follower_bg,
                              pointBorderColor: follower_bg,
                              pointRadius: 6,
                              pointHoverRadius: 8,
                            },
                          ],
                        }}
                        options={{
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              display: false,
                            },
                            title: {
                              display: false,
                              text: "Bar Chart",
                            },
                          },
                          scales: {
                            y: {
                              min:Math.min(followerLabel),
                              ticks: {
                                stepSize: (Math.max(followerLabel)-Math.min(followerLabel)/2),
                              },
                            },
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default About;
