import React, { useEffect, useState } from "react";
import { categories } from "../../common/mockData";

const Section5 = () => {
   const [categoryData,setCategoryData]=useState('TWITTER');
   const [data,setData]=useState()
   const filterData=()=>{
    const newData =  categories.filter((item)=>{
      return item.title == categoryData
      })
      setData(newData)
   }
   useEffect(()=>{
      filterData();
   },[])
  return (
    <>
      <section className="pb-10 pt-20" id="Trends">
        <div className="container">
          {/* <!-- End .section-title --> */}
          <div className="author-profile-wrapper">
            <div className="row">
              <div className="col">
                <ul className="nav custom-tabs author-tabs justify-content-lg-center p-0 d-flex">
                  {categories?.map((item,index)=>{
                   return  <li key={index} onClick={()=>setCategoryData(item.title)}>
                     <a
                       className={` ${index === 0 ? "active" : ""} btn btn-small btn-outline-1`}
                       data-bs-toggle="tab"
                       href="#on-sale"
                     >
                       {item.title}
                     </a>
                   </li>
                  })}
                </ul>
              </div>
            </div>
            <div className="tab-content author-tabs-content">
              <div id="on-sale" className="tab-pane fade show active">
                <div className="newsTablePanel">
                  <table
                    width="100%"
                    className="newsTableBox"
                    id="mytable"
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Image</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                     {data?.length>0 &&data[0].data.map((item,index)=>{
                        return <tr key={index}>
                         <td>
                           <span>{item.id}</span>
                         </td>
                         <td>
                           <img src={item.image} alt="" />
                         </td>
                         <td>
                           {item.description}
                         </td>
                       </tr>
                     })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section5;
