import React from 'react';
import FaqTab from '../../../common/FaqTab';
import Common_Tab from '../../../common/Common_Tab';

const Interactivity_Engagement = ({activeTab,dashData,loading}) => {
  const questions = [
    {
      id: 1,
      title:
        "Are there options for users to provide feedback/ submit inquiries/ participate in surveys, polls, or online consultations on the website?",
      description: "",
      ariaControls: "first",
    },
    {
      id: 2,
      title:
        "Does the website offer online transactions?",
      description: "",
      ariaControls: "second",
    },
    {
      id: 3,
      title:
        "Are there opportunities for users to participate in surveys, polls, or online consultations on the website? (0) Nothing available (1) One available (2) More than one available",
      description: "",
      ariaControls: "third",
    },
    {
      id: 4,
      title:
        "Does the website include social media integration, allowing users to share or interact with content through social platforms?",
      description: "",
      ariaControls: "fourth",
    },
    {
      id: 5,
      title:
        "Does the website provide real-time assistance through chatbots or virtual assistants?",
      description: "",
      ariaControls: "fifth",
    },
    {
      id: 6,
      title:
        "Is it easy to sign up for email updates, newsletters, or alerts about new information",
      description: "",
      ariaControls: "sixth",
    },
    {
      id: 7,
      title:
        "Do the forms include instructions and clear labels with autofill, and validation features for error prevention",
      description: "",
      ariaControls: "seventh",
    },
    {
      id: 8,
      title:
        "If an input error is automatically detected, the item that is in error is identified and the error is described to the user in text.",
      description: "",
      ariaControls: "eight",
    },
    // {
    //   id: 9,
    //   title:
    //     "Website has a Contact Us page",
    //   description: "",
    //   ariaControls: "ninth",
    // },
    {
      id: 9,
      title:
        "If an input error is automatically detected and suggestions for correction are known, then the suggestions are provided to the user, unless it would jeopardize the security or purpose of the content",
      description: "",
      ariaControls: "ninth",
    },
    {
      id: 10,
      title:
        "How many active users are registered and authenticated on the website (1) below expectation (2) as per expectation (3) above expectation",
      description: "",
      ariaControls: "tenth",
    },
  ];
  return <>
  <FaqTab activeTab={activeTab} dashData={dashData} questions={questions} />
  <Common_Tab dashData={dashData} loading={loading} />
  </>
}

export default Interactivity_Engagement
