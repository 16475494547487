import React, { useState } from "react";
import ReviewModal from "./ReviewModal";
import Rating from "@mui/material/Rating";


const Section2 = ({setShow,reviewData}) => {
  console.log("Review Data of review page is",reviewData?.avg_rating)
  // const [show,]=useState(false)
  return (
    <>
      <section
        className="InsightsSectionArea reviewsTitleBox bgArea-5"
        id="Digital-Ranking"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="d-flex align-items-center">
                <img src="images/img-1.jpg" />
                <div className="ProfileName">
                  <h6>Virat Kohli</h6>
                  <h3 className="mb-2 mt-1">
                    User Reviews
                    </h3>
                  <p onClick={() => setShow(true)} style={{cursor:'pointer'}}>
                    Review this title
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <h3 className="rvHd">
                {reviewData?.avg_rating >0 &&
              <Rating sx={{
                      "& .MuiRating-iconFilled": {
                        color: "#faaf00"
                      },
                      "& .MuiRating-iconHover": {
                        color: "#faaf00"
                      }
                    }}
                      name="read-only"
                      value={reviewData?.avg_rating}
                      readOnly
                      size="medium"
                    />}
                {/* {Array(reviewData?.avg_rating).fill("1").map((item,index)=>{
                return<i className="ri-star-line" key={index} style={{marginRight:'2px',color:'#fc902f'}}></i> })} */}
                <span style={{marginLeft:'10px',marginTop:'-10px !important'}}>{reviewData?.total_rating} Reviews</span>
              </h3>

              <div className="reviewsBoxfliter">
                <div className="d-flex align-items-end">
                  {/* <!-- 01 --> */}
                  <div className="col">
                    <div className="form-group">
                      <label>Rating</label>
                      <select className="inputPanel">
                        <option value="0">Show All</option>
                        <option value="1">1 Star</option>
                        <option value="2">2 Stars</option>
                        <option value="3">3 Stars</option>
                        <option value="4">4 Stars</option>
                        <option value="5">5 Stars</option>
                      </select>
                    </div>
                  </div>
                  {/* <!-- 01 --> */}

                  {/* <!-- 01 --> */}
                  <div className="col pl-3">
                    <div className="form-group">
                      <label>Sort by</label>
                      <select className="inputPanel">
                        <option value="curated">Featured</option>
                        <option value="submissionDate" defaultValue="">
                          Review Date
                        </option>
                        <option value="totalVotes">Total Votes</option>
                        <option value="reviewVolume">Prolific Reviewer</option>
                        <option value="userRating">Review Rating</option>
                      </select>
                    </div>
                  </div>
                  {/* <!-- 01 --> */}

                  {/* <!-- 01 --> */}
                  <div className="pl-3">
                    <button type="button" className="filterBtn">
                      <i className="ri-filter-line"></i>{" "}
                    </button>
                  </div>
                  {/* <!-- 01 --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {show ?  : ""} */}
    </>
  );
};

export default Section2;
