import React from 'react';
import  './Dash_Main.module.css';

const Menu = () => {
  return <>
  <div className="main-sidemenu">
               <div className="container-fluid">
                  <ul className="menuPanel">
                     <li className="active"><a href="#"><i className="ri-dashboard-line"></i> Dashboard</a></li>
                     <li className=""><a href="#"><i className="ri-emotion-happy-line"></i> Sentiments</a></li>
                     <li className=""><a href="#"><i className="ri-key-2-line"></i> Key Metrics</a></li>
                     <li className=""><a href="#"><i className="ri-information-line"></i> General Information</a></li>
                     <li className=""><a href="#"><i className="ri-global-line"></i> Traffic/Domain History</a></li>
                     <li className=""><a href="#"><i className="ri-at-line"></i> Mentions Detail</a></li>
                     <li className=""><a href="#"><i className="ri-attachment-line"></i> Most Popular Mentions</a></li>
                  </ul>
               </div>
            </div>
  </>
}

export default Menu
