import React from 'react';
import Dash_1 from './Dash_1';
import Dash_2 from './Dash_2';
import Dash_4 from './Dash_4';
import Dash_5 from './Dash_5';
import Dash6 from './Dash6';

const Overview = ({dashData,loading,setActiveTab}) => {
  return <>
  <Dash_1 dashData={dashData}  />
      <Dash_2 dashData={dashData} setActiveTab={setActiveTab} />
      {/* <Dash_4 dashData={dashData} />
      <Dash_5  dashData={dashData} />
      <Dash6 dashData={dashData} loading={loading} /> */}
  
  </>
}

export default Overview
