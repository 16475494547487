import React from 'react'

const General_Graph = (active) => {
  return <>
  <div
                className={`tab-pane active`}
                id="general-home"
                role="tabpanel"
                aria-labelledby="general-home-tab"
              >
                <div className="charts-one-wrap">
                  <div className="chart-title">
                    <div className="statistics-icon chart-icon">
                      <i className="fa fa-bar-chart"></i>
                    </div>
                    <div className="chart-title-text">
                      Posts by Social Network
                    </div>
                  </div>
                  <div className="networks-table-content">
                    <div className="multiple-chart-item-wrap">
                      <div className="multiple-chart-item">
                        <div className="web-icon-big network-icon-chart"></div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-value-wrap">
                          <div
                            id="webgeneralchartvalue"
                            className="chart-value web-color"
                            style={{ width: "10%" }}
                          ></div>
                        </div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-note">
                          <div id="webgeneralchartnote">10 / 2035</div>
                        </div>
                      </div>
                    </div>
                    <div className="multiple-chart-item-wrap">
                      <div className="multiple-chart-item">
                        <div className="facebook-icon-big network-icon-chart"></div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-value-wrap">
                          <div
                            id="webgeneralchartvalue"
                            className="chart-value facebook-color"
                            style={{ width: "20%" }}
                          ></div>
                        </div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-note">
                          <div id="webgeneralchartnote">20 / 2035</div>
                        </div>
                      </div>
                    </div>
                    <div className="multiple-chart-item-wrap">
                      <div className="multiple-chart-item">
                        <div className="twitter-icon-big network-icon-chart"></div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-value-wrap">
                          <div
                            id="twittergeneralchartvalue"
                            className="chart-value twitter-color"
                            style={{ width: "76%" }}
                          ></div>
                        </div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-note">
                          <div id="twittergeneralchartnote">1549 / 2035</div>
                        </div>
                      </div>
                    </div>
                    <div className="multiple-chart-item-wrap">
                      <div className="multiple-chart-item">
                        <div className="youtube-icon-big network-icon-chart"></div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-value-wrap">
                          <div
                            id="youtubegeneralchartvalue"
                            className="chart-value youtube-color"
                            style={{ width: "53%" }}
                          ></div>
                        </div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-note">
                          <div id="youtubegeneralchartnote">109 / 2035</div>
                        </div>
                      </div>
                    </div>
                    <div className="multiple-chart-item-wrap">
                      <div className="multiple-chart-item">
                        <div className="instagram-icon-big network-icon-chart"></div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-value-wrap">
                          <div
                            id="instagramgeneralchartvalue"
                            className="chart-value instagram-color"
                            style={{ width: "16%" }}
                          ></div>
                        </div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-note">
                          <div id="instagramgeneralchartnote">326 / 2035</div>
                        </div>
                      </div>
                    </div>
                    <div className="multiple-chart-item-wrap">
                      <div className="multiple-chart-item">
                        <div className="tumblr-icon-big network-icon-chart"></div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-value-wrap">
                          <div
                            id="tumblrgeneralchartvalue"
                            className="chart-value tumblr-color"
                            style={{ width: "4%" }}
                          ></div>
                        </div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-note">
                          <div id="tumblrgeneralchartnote">1 / 2035</div>
                        </div>
                      </div>
                    </div>
                    <div className="multiple-chart-item-wrap">
                      <div className="multiple-chart-item">
                        <div className="reddit-icon-big network-icon-chart"></div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-value-wrap">
                          <div
                            id="redditgeneralchartvalue"
                            className="chart-value reddit-color"
                            style={{ width: "6%" }}
                          ></div>
                        </div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-note">
                          <div id="redditgeneralchartnote">6 / 2035</div>
                        </div>
                      </div>
                    </div>
                    <div className="multiple-chart-item-wrap">
                      <div className="multiple-chart-item">
                        <div className="dailymotion-icon-big network-icon-chart"></div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-value-wrap">
                          <div
                            id="dailymotiongeneralchartvalue"
                            className="chart-value dailymotion-color"
                            style={{ width: "19%" }}
                          ></div>
                        </div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-note">
                          <div id="dailymotiongeneralchartnote">38 / 2035</div>
                        </div>
                      </div>
                    </div>
                    <div className="multiple-chart-item-wrap">
                      <div className="multiple-chart-item">
                        <div className="vimeo-icon-big network-icon-chart"></div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-value-wrap">
                          <div
                            id="vimeogeneralchartvalue"
                            className="chart-value vimeo-color"
                            style={{ width: "20%" }}
                          ></div>
                        </div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-note">
                          <div id="vimeogeneralchartnote">0 / 2035</div>
                        </div>
                      </div>
                    </div>
                    <div className="multiple-chart-item-wrap">
                      <div className="multiple-chart-item">
                        <div className="vkontakte-icon-big network-icon-chart"></div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-value-wrap">
                          <div
                            id="vkontaktegeneralchartvalue"
                            className="chart-value vkontakte-color"
                            style={{ width: "20%" }}
                          ></div>
                        </div>
                        <div className="columns-delimiter"></div>
                        <div className="chart-note">
                          <div id="vkontaktegeneralchartnote">6 / 2035</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-calendar line-height16 font-weight500"></i>
                        </div>
                        <div className="chart-title-text">
                          Posts by Week Day
                        </div>
                      </div>
                      <div id="byweekstats">
                        <div className="weekchart-item">
                          <div className="week-icon-chart">
                            <div>Mon</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="weekchart-value-wrap">
                            <div
                              id="monweekchartvalue"
                              className="weekchart-value"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="monweekchartnote">0%</div>
                          </div>
                        </div>
                        <div className="weekchart-item">
                          <div className="week-icon-chart">
                            <div>Tue</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="weekchart-value-wrap">
                            <div
                              id="tueweekchartvalue"
                              className="weekchart-value"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="tueweekchartnote">0%</div>
                          </div>
                        </div>
                        <div className="weekchart-item">
                          <div className="week-icon-chart">
                            <div>Wed</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="weekchart-value-wrap">
                            <div
                              id="wedweekchartvalue"
                              className="weekchart-value"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="wedweekchartnote">0%</div>
                          </div>
                        </div>
                        <div className="weekchart-item">
                          <div className="week-icon-chart">
                            <div>Thu</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="weekchart-value-wrap">
                            <div
                              id="thuweekchartvalue"
                              className="weekchart-value"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="thuweekchartnote">0%</div>
                          </div>
                        </div>
                        <div className="weekchart-item">
                          <div className="week-icon-chart">
                            <div>Fri</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="weekchart-value-wrap">
                            <div
                              id="friweekchartvalue"
                              className="weekchart-value"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="friweekchartnote">0%</div>
                          </div>
                        </div>
                        <div className="weekchart-item">
                          <div className="week-icon-chart">
                            <div>Sat</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="weekchart-value-wrap">
                            <div
                              id="satweekchartvalue"
                              className="weekchart-value"
                              style={{ width: "100%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="satweekchartnote">100%</div>
                          </div>
                        </div>
                        <div className="weekchart-item">
                          <div className="week-icon-chart">
                            <div>Sun</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="weekchart-value-wrap">
                            <div
                              id="sunweekchartvalue"
                              className="weekchart-value"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="sunweekchartnote">0%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-clock font-weight500"></i>
                        </div>
                        <div className="chart-title-text">Posts by Hour</div>
                      </div>
                      <div id="byhourstats">
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>00:00-02:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="1hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="1hourchartnote">0%</div>
                          </div>
                        </div>
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>02:00-04:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="2hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="2hourchartnote">0%</div>
                          </div>
                        </div>
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>04:00-06:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="3hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="3hourchartnote">0%</div>
                          </div>
                        </div>
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>06:00-08:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="4hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "1%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="4hourchartnote">1%</div>
                          </div>
                        </div>
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>08:00-10:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="5hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "1%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="5hourchartnote">1%</div>
                          </div>
                        </div>
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>10:00-12:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="6hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="6hourchartnote">0%</div>
                          </div>
                        </div>
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>12:00-14:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="7hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "3%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="7hourchartnote">3%</div>
                          </div>
                        </div>
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>14:00-16:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="8hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "24%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="8hourchartnote">24%</div>
                          </div>
                        </div>
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>16:00-18:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="9hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "31%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="9hourchartnote">31%</div>
                          </div>
                        </div>
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>18:00-20:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="10hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "25%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="10hourchartnote">25%</div>
                          </div>
                        </div>
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>20:00-22:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="11hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "7%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="11hourchartnote">7%</div>
                          </div>
                        </div>
                        <div className="hourchart-item">
                          <div className="hour-icon-chart">
                            <div>22:00-24:00</div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="hourchart-value-wrap">
                            <div
                              id="12hourchartvalue"
                              className="hourchart-value"
                              style={{ width: "2%" }}
                            ></div>
                          </div>
                          <div className="columns-delimiter"></div>
                          <div className="chart-note-small">
                            <div id="12hourchartnote">2%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="charts-one-wrap">
                  <div className="chart-title">
                    <div className="hashtag-icon chart-icon"></div>
                    <div className="chart-title-text">Hashtags</div>
                  </div>
                  <div className="row">
                    <div className="col-md-7" id="hashtags">
                      <table
                        width="100%"
                        className="newsTableBox"
                        id="mytable"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <thead>
                          <tr>
                            <th scope="col" className="post-number">
                              #
                            </th>
                            <th scope="col">Hashtag</th>
                            <th scope="col" className="post-medium-col">
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="odd">
                            <td>1</td>
                            <td>hamaraappnamoapp</td>
                            <td>176</td>
                          </tr>
                          <tr className="odd">
                            <td>2</td>
                            <td>mannkibaat</td>
                            <td>60</td>
                          </tr>
                          <tr className="odd">
                            <td>3</td>
                            <td>छा_गए_मोदी_योगी</td>
                            <td>46</td>
                          </tr>
                          <tr className="odd">
                            <td>4</td>
                            <td>isupportlaalsinghchaddha</td>
                            <td>29</td>
                          </tr>
                          <tr className="odd">
                            <td>5</td>
                            <td>teestasetalvad</td>
                            <td>15</td>
                          </tr>
                          <tr className="odd">
                            <td>6</td>
                            <td>india</td>
                            <td>116</td>
                          </tr>
                          <tr className="odd">
                            <td>7</td>
                            <td>pmmodi</td>
                            <td>12</td>
                          </tr>
                          <tr className="odd">
                            <td>8</td>
                            <td>hindistan</td>
                            <td>11</td>
                          </tr>
                          <tr className="odd">
                            <td>9</td>
                            <td>narendramodi</td>
                            <td>332</td>
                          </tr>
                          <tr className="odd">
                            <td>10</td>
                            <td>aitcssmc</td>
                            <td>9</td>
                          </tr>
                          <tr className="odd">
                            <td>11</td>
                            <td>argusnews</td>
                            <td>1</td>
                          </tr>
                          <tr className="odd">
                            <td>12</td>
                            <td>covidupdate</td>
                            <td>1</td>
                          </tr>
                          <tr className="odd">
                            <td>13</td>
                            <td>educationnext</td>
                            <td>1</td>
                          </tr>
                          <tr className="odd">
                            <td>14</td>
                            <td>englishheadlines</td>
                            <td>1</td>
                          </tr>
                          <tr className="odd">
                            <td>15</td>
                            <td>nep</td>
                            <td>1</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-5" id="hashtagsmap">
                      <a
                        target="_blank"
                        href="https://twitter.com/search/%23HamaraAppNamoApp"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #hamaraappnamoapp
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://twitter.com/search/%23MannKiBaat"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #mannkibaat
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://twitter.com/search/%23छा_गए_मोदी_योगी"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #छा_गए_मोदी_योगी
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://twitter.com/search/%23ISupportLaalSinghChaddha"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #isupportlaalsinghchaddha
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://twitter.com/search/%23TeestaSetalvad"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #teestasetalvad
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/explore/tags/india"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #india
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://twitter.com/search/%23PMModi"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #pmmodi
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://twitter.com/search/%23Hindistan"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #hindistan
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/explore/tags/narendramodi"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #narendramodi
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://twitter.com/search/%23AITCSSMC"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #aitcssmc
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.tumblr.com/search/ArgusNews"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #argusnews
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.tumblr.com/search/covidupdate"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #covidupdate
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.tumblr.com/search/educationnext"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #educationnext
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.tumblr.com/search/englishheadlines"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #englishheadlines
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.tumblr.com/search/nep"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #nep
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/explore/tags/bjp"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #bjp
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/explore/tags/instagram"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #instagram
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/explore/tags/modi"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #modi
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/explore/tags/amitshah"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #amitshah
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/explore/tags/yogiadityanath"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #yogiadityanath
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/explore/tags/hindu"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #hindu
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/explore/tags/namo"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #namo
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/explore/tags/instagood"
                      >
                        <span className="hashtagmap-item hashtagmap-weight5">
                          #instagood
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
  </>
}

export default General_Graph
