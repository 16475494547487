import axios from "axios";
export const reqData= async(api,setData,type,payload,setLoading,navigate,setError)=>{
    await setLoading(true)
    if(type === "login"){
       await axios.post(`${api}` , payload ).then((res)=>{
            console.log("Response is",res.data);
            setData(res.data);
            localStorage.setItem('checkbrand-token',res.data.access_token)
            navigate("/")
        }).catch((error)=>{
            console.log("Error is",error)
        })
    }
    else if (type === 'subscribe'){
        await axios.post(`${api}` , payload ).then((res)=>{
            console.log("Response is",res.data);
            setData(res.data);
        }).catch((error)=>{
            console.log("Error is",error);
            setError(error)
        })
    }
    else if (type === 'contact'){
        await axios.post(`${api}` , payload ).then((res)=>{
            console.log("Response is",res.data);
            setData(res.data);
        }).catch((error)=>{
            console.log("Error is",error);
            setError(error)
        })
    }
    else{
       await axios.get(`${api}`).then((res)=>{
            setData(res.data)
        }).catch((error)=>{
            console.log("Error is",error)
        })
    }
    await setLoading(false)
    
}