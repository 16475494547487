import React from 'react'

const Section1 = () => {
  return <>
  <section className="searchSectionBox reviewsBanner">
            <div className="container text-center">
             <h2>Virat kohli
             <span>Politicians</span>
             </h2>
            </div>
         </section>
  </>
}

export default Section1
