import React, { useState } from "react";

const Performance = ({ item ,index}) => {
  const [show, setShow] = useState(false);
  return (
    <React.Fragment key={index}>
      <tr 
        className="accordion-header collapsed" onClick={() => setShow(!show)}
      >
        <td>
          <span>F</span> 0{" "}
        </td>
        <td>{item.title}</td>
        <td style={{ width: "40px" }} ></td>
      </tr>
      {show ? (
        <tr
        >
          <td colSpan="3">
            <p>
              {item.description}
            </p>
          </td>
        </tr>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Performance;
