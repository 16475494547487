import React, { useEffect, useState } from "react";
import { reqData } from "../../common/api";
import Footer from '../../common/Footer';
import Live_Child from "./Live_Child";
import Header from "../../common/Header";
import Loader from "../../common/Loader";

const Main = () => {
    const [data,setData]=useState();
    const [clonedData,setClonedData]=useState([])
    const [loading,setLoading]=useState();
    const[search,setSearch]=useState('');
    const getLive=process.env.REACT_APP_LIVE
    const getData=()=>{
        reqData(getLive,setData,"","",setLoading)
    }
    useEffect(()=>{
        getData();
    },[])

    useEffect(()=>{
      if(data){
        setClonedData(data?.results)
      }
    },[data])

    useEffect(()=>{
      if(search?.length === 0){
        setClonedData(data?.results)
      }
      else{
       const newData= data?.results?.filter((item)=>item.name.toLowerCase().includes(search.toLowerCase()))
        setClonedData(newData)
      }
    },[search])
  return (
    <>
    {loading ? <Loader /> : 
      <div className="wrapper">
       <Header />
        <section className="searchSectionBox">
          <div className="container">
            <div className="bgWhiteBox p-3">
              <div className="d-flex align-items-center gap-2">
                <div className="searchbar col">
                  <div className="compareInputPanel">
                    <form
                      method="GET"
                      action="#"
                      accept-charset="UTF-8"
                      id="search_form"
                    >
                      <input
                        className="search_input mb-0 ui-autocomplete-input"
                        type="text"
                        name="qry"
                        placeholder=""
                        autocomplete="off"
                        value={search}
                        onChange={(e)=>setSearch(e.target.value)}
                      />
                      <p
                        className="searchloading  text-center"
                        style={{display:'none'}}
                      >
                        loading...
                      </p>
                      <a href="#" className="search_icon">
                        <i className="ri-search-line"></i>
                      </a>
                    </form>
                  </div>
                </div>

                <div className="dropdown btnDs-1">
                  <button
                    className="btn btn-border-mode dropdown-toggle rounded-pill btn-outline-1 btn-small"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Sort by Latest
                  </button>
                  <ul className="dropdown-menu" >
                    <li>
                      <a className="dropdown-item" href="#">
                        Recently added
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Price: Low to High
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Price: High to Low
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Auction ending soon
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-10 live-auction pt-8">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {clonedData?.map((item,index)=>{
                return <>
                <Live_Child item={item} index={index} />
                </>
              })}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>}
    </>
  );
};

export default Main;
