import React, { useState } from 'react'
import Banner from './Banner'
import Ranking from './Ranking'
import NewsArea from './NewsArea'
import Client from './Client'
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import Auction from './Auction'
import Preloader from '../../common/Preloader'

const Home = ({setShowWeb,showWeb}) => {
  const [showPreLoader,setShowPreloader]=useState(false);
  React.useEffect(()=>{
    const timeout=setTimeout(()=>{
      setShowPreloader(true)
    },1500)
    return () => clearTimeout(timeout);
  },[])
  return <>
  <Preloader showPreLoader={showPreLoader} />
  <div className='wrapper'>
  <Header />
  <Banner setShowWeb={setShowWeb} />
  <Auction showWeb={showWeb} />
  <Ranking />
  <NewsArea />
  <Client />
  <Footer />
  </div>
  </>
}

export default Home
