import React from 'react';
import { Link } from 'react-router-dom';

const Register_Modal = () => {
  return <>
    <div className="modal fade popup" id="popup_bid_success" tabIndex="-1" role="dialog" aria-hidden="true">
         <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content bid-success-content">
               <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
               <i className="ri-close-fill"></i>
               </button>
               <div className="modal-body pa-8">
                  <h3 className="text-center mb-3">Your Bidding
                     Successfuly Added!
                  </h3>
                  <p className="text-center mb-5">your bid <strong>(12ETH) </strong> has been listing
                     to our database
                  </p>
                  <Link to="/" className="btn btn-gradient btn-small w-100 justify-content-center">
                  <span>Watch
                  Other Listings</span></Link>
               </div>
            </div>
         </div>
      </div>
      {/* <!-- End Bit success popup -->
      <!-- Place a bit Modal --> */}
      <div className="modal fade RegisterModal" id="RegisterBrand" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
               <div className="modal-header flex-column px-8">
                  <h3 className="modal-title" id="placeBitLaebl">Register Your Brand</h3>
                  <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                  <i className="ri-close-fill"></i>
                  </button>
               </div>
               {/* <!-- End modal-header --> */}
               <div className="modal-body px-8 py-5">
                  <p className="text-center">To measure your Digital-Ranking | Digital Score | Share of Voice | Competition Analysis | Digital media monitoring</p>
                  <form action="#">
                     <div className="row mt-4">
                        {/* <!-- 01 --> */}
                        <div className="col-md-6 mb-4">
                           <div className="form-group">
                              <input type="text" id="bit" placeholder="Brand Name" />
                           </div>
                        </div>
                        {/* <!-- ./01 -->
                        <!-- 01 --> */}
                        <div className="col-md-6 mb-4">
                           <div className="form-group">
                              <input type="text" id="bit" placeholder="Email Id" />
                           </div>
                        </div>
                        {/* <!-- ./01 -->
                        <!-- 01 --> */}
                        <div className="col-md-6 mb-4">
                           <div className="form-group">
                              <input type="text" id="bit" placeholder="Phone No." />
                           </div>
                        </div>
                        {/* <!-- ./01 -->
                        <!-- 01 --> */}
                        <div className="col-md-6 mb-4">
                           <div className="field-box">
                              <select id="subject" className="w-100 selectClass">
                                 <option defaultValue="">Select User Type</option>
                                 <option value="1">Politicians</option>
                                 <option value="2">Actors</option>
                                 <option value="4">CEO</option>
                                 <option value="5">Company</option>
                                 <option value="6">Influencers</option>
                                 <option value="7">Cricketers</option>
                                 <option value="8">Ministries</option>
                                 <option value="0">Others</option>
                              </select>
                           </div>
                        </div>
                        {/* <!-- ./01 -->
                        <!-- 01 --> */}
                        <div className="col-lg-6 mb-4">
                           <div className="field-box">
                              <label className="checkbox">
                              <input className="form-check-input me-1" type="checkbox" value="" required=""/> I accept terms and conditions </label>
                           </div>
                        </div>
                        {/* <!-- ./01 -->
                        <!-- 01 --> */}
                        <div className="col-lg-6 mb-4">
                           <div className="field-box">
                              <label className="checkbox">
                              <input className="form-check-input me-1" type="checkbox" value="" required="" /> I want to receive news via email </label>
                           </div>
                        </div>
                        {/* <!-- ./01 --> */}
                     </div>
                  </form>
               </div>
               {/* <!-- End modal-body --> */}
               <div className="modal-footer px-8">
                  <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#popup_bid_success" aria-label="Close">
                  <span>REGISTER BRAND</span>
                  </button>
               </div>
               {/* <!-- End .modal-footer --> */}
            </div>
            {/* <!-- End .modal-content --> */}
         </div>
      </div>
  </>
}

export default Register_Modal
