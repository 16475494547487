import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";

export default function Authenticate_Dialogue({ open, setOpen, title }) {
  const navigate = useNavigate();
  const handleClose = () => {
    localStorage.removeItem("checkbrand-token");
    setOpen(false);
  };

  return (
    <div style={{position:'relative',zIndex:99999999}}>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: "16px" }}>
          {title}
        </DialogTitle>
        <DialogActions>
            <>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "red",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "red",
                  },
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => setOpen(false)}
                sx={{
                  backgroundColor: "green",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "green",
                  },
                }}
              >
                No
              </Button>
            </>
        </DialogActions>
      </Dialog>
    </div>
  );
}
