import React from "react";
import Viral_section from "./viral_section/Viral_section";

const Section6 = () => {
  const viral_section = [
    { id: 1, title: "Videos", slug: "viral_videos" },
    { id: 2, title: "NEWS", slug: "viral_news" },
    { id: 3, title: "BLOGS", slug: "viral_blogs" },
  ];
  return (
    <>
      <div id="Consultation">
        <section className="pb-10 pt-0">
          <div className="container">
            <div className="headingAreaBox mb-5">
              <h1>
                Current <strong>ASK FOR CONSULTATION</strong>
              </h1>
            </div>
            <div className="author-profile-wrapper">
              <div className="row">
                {viral_section?.map((item, index) => {
                  return <Viral_section key={index} item={item} index={index} />;
                })}
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-4">
                <div className="boxAreaChart mb-4">
                  <h6 className="mb-0">FACEBOOK</h6>
                  <div className="sectionAreaTwo">
                    <div className="progress-bars">
                      <div className="progressArea">
                        Apple
                        <span>1.33 Cr</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar bg-11"
                          data-value="6.37"
                          style={{ width: "6.37%" }}
                        ></div>
                      </div>
                    </div>
                    <div className="progress-bars">
                      <div className="progressArea">
                        Samsung
                        <span>16.03 Cr</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar bg-12"
                          data-value="76.81"
                          style={{ width: "76.81%" }}
                        ></div>
                      </div>
                    </div>
                    <div className="progress-bars">
                      <div className="progressArea">
                        Nike Inc.
                        <span>3.51 Cr</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar bg-13"
                          data-value="16.82"
                          style={{ width: "16.82%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="boxAreaChart mb-4">
                  <h6 className="mb-0">TWITTER</h6>
                  <div className="sectionAreaTwo">
                    <ul className="oddeven2">
                      <li>
                        <img
                          src="/https://checkbrand.online/frontend/images/profile_pic_16045706001288644426.jpeg"
                          alt="Apple"
                        />{" "}
                        <span className="Text">Apple</span>
                        <span>54 Lac</span>
                      </li>
                      <li>
                        <img
                          src="/https://checkbrand.online/frontend/images/profile_pic_16037846031517820200.jpeg"
                          alt="Samsung"
                        />{" "}
                        <span className="Text">Samsung </span>
                        <span>22 Lac</span>
                      </li>
                      <li>
                        <img
                          src="/https://checkbrand.online/frontend/images/profile_pic_16032593891635713498.jpeg"
                          alt="Samsung"
                        />{" "}
                        <span className="Text">Nike Inc. </span>
                        <span>82 Lac</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="boxAreaChart mb-4">
                  <h6 className="mb-0">YOUTUBE</h6>
                  <div className="sectionAreaTwo">
                    <div className="progress-bars">
                      <div className="progressArea">
                        Apple
                        <span>1.33 Cr</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar bg-11"
                          data-value="6.37"
                          style={{ width: "6.37%" }}
                        ></div>
                      </div>
                    </div>
                    <div className="progress-bars">
                      <div className="progressArea">
                        Samsung
                        <span>16.03 Cr</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar bg-12"
                          data-value="76.81"
                          style={{ width: "76.81%" }}
                        ></div>
                      </div>
                    </div>
                    <div className="progress-bars">
                      <div className="progressArea">
                        Nike Inc.
                        <span>3.51 Cr</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar bg-13"
                          data-value="16.82"
                          style={{ width: "16.82%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </>
  );
};

export default Section6;
