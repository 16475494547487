import React from "react";
import { Link } from "react-router-dom";
import Register_Email from "./Register_Email";

const Footer = () => {
  return (
    <> 
      <footer className="footer-wrapper">
        <div className="footer-inner pt-8 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6 mb-4">
                <div className="footer-widget first-block">
                  <div className="mb-4">
                    <Link to="/" className="logo-light">
                      <img src="images/logo.png" alt="brand" />
                    </Link>
                  </div>
                  <p className="mb-5">
                    CheckBrand is the only platform to track your digital
                    ranking, digital presence, and social media presence along
                    with detailed analytics reports by which you can measure all
                    the parameters of a digital domain.
                  </p>
                  <div className="social">
                    <Link className="icon-facebook" href="#">
                      <i className="ri-facebook-line"></i>
                    </Link>
                    <Link className="icon-twitter" href="#">
                      <i className="ri-twitter-line"></i>
                    </Link>
                    <Link className="icon-instagram" href="#">
                      <i className="ri-instagram-line"></i>
                    </Link>
                    <Link className="icon-linkedin" href="#">
                      <i className="ri-linkedin-line"></i>
                    </Link>
                    <Link className="icon-youtube" href="#">
                      <i className="ri-youtube-line"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 mb-4">
                <div className="footer-widget">
                  <h4>Marketplace</h4>
                  <ul className="footer-list-widget">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/top100">TOP 100</Link>
                    </li>
                    <li>
                      <Link to="#">Trends</Link>
                    </li>
                    <li>
                      <Link to="#">Google Search</Link>
                    </li>
                    <li>
                      <Link to="#">Top Wiki</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 mb-4">
                <div className="footer-widget">
                  <h4>Community</h4>
                  <ul className="footer-list-widget">
                    <li>
                      <Link to="#">Price</Link>
                    </li>
                    <li>
                      <Link to="/brand-register">Register Your Brand </Link>
                    </li>
                    <li>
                      <Link to="#">Top News</Link>
                    </li>
                    <li>
                      <Link to="#">Top 500</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-4">
                <div className="footer-widget">
                  <h4>join newsletter</h4>
                  <div style={{marginBottom:'10px'}}>
                  <p>
                    Signup for our newsletter to get the latest news in your
                    inbox.
                  </p>
                  </div>
                  {/* <form action="#"> */}
                   <Register_Email />
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerMiddleAeea" id="footerAreaEx">
          <div className="container">
            <div className="bg-bottomLink">
              <Link to="#">Product Launch</Link>
              <Link to="#">Share of Voice</Link>
              <Link to="#">Media Monitoring</Link>
              <Link to="#">AI-Based Sentiment Analysis</Link>
              <Link to="#">Social Listening</Link>
              <Link to="#">ORM</Link>
              <Link to="#">Market Research</Link>
              <Link to="#">Monitor the Market and Trends</Link>
              <Link to="#">Gather Audience Insights</Link>
              <Link to="#">Real Time Analytics</Link>
              <Link to="#">Social Media Reports</Link>
              <Link to="#">Real Time Media Monitoring</Link>
              <Link to="#">Digital Survey</Link>
              <Link to="#">Digital Research</Link>
              <Link to="#">Real Time Alerting</Link>
              <Link to="#">Competitive Intelligence</Link>
              <Link to="#">Trend Analysis</Link>
              <Link to="#">Sales Intelligence</Link>
              <Link to="#">Market Intelligence</Link>
              <Link to="#">Brand Management</Link>
              <Link to="#">Branding Campaign</Link>
              <Link to="#">PR Reporting</Link>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                {" "}
                CheckBrand © 2020
                <Link to="#">About</Link>
                <Link to="#">Privacy Policy</Link>
                <Link to="#">Cookie Policy</Link>
                <Link to="#">Terms of Use</Link>
                <Link to="#">Disclaimer</Link>
                <Link to="#">F.A.Q.</Link>
                <Link to="#">BLOGS</Link>
              </div>
              <div className="col-lg-4 text-right">
                Powered by ADG Online Solutions
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
