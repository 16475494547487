import React from "react";
import { Link } from "react-router-dom";

const Ranking = () => {
  return (
    <>
      <section className="portfolio bg-8">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-md-6">
              <div className="dgRanBox">
                <div>
                  <h5>Why Digital Ranking?</h5>
                  <p>
                    CheckBrand Digital Ranking is like your portfolio in the
                    digital space. An in-depth assessment of your brand will
                    identify your weaknesses and strengths. CheckBrand discovers
                    favorable opportunities without interruption to give the
                    best for the brand. As our product is unique it develops a
                    healthy competition between the brands for boosting their
                    presence on the web. Our Digital Ranking is the assessment
                    of, search engine scores, sentimental analysis, social media
                    platforms, and many more factors.
                  </p>
                </div>
                <div className="group-btn mt-5">
                  <Link to="/login" className="btn btn-gradient" tabIndex="0">
                    <span>
                      <i className="ri-rocket-line"></i>
                      Explore More
                    </span>
                  </Link>
                  <Link
                    to="https://youtu.be/2ydz3DjkFS0"
                    className="btn btn-gradient "
                    tabIndex="0"
                  >
                    <span>
                      <i className="ri-edit-line"></i>Enhance your Digital
                      Space!
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 position-relative">
              <div className="videoAreaPanel">
                <iframe
                  src="https://player.vimeo.com/video/472521549"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ranking;
