import React from 'react'
import Dash_4 from '../component/Web_Dashboard/Overview/Dash_4'
import Dash_5 from '../component/Web_Dashboard/Overview/Dash_5'
import Dash6 from '../component/Web_Dashboard/Overview/Dash6'

const Common_Tab = ({dashData,loading}) => {
  return <>
  <Dash_4 dashData={dashData} loading={loading} />
  <Dash_5 dashData={dashData} loading={loading} />
  <Dash6 dashData={dashData} loading={loading} />
  
  </>
}

export default Common_Tab
