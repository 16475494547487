import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  Legend,
  Filler,
} from "chart.js";
import Loader from "../../../common/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
);

const Compared_Ranking = ({ allData, dash_data ,setLoadingScreen,loadingScreen}) => {
  const [mentionDate, setMentionDate] = useState();
  const [metionLabel, setMentionLabel] = useState();
  const [mention_bg, setMention_Bg] = useState(["#e3963a","#cd4fe3","#e33d7a","#79e33c","#40a8e3"]);
  const [mentionData, setMentionData] = useState();
  const [chartHeight, setChartHeight] = useState(110);
  useEffect(() => {
    if(allData){
      setLoadingScreen(false)
    }
    else if (allData?.length > 3) {
      setChartHeight(150);
    }
  }, [allData]);

  const compared_Data = [
    { id: 1, slug: "ranking", title: "Digital Ranking" },
    { id: 2, slug: "brand_value", title: "Brand Value" },
    { id: 3, slug: "score", title: "Brand Score" },
    { id: 4, slug: "category_rank", title: "Category Ranking" },
    { id: 5, slug: "avg_rating", title: "Rating" },
  ];

  const recommendedData = [
    { id: 1, slug: "mentions", title: "MENTIONS" },
    { id: 2, slug: "sentiments", title: "ENGAGEMENT", subSlug: "engagements" },
  ];

  const reachData = [
    { id: 3, slug: "brand_value", title: "SENTIMENT" },
    { id: 4, slug: "potential_reach", title: "POTENTIAL REACH" },
  ];

  useEffect(() => {
    if (dash_data) {
      const mention_date = allData.map((item) => item.name);
      const mention_label = allData.map((item) => item.mentions);
      setMentionDate(mention_date);
      setMentionLabel(mention_label);
      setMentionData({
        labels: mentionDate,
        datasets: [
          {
            label: "",
            data: metionLabel,
            fill: true,
            borderColor: "rgba(252, 92, 101)",
            backgroundColor: "rgba(252, 92, 101, 0.4)",
            pointBackgroundColor: mention_bg,
            pointBorderColor: mention_bg,
            pointRadius: 5,
            pointHoverRadius: 7,
          },
        ],
      });
    }
  }, [dash_data]);
  const bg_color='rgb(0,0,0,0.5)'

  console.log("Dash data is()=>", allData);
  return (
    <>
    {loadingScreen && <Loader bgColor={bg_color} />  }
      <section>
        <section className="InsightsSectionArea bgArea-5" id="Digital-Ranking">
          <div className="container">
            <h1>
              Current
              <strong>Digital Ranking</strong>
            </h1>
            <div className="row align-items-center">
              {compared_Data.map((compareItem, index) => {
                return (
                  <div className="col" key={index}>
                    <div className="InsightsArea">
                      <div id="chart">
                        <ReactApexChart
                          options={{
                            chart: {
                              type: "bar",
                              height: 100,
                              toolbar: {
                                show: false,
                              },
                            },
                            grid: {
                              show: true,
                              xaxis: {
                                lines: {
                                  show: true,
                                },
                              },
                              yaxis: {
                                lines: {
                                  show: true,
                                },
                              },
                            },
                            plotOptions: {
                              bar: {
                                barHeight: "100%",
                                distributed: true,
                                horizontal: true,
                                dataLabels: {
                                  enabled: true,
                                  position: "bottom",
                                },
                                // Adjust the barWidth to add space between bars
                                barWidth: 50, // Adjust this value as needed
                              },
                            },
                            colors: [
                              "#e3963a",
                              "#cd4fe3",
                              "#e33d7a",
                              "#79e33c",
                              "#40a8e3",
                            ],
                            dataLabels: {
                              enabled: true,
                              textAnchor: "start",
                              style: {
                                colors: ["black"],
                                fontSize: "11.5px",
                                leading: "12px",
                                fontWeight: 500,
                              },
                              formatter: function (val, opt) {
                                return (
                                  opt?.w?.globals?.labels[opt?.dataPointIndex] +
                                  ":  " +
                                  val?.toString()
                                );
                              },
                              offsetX: 0,
                              dropShadow: {
                                enabled: false,
                              },
                            },
                            stroke: {
                              width: 3,
                              colors: ["transparent"],
                            },
                            legend: {
                              show: false,
                            },
                            xaxis: {
                              categories: allData?.map((item) => item?.name),
                              axisBorder: {
                                show: false,
                              },
                              labels: {
                                show: false,
                              },
                            },
                            yaxis: {
                              labels: {
                                show: false,
                              },
                              axisBorder: {
                                show: false,
                              },
                            },
                            title: {
                              align: "center",
                              floating: false,
                            },
                            tooltip: {
                              theme: "dark",
                              x: {
                                show: false,
                              },
                              y: {
                                title: {
                                  formatter: function (val, opt) {
                                    const itemName =
                                      allData[opt.dataPointIndex].name;
                                    return `${itemName}:`;
                                  },
                                },
                              },
                            },
                          }}
                          series={[
                            {
                              data: allData?.map(
                                (item) => item[compareItem.slug]
                              ),
                            },
                          ]}
                          type="bar"
                          height={chartHeight}
                        />
                      </div>
                      <h6>{compareItem.title}</h6>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <div className="SearchPostSection" id="Recommendattons">
          <section className="InsightsSectionArea">
            <div className="container">
              <h1 className="d-flex align-items-center">
                Current&nbsp;
                <strong>Recommendations</strong>
                {/* <a href="#" className="ms-auto btnAnalytics">
                  Click on Detailed Analytics
                </a> */}
              </h1>
              <div className="row  pt-3 pb-0">
                <div className="col-3">
                  {recommendedData?.slice(0, 2).map((recommendItem, index) => {
                    return (
                      <div className="boxAreaChart mb-4" key={index}>
                        <h6>{recommendItem.title}</h6>
                        <div className="chartContainer">
                          <ReactApexChart
                            options={{
                              chart: {
                                type: "bar",
                                height: 100,
                                toolbar: {
                                  show: false,
                                },
                              },
                              grid: {
                                show: false,
                                xaxis: {
                                  lines: {
                                    show: false,
                                  },
                                },
                                yaxis: {
                                  lines: {
                                    show: false,
                                  },
                                },
                              },
                              plotOptions: {
                                bar: {
                                  barHeight: "100%",
                                  distributed: true,
                                  horizontal: true,
                                  dataLabels: {
                                    enabled: true,
                                    position: "bottom",
                                  },
                                  // Adjust the barWidth to add space between bars
                                  barWidth: 50, // Adjust this value as needed
                                },
                              },
                              colors: [
                                "#e3963a",
                                "#cd4fe3",
                                "#e33d7a",
                                "#79e33c",
                                "#40a8e3",
                              ],
                              dataLabels: {
                                enabled: true,
                                textAnchor: "start",
                                style: {
                                  colors: ["black"],
                                  fontSize: "11.5px",
                                  leading: "12px",
                                  fontWeight: 500,
                                },
                                formatter: function (val, opt) {
                                  return (
                                    opt?.w?.globals?.labels[
                                      opt?.dataPointIndex
                                    ] +
                                    ":  " +
                                    val?.toString()
                                  );
                                },
                                offsetX: 0,
                                dropShadow: {
                                  enabled: false,
                                },
                              },
                              stroke: {
                                width: 3,
                                colors: ["transparent"],
                              },
                              legend: {
                                show: false,
                              },
                              xaxis: {
                                categories: allData?.map((item) => item?.name),
                                axisBorder: {
                                  show: false,
                                },
                                labels: {
                                  show: false,
                                },
                              },
                              yaxis: {
                                labels: {
                                  show: false,
                                },
                                axisBorder: {
                                  show: false,
                                },
                              },
                              // title: {
                              //   align: "center",
                              //   floating: false,
                              // },
                              tooltip: {
                                theme: "dark",
                                x: {
                                  show: false,
                                },
                                y: {
                                  title: {
                                    formatter: function (val, opt) {
                                      const itemName =
                                        allData[opt.dataPointIndex].name;
                                      console.log("item is", itemName);
                                      return `${itemName}:`;
                                    },
                                  },
                                },
                              },
                            }}
                            series={[
                              {
                                data: recommendItem?.subSlug
                                  ? allData?.map((item) =>
                                      Math.floor(
                                        item[recommendItem.slug][
                                          recommendItem.subSlug
                                        ]
                                      )
                                    )
                                  : allData?.map((item) =>
                                      Math.floor(item[recommendItem.slug])
                                    ),
                              },
                            ]}
                            type="bar"
                            height={chartHeight}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="col-9">
                  <div className="boxAreaChart">
                    <h6>MENTIONS</h6>
                    <div
                      style={{
                        height: allData?.length > 3 ? "422px" : "340px",
                        width: "100% !important",
                      }}
                    >
                      {mentionData && (
                        <Line
                          data={{
                            labels: allData.map((item) => item.name),
                            datasets: [
                              {
                                label: "",
                                data: allData.map((item) => item.mentions),
                                fill: true,
                                borderColor: "rgba(252, 92, 101, 0.6)",
                                backgroundColor: "rgba(252, 92, 101, 0.4)",
                                pointBackgroundColor: mention_bg,
                                pointBorderColor: mention_bg,
                                pointRadius: 6,
                                pointHoverRadius: 8,
                              },
                            ],
                          }}
                          options={{
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                display: false,
                              },
                              title: {
                                display: false,
                                text: "Bar Chart",
                              },
                            },
                            scales: {
                              y: {
                                min: Math.min(
                                  allData.map((item) => item.mentions)
                                ),
                                // beginAtZero: true,
                                ticks: {
                                  stepSize:
                                   ( Math.min(
                                      allData.map((item) => item.mentions)
                                    ) -
                                    Math.max(
                                      allData.map((item) => item.mentions)
                                    ))/2,
                                },
                              },
                            },
                          }}
                          width={400}
                          height={200}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3">
                <div className="col-3">
                  {reachData?.map((reachedItem, index) => {
                    return (
                      <div className="boxAreaChart" key={index}>
                        <h6> {reachedItem.title}</h6>
                        <div className="chartContainer-14">
                          <ReactApexChart
                            options={{
                              chart: {
                                type: "bar",
                                height: 100,
                                toolbar: {
                                  show: false,
                                },
                              },
                              grid: {
                                show: false,
                                xaxis: {
                                  lines: {
                                    show: false,
                                  },
                                },
                                yaxis: {
                                  lines: {
                                    show: false,
                                  },
                                },
                              },
                              plotOptions: {
                                bar: {
                                  barHeight: "100%",
                                  distributed: true,
                                  horizontal: true,
                                  dataLabels: {
                                    enabled: true,
                                    position: "bottom",
                                  },
                                  // Adjust the barWidth to add space between bars
                                  barWidth: 50, // Adjust this value as needed
                                },
                              },
                              colors: [
                                "#e3963a",
                                "#cd4fe3",
                                "#e33d7a",
                                "#79e33c",
                                "#40a8e3",
                              ],
                              dataLabels: {
                                enabled: true,
                                textAnchor: "start",
                                style: {
                                  colors: ["black"],
                                  fontSize: "11.5px",
                                  leading: "12px",
                                  fontWeight: 500,
                                },
                                formatter: function (val, opt) {
                                  return (
                                    opt?.w?.globals?.labels[
                                      opt?.dataPointIndex
                                    ] +
                                    ":  " +
                                    val?.toString()
                                  );
                                },
                                offsetX: 0,
                                dropShadow: {
                                  enabled: false,
                                },
                              },
                              stroke: {
                                width: 3,
                                colors: ["transparent"],
                              },
                              legend: {
                                show: false,
                              },
                              xaxis: {
                                categories: allData?.map((item) => item?.name),
                                axisBorder: {
                                  show: false,
                                },
                                labels: {
                                  show: false,
                                },
                              },
                              yaxis: {
                                labels: {
                                  show: false,
                                },
                                axisBorder: {
                                  show: false,
                                },
                              },
                              // title: {
                              //   align: "center",
                              //   floating: false,
                              // },
                              tooltip: {
                                theme: "dark",
                                x: {
                                  show: false,
                                },
                                y: {
                                  title: {
                                    formatter: function (val, opt) {
                                      const itemName =
                                        allData[opt.dataPointIndex].name;
                                      console.log("item is", itemName);
                                      return `${itemName}:`;
                                    },
                                  },
                                },
                              },
                            }}
                            series={[
                              {
                                data: allData?.map((item) =>
                                  Math.floor(item[reachedItem.slug])
                                ),
                              },
                            ]}
                            type="bar"
                            height={chartHeight}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="col-9">
                  <div className="boxAreaChart">
                    <h6>FOLLOWERS</h6>
                    <div
                      style={{
                        height: allData?.length > 3 ? "422px" : "340px",
                        width: "100% !important",
                      }}
                    >
                      {mentionData && (
                        <Line
                          data={{
                            labels: allData.map((item) => item.name),
                            datasets: [
                              {
                                label: "",
                                data: allData.map((item) => item.mentions),
                                fill: true,
                                borderColor: "rgba(252, 92, 101, 0.6)",
                                backgroundColor: "rgba(252, 92, 101, 0.4)",
                                pointBackgroundColor: mention_bg,
                                pointBorderColor: mention_bg,
                                pointRadius: 6,
                                pointHoverRadius: 8,
                              },
                            ],
                          }}
                          options={{
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                display: false,
                              },
                              title: {
                                display: false,
                                text: "Bar Chart",
                              },
                            },
                            scales: {
                              y: {
                                min: Math.min(
                                  allData.map((item) => item.mentions)
                                ),
                                // beginAtZero: true,
                                ticks: {
                                  stepSize:
                                   ( Math.min(
                                      allData.map((item) => item.mentions)
                                    ) -
                                    Math.max(
                                      allData.map((item) => item.mentions)
                                    ))/2,
                                },
                              },
                            },
                          }}
                          width={400}
                          height={200}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Compared_Ranking;
