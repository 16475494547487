import React ,{ useEffect} from "react";
import { performanceSuggestion ,contentData,responseData} from "../../../commonJson/mockData";
import Performance from "./Performance";

const Dash_4 = ({dashData}) => {
  return (
    <>
      <section className="ruralDevtSection_web pt-5 pb-5">
        <div className="container">
          <div className="d-flex gap-3  align-items-center mb-4 headingArea_web">
            <div>
              {/* <img src="http://webalytics.companydemo.in/assets/images/img06.jpg" alt="Logo" /> */}
              <img src={dashData?.website_logo} alt="Logo" style={{height:'44px',objectFit:'contain',padding:'2px'}} />
            </div>
            <div>
              <h1>
                Improve Page <span>Performance</span>
              </h1>
            </div>
          </div>
          <div className="accordion" id="accordionExample">
            <table
              className="performanceTable"
              border="0"
              cellSpacing="0"
              cellPadding="0"
            >
              <thead>
                <tr>
                  <th style={{ width: "100px" }}>GRADE</th>
                  <th colSpan="2">SUGGESTION</th>
                </tr>
              </thead>
              <tbody>
                {dashData?.performance_suggestion?.map((item,index)=>{
                 return <>
                <Performance key={index} item={item} />
                </>})}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <section className="rankingSection-02">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="mb-3">Content size by content type</h1>
              <div className="card">
                <table className="performanceTable">
                  <thead>
                    <tr className="text-center">
                      <th colSpan="3">PERCENT </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashData?.web_content_data?.map((item,index)=>{
                    return <tr key={index}>
                      <td style={{textAlign:'center'}}>
                        {/* <img src="assets/images/image.png" alt="img" srcSet="" />{" "} */}
                        {item.title}
                      </td>
                      <td style={{textAlign:'center'}}>{item.percent == 'N/A' ? item.percent : `${item.percent} %`}</td>
                      <td style={{textAlign:'center'}}>{item.storage == 'N/A' ? item.storage : `${item.storage} MB`}</td>
                    </tr>})}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col">
              <h1 className="mb-3">Response Codes</h1>
              <div className="card">
                <table className="performanceTable">
                  <thead>
                    <tr>
                      <th>RESPONSE CODE</th>
                      <th>RESPONSES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashData?.last_6_response?.slice(0,5).map((item,index)=>{
                   return <tr key={index}>
                      <td>
                        <span
                          style={{
                            background: item.code_name == 200 ? "rgb(113, 194, 66)" :"rgb(61, 164, 240)",
                            padding: "4px",
                            borderRadius: "3px",
                          }}
                        >
                          {item?.code_name}
                        </span>{" "}
                        {item?.code_name == 200 ? "Ok" : "Forbidden from accessing a valid URL"}
                        
                      </td>
                      <td>{item.code_count}</td>
                    </tr>})}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dash_4;
