import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { data as mockData } from "../../common/mockData";
import axios from "axios";
const Brand_rank = () => {
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData,setFilteredData]=useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      if (value?.length > 0) {
        setShow(true);
      } else {
        setShow(false);
      }
    }, [value]);

  async function getData() {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.frontend.checkbrand.online/public/api/v1/top100brands`,
        {
          headers: {
            accept: "*/*",
          },
        }
      );
      setOriginalData(response?.data);
      console.log("Response of brands",response.data)
      setLoading(false);
    } catch (error) {
      console.error("Error:", error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(()=>{
    const filteredData1=originalData?.top100brands?.filter((item)=>{
      return  item.name.toLowerCase().includes(value.toLowerCase())
    })
    setFilteredData(filteredData1)
  },[value])
  
  return (
    <>
      <div id="digitalRank-sale" className="tab-pane fade active show">
        <div className="d-flex align-items-center">
          <div className="col-md-6">
            <div className="bannerHdText">
              <h1>
                Check Your <span>Digital Rank</span> and Score
              </h1>
              <p>
                Understand your digital footprint, measure your brand impact
                with AI, and grow your brand with data
              </p>
              <div className="pr-5 mr-2">
                <div
                  className="serachArea d-flex mt-5"
                  style={{
                    position: "relative",
                    borderTopLeftRadius: "50px",
                    borderTopRightRadius: "50px",
                    borderBottomLeftRadius: show ? "0px" : "50px",
                    borderBottomRightRadius: show ? "0px" : "50px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Check Your Digital Ranking"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <button className="btn">Search </button>
                  <div
                    id="inputField1"
                    className=""
                    style={{
                      background: "white",
                      width: "100%",
                      position: "absolute",
                      top: "70px",
                      left: "0px",
                      display: show ? "" : "none",
                      padding: "30px",
                      zIndex: 999,
                      borderRadius: "0px 0px 50px 50px",
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        borderRadius: "100px",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: 10,
                          marginBottom: "20px",
                        }}
                      >
                        <div style={{display:'inline-flex'}}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-5 h-5"
                          style={{ width: "20px", height: "20px" }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.577 4.878a.75.75 0 01.919-.53l4.78 1.281a.75.75 0 01.531.919l-1.281 4.78a.75.75 0 01-1.449-.387l.81-3.022a19.407 19.407 0 00-5.594 5.203.75.75 0 01-1.139.093L7 10.06l-4.72 4.72a.75.75 0 01-1.06-1.061l5.25-5.25a.75.75 0 011.06 0l3.074 3.073a20.923 20.923 0 015.545-4.931l-3.042-.815a.75.75 0 01-.53-.919z"
                            clipRule="evenodd"
                          />
                        </svg>

                        <h6 style={{ margin: "auto", fontWeight: "500",marginLeft:'10px' }}>
                          Popular
                        </h6>
                        </div>
                        <div style={{gap:'10px'}}>
                        {originalData?.top100brands?.slice(0,3).map((item) => {
                          return (
                            <Link to={`/dashboard/${item.purl}`} key={item.purl}>
                            <div
                              className=""
                              style={{
                                display: "inline-flex",
                                gap: "5px",
                                backgroundColor: "#f0f4ff",
                                padding: "5px",
                                borderRadius: "10px",
                                marginLeft:'10px'
                              }}
                            >
                              <img
                                src={originalData.image_path + item.dp}
                                style={{ width: "20px", margin: "auto" }}
                              />
                              <p
                                style={{
                                  margin: "auto",
                                }}
                              >
                                {item.name}
                              </p>
                            </div>
                            </Link>
                          );
                        })}
                        </div>
                      </div>
                      {filteredData?.length > 0 ? (
                        filteredData.slice(0, 5).map((item, index) => {
                          return (
                            <Link to={`/dashboard/${item.purl}`} key={item.purl}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "flex-col",
                                background: "white",
                                padding:'5px'
                              }}
                            >
                              <div style={{ display: "inline-flex" }}>
                                <img
                                  src={originalData.image_path + item.dp}
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginTop: "5px",
                                    marginRight: "10px",
                                  }}
                                />
                                <p>{item.name}</p>
                              </div>
                            </div>
                            </Link>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            display: "inline-flex",
                            borderRadius: "0px 0px 50px 50px",
                            textAlign: "center",
                          }}
                        >
                          <p>No brand found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bannerBtn mt-5">
                <Link to="/brand-register" className="btn">
                  Register Your Brand{" "}
                </Link>{" "}
                <Link to="/login" className="btn btn-bd ms-2">
                  Get Started{" "}
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="homeBannerSilderWrapper">
              <div className="homeBannerSilderWrapper">
                <div className="homeBannerSilder">
                  <div className="bannerSlider">
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <div className="heartIcon">
                          <i className="ri-heart-fill"></i>
                        </div>
                        <div className="d-flex-center justify-content-center mt-2">
                          <div className="number counter-item-active">32</div>
                          <div className="count-kilo">
                            K <span>+</span>
                          </div>
                        </div>
                        <div className="rateName mt-2 mb-2">
                          Engagement Rate
                        </div>
                        <div className="ratingBox upColor">
                          {" "}
                          <i className="ri-arrow-up-fill slide_up_down"></i>
                          15%{" "}
                        </div>
                      </div>
                    </div>
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <div className="d-flex align-items-center">
                          <img src="images/img-1.jpg" />
                          <div className="d-flex-center">
                            <div className="count-kilo">#</div>
                            <div className="number counter-item-active">1</div>
                          </div>
                          <div className="ratingBox upColor">
                            {" "}
                            <i className="ri-arrow-up-fill slide_up_down"></i>
                            15%{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <div className="heartIcon">
                          <i className="ri-heart-fill"></i>
                        </div>
                        <div className="d-flex-center justify-content-center mt-2">
                          <div className="number counter-item-active">82</div>
                          <div className="count-kilo">
                            Cr <span>+</span>
                          </div>
                        </div>
                        <div className="rateName mt-2 mb-2">Value</div>
                        <div className="ratingBox upColor">
                          {" "}
                          <i className="ri-arrow-up-fill slide_up_down"></i>
                          15%{" "}
                        </div>
                      </div>
                    </div>
                    <div className="ratingRadBox">
                      <div className="d-flex-center justify-content-center mb-2">
                        <div className="number counter-item-active">110</div>
                        <div className="count-kilo">K</div>
                      </div>
                      <div className="f-20">Score</div>
                    </div>
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <img src="images/img-02.webp" className="w-auto" />
                        <div className="d-flex-center justify-content-center mt-2">
                          <div className="number counter-item-active">82</div>
                          <div className="count-kilo">
                            Cr <span>+</span>
                          </div>
                        </div>
                        <div className="rateName mt-2 mb-2">Value</div>
                      </div>
                    </div>
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <img
                          src="images/img-05.jpg"
                          className="w-auto"
                          style={{ height: "100px" }}
                        />
                      </div>
                    </div>
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <div className="d-flex align-items-center">
                          <img src="images/img-1.jpg" />
                          <div className="d-flex-center">
                            <div className="count-kilo">#</div>
                            <div className="number counter-item-active">1</div>
                          </div>
                          <div className="ratingBox upColor">
                            {" "}
                            <i className="ri-arrow-up-fill slide_up_down"></i>
                            15%{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <div className="heartIcon">
                          <i className="ri-heart-fill"></i>
                        </div>
                        <div className="d-flex-center justify-content-center mt-2">
                          <div className="number counter-item-active">32</div>
                          <div className="count-kilo">
                            K <span>+</span>
                          </div>
                        </div>
                        <div className="rateName mt-2 mb-2">
                          Engagement Rate
                        </div>
                        <div className="ratingBox upColor">
                          {" "}
                          <i className="ri-arrow-up-fill slide_up_down"></i>
                          15%{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bannerSlider bannerSliderSlow">
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <div className="heartIcon">
                          <i className="ri-heart-fill"></i>
                        </div>
                        <div className="d-flex-center justify-content-center mt-2">
                          <div className="number counter-item-active">32</div>
                          <div className="count-kilo">
                            K <span>+</span>
                          </div>
                        </div>
                        <div className="rateName mt-2 mb-2">
                          Engagement Rate
                        </div>
                        <div className="ratingBox upColor">
                          {" "}
                          <i className="ri-arrow-up-fill slide_up_down"></i>
                          15%{" "}
                        </div>
                      </div>
                    </div>
                    <div className="cardBox-01">
                      <div className="noRank">#1</div>
                      <img src="images/img03.png" />
                    </div>
                    {/* <!-- box 01 --> */}
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <div className="heartIcon">
                          <i className="ri-heart-fill"></i>
                        </div>
                        <div className="d-flex-center justify-content-center mt-2">
                          <div className="number counter-item-active">82</div>
                          <div className="count-kilo">
                            Cr <span>+</span>
                          </div>
                        </div>
                        <div className="rateName mt-2 mb-2">Value</div>
                        <div className="ratingBox upColor">
                          {" "}
                          <i className="ri-arrow-up-fill slide_up_down"></i>
                          15%{" "}
                        </div>
                      </div>
                    </div>
                    {/* <!-- ./box 01 -->
                                 <!-- box 01 --> */}
                    <div>
                      <img src="images/img04.png" className="w-100" />
                    </div>
                    {/* <!-- ./box 01 -->
                                 <!-- box 01 --> */}
                    <div>
                      <img src="images/img05.webp" className="w-100" />
                    </div>
                    {/* <!-- ./box 01 -->
                                 <!-- box 03 --> */}
                    <div className="ratingRadBox">
                      <div className="d-flex-center justify-content-center mb-2">
                        <div className="number counter-item-active">110</div>
                        <div className="count-kilo">K</div>
                      </div>
                      <div className="f-20">Score</div>
                    </div>
                    {/* <!-- ./box 03 -->
                                 <!-- box 01 --> */}
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <img src="images/img-02.webp" className="w-auto" />
                        <div className="d-flex-center justify-content-center mt-2">
                          <div className="number counter-item-active">82</div>
                          <div className="count-kilo">
                            Cr <span>+</span>
                          </div>
                        </div>
                        <div className="rateName mt-2 mb-2">Value</div>
                      </div>
                    </div>
                    {/* <!-- ./box 01 -->
                                 <!-- box 01 --> */}
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <img
                          src="images/img-05.jpg"
                          className="w-auto"
                          style={{ height: "100px" }}
                        />
                      </div>
                    </div>
                    {/* <!-- ./box 01 -->
                                 <!-- box 02 --> */}
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <div className="d-flex align-items-center">
                          <img src="images/img-1.jpg" />
                          <div className="d-flex-center">
                            <div className="count-kilo">#</div>
                            <div className="number counter-item-active">1</div>
                          </div>
                          <div className="ratingBox upColor">
                            {" "}
                            <i className="ri-arrow-up-fill slide_up_down"></i>
                            15%{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- ./box 02 -->
                                 <!-- box 01 --> */}
                    <div className="cardBox">
                      <div className="rankingBox text-center">
                        <div className="heartIcon">
                          <i className="ri-heart-fill"></i>
                        </div>
                        <div className="d-flex-center justify-content-center mt-2">
                          <div className="number counter-item-active">32</div>
                          <div className="count-kilo">
                            K <span>+</span>
                          </div>
                        </div>
                        <div className="rateName mt-2 mb-2">
                          Engagement Rate
                        </div>
                        <div className="ratingBox upColor">
                          {" "}
                          <i className="ri-arrow-up-fill slide_up_down"></i>
                          15%{" "}
                        </div>
                      </div>
                    </div>
                    {/* <!-- ./box 01 -->  */}
                  </div>
                  {/* <!-- ./right slider --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ./Digital Rank Section --> */}
      </div>
    </>
  );
};

export default Brand_rank;
