import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";
import {
  countryData,
  data,
  data2,
  data3,
  data4,
  options,
  options2,
  options3,
  options4,
  comparison,
} from "../../../commonJson/mockData";
import { Bar, Line, line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);
const Dash6 = ({ dashData, loading }) => {
  const [country, setCountry] = useState();
  const [id, setId] = useState(0);

  console.log(
    "Dash________DAta",
    dashData?.different_country_data
      ? dashData.different_country_data[id]?.percent
      : "N/A"
  );

  const filteredData = (data) => {
    if (dashData?.different_country_data) {
      const newData = dashData?.different_country_data.filter((item) => {
        return item.title == data;
      });
      return newData[0]?.contryImg;
    }
  };
  const filteredPercent = (data) => {
    if (dashData?.different_country_data) {
      const newData = dashData?.different_country_data.filter((item) => {
        return item.title == data;
      });
      if (newData[0]?.percent == "N/A") {
        return newData[0]?.percent;
      }
      return `${newData[0]?.percent} %`;
    }
  };

  useEffect(() => {
    if (dashData.length > 0) {
      const newData = dashData?.different_country_data[0].contryImg;
      setCountry(newData);
    }
  }, []);

  const addData = (title, index) => {
    setCountry(title);
    setId(index);
  };

  console.log("THE LENGTH", dashData?.different_country_data?.length);
  return (
    <>
      <>
        <section className="trafficAndEngagSection pt-5 pb-5">
          <div className="container">
            <div className="d-flex gap-3  align-items-center mb-4 headingArea_web">
              <div>
                {/* <img
                  src="http://webalytics.companydemo.in/img06.jpg"
                  alt="Logo"
                /> */}
                <img
                  src={dashData?.website_logo}
                  style={{height:'44px',objectFit:'contain',background:'white',padding:'2px'}}
                  alt="Logo"
                />
              </div>
              <div>
                <h1>Traffic and Engagement</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div className="card">
                  <div className="card-body p-0">
                    <h4>
                      Traffic & Engagement <span>Last Month</span>
                    </h4>
                    <div className="p-2">
                      <table className="performanceTable table-striped ">
                        <tbody>
                          {dashData?.traffic_engagement?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <p>{item.title}</p>
                                </td>
                                <td>
                                  <p>{item.data}</p>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="card">
                  <div className="card-body p-0">
                    <h4>
                      Total visits last <span>3 months</span>
                    </h4>
                    <div className="p-2">
                      <div style={{ height: "300px" }}>
                        <Bar data={data2} options={options2} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ruralDevtSection_web pt-5 pb-5">
          <div className="container">
            <div className="d-flex gap-3  align-items-center mb-4 headingArea_web">
              <div>
                {/* <img
                  src="http://webalytics.companydemo.in/img06.jpg"
                  alt="Logo"
                /> */}
                <img
                  src={dashData?.website_logo}
                  alt="Logo"

                  style={{height:"44px",background:'white',objectFit:'contain',padding:'2px'}}
                />
              </div>
              <div>
                <h1>Traffic share by country: {dashData.website_name}</h1>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-2">
                <div className="card">
                  <div className="card-body tabHrPanel">
                    <ul
                      className="nav nav-tabs nav-fill"
                      id="ex1"
                      role="tablist"
                    >
                      {dashData?.different_country_data?.map((item, index) => {
                        return (
                          <li
                            key={item.title}
                            className="nav-item"
                            role="presentation"
                            style={{ textAlign: "left !important" }}
                            onClick={() => addData(item.title, index)}
                          >
                            <a
                              className={` ${
                                index === id ? "nav-link active" : "nav-link"
                              } `}
                              id="#ex2-tab-1"
                              data-bs-toggle="tab"
                              href="#ex2-tabs-1"
                              role="tab"
                              aria-controls="ex2-tabs-1"
                              aria-selected="true"
                            >
                              {/* <img src={item.logo} style={{objectFit:'contain'}} /> */}
                              <img
                                src="http://webalytics.companydemo.in/india.jpg"
                                style={{ objectFit: "contain" }}
                              />
                              {item.title}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8 ">
                <div className="tab-content" id="ex2-content">
                  <div
                    className="tab-pane fade show active"
                    id="#ex2-tabs-1"
                    role="tabpanel"
                    aria-labelledby="#ex2-tab-1"
                  >
                    {/* <div className="tabPanelArea">
                    <div className="row justify-content-between">
                      <div className="col-md-6 text-center">
                        <img src={ id == 0 ? dashData?.different_country_data[id].contryImg :filteredData(country)} style={{height:'350px',objectFit:'contain'}} />
                      </div>

                      <div className="col-md-4">
                        <div className="card-03 bg-gradient-burning mb-2">
                          <div className="d-flex align-items-center">
                            <i className="ri-dashboard-line"></i>
                            <div className="ms-auto text-end color-w f-15">
                              {dashData?.website_url}
                              <span className="f-30">{ id == 0 ? dashData?.different_country_data[id].percent :filteredPercent(country)}</span>
                            </div>
                          </div>
                          <div id="chart7"></div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    <div className="tabPanelArea">
                      <div className="row justify-content-between">
                        <div className="col-md-6 text-center">
                          {dashData?.different_country_data && id == 0 ? (
                            <img
                              src={dashData?.different_country_data[0].contryImg}
                              style={{ height: "350px", objectFit: "contain" }}
                            />
                          ):(
                            <img
                              src={filteredData(country)}
                              style={{ height: "350px", objectFit: "contain" }}
                            />
                          )}
                        </div>

                        <div className="col-md-4">
                          <div className="card-03 bg-gradient-burning mb-2">
                            <div className="d-flex align-items-center">
                              <i className="ri-dashboard-line"></i>
                              <div className="ms-auto text-end color-w f-15" >
                                {dashData?.website_url}
                                {dashData?.different_country_data && id == 0 ? (
                            <span className="f-15" >
                            {dashData?.different_country_data[0]?.percent}
                          </span>
                          ):(
                            <span className="f-15">
                                    {filteredPercent(country)}
                                  </span>
                          )} 
                              </div>
                            </div>
                            <div id="chart7"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="rankingSection-02">
          <div className="container">
            <div className="d-flex gap-3  align-items-center mb-4 headingArea_web">
              <div className="w-100">
                <h1>Audience Demographics Comparison</h1>
                <p>
                  Ministry of Education visitors comparison by gender and age
                  distribution
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body p-0">
                    <h4>Gender Distribution</h4>
                    <div className="p-2">
                      <div style={{ height: "300px", width: "558px" }}>
                        <Bar data={data} options={options} />
                      </div>
                      {/* <canvas id="chartLine-02" width="558" height="300"></canvas> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body p-0">
                    <h4>Age Distribution</h4>
                    <div className="p-2">
                      <div style={{ height: "300px", width: "558px" }}>
                        <Line data={data4} options={options4} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="rankingSection-02">
          <div className="container">
            <div className="d-flex gap-3  align-items-center mb-4 headingArea_web">
              <div className="w-100">
                <h1>Marketing Channels for {dashData?.website_url}</h1>
                <p className="w-50">
                  Analyze {dashData?.website_url} traffic sources distribution
                  to learn more about each website's digital marketing
                  priorities & strategies
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body p-0">
                    <h4>Marketing Channels Distribution</h4>
                    <div className="p-2">
                      <div style={{ height: "550px" }}>
                        <Bar data={data3} options={options3} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ruralDevtSection_web pt-5 pb-5 compareSection">
          <div className="container">
            <div className="d-flex gap-3  align-items-center mb-2 headingArea_web">
              <div className="w-100">
                <h1>Compare {dashData?.website_url} to other websites</h1>
                <p className="w-50 mb-0">
                  See how {dashData?.website_url} stacks up against its other
                  top competitors.
                </p>
              </div>
            </div>

            <div className="row">
              {comparison?.map((item, index) => {
                return (
                  <div className="col-md-4" key={index}>
                    <div className="card compareAreaBox">
                      <div className="card-body p-0">
                        <div className="d-flex">
                          <div className="col">
                            <img
                              src="http://webalytics.companydemo.in/img06.jpg"
                              alt="Logo"
                            />
                            <p>{item.first}</p>
                          </div>

                          <div className="col">
                            <img
                              src="http://webalytics.companydemo.in/img06.jpg"
                              alt="Logo"
                            />
                            <p>{item.second}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default Dash6;
