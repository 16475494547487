import React from 'react';
import FaqTab from '../../../common/FaqTab';
import Common_Tab from '../../../common/Common_Tab';
const Navigation = ({activeTab,dashData,loading}) => {
  const questions = [
    {
      id: 1,
      title:
        "The website has a sitemap",
      description: "",
      ariaControls: "first",
    },
    {
      id: 2,
      title:
        "Website has a FAQ",
      description: "",
      ariaControls: "second",
    },
    {
      id: 3,
      title:
        "Navigation components are consistent across the site ",
      description: "",
      ariaControls: "third",
    },
    {
      id: 4,
      title:
        "Homepage linked to all pages on the website",
      description: "",
      ariaControls: "fourth",
    },
    {
      id: 5,
      title:
        "The website has breadcrumbs",
      description: "",
      ariaControls: "five",
    },
    {
      id: 6,
      title:
        "Correct title of the homepage (appearing on the top bar of the browser) is provided",
      description: "",
      ariaControls: "six",
      
    },
    {
      id: 7,
      title:
        "Correct title of the homepage (appearing on the top bar of the browser) is provided",
      description: "",
      ariaControls: "seven",
    },
    {
      id: 8,
      title:
        "Website links are functional and no broken links",
      description: "",
      ariaControls: "eight",
    },
    {
      id: 9,
      title:
        "Are there any web page error messages like 404/500 appear/displayed on any page under the website",
      description: "",
      ariaControls: "nine",
    },
  ];
  return <>
  <section className="ruralDevtSection_web pt-5 pb-5 mb-5">
         <div className="container">
            <div className="d-flex gap-3  align-items-center mb-4 headingArea_web">
               <div>
                  <img src={dashData?.website_logo} />
               </div>
               <div>
                  <h1> {activeTab}</h1>
               </div>
            </div>
        <div className="performanceTechnicalBox_web">
            <div className="row">
                <div className="col-lg-6">
                    <div className="questionBox">
                       <h4 className="align-items-center"><span>Q1</span> The website has a sitemap</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                       </div>
                    </div>
                    <div className="questionBox">
                       <h4 className="align-items-center"><span>Q3</span> Navigation components are consistent across the site</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                       </div>
                    </div>
                    <div className="questionBox">
                       <h4 className="align-items-center"><span>Q5</span> The website has breadcrumbs</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                       </div>
                    </div>
                    <div className="questionBox">
                       <h4 className="align-items-center"><span>Q7</span> Correct title of the homepage (appearing on the top bar of the browser) is provided</h4> 
                        <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                       </div>
                    </div>
                     <div className="questionBox">
                       <h4 className="align-items-center"><span>Q9</span> Website links are functional and no broken links</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                       </div>
                    </div>
                </div>
                <div className="col-lg-6">
                     <div className="questionBox">
                       <h4 className="align-items-center"><span>Q2</span> Website has a FAQ </h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                       </div>
                    </div>
                     <div className="questionBox">
                       <h4 className="align-items-center"><span>Q4</span> Homepage linked to all pages on the website</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                       </div>
                    </div>
                     <div className="questionBox">
                       <h4 className="align-items-center"><span>Q6</span> Click depth is equal to or less than 3</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                           
                       </div>
                    </div>
                    <div className="questionBox">
                       <h4 className="align-items-center"><span>Q8</span> Website Links are distinguishable in terms of colour,format</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                           
                       </div>
                    </div>
                    <div className="questionBox">
                       <h4 className="align-items-center"><span>Q10</span> Are there any web page error messagees like 404/500 appear/displayed on any page under the website</h4> 
                       <div className="anSBos gap-3 align-items-center">
                           <div><i className="ri-check-line"></i> <span>Yes</span></div>
                           <div><i className="ri-close-line"></i> <span>No</span></div>
                           
                       </div>
                    </div>
                </div>
            </div>
        </div>
         </div>
      </section>
  {/* <FaqTab activeTab={activeTab} dashData={dashData} questions={questions} /> */}
  <Common_Tab dashData={dashData} loading={loading} />
  </>
}

export default Navigation
