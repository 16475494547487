import React from "react";
import Common_Chart from "../../../common/Common_Chart";
import { backlinkData,backlinkType,backTypes } from "../../../commonJson/mockData";

const Dash_5 = ({dashData}) => {
  return (
    <>
      <section className="rankingSection-02">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body p-0">
                  <h4>
                    Organic Traffic <span>250338/month</span>
                  </h4>
                  <div className="p-2">
                    <Common_Chart  width={550} height={250}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body p-0">
                  <h4>
                    Organic Keywords <span>76003</span>
                  </h4>
                  <div className="p-2">
                  <Common_Chart  width={550} height={250} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="rankingSection-02">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="card">
                <div className="card-body p-0">
                  <h4 style={{fontSize:'14px'}}>
                    Backlinks <span>({dashData?.website_url})</span>
                  </h4>
                  <div className="p-3 pt-1">
                    <table className="performanceTable table-striped ">
                      <thead>
                        <tr>
                          <th style={{ width: "43%" }}>
                            Referring Page Title / Referring Page URL
                          </th>
                          <th style={{ width: "43%" }}>
                            Anchor Text / Link URL
                          </th>
                          <th className="text-end" style={{ width: "14%" }}>
                            Type
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {dashData?.back_linkData?.slice(0,7).map((item,index)=>{
                        return<tr key={index}>
                          <td>
                            <p>{item.title}</p>
                            <a
                              href={item.url}
                              target="_blank"
                            >
                              {" "}
                              {item.url}{" "}
                            </a>
                          </td>
                          <td>
                            <p>{item.anchorText}</p>
                            <a href={item.linkUrl} target="_blank">
                              {item.linkUrl}
                            </a>
                          </td>
                          <td className="text-end">
                            <span className={`badge ${item.type == 'No Follow' ? "" : "bg-warning"} border-warning fs-11`}>
                              {item.type}
                            </span>
                          </td>
                        </tr>})}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              {dashData?.back_link?.map((item,index)=>{
              return <div key={index} className={`card-03 ${item.title == "No. of Follow Backlinks" ? "bg-gradient-burning": "bg-gradient-green"} mb-2`}>
                <div className="d-flex align-items-center">
                  <i className="ri-dashboard-line"></i>
                  <div className="ms-auto text-end color-w f-15">
                    {item.title}
                    <span className="f-15">{item.rank}</span>
                  </div>
                </div>
                <div id="chart7"></div>
              </div>})}
              <div className="card">
                <div className="card-body p-0">
                  <h4>Backlink Types</h4>
                  <div className="p-2 pt-0">
                    {dashData?.back_link_types?.map((item,index)=>{
                    return<div className="progress-wrapper mb-1" key={index}>
                      <div className="d-flex align-items-end">
                        <div className="text-secondary">{item.title}</div>
                        <div className="text-success ms-auto">{item.percent}</div>
                      </div>
                      <div className="progress" style={{ height: "6px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: item.percent, background: item.title == 'Text' && '#623aa2'
                          || item.title == 'Image' && '#f1388b' || item.title == 'Form' && '#ff9f40' 
                          || item.title == 'Frame' && '#0d6efd' }}
                        ></div>
                      </div>
                    </div>})}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dash_5;
