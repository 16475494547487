import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Slide from 'react-reveal/Slide'
import { ToastContainer, toast } from "react-toastify";

const ReviewModal = ({ setShow, setSucess, setError,getData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const slugValue = location.pathname.replace("/review/", "");
  const token = localStorage.getItem("checkbrand-token");
  const [field, setField] = useState({
    rating: "",
    review: "",
    slug: slugValue,
  });

  const postReview = () => {
    if (token) {
      postData();
    } else {
      navigate("/login");
    }
  };
  const postData = () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let formData = new FormData();
    formData.append("rating", field.rating);
    formData.append("review", field.review);
    formData.append("slug", field.slug);
    axios
      .post(
        `https://api.frontend.checkbrand.online/public/api/v1/review`,
        formData,
        config
      )
      .then((res) => {
        console.log("Review Posted Succesfully", res.data.validation);
        setSucess(res.data.validation);
        setShow(false);
        if(res.data.validation === true){
        toast("Review Posted sucessfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getData();
      }
      })
      .catch((error) => {
        console.log("Error occured", error);
        setError(error);
        setShow(false);
        toast("Error Posting Review");
      });
  };
  return (
    <>
    
      <div
        className="modal fade right reviewsModalPanel show"
        id="reviewsModal"
        tabIndex="-1"
        aria-labelledby="reviewsModalLabel"
        style={{ display: "block" }}
        aria-modal="true"
        role="dialog"
        // onClick={()=>setShow(false)}
      ><Slide right>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShow(false)}
              ></button>

              <div className="userReviewsArea">
                <div className="d-flex align-items-center">
                  <img src="images/img-1.jpg" />
                  <div className="ProfileName">
                    <h6>Virat Kohli</h6>
                    <h3 className="mb-2 mt-1">Politicians</h3>
                  </div>
                </div>
              </div>

              <div className="p-3 userRatingArea">
                <h2>YOUR RATING </h2>
                <div className="p-3">
                  <div className="">
                  <Rating className="star_label"
                    sx={{
                      "& .MuiRating-iconFilled": {
                        color: "#faaf00"
                      },
                      "& .MuiRating-iconHover": {
                        color: "#faaf00"
                      }
                    }}
                    name="read-only"
                    size="large"
                    // sx={{
                    //   color: "gold !important",
                    //   fontSize: "x-large !important",
                    //   marginLeft: "-10px !important",
                    // }}
                    value={field.rating}
                    onChange={(event, newValue) => {
                      setField({ ...field, ["rating"]: newValue });
                    }}
                  />
                  </div>
                </div>

                <h2>YOUR REVIEW</h2>
                <div className="p-3">
                  <div className="form-group">
                    {/* <input
                      type="text"
                      id="bit"
                      placeholder="Write a headline for your review here"
                    /> */}
                  </div>
                  <aside className="character-count pb-1">
                    Required characters:<span>250</span>
                  </aside>
                  <div className="form-group">
                    <textarea
                      placeholder="Write your review here"
                      maxlength="10000"
                      rows="6"
                      className=""
                      required=""
                      value={field.review}
                      onChange={(e) =>
                        setField({ ...field, ["review"]: e.target.value })
                      }
                    ></textarea>
                  </div>

                  {/* <div className="form-group">
                    <p className="p-0 mb-1">Does this review contain spoilers?</p>
                    <div className="d-flex align-items-center">
                      <input
                        className="w-auto mr-1 mr-1"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label className="m-0 p-0 mr-2">Yes</label>
                      <input
                        type="checkbox"
                        className="w-auto mr-1 mr-1"
                        id="vehicle2"
                        name="vehicle2"
                        value="Car"
                      />
                      <label className="m-0 p-0">No</label>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="modal-footer p-1 text-left">
              <button
                type="button"
                className="submitBtn"
                onClick={postReview}
                style={{
                  background:
                    field.review?.length >= 250 ? "#fc9a3a" : "darkgray",
                    pointerEvents:field.review?.length >= 250 ? "" :"none"
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        </Slide>
      </div>
      
    </>
  );
};

export default ReviewModal;
