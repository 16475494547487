import React from 'react'
import { Skeleton } from '@mui/material'
const Top100_Skeleton = () => {
  return <>
  <div className="top-seller-style-five">
      <div className="d-flex align-items-center gap-4">
        <div className="items-number d-flex-center flex-column position-relative lineRt h-Area w-10">
          <Skeleton variant="text" width={80} height={30} animation="wave" />
          <Skeleton variant="text" width={60} height={20} animation="wave" />
          <div style={{display:'flex',gap:'10px'}}>
          <div className="IcolorBox mt-2" style={{display:'inline-flex'}}>
            <Skeleton variant="circle" width={16} height={16} animation="wave" />{" "}
            {/* <Skeleton variant="text" width={20} height={20} animation="wave" />{" "} */}
            
          </div>
          <div className="IcolorBox mt-2" style={{display:'inline-flex'}}>
          <Skeleton variant="circle" width={16} height={16} animation="wave" />{" "}
            {/* <Skeleton variant="text" width={20} height={20} animation="wave" />{" "} */}
            </div>
            </div>
        </div>
        <div className="thumb-wrapper flex-column position-relative lineRt h-Area w-11">
          <Skeleton variant="rectangular" width={50} height={50} animation="wave" />
        </div>
        <div className="content lineRt position-relative h-Area w-12">
          <Skeleton variant="text" width={150} height={20} animation="wave" />
          <div className="d-flex gap-3">
            <Skeleton variant="text" width={60} height={20} animation="wave" />
            <Skeleton variant="text" width={100} height={20} animation="wave" />
          </div>
        </div>
        <div className="content flex-column text-center lineRt position-relative h-Area w-13">
          <Skeleton variant="text" width={70} height={30} animation="wave" />
          <Skeleton variant="text" width={60} height={20} animation="wave" />
        </div>
        <div className="content flex-column text-center lineRt position-relative h-Area w-14">
          <Skeleton variant="text" width={70} height={30} animation="wave" />
          <Skeleton variant="text" width={60} height={20} animation="wave" />
        </div>
        <div className="content flex-column text-center lineRt position-relative h-Area w-15">
          <Skeleton variant="text" width={70} height={30} animation="wave" />
          <Skeleton variant="text" width={60} height={20} animation="wave" />
        </div>
        <div className="content flex-column text-center lineRt position-relative h-Area w-16">
          <Skeleton variant="text" width={70} height={30} animation="wave" />
          <Skeleton variant="text" width={60} height={20} animation="wave" />
        </div>
        <div className="content flex-column text-center position-relative lineRt h-Area w-17">
          <Skeleton variant="text" width={40} height={30} animation="wave" />
          <Skeleton variant="text" width={80} height={20} animation="wave" />
        </div>
        {/* <ul className="social" style={{gap:'10px'}}>
          <li>
            <Skeleton variant="circle" width={10} height={10} animation="wave" />
          </li>
          <li>
            <Skeleton variant="circle" width={10} height={10} animation="wave" />
          </li>
          <li>
            <Skeleton variant="circle" width={10} height={10} animation="wave" />
          </li>
        </ul> */}
      </div>
    </div>
  </>
}

export default Top100_Skeleton
