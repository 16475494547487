import React, { useEffect, useState } from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Header from "../../common/Header";
import Section5 from "./Section5";
import Footer from "../../common/Footer";
import Section6 from "./Section6";
import LeftMenu from "./LeftMenu";
import About from "./About";
import { useLocation, useNavigate } from "react-router-dom";
import { reqData } from "../../common/api";
import Loader from "../../common/Loader";
import Preloader from "../../common/Preloader";
import Compared_Ranking from "./Compared/Compared_Ranking";

const Main = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [loadingScreen,setLoadingScreen]=useState();
  const location = useLocation();
  const [compareData, setCompareData] = useState(data?.response);
  const [allData, setAllData] = useState([compareData]);
  const dynamicRoute = location.pathname.replace("/dashboard", "");
  const brand_data = `${process.env.REACT_APP_BRAND_DATA}${dynamicRoute}`;
  function getData() {
    reqData(brand_data, setData, "", "", setLoading);
  }
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [location.pathname]);
  useEffect(() => {
    if (data) {
      setCompareData(data.response);
      setAllData([compareData]);
    }
  }, [data]);
  const bg_color="#ffffff"
  return (
    <>
      {loading  ? (
        <Loader bgColor={bg_color} />
      ) : (
        <>
          <Header />
          <LeftMenu dash_data={data} pathname={location.pathname} />
          <Section1
            dash_data={data}
            pathname={location.pathname}
            id={data?.response?.brand_user_type_id}
            allData={allData}
            setAllData={setAllData}
            compareData={compareData}
            setCompareData={setCompareData}
            setLoadingScreen={setLoadingScreen}
          />
          {allData?.length > 1 ? (
            <Compared_Ranking allData={allData} dash_data={data} setLoadingScreen={setLoadingScreen} loadingScreen={loadingScreen} />
          ) : ( <>
            <Section2
              dash_data={data}
              pathname={location.pathname}
              allData={allData}
              setAllData={setAllData}
              compareData={compareData}
              setCompareData={setCompareData}
            />
            <About dash_data={data} pathname={location.pathname} />
            </>
          )}
          <Section5 dash_data={data} pathname={location.pathname} />
          <Section6 dash_data={data} pathname={location.pathname} />
          <Footer />
          </>
      )}
    </>
  );
};

export default Main;
