import React from 'react';
import FaqTab from '../../../common/FaqTab';
import Common_Tab from '../../../common/Common_Tab';

const Integration_Services = ({activeTab,dashData,loading}) => {
  const questions = [
    {
      id: 1,
      title:
        "Can users access personalized accounts to avail government services? ",
      description: "",
      ariaControls: "first",
    },
    {
      id: 2,
      title:
        "Are there end-to-end online services for tasks like applying for permits, benefits etc? ",
      description: "",
      ariaControls: "second",
    },
    {
      id: 3,
      title:
        "Can documents be downloaded in different formats like PDF, Excel etc?",
      description: "",
      ariaControls: "third",
    },
    {
      id: 4,
      title:
        "Does the website host an online grievance handling system? ",
      description: "",
      ariaControls: "fourth",
    },
    {
      id: 5,
      title:
        "Are third-party services, APIs or integrations used on the website?",
      description: "",
      ariaControls: "fifth",
    },
    {
      id: 6,
      title:
        "Does the website comply with government web policies?",
      description: "",
      ariaControls: "sixth",
    },
    {
      id: 7,
      title:
        "How frequently are security patches and updates installed (year+/ half yearly/quarterly/monthly)",
      description: "",
      ariaControls: "seventh",
    },
  ];
  return <>
  <FaqTab activeTab={activeTab} dashData={dashData} questions={questions} />
  <Common_Tab  dashData={dashData} loading={loading}/>
  </>
}

export default Integration_Services
