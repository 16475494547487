import React from 'react';
import FaqTab from '../../../common/FaqTab';
import Common_Tab from '../../../common/Common_Tab';

const Accessiblity = ({activeTab,dashData,loading}) => {
  const questions = [
    {
      id: 1,
      title:
        "All functionality of the content is operable through a keyboard interface without requiring specific timings for individual keystrokes, except where the underlying function requires input that depends on the path of the user’s movement and not just the endpoints.",
      description: "",
      ariaControls: "first",
    },
    {
      id: 2,
      title:
        "Any keyboard operable user interface has a mode of operation where the keyboard focus indicator is visible",
      description: "",
      ariaControls: "second",
    },
    {
      id: 3,
      title:
        "Content does not restrict its view and operation to a single display orientation, such as portrait or landscape, unless a specific display orientation is essential",
      description: "",
      ariaControls: "third",
    },
    {
      id: 4,
      title:
        "Except for captions and images of text, text can be resized without assistive technology up to 200 percent without loss of content or functionality",
      description: "",
      ariaControls: "fourth",
    },
    {
      id: 5,
      title:
        "All non-text content that is presented to the user has a text alternative that serves the equivalent purpose",
      description: "",
      ariaControls: "fifth",
    },
    {
      id: 6,
      title:
        "Headings and labels describe topic or purpose",
      description: "",
      ariaControls: "sixth",
    },
    {
      id: 7,
      title:
        "Web pages/app screens have titles that describe topic or purpose",
      description: "",
      ariaControls: "seventh",
    },
    {
      id: 8,
      title:
        "If keyboard focus can be moved to a component of the page using a keyboard interface, then focus can be moved away from that component using only a keyboard interface and, if it requires more than unmodified arrow or tab keys or other standard exit methods, the user is advised of the method for moving focus away",
      description: "",
      ariaControls: "eight",
    },
    {
      id: 9,
      title:
        "The default human language of each Web page can be programmatically determined",
      description: "",
      ariaControls: "ninth",
    },
    {
      id: 10,
      title:
        "In content implemented using markup languages, elements have complete start and end tags, elements are nested according to their specifications, elements do not contain duplicate attributes, and any IDs are unique, except where the specifications allow these features",
      description: "",
      ariaControls: "tenth",
    },
    {
      id: 11,
      title:
        "For all user interface components (including but not limited to form elements, links and components generated by scripts), the name and role can be programmatically determined; states, properties, and values that can be set by the user can be programmatically set; and notification of changes to these items is available to user agents, including assistive technologies",
      description: "",
      ariaControls: "eleven",
    },
    {
      id: 12,
      title:
        "In content implemented using markup languages, status messages can be programmatically determined through role or properties such that they can be presented to the user by assistive technologies without receiving focus",
      description: "",
      ariaControls: "tweleve",
    },
    {
      id: 13,
      title:
        "Content can be presented without loss of information or functionality, and without requiring scrolling in two dimensions",
      description: "",
      ariaControls: "thirteen",
    },
    {
      id: 14,
      title:
        "Information, structure, and relationships conveyed through presentation can be programmatically determined or are available in text",
      description: "",
      ariaControls: "fourteen",
    },
    {
      id: 15,
      title:
        "Where receiving and then removing pointer hover or keyboard focus triggers additional content to become visible and then hidden",
      description: "",
      ariaControls: "fifteen",
    },
    {
      id: 16,
      title:
        "Colour is not used as the only visual means of conveying information, indicating an action, prompting a response, or distinguishing a visual element",
      description: "",
      ariaControls: "sixteen",
    },
    {
      id: 17,
      title:
        "A mechanism is available to bypass blocks of content that are repeated on multiple Web pages",
      description: "",
      ariaControls: "seventeen",
    },
    {
      id: 18,
      title:
        "When the sequence in which content is presented affects its meaning, a correct reading sequence can be programmatically determined",
      description: "",
      ariaControls: "eighteen",
    },
    {
      id: 19,
      title:
        "Instructions provided for understanding and operating content do not rely solely on sensory characteristics of components such as shape, colour, size, visual location, orientation, or sound",
      description: "",
      ariaControls: "nineteen",
    },
    {
      id: 20,
      title:
        "The purpose of each input field collecting information about the user can be programmatically determined ",
      description: "",
      ariaControls: "twenty",
    },
    {
      id: 21,
      title:
        "The visual presentation of text and images of text has a contrast ratio of at least 4.5:1, except for Large Text, Incidental, Logotypes Objects",
      description: "",
      ariaControls: "twentyOne",
    },
    {
      id: 22,
      title:
        "If a Web page can be navigated sequentially and the navigation sequences affect meaning or operation, focusable components receive focus in an order that preserves meaning and operability",
      description: "",
      ariaControls: "twentytwo",
    },
    {
      id: 23,
      title:
        "The purpose of each link can be determined from the link text alone or from the link text together with its programmatically determined link context, except where the purpose of the link would be ambiguous to users in general",
      description: "",
      ariaControls: "twentyThree",
    },
    {
      id: 24,
      title:
        "If a Web page can be navigated sequentially and the navigation sequences affect meaning or operation, focusable components receive focus in an order that preserves meaning and operability",
      description: "",
      ariaControls: "twentyfour",
    },
    {
      id: 25,
      title:
        "The purpose of each link can be determined from the link text alone or from the link text together with its programmatically determined link context, except where the purpose of the link would be ambiguous to users in general",
      description: "",
      ariaControls: "twentyFive",
    },
    {
      id: 26,
      title:
        "More than one way is available to locate a Web page within a set of Web pages except where the Web Page is the result of, or a step in, a process",
      description: "",
      ariaControls: "twentySix",
    },
    {
      id: 27,
      title:
        "All functionality that uses multipoint or path-based gestures for operation can be operated with a single pointer without a path-based gesture, unless a multipoint or path-based gesture is essential",
      description: "",
      ariaControls: "twentySeven",
    },
    {
      id: 28,
      title:
        "For functionality that can be operated using a single pointer, at least one of the following is true: No Down Event, Abort or Undo, Up Reversal, Essential",
      description: "",
      ariaControls: "twentyEight",
    },
  ];
  return <>
  <FaqTab activeTab={activeTab} dashData={dashData} questions={questions} />
  <Common_Tab dashData={dashData} loading={loading} />
  </>
}

export default Accessiblity
