import React from 'react'

const Types_Graph = (active) => {
  return <>
  <div
                className={`tab-pane active`}
                id="Types-home"
                role="tabpanel"
                aria-labelledby="Types-home-tab"
              >
                <div className="generalstats-wrap">
                  <ul className="generalstats">
                    <li className="active">
                      <span className="current">Video 347</span>
                      <span className="general">
                        <span className="total" id="allpositive2">
                          (17)% of total)
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="current">Photo 1130 </span>
                      <span className="general">
                        <span className="total" id="allnegative2">
                          (56)% of total)
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="current">Link 388 </span>
                      <span className="general">
                        <span className="total" id="allneutral2">
                          (19)% of total)
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="current">Status 170</span>
                      <span className="general">
                        <span className="total" id="allneutral2">
                          (8)% of total)
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  className="analytics-wrap mt-4"
                  style={{ display: "none" }}
                >
                  <div className="row">
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  web-bgcolor">
                        <h2>
                          <i className="fas fa-globe"></i> Web
                        </h2>
                        <div className="chartAnalyticsArea">
                          <div
                            id="webTypesChart"
                            style={{ height: "230px", width: "230px" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  f-bgcolor">
                        <h2>
                          <i className="fab fa-facebook-f"></i> Facebook
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  t-bgcolor">
                        <h2>
                          <i className="fab fa-twitter"></i> Twitter
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  instagram-bgcolor">
                        <h2>
                          <i className="fab fa-instagram"></i> Instagram
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  youtube-bgcolor">
                        <h2>
                          <i className="fab fa-instagram"></i> Youtube
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  tumblr-bgcolor">
                        <h2>
                          <i className="fab fa-tumblr"></i> Tumblr
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  reddit-bgcolor">
                        <h2>
                          <i className="fab fa-reddit"></i> Reddit
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  flickr-bgcolor">
                        <h2>
                          <i className="fab fa-flickr"></i> Flickr
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  reddit-dailymotion">
                        <h2>
                          <i className="fab fa-dailymotion"></i> Dailymotion
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  vkontakte-bgcolor">
                        <h2>
                          <i className="fab fa-vkontakte"></i> Vkontakte
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-user line-height16"></i>
                        </div>
                        <div className="chart-title-text">Popular Videos</div>
                      </div>
                      <div id="hashtags">
                        <table
                          width="100%"
                          className="newsTableBox border-none"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr className="odd">
                              <td>1</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/metesohtaoglu/status/1553501001449177089"
                                >
                                  📹 Başbakan Virat kohli'n ...
                                </a>
                              </td>
                              <td>10</td>
                            </tr>
                            <tr className="odd">
                              <td>2</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/metesohtaoglu/status/1553500800336510976"
                                >
                                  📹 PM Virat kohli's #India ...
                                </a>
                              </td>
                              <td>7</td>
                            </tr>
                            <tr className="odd">
                              <td>3</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/GeneralBakshi/status/1553455485701222401"
                                >
                                  A Message From A 93 Year Old ...
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>4</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/sanjayfadnavis1/status/1553439988750381056"
                                >
                                  विकासपुरुष ...
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>5</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/airnewsalerts/status/1553422417720123393"
                                >
                                  #EveningNews Prime Minister ...
                                </a>
                              </td>
                              <td>0</td>
                            </tr>
                            <tr className="odd">
                              <td>6</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crnht"
                                >
                                  समुद्र के प� ...
                                </a>
                              </td>
                              <td>33580</td>
                            </tr>
                            <tr className="odd">
                              <td>7</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crl9g"
                                >
                                  Kaushal Raj Sharma: पीए� ...
                                </a>
                              </td>
                              <td>12980</td>
                            </tr>
                            <tr className="odd">
                              <td>8</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crjps"
                                >
                                  Chess Olympiad 2022 | வே� ...
                                </a>
                              </td>
                              <td>7559</td>
                            </tr>
                            <tr className="odd">
                              <td>9</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crho9"
                                >
                                  வெல்கம் டூ � ...
                                </a>
                              </td>
                              <td>6675</td>
                            </tr>
                            <tr className="odd">
                              <td>10</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crhi9"
                                >
                                  பாஜக கூட்ட� ...
                                </a>
                              </td>
                              <td>6120</td>
                            </tr>
                            <tr className="odd">
                              <td>11</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/r/Documentaries/comments/wc2qx9/final_solution_2004_how_indian_prime_minister/"
                                >
                                  Final Solution (2004) - How In ...
                                </a>
                              </td>
                              <td>5</td>
                            </tr>
                            <tr className="odd">
                              <td>12</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/Cgo8w5HuDbj"
                                >
                                  India's Lost Cultural Heritage ...
                                </a>
                              </td>
                              <td>1869</td>
                            </tr>
                            <tr className="odd">
                              <td>13</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/Cgo3Q4iIRXI"
                                >
                                  [ ❤️✨ KAUN TUJHE YOON PY ...
                                </a>
                              </td>
                              <td>352</td>
                            </tr>
                            <tr className="odd">
                              <td>14</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpelExA5Ga"
                                >
                                  🚩 धन्य है व� ...
                                </a>
                              </td>
                              <td>132</td>
                            </tr>
                            <tr className="odd">
                              <td>15</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/Cgo4lnPLwN2"
                                >
                                  ⚔️🪖Gardwal Rifle ⚔️ ...
                                </a>
                              </td>
                              <td>67</td>
                            </tr>
                            <tr className="odd">
                              <td>16</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpDw4hjJ_a"
                                >
                                  देखिए, प्रध� ...
                                </a>
                              </td>
                              <td>65</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-user line-height16"></i>
                        </div>
                        <div className="chart-title-text">Popular Photos</div>
                      </div>
                      <div id="hashtags1">
                        <table
                          width="100%"
                          className="newsTableBox border-none"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr className="odd">
                              <td>1</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/AgnishwarJ/status/1553382717169057794"
                                >
                                  First Ever Agricultu ...
                                </a>
                              </td>
                              <td>3</td>
                            </tr>
                            <tr className="odd">
                              <td>2</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/hardoi_bjp/status/1553399187454447616"
                                >
                                  प्रधान� ...
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>3</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/yogina_na/status/1553467150186201090"
                                >
                                  12) Idriss Vante La ...
                                </a>
                              </td>
                              <td>1</td>
                            </tr>
                            <tr className="odd">
                              <td>4</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/jaiprakashshah2/status/1553463106902249473"
                                >
                                  Anna University Conv ...
                                </a>
                              </td>
                              <td>1</td>
                            </tr>
                            <tr className="odd">
                              <td>5</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/sasiperumpadap3/status/1553459271731331072"
                                >
                                  PM Launches Power Se ...
                                </a>
                              </td>
                              <td>1</td>
                            </tr>
                            <tr className="odd">
                              <td>6</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpIwGEpys8"
                                >
                                  PM Modi Called This ...
                                </a>
                              </td>
                              <td>1549</td>
                            </tr>
                            <tr className="odd">
                              <td>7</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/Cgo92w6vmn0"
                                >
                                  ಜೈ ಶ್ರೀ ...
                                </a>
                              </td>
                              <td>1080</td>
                            </tr>
                            <tr className="odd">
                              <td>8</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpWgkzvjtJ"
                                >
                                  તળાજા ત� ...
                                </a>
                              </td>
                              <td>999</td>
                            </tr>
                            <tr className="odd">
                              <td>9</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpDtKUhxaR"
                                >
                                  સરકારી � ...
                                </a>
                              </td>
                              <td>945</td>
                            </tr>
                            <tr className="odd">
                              <td>10</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/Cgo3E4GhVEp"
                                >
                                  PSI Result 🎯 પ ...
                                </a>
                              </td>
                              <td>826</td>
                            </tr>
                            <tr className="odd">
                              <td>11</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/id266438029?w=wall266438029_14884"
                                >
                                  La Fin De L'hégémo ...
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-user line-height16"></i>
                        </div>
                        <div className="chart-title-text">Popular Links</div>
                      </div>
                      <div id="hashtags1">
                        <table
                          width="100%"
                          className="newsTableBox border-none"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr className="odd">
                              <td>1</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/markets/status/1553454060438708226"
                                >
                                  Unpaid Bills In Indi ...
                                </a>
                              </td>
                              <td>4</td>
                            </tr>
                            <tr className="odd">
                              <td>2</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/Aamir_dangal/status/1553408563317731328"
                                >
                                  #ISupportLaalSinghCh ...
                                </a>
                              </td>
                              <td>3</td>
                            </tr>
                            <tr className="odd">
                              <td>3</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/ashleyforsoil/status/1553382319427588097"
                                >
                                  @SaveSoilAcharya @el ...
                                </a>
                              </td>
                              <td>3</td>
                            </tr>
                            <tr className="odd">
                              <td>4</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/EconomicTimes/status/1553417637190701056"
                                >
                                  Ease Of Justice Was ...
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>5</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/HindutvaWatchIn/status/1553461927971635201"
                                >
                                  Now It Is Official T ...
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>6</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/r/HydrogenSocieties/comments/wbvtrb/india_prime_minister_narendra_modi_urges_states/"
                                >
                                  India: Prime Ministe ...
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>7</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/r/TIMESINDIAauto/comments/wbqmhu/in_speed_up_release_of_undertrial_prisoners_pm/"
                                >
                                  [IN] - Speed Up Rele ...
                                </a>
                              </td>
                              <td>1</td>
                            </tr>
                            <tr className="odd">
                              <td>8</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/r/AutoNewspaper/comments/wbqrr1/in_speed_up_release_of_undertrial_prisoners_pm/"
                                >
                                  [IN] - Speed Up Rele ...
                                </a>
                              </td>
                              <td>1</td>
                            </tr>
                            <tr className="odd">
                              <td>9</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/id531181670?w=wall531181670_204378"
                                >
                                  Teil II Zudem Ist E ...
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>10</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/club210926009?w=wall-210926009_6955"
                                >
                                  247 News Around The ...
                                </a>
                              </td>
                              <td>0</td>
                            </tr>
                            <tr className="odd">
                              <td>11</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/news8plus?w=wall-211824226_6736"
                                >
                                  India’s Star Femin ...
                                </a>
                              </td>
                              <td>0</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  </>
}

export default Types_Graph
