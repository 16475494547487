import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import Top100_Child from "./Top100_Child";
import { reqData } from "../../common/api";
import Top100_Skeleton from "./Top100_Skeleton";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";

const Top100 = () => {
  const location=useLocation()
  const top100brands=process.env.REACT_APP_TOP_100_BRANDS
  const [data,setData]=useState();
  const [filteredData,setFilteredData]=useState();
  const [loading,setLoading]=useState();
  const [categoryId,setCategoryId]=useState([]);
  const skeletonDummy=[1,2,3,4,5,6,7,8,9,10]
  function getData(){
    reqData(top100brands,setData,"top100","",setLoading)
  }

  useEffect(()=>{
    getData();
  },[])
  const [categoryData,setCategoryData]=useState();
  const [categoryloading,setCategoryLoading]=useState();
  const categoryApi=process.env.REACT_APP_CATEGORIES;

  const getCategoryData=()=>{
    reqData(categoryApi,setCategoryData,"category","",setCategoryLoading,"")
  }

  useEffect(()=>{
    getCategoryData();
  },[]);

  useEffect(()=>{
    if(data?.top100brands){
      setFilteredData(data.top100brands)
    }
  },[data])

  const addId = (id) => {
    if (categoryId.includes(id)) {
      const newData = categoryId.filter((item, i) => item !== id);
      setCategoryId(newData);
    } else {
      setCategoryId([...categoryId, id]);
    }
  };

  const addFilter=(id)=>{
    const newData=data?.top100brands.filter((item)=>{
      return item.brand_type_id === id
    })
    setFilteredData(newData)
  }
  useEffect(()=>{
    if(categoryId.length == 0){
     setFilteredData(data?.top100brands)
    }
    else {
      const filteration = data?.top100brands?.filter((item)=>{
        return categoryId.includes(item.brand_type_id)
      })
      setFilteredData(filteration)
    }
    
  },[categoryId])

  const dummyCategory=[1,2,3,4,5]

  console.log("filteredData length is",filteredData?.length)
  console.log("Length of category id is",categoryId.length)
  return (
    <>
      <Header />
      <section className="innerHeader">
        <h2>TOP 100 Brands</h2>
        <span>Digital | Social | Online | Influencers</span>
      </section>
      <section className="pb-10 live-auction pt-8">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="filter-wrapper">
                <div className="filter-custom-item">
                  <h4
                    className="top100_accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#categories"
                    style={{fontWeight:400}}
                  >
                    Categories
                  </h4>
                  <div id="categories" className="collapse show">
                    <div className="filter-item-list">
                      {categoryloading ?  dummyCategory?.map((item,index)=>{
                     return <Skeleton className="form-check" key={index} animation="wave">
                        <Skeleton
                          className="form-check-input" animation="wave"
                        />
                        <Skeleton className="form-check-label" width={100} height={40} style={{marginLeft:'40px'}} animation="wave" />
                      </Skeleton>}) : 
                      categoryData?.categories?.map((item,index)=>{
                     return <div className="form-check" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={item.name}
                          onChange={()=>addId(item.id)}
                        />
                        <label className="form-check-label" htmlFor={item.name}>
                          {item.name}
                        </label>
                      </div>})}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9">
              <div className="author-profile-wrapper">
                <div className="tabStPanel mb-7">
                  <div className="d-flex">
                    <div className="col-6 col-lg-auto">
                    </div>
                    <div className="col">
                      <ul className="nav tabsLink-2 justify-content-lg-left p-0 d-flex" >
                        <li >
                          <a
                            className="active btn btn-small btn-outline-1"
                            data-bs-toggle="tab"
                            href="#on-sale"
                          >
                            Top 100
                          </a>
                        </li>
                        {/* <li>
                          <a
                            className="btn btn-small btn-outline-1"
                            data-bs-toggle="tab"
                            href="#owned"
                          >
                          
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn btn-small btn-outline-1"
                            data-bs-toggle="tab"
                            href="#created"
                          >
                            
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn btn-small btn-outline-1"
                            data-bs-toggle="tab"
                            href="#liked"
                          >
                            
                          </a>
                        </li> */}
                      </ul>
                    </div>
                    <div className="col-6 col-lg-auto">
                      <div className="dropdown">
                        <button
                          className="btn btn-border-mode dropdown-toggle rounded-pill btn-outline-1 btn-small"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Filter
                        </button>
                        <ul className="dropdown-menu">
                          {categoryData?.categories?.map((item,index)=>{
                         return <li key={index} onClick={()=>addFilter(item.id)}>
                            <a className="dropdown-item" href={`${location.pathname}#`}>
                              {item.name}
                            </a>
                          </li>})}
                          <li onClick={()=>setFilteredData(data?.top100brands)}>
                            <a className="dropdown-item" href={`${location.pathname}#`}>
                              Show All
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-content author-tabs-content">
                  <div id="on-sale" className="tab-pane fade show active">
                    {loading == false ? 
                      filteredData?.map((item,index)=>{
                      return <>
                      <Top100_Child key={index} item={item} index={index} data={data} /> 
                      {categoryId.length>0 && filteredData?.length === 0 && "No Data found"}
                      </> 
                       
                      }):
                    skeletonDummy.map((item,index)=>{
                    return <Top100_Skeleton key={index} />
                    })
                    }
                  </div>
                  <div id="owned" className="tab-pane fade">
                    {" "}
                    2{" "}
                  </div>
                  <div id="created" className="tab-pane fade">
                    {" "}
                    3{/* <!-- End .row --> */}
                  </div>
                  {/* <!-- End created product --> */}
                  <div id="liked" className="tab-pane fade">
                    {" "}
                    4{/* <!-- End .row --> */}
                  </div>
                  {/* <!-- End liked product --> */}
                </div>
              </div>
            </div>
          </div>
          </div>
      </section>
    </>
  );
};

export default Top100;
