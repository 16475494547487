import React from "react";
import FaqTab from '../../../common/FaqTab';
import Common_Tab from '../../../common/Common_Tab';

const Security_Privacy = ({ activeTab, dashData,loading }) => {
  const questions = [
    {
      id: 1,
      title:
        "Does the website use encryption (e.g., SSL/TLS) to secure sensitive information during transmission and access?",
      description: "",
      ariaControls: "first",
    },
    {
      id: 2,
      title:
        "Does the website provide a secure login process for authentication?",
      description: "",
      ariaControls: "second",
    },
    {
      id: 3,
      title:
        "Does the website provide a clear and easily accessible privacy policy for collecting user data and explains how the data is used?",
      description: "",
      ariaControls: "third",
    },
    {
      id: 4,
      title:
        "Is the website compliant with relevant data protection and privacy laws and regulations? ",
      description: "",
      ariaControls: "fourth",
    },
    {
      id: 5,
      title:
        "What is the current cybersecurity risk rating based on audits - Secuirty Aduit Certification",
      description: "",
      ariaControls: "fifth",
    },
    {
      id: 6,
      title:
        "Is there a comprehensive data protection and retention policy in place for the website's data?",
      description: "",
      ariaControls: "sixth",
    },
    {
      id: 7,
      title:
        "Is there a comprehensive logging and monitoring system implemented to track and detect any suspicious activities or security breaches?",
      description: "",
      ariaControls: "seventh",
    },
    {
      id: 8,
      title:
        "Is the website free from known security vulnerabilities or issues? ",
      description: "",
      ariaControls: "eight",
    },
  ];
  console.log("Web Site logo url is", dashData);
  return (
    <>
      <FaqTab activeTab={activeTab} dashData={dashData} questions={questions} />
      <Common_Tab dashData={dashData} loading={loading} />
    </>
  );
};

export default Security_Privacy;
