import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { reqData } from "../../common/api";

const Live_Child = ({ item, index}) => {
  const [show, setShow] = useState(false);
  const api = process.env.REACT_APP_CHART_DATA;
  const [chartData, setChartData] = useState();
  const [chartType, setChartType] = useState("");
  const [count, setCount] = useState([]);
  const [random,setRandom]=useState({
    follower_random:0,
    mention_random:0,
    reach_random:0,
    engagement_random:0
  })
  const [loading, setloading] = useState();
  const [type, setType] = useState("");
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        fill: true,
        borderColor: "rgba(252, 92, 101, 0.6)",
        backgroundColor: "rgba(252, 92, 101, 0.4)",
        pointBackgroundColor: "rgba(252, 92, 101, 0.6)",
        pointBorderColor: "rgba(252, 92, 101, 0.6)",
        pointRadius: 6,
        pointHoverRadius: 8,
      },
    ],
  });

  function getData(slug) {
    const chartApi = `${api}${slug}`;
    reqData(chartApi, setChartData, "", "", setloading);
  }
  const showFollowerChart = (type, slug, type_chart) => {
    setType(type);
    setShow(true);
    setChartType(type_chart);
    getData(slug);
  };

  useEffect(() => {
    if (chartData) {
      setData({
        labels: chartData && chartData[chartType].map((item) => item.date),
        datasets: [
          {
            label: "",
            data: chartData && chartData[chartType].map((item) => item.count),
            fill: true,
            borderColor: "rgba(252, 92, 101, 0.6)",
            backgroundColor: "rgba(252, 92, 101, 0.4)",
            pointBackgroundColor: "rgba(252, 92, 101, 0.6)",
            pointBorderColor: "rgba(252, 92, 101, 0.6)",
            pointRadius: 6,
            pointHoverRadius: 8,
          },
        ],
      });
      const countData = chartData[chartType].map((item) => item.count);
      setCount([countData]);
    }
  }, [chartData]);

  useEffect(() => {
    const interval = setInterval(() => {
      const min = -10000;
      const max = 10000;
        setRandom({
          follower_random:Math.floor(Math.random() *(max - min + 1)) + min,
          mention_random:Math.floor(Math.random() *(max - min + 1)) + min,
          reach_random:Math.floor(Math.random() *(1000 - (-1000) + 1)) + (-1000),
          engagement_random:Math.floor(Math.random() *(1000 - (-1000) + 1)) + (-1000),
        })
    }, 2000);
    return () => clearInterval(interval);
}, [count]);
  return (
    <>
      <div className="top-seller-style-five liveBoxPanel hoverEf" key={index}>
        <div className="d-flex align-items-top gap-0">
          <div className="items-number valueBoxIt pl-0 pt-2 pr-2 d-flex-center flex-column position-relative">
            <span className="cpName" style={{top:'-18px'}}>{item.name}</span>
            <strong>{item.rank}</strong>
            <div className="IcolorBox mt-1">
              <i className="ri-thumb-up-line"></i> {item.lc}{" "}
              <i className="ri-thumb-down-line ml-1"></i> {item.dlc}
            </div>
          </div>
          <div className="valueBoxIt pl-2 pr-2 pt-1 d-flex-center flex-column">
            <a href="#" className="thumb mb-1">
              <img
                src={"https://checkbrand.online/frontend/images/" + item.dp}
                alt="top sellter"
              />
            </a>
          </div>
          <div className="valueBoxIt col pl-2 pr-2">
            <span>
              <i className="ri-bookmark-line"></i> Value
            </span>
            <h6>{item.bv}</h6>
            <h4>({item.bvv})</h4>
          </div>
          <div className="valueBoxIt col-md-1 pl-2 pr-2">
            <span>
              <i className="ri-line-chart-line"></i> Score
            </span>
            <h6>{item.sc}</h6>
            {/* <h4>({item.bv})</h4> */}
          </div>
          <div className="valueBoxIt col pl-2 pr-5">
            <span>
              <i className="ri-line-chart-line"></i> Followers
            </span>
            <h6>{item.follower_flag + random.follower_random}</h6>
            <h4>({item.f})</h4>{" "}
            <div className={`IcolorBox ${random.follower_random > 0 ? "greenIcon" : "redIcon"}  d-flex`}>
              <i className={`ri-arrow-${random.follower_random > 0 ? "up" : "down"}-line`}></i> ({random.follower_random.toString().replace("-","")})
            </div>
            <button
              type="button"
              className="viewAllStBtn"
              onClick={() =>
                showFollowerChart(
                  "Followers Chart",
                  item.purl,
                  "followers_data"
                )
              }
            >
              <i className="ri-add-line"></i>
            </button>
          </div>

          <div className="valueBoxIt col-md-3 pl-2 pr-5">
            <span className="p-0">
              <i className="ri-line-chart-line"></i> Mentions
            </span>

            <div className="d-flex gap-2">
              <div className="col line-l">
                <span className="li-h pb-0 f-11">Today</span>
                <h6>{item.mentions_flag + random.mention_random}</h6>
                <h4>({item.m})</h4>{" "}
                <div className={`IcolorBox ${random.mention_random > 0 ? "greenIcon" : "redIcon"}  d-flex`}>
              <i className={`ri-arrow-${random.mention_random > 0 ? "up" : "down"}-line`}></i> ({random.mention_random.toString().replace("-","")})
            </div>
              </div>
              <div className="col">
                <span className="li-h pb-0 f-11">14 Days</span>
                <h6>{item.avg}</h6>
                <h4>({item.a})</h4>
              </div>
            </div>

            <button
              type="button"
              className="viewAllStBtn"
              onClick={() =>
                showFollowerChart("Mentions Chart", item.purl, "mentions_data")
              }
            >
              <i className="ri-add-line"></i>
            </button>
          </div>

          <div className="valueBoxIt col pl-2 pr-5">
            <span>
              <i className="ri-line-chart-line"></i> Reach
            </span>
            <h6>{+item.total_reach + random.reach_random}</h6>
            <h4>({item.r})</h4>{" "}
            <div className={`IcolorBox ${random.reach_random > 0 ? "greenIcon" : "redIcon"}  d-flex`}>
              <i className={`ri-arrow-${random.reach_random > 0 ? "up" : "down"}-line`}></i> ({random.reach_random.toString().replace("-","")})
            </div>
            <button
              type="button"
              className="viewAllStBtn"
              onClick={() =>
                showFollowerChart("Reach Chart", item.purl, "total_reach")
              }
            >
              <i className="ri-add-line"></i>
            </button>
          </div>

          <div className="valueBoxIt col pl-2 pr-1 b-0">
            <span>
              <i className="ri-line-chart-line"></i> Engagements
            </span>
            <h6>{item.eng_flag + random.engagement_random}</h6>
            <h4>({item.e})</h4>{" "}
            <div className={`IcolorBox ${random.engagement_random > 0 ? "greenIcon" : "redIcon"}  d-flex`}>
              <i className={`ri-arrow-${random.engagement_random > 0 ? "up" : "down"}-line`}></i> ({random.engagement_random.toString().replace("-","")})
            </div>
            <button
              type="button"
              className="viewAllStBtn mn-12"
              onClick={() =>
                showFollowerChart(
                  "Engagement Chart",
                  item.purl,
                  "total_engagements"
                )
              }
            >
              <i className="ri-add-line"></i>
            </button>
          </div>
          <Link to={`/dashboard/${item.purl}`} className="btnMoreDs">
            more »
          </Link>
        </div>
        {show && (
          <div className="ViewBoxArea-01">
            <h4>{type}</h4>
            <div style={{ height: "400px", padding: "10px" }}>
              <Line
                data={{
                  labels:
                    chartData && chartData[chartType].map((item) => item.date),
                  datasets: [
                    {
                      label: "",
                      data:
                        chartData &&
                        chartData[chartType].map((item) => item.count),
                      fill: true,
                      borderColor: "rgba(252, 92, 101, 0.6)",
                      backgroundColor: "rgba(252, 92, 101, 0.4)",
                      pointBackgroundColor: "rgba(252, 92, 101, 0.6)",
                      pointBorderColor: "rgba(252, 92, 101, 0.6)",
                      pointRadius: 6,
                      pointHoverRadius: 8,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false,
                      text: "Bar Chart",
                    },
                  },
                  scales: {
                    y: {
                      min: count && Math.min(count),
                      // max: count && (Math.max(count)+ Math.max(count)-Math.min(count)/2),
                      //   beginAtZero: true,
                      ticks: {
                        stepSize:
                          count && Math.max(count) - Math.min(count) / 2,
                      },
                    },
                  },
                }}
                width={400}
                height={200}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Live_Child;
