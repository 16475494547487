import React from "react";

const FaqTab = ({ activeTab, dashData ,questions}) => {
  return (
    <>
      <section className="ruralDevtSection_web pt-5 pb-5 mb-5">
        <div className="container">
          <div className="d-flex gap-3  align-items-center mb-4 headingArea_web">
            <div>
              <img src={dashData?.website_logo} />
            </div>
            <div>
              <h1>{activeTab}</h1>
            </div>
          </div>
          <div className="accordion faqSectionArea" id="regularAccordionRobots">
            {questions.map((item,index)=>{
           return <div className="accordion-item" key={index}>
              <h2 id={`regularHeading${item.ariaControls}`} className="accordion-header">
                <button
                  className={`accordion-button ${index !== 0 && "collapsed"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#regularCollapse${item.ariaControls}`}
                  aria-expanded="false"
                  aria-controls={`#regularCollapse${item.ariaControls}`}
                >
                  <span>Q{item.id}</span>
                  {item.title}
                </button>
              </h2>
              <div
                id={`regularCollapse${item.ariaControls}`}
                className={`accordion-collapse collapse ${index === 0 && "show"} `}
                aria-labelledby={`regularHeading${item.ariaControls}`}
                data-bs-parent="#regularAccordionRobots"
              >
                <div className="accordion-body" style={{fontSize:'13px'}}>
                  Overview of the basic fundamentals of robot kinesiology,
                  including rotational motion, laws of thermodynamics, space,
                  time, and momentum. Students will learn to analyze and explain
                  workings and gesticulations, identify and describe metals and
                  fluids at rest and in motion, and explain the impact that the
                  laws of gravity have on different forms of energy.
                </div>
              </div>
            </div>})}
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqTab;
