import React from "react";
import { PropagateLoader, PulseLoader } from "react-spinners";

const Loader = ({bgColor}) => {
  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: bgColor,
          position:'fixed',
          top:0,
          left:0,
          zIndex:9999999999999
        }}
      >
        <PropagateLoader color="#ff512f" />
      </div>
    </>
  );
};

export default Loader;
