import React from 'react'

const Sentiment_Graph = (active) => {
  return <>
  <div
                className={`tab-pane active`}
                id="Sentiment-home"
                role="tabpanel"
                aria-labelledby="Sentiment-home-tab"
              >
                <div className="generalstats-wrap">
                  <ul className="generalstats">
                    <li className="active">
                      <span className="current positiveText">Positive 535</span>
                      <span className="general">
                        <span className="total" id="allpositive2">
                          (26% of total)
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="current negativeText">Negative 299</span>
                      <span className="general">
                        <span className="total" id="allnegative2">
                          (15% of total)
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="current neutralText">Neutral 1201</span>
                      <span className="general">
                        <span className="total" id="allneutral2">
                          (59% of total)
                        </span>
                      </span>
                    </li>
                    <li style={{ display: "none" }}>
                      <span className="current" id="allratio">
                        Ratio 7:3
                        <div className="analytics-general-icon positive"></div>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="analytics-wrap mt-4">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  web-bgcolor">
                        <h2>
                          <i className="fas fa-globe"></i> Web
                        </h2>
                        <div className="chartAnalyticsArea">
                          <div
                            id="webAnalyticsChart"
                            style={{ height: "230px", width: "230px" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  f-bgcolor">
                        <h2>
                          <i className="fab fa-facebook-f"></i> Facebook
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          id="fbChartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  t-bgcolor">
                        <h2>
                          <i className="fab fa-twitter"></i> Twitter
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          id="twitterChartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  youtube-bgcolor">
                        <h2>
                          <i className="fab fa-instagram"></i> Youtube
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          id="youtubeChartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  tumblr-bgcolor">
                        <h2>
                          <i className="fab fa-tumblr"></i> Tumblr
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          id="tumblrChartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  reddit-bgcolor">
                        <h2>
                          <i className="fab fa-reddit"></i> Reddit
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          id="redditChartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  reddit-dailymotion">
                        <h2>
                          <i className="fab fa-dailymotion"></i> Dailymotion
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          id="dailymotionChartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="analyticsWrapBox  vkontakte-bgcolor">
                        <h2>
                          <i className="fab fa-vkontakte"></i> Vkontakte
                        </h2>
                        <div
                          className="chartAnalyticsArea"
                          id="vkontakteChartAnalyticsArea"
                          style={{ height: "230px", width: "230px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-user line-height16"></i>
                        </div>
                        <div className="chart-title-text">
                          Popular Positive Posts
                        </div>
                      </div>
                      <div id="hashtags">
                        <table
                          width="100%"
                          className="newsTableBox border-none"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr>
                              <td className="twitter-color chart-network-cell post-number-td">
                                <div className="twitter-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/metesohtaoglu/status/1553500800336510976"
                                >
                                  📹 PM Narendra Mod ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">7</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/ashleyforsoil/status/1553382319427588097"
                                >
                                  @SaveSoilAcharya @el ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">3</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/GeneralBakshi/status/1553455485701222401"
                                >
                                  A Message from a 93 ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/EconomicTimes/status/1553417637190701056"
                                >
                                  Ease of justice was ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/jaiprakashshah2/status/1553463106902249473"
                                >
                                  Anna University conv ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color chart-network-cell post-number-td">
                                <div className="dailymotion-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8croqr"
                                >
                                  CJI NV Ramana बो ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">33</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crmrt"
                                >
                                  Weightlifter Sanket ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">16</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crhw6"
                                >
                                  Headlines: Ola Rubbi ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">9</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crhuf"
                                >
                                  PM Modi attends firs ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">5</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crmv3"
                                >
                                  PM Modi reiterates f ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color chart-network-cell post-number-td">
                                <div className="reddit-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/r/Documentaries/comments/wc2qx9/final_solution_2004_how_indian_prime_minister/"
                                >
                                  Final Solution (2004 ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">5</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color chart-network-cell post-number-td">
                                <div className="instagram-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpAM-PvxXx"
                                >
                                  Scorpio -N ❤️❤ ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">556</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpYK_euOVk"
                                >
                                  #LinkInBio | They ar ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">369</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/Cgo5ctPPu5k"
                                >
                                  Basics of Indian inc ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">155</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpNk_QDNXp"
                                >
                                  It is a crime to ren ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">69</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/Cgo4lnPLwN2"
                                >
                                  ⚔️🪖Gardwal Ri ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">67</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vkontakte-color chart-network-cell post-number-td">
                                <div className="vkontakte-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/id531181670?w=wall531181670_204378"
                                >
                                  Teil II Zudem ist e ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vkontakte-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/news8plus?w=wall-211824226_6736"
                                >
                                  India’s star femin ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">0</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-user line-height16"></i>
                        </div>
                        <div className="chart-title-text">
                          Popular Negative Posts
                        </div>
                      </div>
                      <div id="hashtags1">
                        <table
                          width="100%"
                          className="newsTableBox border-none"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr>
                              <td className="twitter-color chart-network-cell post-number-td">
                                <div className="twitter-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/thecaravanindia/status/1553425192201879553"
                                >
                                  #CaravanColumn | Dec ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">0</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/SubrataC_AITC/status/1553430681119703040"
                                >
                                  @AITCSSMC @ModirKirt ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">0</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/mahakal_ki_bari/status/1553493973561495552"
                                >
                                  @INDVikasS Best CM b ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">0</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/NICKSUKHDEVE1/status/1553437520653795328"
                                >
                                  @RealMaxtern Narendr ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">0</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/ArvindK45218602/status/1553417522938286082"
                                >
                                  @adhirrcinc @IndiaT ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">0</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color chart-network-cell post-number-td">
                                <div className="dailymotion-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crh03"
                                >
                                  മന്ത്ര� ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">3</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color chart-network-cell post-number-td">
                                <div className="reddit-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/r/TIMESINDIAauto/comments/wbqmhu/in_speed_up_release_of_undertrial_prisoners_pm/"
                                >
                                  [IN] - Speed up rele ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/r/AutoNewspaper/comments/wbqrr1/in_speed_up_release_of_undertrial_prisoners_pm/"
                                >
                                  [IN] - Speed up rele ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color chart-network-cell post-number-td">
                                <div className="instagram-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/Cgo8w5HuDbj"
                                >
                                  India's lost cultura ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1869</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpBWbRI0g3"
                                >
                                  In a major crackdown ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">48</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/Cgo7T2DMcEv"
                                >
                                  The Gujarat police a ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">21</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-user line-height16"></i>
                        </div>
                        <div className="chart-title-text">
                          Popular Neutral Posts
                        </div>
                      </div>
                      <div id="hashtags1">
                        <table
                          width="100%"
                          className="newsTableBox border-none"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr>
                              <td className="twitter-color chart-network-cell post-number-td">
                                <div className="twitter-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/metesohtaoglu/status/1553501001449177089"
                                >
                                  📹 Başbakan Naren ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">10</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/markets/status/1553454060438708226"
                                >
                                  Unpaid bills in Indi ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">4</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/Aamir_dangal/status/1553408563317731328"
                                >
                                  #ISupportLaalSinghCh ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">3</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/AgnishwarJ/status/1553382717169057794"
                                >
                                  First ever Agricultu ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">3</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/HindutvaWatchIn/status/1553461927971635201"
                                >
                                  Now it is official t ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color chart-network-cell post-number-td">
                                <div className="dailymotion-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crnht"
                                >
                                  समुद्र � ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">33580</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crl9g"
                                >
                                  Kaushal Raj sharma: ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">12980</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crjps"
                                >
                                  Chess Olympiad 2022 ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">7559</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crho9"
                                >
                                  வெல்கம� ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">6675</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/video/x8crhi9"
                                >
                                  பாஜக கூ� ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">6120</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color chart-network-cell post-number-td">
                                <div className="reddit-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/r/HydrogenSocieties/comments/wbvtrb/india_prime_minister_narendra_modi_urges_states/"
                                >
                                  India: Prime Ministe ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/r/u_mediawala/comments/wc282y/ujjain_news_a_lot_of_chaos_around_the_famous/"
                                >
                                  Ujjain News: A lot o ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/r/AskReddit/comments/wbxt5k/what_you_think_narendra_modi_is_overhyped_or_he/"
                                >
                                  what you think naren ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tumblr-color chart-network-cell post-number-td">
                                <div className="tumblr-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://argusnews.tumblr.com/post/691184510253105152/12-pm-headlines-30-july-2022-argus-news"
                                >
                                  {" "}
                                  12 PM Headlines | 3 ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">0</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color chart-network-cell post-number-td">
                                <div className="instagram-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpIwGEpys8"
                                >
                                  PM Modi called this ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1549</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/Cgo92w6vmn0"
                                >
                                  ಜೈ ಶ್ರೀ ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1080</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpWgkzvjtJ"
                                >
                                  તળાજા ત� ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">999</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/CgpDtKUhxaR"
                                >
                                  સરકારી � ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">945</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="instagram-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/p/Cgo3E4GhVEp"
                                >
                                  PSI Result 🎯 પ ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">826</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vkontakte-color chart-network-cell post-number-td">
                                <div className="vkontakte-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/id448585010?w=wall448585010_91767"
                                >
                                  🔥🔥GAMEQVER CAB ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">5</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vkontakte-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/id266438029?w=wall266438029_14884"
                                >
                                  La fin de l'hégémo ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vkontakte-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/club210926009?w=wall-210926009_6955"
                                >
                                  247 News Around The ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">0</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  </>
}

export default Sentiment_Graph
