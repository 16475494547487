import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { reqData } from "../../common/api";

const Compare = ({
  item,
  index,
  field,
  setField,
  compareField,
  pathname,
  id,
  allData,
  setAllData,
  setLoadingScreen
}) => {
  const [placeholder, showPlaceholder] = useState(true);
  const [data, setData] = useState();
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState();
  const [comparedLoading, setComparedLoading] = useState();
  const searchApi = `https://api.frontend.checkbrand.online/public/api/v1/search_results?search=${item.compareWeb}&cat=${id}`;

  const handleChange = (e, i) => {
    const newData = field.map((fieldItem, index) => {
      if (index === i) {
        return { ...fieldItem, [e.target.name]: e.target.value };
      } else {
        return fieldItem;
      }
    });
    setField(newData);
  };

  const addField = () => {
    const newField = [...field];
    newField.push(compareField);
    setField(newField);
  };

  useEffect(() => {
    let timer;
    const debounceSearch = () => {
      setLoading(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
        axios
          .get(`${searchApi}`)
          .then((res) => {
            console.log("Response is", res.data);
            setSearchData(res.data);
          })
          .catch((error) => {
            console.log("Error is", error);
          });
        setLoading(false);
      }, 2000);
    };

    if (item.compareWeb.length > 0 && item.disabled === false) {
      debounceSearch();
    }
    return () => clearTimeout(timer);
  }, [item]);

  const getInput = (value, i, url) => {
    setLoadingScreen(true)
    const newData = field.map((fieldItem, index) => {
      if (index === i) {
        return { ...fieldItem, compareWeb: value, disabled: true };
      } else {
        return fieldItem;
      }
    });
    setField(newData);
    setSearchData([]);
    const brand_data = `${process.env.REACT_APP_BRAND_DATA}/${url}`;
    reqData(brand_data, setData, "", "", setComparedLoading);
  };

  useEffect(() => {
    if (data?.response) {
      const newData = [...allData];
      newData.push(data.response);
      setAllData(newData);
      if (field.length <= 3) {
        addField();
      }
    }
  }, [data]);

  const deleteData = (index) => {
    if (index === 3 && field.length === 4) {
      const newField = [...field];
      newField.push(compareField);
      newField.splice(index, 1);
      setField(newField);
      const newData = [...allData];
      newData.splice(index + 1, 1);
      setAllData(newData);
    } else {
      const newField = [...field];
      newField.splice(index, 1);
      setField(newField);
      const newData = [...allData];
      newData.splice(index + 1, 1);
      setAllData(newData);
    }
  };

  return (
    <>
      <div className="col" key={index}>
        <div className="compareInputPanel" id="search_form_compair">
          <input
            className="search_input mb-0 compare-input ui-autocomplete-input"
            type="search"
            name="compareWeb"
            autoComplete="off"
            value={item.compareWeb}
            disabled={item.disabled}
            onChange={(e) => handleChange(e, index)}
            onClick={() => showPlaceholder(false)}
          />
          {placeholder && (
            <>
              <div
                className="compareContentArea"
                style={{ display: "block", color: "black !important" }}
              >
                <i className="ri-add-circle-line"></i> Compare
              </div>
            </>
          )}

          {loading && (
            <p
              className="Compairsearchloading text-center"
              style={{ display: "", color: "black !important" }}
            >
              loading...
            </p>
          )}

          {item.disabled ? (
            <div className="close-Area " onClick={() => deleteData(index)}>
              <i className="ri-close-circle-line"></i>
            </div>
          ) : (
            <Link to={pathname + `#`} className="search_icon">
              <i className="ri-search-line"></i>
            </Link>
          )}

          {searchData.length > 0 && (
            <ul
              className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content ui-corner-all"
              id="ui-id-3"
              tabIndex="0"
              style={{ width: "527px" }}
            >
              {searchData?.map((item, searchIndex) => {
                return item.label === "No search results." ? (
                  <li
                    className="ui-menu-item"
                    role="presentation"
                    key={searchIndex}
                  >
                    <a id="ui-id-20" className="ui-corner-all" tabIndex="-1">
                      {item.label}
                    </a>
                  </li>
                ) : (
                  <li
                    className="ui-menu-item"
                    role="presentation"
                    key={searchIndex}
                  >
                    <a
                      id="ui-id-20"
                      className="ui-corner-all"
                      tabIndex="-1"
                      onClick={() => getInput(item.label, index, item.url)}
                    >
                      {item.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default Compare;
