import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { reqData } from "../../common/api";
import { Skeleton } from "@mui/material";
import { Link } from 'react-router-dom'

const NewsArea = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  const slider = React.useRef(null);
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const homeblogsapi = process.env.REACT_APP_HOME_BLOGS;
  const dummyNews=[1,2,3,4,5,6,7,8,9,10]

  function getData() {
    reqData(homeblogsapi, setData, "blogs_news", "", setLoading);
  }

  useEffect(() => {
    getData();
  }, []);

  console.log("THE DATA OF NEWS EDITOR IS",data)

  // console.log("loading is ", loading);
  return (
    <>
      <section className="newsAreaSection addedHeight">
        <div className="container">
          <div className="col-12">
            <div className="block-text text-center">
              <h6 className="sub-heading">
                <span>Our Editorials</span>
              </h6>
              <h3 className="heading">IN THE NEWS</h3>
            </div>
          </div>
        </div>

        <div className="slider slick-activation-01 slick-gutter-15 slick-gutter-10 slick-pagination-50 slick-arrow-top slick-initialized slick-slider">
          
          <button className="slide-arrow prev-arrow" onClick={() => slider?.current?.slickPrev()}>
            <i className="ri-arrow-left-line"></i>
          </button>
          <button className="slide-arrow next-arrow" onClick={() => slider?.current?.slickNext()}>
            <i className="ri-arrow-right-line"></i>
          </button>
          <Slider ref={slider}  {...settings}>
            {loading ? 
            dummyNews.map((item,index)=>{
              return <>
               <div key={index}>
                <div
                  className="explore-style-one slick-slide slick-cloned"
                  style={{ width: "350px" }}
                  data-slick-index="-2"
                  id=""
                  aria-hidden="true"
                  tabIndex="-1"
                >
                  <div className="thumb">
                    <Link tabIndex="-1">
                    <Skeleton variant="square"  height={200} animation="wave" />
                    </Link>
                    <div
                      className="countdown btn btn-gradient btn-small"
                      data-date="2022-10-10"
                      style={{ display: "none" }}
                    ></div>
                    <button className="reaction-btn" tabIndex="-1">
                      <i className="ri-heart-fill"></i>
                      <span>08</span>
                    </button>
                  </div>
                  <div className="content newsArea">
                    <div className=" d-flex-between pt-4 pb-1">
                      <h3 className="title">
                        <a href="#" tabIndex="-1">
                          <Skeleton variant="text" width={230} height={20} animation="wave" />
                        </a>
                      </h3>
                    </div>
                    <div className="product-share-wrapper">
                      <div className="profile-share d-flex-center">
                        <p><Skeleton variant="text" width={300} height={20} animation="wave" /></p>
                      </div>
                    </div>
                    <div className="action-wrapper d-flex-between pt-2">
                      <div className="IcolorBox ">
                        <Skeleton variant="text" width={80} animation="wave" />
                      </div>
                      <div className="IcolorBox">
                      <Skeleton variant="text" width={80} animation="wave" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </>
            }) : data?.recent_posts.map((item, index) => {
              return <>
              <Link to={`/blog_details/${item.slug}`}>
                <div key={index}>
                  <div
                    className="explore-style-one slick-slide slick-cloned"
                    style={{ width: "350px" }}
                    data-slick-index="-2"
                    id=""
                    aria-hidden="true"
                    tabIndex="-1"
                  >
                    <div className="thumb">
                        <img
                          src={data.image_path + item.photo_file}
                          alt="nft live auction thumbnail"
                        />
                      <div
                        className="countdown btn btn-gradient btn-small"
                        data-date="2022-10-10"
                        style={{ display: "none" }}
                      ></div>
                      {/* <!-- End .count-down --> */}
                      <button className="reaction-btn" tabIndex="-1">
                        <i className="ri-heart-fill"></i>
                        <span>08</span>
                      </button>
                      {/* <!-- End .reaction-count --> */}
                    </div>
                    {/* <!-- End .thumb --> */}
                    <div className="content newsArea">
                      <div className=" d-flex-between pt-4 pb-1">
                        <h3 className="title">
                          <a  tabIndex="-1">
                            {item.title_en}
                          </a>
                        </h3>
                      </div>
                      {/* <!-- .header --> */}
                      <div className="product-share-wrapper">
                        <div className="profile-share d-flex-center">
                          <p>{item.details_en}</p>
                        </div>
                      </div>
                      {/* <!-- End product-share-wrapper --> */}
                      {/* <!-- End .product-owner --> */}
                      <div className="action-wrapper d-flex-between pt-2">
                        <div className="IcolorBox ">
                          <i className="ri-time-line"></i> May 5, 2023
                        </div>
                        <div className="IcolorBox">
                          <i className="ri-thumb-up-line"></i> {item.stats.up}
                          <i className="ri-thumb-down-line"></i>{" "}
                          {item.stats.down}{" "}
                        </div>
                      </div>
                      {/* <!-- action-wrapper --> */}
                    </div>
                    {/* <!-- End .content --> */}
                  </div>
                </div>
                </Link>
              </>
            })}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default NewsArea;
