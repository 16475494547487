import React from 'react'

const Links_Graph = (active) => {
  return <>
  <div
                className={`tab-pane active`}
                id="links-home"
                role="tabpanel"
                aria-labelledby="links-home-tab"
              >
                <div className="generalstats-wrap mb-4">
                  <ul className="generalstats">
                    <li className="active">
                      <span className="current">Links 962</span>
                    </li>
                    <li>
                      <span className="current">Domains 76</span>
                    </li>
                  </ul>
                </div>
                <div className="charts-one-wrap">
                  <div className="chart-title">
                    <div className="statistics-icon chart-icon">
                      <i className="fa fa-user line-height16"></i>
                    </div>
                    <div className="chart-title-text">Domains</div>
                  </div>
                  <div className="row">
                    <div className="col-md-7" id="hashtags">
                      <table
                        width="100%"
                        className="newsTableBox"
                        id="mytable"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <thead>
                          <tr>
                            <th scope="col" className="post-number">
                              #
                            </th>
                            <th scope="col">Domain</th>
                            <th scope="col" className="post-medium-col">
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="odd">
                            <td>1</td>
                            <td>Facebook</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>2</td>
                            <td>Twitter</td>
                            <td>73</td>
                          </tr>
                          <tr className="odd">
                            <td>3</td>
                            <td>Googleplus</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>4</td>
                            <td>Dailymotion</td>
                            <td>2</td>
                          </tr>
                          <tr className="odd">
                            <td>5</td>
                            <td>Vimeo</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>6</td>
                            <td>Reddit</td>
                            <td>3</td>
                          </tr>
                          <tr className="odd">
                            <td>7</td>
                            <td>Tumblr</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>8</td>
                            <td>Instagram</td>
                            <td>1</td>
                          </tr>
                          <tr className="odd">
                            <td>9</td>
                            <td>Youtube</td>
                            <td>1</td>
                          </tr>
                          <tr className="odd">
                            <td>10</td>
                            <td>Web</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>11</td>
                            <td>Vine</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>12</td>
                            <td>Vkontakte</td>
                            <td>2</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-5">
                      <div
                        id="DomainsChartArea"
                        style={{ height: "380px", width: "380px" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-user line-height16"></i>
                        </div>
                        <div className="chart-title-text">Popular Links</div>
                      </div>
                      <div id="hashtags">
                        <table
                          width="100%"
                          className="newsTableBox border-none"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr className="odd">
                              <td>1</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://trib.al/S8hA6tv"
                                >
                                  Trib.al/S8hA6tv
                                </a>
                              </td>
                              <td>4</td>
                            </tr>
                            <tr className="odd">
                              <td>2</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.suasnews.com/2022/07/first-ever-agriculture-drone-loan-sanctioned-for-garuda-aerospace-kisan-drone/"
                                >
                                  Suasnews.com/2022/07/first-…
                                </a>
                              </td>
                              <td>3</td>
                            </tr>
                            <tr className="odd">
                              <td>3</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.thequint.com/entertainment/celebrities/narendra-modi-reveals-chinese-president-xi-jinping-aamir-khans-dangal-on-babita-phogat"
                                >
                                  Thequint.com/entertainment/…
                                </a>
                              </td>
                              <td>3</td>
                            </tr>
                            <tr className="odd">
                              <td>4</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/narendramodi/status/1533124770874675200?lang=en"
                                >
                                  Twitter.com/narendramodi/s…
                                </a>
                              </td>
                              <td>3</td>
                            </tr>
                            <tr className="odd">
                              <td>5</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://economictimes.indiatimes.com/news/india/speed-up-release-of-undertrial-prisoners-pm-narendra-modi/articleshow/93227977.cms"
                                >
                                  Economictimes.indiatimes.com/news/india/spe…
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>6</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://hindi.news18.com/news/business/national-flag-demand-increases-in-india-after-pm-narendra-modi-calls-har-ghar-tiranga-abhiyan-dlpg-4428198.html"
                                >
                                  Hindi.news18.com/news/business/…
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>7</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://hindutvawatch.org/its-official-now-govt-reduced-minority-welfare-fund/"
                                >
                                  Hindutvawatch.org/its-official-n…
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>8</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/BJP4India"
                                >
                                  Twitter.com/BJP4India
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>9</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://youtu.be/cMjxpmfOPKI"
                                >
                                  Youtu.be/cMjxpmfOPKI
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>10</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.facebook.com/BJP4Delhi"
                                >
                                  Facebook.com/BJP4Delhi
                                </a>
                              </td>
                              <td>1</td>
                            </tr>
                            <tr className="odd">
                              <td>11</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://bit.ly/ArgusNewsiOSApp"
                                >
                                  Http://bit.ly/ArgusNewsiOSApp
                                </a>
                              </td>
                              <td>4</td>
                            </tr>
                            <tr className="odd">
                              <td>12</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/user/LokmatNews?sub_confirmation=1"
                                >
                                  Https://www.youtube.com/user/LokmatNews?sub_confirmation=1
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>13</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://youtu.be/E7vvEsI2nJQ"
                                >
                                  Https://youtu.be/E7vvEsI2nJQ
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>14</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/watch?v=tLQA8zJhogU"
                                >
                                  Final Solution
                                </a>
                              </td>
                              <td>5</td>
                            </tr>
                            <tr className="odd">
                              <td>15</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://timesofindia.indiatimes.com/india/speed-up-release-of-undertrial-prisoners-pm-narendra-modi/articleshow/93227973.cms"
                                >
                                  Https://timesofindia.indiatimes.com/india/speed-up-release-of-undertrial-prisoners-pm-narendra-modi/articleshow/93227973.cms
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>16</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.ndtv.com/india-news/pm-narendra-modi-urges-states-to-clear-power-sector-dues-launches-2-green-hydrogen-plants-3208639"
                                >
                                  Https://www.ndtv.com/india-news/pm-narendra-modi-urges-states-to-clear-power-sector-dues-launches-2-green-hydrogen-plants-3208639
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>17</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://youtu.be/m-xvH5ugELA"
                                >
                                  Https://youtu.be/m-xvH5ugELA
                                </a>
                              </td>
                              <td>33</td>
                            </tr>
                            <tr className="odd">
                              <td>18</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://t.co/i04Fv2owtW"
                                >
                                  Https://t.co/i04Fv2owtW
                                </a>
                              </td>
                              <td>1</td>
                            </tr>
                            <tr className="odd">
                              <td>19</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://youtu.be/DKxS7_BBMNg"
                                >
                                  Https://youtu.be/DKxS7_BBMNg
                                </a>
                              </td>
                              <td>0</td>
                            </tr>
                            <tr className="odd">
                              <td>20</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.welt.de/politik/ausland/plus240085177/Ringen-um-die-Weltordnung-Es-steht-eine-Zeit-grosser-Instabilitaet-bevor.html"
                                >
                                  Ringen Um Die Weltordnung: Es Steht Eine Zeit
                                  Großer Instabilität Bevor - WELT
                                </a>
                              </td>
                              <td>2</td>
                            </tr>
                            <tr className="odd">
                              <td>21</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://m.vk.com/@news8plus-rss-825433538-1590318526"
                                >
                                  Commonwealth Games: Mirabai Chanu Won India’s
                                  First Gold, PM Modi Tweeted Congratulations
                                </a>
                              </td>
                              <td>0</td>
                            </tr>
                            <tr className="odd">
                              <td>22</td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://m.vk.com/@-210926009-rss-547934502-1505553292"
                                >
                                  Vice President Venkaiah Naidu To New MPs
                                </a>
                              </td>
                              <td>0</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  </>
}

export default Links_Graph
