import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { reqData } from "../../common/api";
import Rating from "@mui/material/Rating";

const Top100_Child = ({ item, index, data }) => {
  const [show, setShow] = useState(false);

  const handleButtonClick = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    setShow(!show);
  };

  return (
    <>
      <Link to={`/dashboard/${item.purl}`}>
        <div className="top-seller-style-five" key={index}>
          <div className="d-flex align-items-center gap-4">
            <>
              <div className="items-number d-flex-center flex-column position-relative lineRt h-Area w-10">
                <span className="cpName">Company</span>
                <strong>{item.rnk}</strong>
                <div
                  className="IcolorBox mt-2"
                  style={{ display: "inline-flex" }}
                >
                  <i className="ri-thumb-up-line"></i> {item.lc}{" "}
                  <i className="ri-thumb-down-line ml-1"></i> {item.dlc}
                </div>
              </div>

              <div className="thumb-wrapper flex-column position-relative lineRt h-Area w-11">
                <a href="#" className="thumb mb-1">
                  <img src={data.image_path + item.dp} alt="top sellter" />
                </a>

                {/* <!-- <span className="cpName">Company</span> --> */}

                {/* <!-- <button type="button" id="#" className="compareBtn">
                                              <i className="ri-scales-3-line"></i>
                                              </button> --> */}
              </div>

              <div className="content lineRt position-relative h-Area w-12">
                <h4 className="title pb-1">
                  <a href="#">{item.name}</a>
                  {/* <!--  <span className="cpName">Company</span> --> */}
                </h4>

                <div className="d-flex gap-3">
                  <div
                    className="IcolorBox greenIcon"
                    style={{ display: "inline-flex" }}
                  >
                    <i className="ri-arrow-up-line"></i> {item.gn.val}%
                  </div>
                  <div
                    className="IcolorBox brandScorePanel"
                    style={{ display: "inline-flex" }}
                  >
                    <i className="ri-bar-chart-line"></i> {Math.floor(item.sc)}
                    /100
                  </div>
                </div>
              </div>

              <div className="content flex-column text-center lineRt position-relative h-Area w-13">
                <div className="NoBox" style={{ display: "inline-flex" }}>
                  {item.bv}
                </div>

                <div className="NameBox" style={{ display: "inline-flex" }}>
                  <i className="ri-bookmark-line"></i> Value
                </div>
              </div>

              <div className="content flex-column text-center lineRt position-relative h-Area w-14">
                <div className="NoBox" style={{ display: "inline-flex" }}>
                  {item.sc}
                </div>

                <div className="NameBox" style={{ display: "inline-flex" }}>
                  <i className="ri-line-chart-line"></i> Score
                </div>
              </div>

              <div className="content flex-column text-center lineRt position-relative h-Area w-15">
                <div className="NoBox" style={{ display: "inline-flex" }}>
                  {(item?.sentiments_data?.gt / 1000).toFixed(2)} <span>K</span>
                </div>

                <div className="NameBox" style={{ display: "inline-flex" }}>
                  <i className="ri-pie-chart-line"></i> Trends
                </div>
              </div>

              <div className="content flex-column text-center lineRt position-relative h-Area w-16">
                <div className="NoBox" style={{ display: "inline-flex" }}>
                  {(item?.sentiments_data?.mn / 1000).toFixed(2)} <span>K</span>
                </div>

                <div className="NameBox" style={{ display: "inline-flex" }}>
                  <i className="ri-at-line"></i> Mentions
                </div>
              </div>

              <div className="content flex-column text-center position-relative lineRt h-Area w-17">
                <div className="NameBox">
                  {/* {item.rt > 0  ? Array(item.rt).fill("_").map((item,index)=>{ */}
                  {/* return  */}
                  {item.rt > 0 ? (
                    <Rating
                      name="read-only"
                      value={item.rt}
                      readOnly
                      size="small"
                    />
                  ) : (
                    <Rating
                      name="read-only"
                      value={item.rt}
                      readOnly
                      size="small"
                    />
                  )}
                  {/* :  */}
                </div>

                <div className="NameBox" style={{ display: "inline-flex" }}>
                  Rating
                </div>
              </div>

              <ul className="social" style={{ flexDirection: "column" }}>
                <li>
                  <Link to={item.fb}>
                    <i className="ri-facebook-line"></i>
                  </Link>
                </li>
                <li>
                  <Link to={item.tw}>
                    <i className="ri-twitter-line"></i>
                  </Link>
                </li>
                <li>
                  <Link to={item.ln}>
                    <i className="ri-linkedin-line"></i>
                  </Link>
                </li>
                <li>
                  <Link to={item.inl}>
                    <i className="ri-instagram-line"></i>
                  </Link>
                </li>
              </ul>
            </>

            <button
              type="button"
              className="readAllBtn"
              onClick={handleButtonClick}
            >
              <span className={show && "top_100_description"}>
                <i className="ri-add-line"></i>
              </span>
            </button>
          </div>
          {/* <!-- show Box --> */}
          {show && (
            <div className="ViewBoxArea">
              <h4>
                {item.name}
                {/* <span> Company </span> */}
              </h4>
              <p>{item.desc}</p>
            </div>
          )}
          {/* <!-- show Box --> */}
        </div>
      </Link>
    </>
  );
};

export default Top100_Child;
