import React from 'react'

const Users_Graph = (active) => {
  return <>
  <div
                className={`tab-pane active`}
                id="Users-home"
                role="tabpanel"
                aria-labelledby="Users-home-tab"
              >
                <div className="charts-one-wrap">
                  <div className="chart-title">
                    <div className="statistics-icon chart-icon">
                      <i className="fa fa-user line-height16"></i>
                    </div>
                    <div className="chart-title-text">Users</div>
                  </div>
                  <div className="row">
                    <div className="col-md-7" id="hashtags">
                      <table
                        width="100%"
                        className="newsTableBox"
                        id="mytable"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <thead>
                          <tr>
                            <th scope="col" className="post-number">
                              #
                            </th>
                            <th scope="col">Network</th>
                            <th scope="col" className="post-medium-col">
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="odd">
                            <td>1</td>
                            <td>Facebook</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>2</td>
                            <td>Twitter</td>
                            <td>1112</td>
                          </tr>
                          <tr className="odd">
                            <td>3</td>
                            <td>Googleplus</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>4</td>
                            <td>Dailymotion</td>
                            <td>15</td>
                          </tr>
                          <tr className="odd">
                            <td>5</td>
                            <td>Vimeo</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>6</td>
                            <td>Reddit</td>
                            <td>6</td>
                          </tr>
                          <tr className="odd">
                            <td>7</td>
                            <td>Tumblr</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>8</td>
                            <td>Instagram</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>9</td>
                            <td>Youtube</td>
                            <td>87</td>
                          </tr>
                          <tr className="odd">
                            <td>10</td>
                            <td>Web</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>11</td>
                            <td>Vine</td>
                            <td>0</td>
                          </tr>
                          <tr className="odd">
                            <td>12</td>
                            <td>Vkontakte</td>
                            <td>6</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-5">
                      <div
                        id="UsersChartBox"
                        style={{ height: "380px", width: "380px" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-user line-height16"></i>
                        </div>
                        <div className="chart-title-text">Popular Users</div>
                      </div>
                      <div id="hashtags">
                        <table
                          width="100%"
                          className="newsTableBox border-none"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr>
                              <td className="twitter-color chart-network-cell post-number-td">
                                <div className="twitter-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/metesohtaoglu"
                                >
                                  Mete Sohtaoğlu ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">17</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/markets"
                                >
                                  Bloomberg Markets ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">4</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/Aamir_dangal"
                                >
                                  . ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">3</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/AgnishwarJ"
                                >
                                  AgnishwarJayaprakash ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">3</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/jaiprakashshah2"
                                >
                                  Jai Prakash Shah ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">3</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/ashleyforsoil"
                                >
                                  ashley byers #SaveSo ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">3</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/hardoi_bjp"
                                >
                                  BJP Hardoi ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/EconomicTimes"
                                >
                                  Economic Times ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/HindutvaWatchIn"
                                >
                                  HindutvaWatch ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/GeneralBakshi"
                                >
                                  Maj Gen (Dr)GD Baksh ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/amarujala"
                                >
                                  Amar Ujala ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">833464</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/oneindiatamil"
                                >
                                  Oneindia Tamil ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">299048</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/oneindiahindi"
                                >
                                  Oneindia Hindi | व ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">188</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/arynews"
                                >
                                  ARY NEWS ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">42</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/hwnewsnetwork"
                                >
                                  HW News Network ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">290</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/aajtak"
                                >
                                  Aaj Tak ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">48</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/mediaonetv"
                                >
                                  MediaOne TV ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">54</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/indianexpress"
                                >
                                  The Indian Express ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">179</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/hwenglish"
                                >
                                  HW News English ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">260</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/indiatoday"
                                >
                                  India Today ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">23</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/user/888gooner"
                                >
                                  888gooner ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">5</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/user/chopchopped"
                                >
                                  chopchopped ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/user/AutoNewsAdmin"
                                >
                                  AutoNewsAdmin ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">11</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/user/AutoNewspaperAdmin"
                                >
                                  AutoNewspaperAdmin ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">12</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/user/mediawala"
                                >
                                  mediawala ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="reddit-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://www.reddit.com/user/titan__09"
                                >
                                  titan__09 ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vkontakte-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/id448585010"
                                >
                                  Feďofeďo Šuba ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">5</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vkontakte-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/id266438029"
                                >
                                  Boris Maslennikov ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vkontakte-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/id531181670"
                                >
                                  Birgit Kugler ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vkontakte-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/wesselyivanowitsch"
                                >
                                  Pjotr Wesselyivanowi ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">1</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vkontakte-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/club210926009"
                                >
                                  May Thet Htar Swe ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">0</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="vkontakte-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="http://vk.com/news8plus"
                                >
                                  News8Plus ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">0</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="charts-one-wrap">
                      <div className="chart-title">
                        <div className="statistics-icon chart-icon">
                          <i className="fa fa-user line-height16"></i>
                        </div>
                        <div className="chart-title-text">Active Users</div>
                      </div>
                      <div id="hashtags1">
                        <table
                          width="100%"
                          className="newsTableBox border-none"
                          id="mytable"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr>
                              <td className="twitter-color chart-network-cell post-number-td">
                                <div className="twitter-network-img"></div>
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/StmSunil"
                                >
                                  SUNIL Stm ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">10</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/ABHIRAM59725388"
                                >
                                  ABHIRAMI R ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">9</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/AJEESHKVIJAYAN2"
                                >
                                  AJEESH K VIJAYAN ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">9</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/Abhishekajgra"
                                >
                                  Abhishek Tiwari ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">9</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/BipinNa75334248"
                                >
                                  Bipin Nayak ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">9</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/bjp4jain2"
                                >
                                  Bjp4jain2 ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">9</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/KarthikaBNair4"
                                >
                                  Karthika B Nair ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">9</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/MehrauliK"
                                >
                                  Mehrauli Kutub ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">9</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/linda_newmai"
                                >
                                  NE BJP ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">9</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="twitter-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://twitter.com/OfficeRishikesh"
                                >
                                  Office of Rishikesh ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">9</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/oneindiatamil"
                                >
                                  Oneindia Tamil ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">24</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/hwnewsnetwork"
                                >
                                  HW News Network ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">18</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/oneindiahindi"
                                >
                                  Oneindia Hindi | व ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">5</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/mediaonetv"
                                >
                                  MediaOne TV ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">6</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/aajtak"
                                >
                                  Aaj Tak ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">4</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/amarujala"
                                >
                                  Amar Ujala ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">7</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/indiatoday"
                                >
                                  India Today ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">4</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/lokmatonline"
                                >
                                  Lokmat ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="dailymotion-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.dailymotion.com/v6newstelugu"
                                >
                                  V6 News Telugu ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">5</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="youtube-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UC1NF71EwP41VdjAU1iXdLkw"
                                >
                                  Narendra Modi ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">16</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="youtube-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UCexbSmjqDwdfsaKXk8hnswQ"
                                >
                                  A.s Just fun ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">4</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="youtube-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UCzkdNXreN5sqOO7sBvQUgGQ"
                                >
                                  TV 9 इंडिय ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">6</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="youtube-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UCOutOIcn_oho8pyVN3Ng-Pg"
                                >
                                  TV9 Bharatvarsh ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">3</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="youtube-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UCt4t-jeY85JegMlZ-E5UWtA"
                                >
                                  Aaj Tak ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="youtube-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UClmlqOOktUTpfW0EkTiqSjQ"
                                >
                                  Aaj Tak HD ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="youtube-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UC_cZYFovNQTsKw1ZLmO_AMA"
                                >
                                  First India News ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="youtube-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UCm7lHFkt2yB_WzL67aruVBQ"
                                >
                                  Hindustan Times ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="youtube-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UCttspZesZIDEwwpVIgoZtWQ"
                                >
                                  IndiaTV ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">10</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="youtube-color"></td>
                              <td>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UCDqZJ76kzj3u_ZW5fkrnysg"
                                >
                                  Modi ki awaaz ....
                                </a>
                              </td>
                              <td className="post-large-td">
                                <div className="like-icon">2</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  </>
}

export default Users_Graph
