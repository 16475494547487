import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { data as mockData } from "../../common/mockData";
import Brand_rank from "./Brand_rank";
import Web_rank from "./Web_rank";
const Banner = ({ setShowWeb }) => {
  return (
    <>
      <section className="bannerSection">
        <img
          src="images/banner-icon-1-1.png"
          alt="Awesome Image"
          className="bubble-1"
        />
        <img
          src="images/banner-icon-1-3.png"
          alt="Awesome Image"
          className="bubble-2"
        />
        <img
          src="images/banner-icon-1-2.png"
          alt="Awesome Image"
          className="bubble-3"
        />
        <img
          src="images/banner-icon-1-4.png"
          alt="Awesome Image"
          className="bubble-4"
        />
        <img
          src="images/banner-icon-1-5.png"
          alt="Awesome Image"
          className="bubble-5"
        />
        <img
          src="images/banner-icon-1-6.png"
          alt="Awesome Image"
          className="bubble-6"
        />
        <div className="container-fluid">
          <ul className="nav custom-tabs banner-tabs p-0 d-flex">
            <li>
              <Link
                className="active"
                data-bs-toggle="tab"
                to="#digitalRank-sale"
                onClick={() => setShowWeb(false)}
              >
                Check Your Brand
              </Link>
            </li>
            <li>
              {/* <Link
                className=""
                data-bs-toggle="tab"
                to="#webRank-sale"
                onClick={() => setShowWeb(true)}
              >
                Check Your Website
              </Link> */}
            </li>
          </ul>

          <div className="tab-content banner-tabs-content mt-23">
           <Brand_rank />
            <Web_rank />
          </div>
        </div>
      </section>

      <div className="bannerButtomArea"></div>
    </>
  );
};

export default Banner;
