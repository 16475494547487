import React from 'react';
import FaqTab from '../../../common/FaqTab';
import Common_Tab from '../../../common/Common_Tab';

const Content_Information = ({activeTab,dashData,loading}) => {
  const questions = [
    {
      id: 1,
      title:
        "Is the website content are regularly updated with latest govt services, initiatives, policies & regulations, news and events (weekly, biweekly, monthly, month+)",
      description: "",
      ariaControls: "first",
    },
    {
      id: 2,
      title:
        "Does the website present information in a clear and understandable manner that can be understood especially by people with lower secondary education level reading ability such as text summary, visual illustrations",
      description: "",
      ariaControls: "second",
    },
    {
      id: 3,
      title:
        "Does the website provide multilingual support, allowing users to access content in different languages? (0)One Language (1) Bilingual (2) Multilingual",
      description: "",
      ariaControls: "third",
    },
    {
      id: 4,
      title:
        "Is the content free from spelling and grammatical mistakes? ",
      description: "",
      ariaControls: "fourth",
    },
    {
      id: 5,
      title:
        "Are relevant and descriptive hyperlinks used throughout the content?",
      description: "",
      ariaControls: "fifth",
    },
    {
      id: 6,
      title:
        "Does the website provide a content search functionality with robust features like sorting and filtering?",
      description: "",
      ariaControls: "sixth",
    },
    {
      id: 7,
      title:
        "Does the content make effective use of multimedia elements, such as, images, audio, video",
      description: "",
      ariaControls: "seventh",
    },
    {
      id: 8,
      title:
        "Website has a About Us page",
      description: "",
      ariaControls: "eight",
    },
    {
      id: 9,
      title:
        "Website has a Contact Us page",
      description: "",
      ariaControls: "ninth",
    },
    {
      id: 10,
      title:
        "Is the organizational structure and hierarchy of the department available on the website?",
      description: "",
      ariaControls: "tenth",
    },
    {
      id: 11,
      title:
        "Are the priorities, scope of the department, programmes, flagship schemes outlined on the website?",
      description: "",
      ariaControls: "eleven",
    },
    {
      id: 12,
      title:
        "Are key performance indicators available for programmes and organisations?",
      description: "",
      ariaControls: "tweleve",
    },
    {
      id: 13,
      title:
        "Does the website have relevant policies like Copyright Policy, Terms & Conditions",
      description: "",
      ariaControls: "thirteen",
    },
    {
      id: 14,
      title:
        "Is the website content optimized for SEO and are the practices being followed?",
      description: "",
      ariaControls: "fourteen",
    },
    {
      id: 15,
      title:
        "Are site analytics being used to identify visitor behaviour patterns and opportunities for improvement?",
      description: "",
      ariaControls: "fifteen",
    },
  ];
  return <>
  <FaqTab activeTab={activeTab} dashData={dashData} questions={questions} />
  <Common_Tab dashData={dashData} loading={loading} />
  </>
}

export default Content_Information
